@import "../../../styles/standard.module.scss";
.assessment_modal {
    h5 {
        font-size: 18px;
        font-weight: normal;
    }
}

.spacing {
    padding: 10px;
}
.upload_label {
    margin-right: 67px;
    font-family: $secondary-font-bold;
}
.custom_wrapper {
    width: 212px !important;
    height: 212px !important;
    object-fit: inherit !important;
    border-radius: 0px !important;
    display: inline-block;
    align-items: inherit !important;
    justify-content: inherit !important;
    margin-bottom: 48px;
}
.browse_btn {
    border-radius: 2px;
    border: solid 1px #cccccc;
    background-color: #fafafa;
    padding: 10px 0px;
    width: 140px;
    text-align: center;
    text-decoration: none !important;
    margin-left: 0px !important;
    font-family: $secondary-font-regular;
    font-weight: 500 !important;
    color: #333333 !important;
    margin-bottom: 56px !important;
}
.browse_btn:hover {
    cursor: pointer;
}
.btn_add,
.btn_add:hover {
    width: 155px;
    height: 42px;
    margin: 0;
    padding: 9px 16px 9px 9px;
    border-radius: 2px;
    background-color: $PrimaryButton-bg;
    font-family: $secondary-font-regular;
    color: $Plain-bg;
    font-size: $Typography-2;
}
.label_spec {
    margin-bottom: 0;
    font-family: $secondary-font-bold;
}
.col_spec {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
    display: flex;
    flex-wrap: wrap;
}
.select_wrapper {
    border-radius: 2px;
    border: solid 1px $border-color;
    height: 40px;
    background: url("../../../static/images/down-arrow.png") right 10px center no-repeat;
}
.basic_select {
    margin: 0;
    width: 100%;
    height: 40px;
    padding: 0 10px;
    appearance: none;
    -webkit-appearance: none;
    outline: 0;
    border-radius: 2px;
    border: solid 0px $border-color;
    background: none;
    option {
        font-size: 14px;
        font-weight: bold;
        color: #333;
    }
    optgroup {
        font-size: 14px;
        option {
            font-weight: normal;
        }
    }
}
.basic_textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}

.btn_custom {
    padding: 0;
    width: 140px;
    height: 48px;
    border-radius: 2px;
    font-family: $secondary-font-regular;
    background-color: #0052cc;
    font-size: 18px;
}

.btn_small,
.btn_small:hover {
    width: 140px;
    height: 48px;
    font-family: $secondary-font-regular;
    background-color: #0052cc;
    color: #ffffff;
    font-size: 16px;
}

div[role="toolbar"] {
    background: transparent !important;
}
button[name="select-tool-zoom-in"],
button[name="select-tool-zoom-out"],
button[name="unselect-tools"],
button[name="select-tool-pan"],
button[name="fit-to-viewer"] {
    > svg {
        stroke-width: 0.01em;
        fill: #666;
    }
    &:hover {
        > svg {
            fill: #aaa;
        }
    }
    &:focus {
        > svg {
            fill: #111;
        }
    }
}

.svg_outer {
    padding: 0px 10%;
    &:first-child {
        border-right: 1px solid #ddd;
    }
    rect {
        fill: #fff !important;
        stroke: #fff !important;
    }
}

.notes_label {
    span {
        font-family: $secondary-font-regular;
    }
}

.left_right {
    position: relative;
    z-index: 100000;
    text-align: center;
    > span {
        display: block;
        width: 25px;
        height: 25px;
        background-color: #888;
        color: #fff;
        border-radius: 100%;
        font-size: 13px;
        line-height: 25px;
    }
    > span:first-child {
        position: absolute;
        top: 50px;
        left: -15px;
    }
    > span:last-child {
        position: absolute;
        top: 50px;
        right: -15px;
    }
}
// Styles for loader
.loader_position {
    margin-bottom: 10px;
}
.loader_img {
    max-width: 50px;
}

.basic_input {
    width: 250px;
    height: 40px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}

.btn_small_cancel,
.btn_small_cancel:hover {
    width: 140px;
    height: 48px;
    font-family: $secondary-font-regular;
    background-color: $Plain-bg;
    border: 1px solid $bdr-color-tertiary !important;
    color: $PrimaryColor-text;
    font-size: $Typography-2;
    margin-right: 16px;
}

.custom_modal_size {
    max-width: 1004px;
}
.label_bold {
    font-family: $secondary-font-bold;
}

.risk_content {
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.risk_content .col_spec {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
    display: flex;
}
.label_spec span {
    margin-bottom: 0;
    font-family: $secondary-font-regular;
    font-size: 16px;
    line-height: normal;
}

.risk_select_box {
    width: 100%;
    height: 40px;
    margin: 4px 0 0 0px;
    padding: 0;
    border-radius: 2px;
    border: solid 1px #c1c7d0;
    background-color: #ffffff;
}
.risk_select_box .dropdown_toggle {
    width: 100% !important;
    background-color: #fff !important;
    color: #333 !important;
    text-align: left !important;
}
.risk_drop_down {
    width: 100% !important;
    max-height: 320px !important;
    overflow-y: auto !important;
}
.risk_drop_down .risk_item_wrap {
    white-space: normal !important;
    padding: 5px 10px !important;
}
.risk_select_box .drop_arrow:after {
    border: 0 !important;

    background: url("../../../static/images/down-arrow.png") right 10px center no-repeat !important;
}

.popup_broden {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.none_risk {
    background: RGBA(54, 179, 126, 0.05);
}
.mild_risk {
    background: RGBA(255, 196, 0, 0.05);
}
.high_risk {
    background: RGBA(222, 53, 11, 0.05);
}

.wound_none,
.wound_mild,
.wound_moderate,
.wound_high,
.wound_veryhigh {
    display: flex;
    list-style: none;
    padding-left: 0;
    position: relative;
    text-align: center;
}
.wound_none {
    background: RGBA(54, 179, 126, 0.1);
}
.wound_mild {
    background: rgba(255, 196, 0, 0.1);
}
.wound_moderate {
    background: rgba(255, 196, 0, 0.1);
}
.wound_high {
    background: rgba(222, 53, 11, 0.1);
}
.wound_veryhigh {
    background: rgba(222, 53, 11, 0.1);
}
.wound_none li {
    color: #333333;
    font-family: $secondary-font-bold;
    font-size: 16px;
    padding: 0;
    background-color: #fff;
    border: 2px solid #36b37e;
    border-radius: 4px;
    margin-right: 12px;
    position: relative;
    width: 36px;
    height: 32px;
}
.wound_none li.active {
    background-color: #36b37e;
    color: #fff;
}

.wound_none li.active:after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15.6px 9px 0 9px;
    border-color: #36b37e transparent transparent transparent;
    position: absolute;
    left: 8px;
    bottom: -12px;
}
.wound_mild li {
    color: #333333;
    font-family: $secondary-font-bold;
    font-size: 16px;
    padding: 0;
    background-color: #fff;
    border: 2px solid #ffc400;
    border-radius: 4px;
    margin-right: 12px;
    position: relative;
    width: 36px;
    height: 32px;
}
.wound_mild li.active {
    background-color: #ffc400;
    color: #fff;
}

.wound_mild li.active:after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15.6px 9px 0 9px;
    border-color: #ffc400 transparent transparent transparent;
    position: absolute;
    left: 8px;
    bottom: -12px;
}
.wound_moderate li {
    color: #333333;
    font-family: $secondary-font-bold;
    font-size: 16px;
    padding: 0;
    background-color: #fff;
    border: 2px solid #fa9e00;
    border-radius: 4px;
    margin-right: 12px;
    position: relative;
    width: 36px;
    height: 32px;
}
.wound_moderate li.active {
    background-color: #fa9e00;
    color: #fff;
}

.wound_moderate li.active:after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15.6px 9px 0 9px;
    border-color: #fa9e00 transparent transparent transparent;
    position: absolute;
    left: 8px;
    bottom: -12px;
}
.wound_high li {
    color: #333333;
    font-family: $secondary-font-bold;
    font-size: 16px;
    padding: 0;
    background-color: #fff;
    border: 2px solid #f56076;
    border-radius: 4px;
    margin-right: 12px;
    position: relative;
    width: 36px;
    height: 32px;
}
.wound_high li.active {
    background-color: #f56076;
    color: #fff;
}

.wound_high li.active:after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15.6px 9px 0 9px;
    border-color: #f56076 transparent transparent transparent;
    position: absolute;
    left: 8px;
    bottom: -12px;
}
.wound_veryhigh li {
    color: #333333;
    font-family: $secondary-font-bold;
    font-size: 16px;
    padding: 0;
    background-color: #fff;
    border: 2px solid #de350b;
    border-radius: 4px;
    margin-right: 12px;
    position: relative;
    width: 36px;
    height: 32px;
}
.wound_veryhigh li.active {
    background-color: #de350b;
    color: #fff;
}

.wound_veryhigh li.active:after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15.6px 9px 0 9px;
    border-color: #de350b transparent transparent transparent;
    position: absolute;
    left: 8px;
    bottom: -12px;
}
.risk_label {
    font-family: $secondary-font-bold;
    font-size: 14px;
    padding: 0 10px !important;
}

.risk_text {
    font-family: $secondary-font-regular;
    font-size: 14px;
    padding: 0 10px !important;
    min-height: 70px;
}

.risk_text_listing {
    font-family: $secondary-font-regular;
    font-size: 14px;
    list-style-type: none;
    padding: 0 10px !important;
}
.modal_title span {
    font-family: $secondary-font-bold;
    font-size: 14px;
    color: #333;
    opacity: 0.7;
}
.modal_title strong {
    font-family: $secondary-font-bold;
    font-size: 20px;
    color: #333;
    opacity: 1;
}
.mt70 {
    margin-top: 70px !important;
}
.mt40 {
    margin-top: 40px !important;
}
