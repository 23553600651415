@import '../../../styles/standard.module.scss';
h5{
    margin: 20px 0;
}
.btn_custom {
    height: 40px;
    border-radius: 2px;
    font-family: $secondary-font-regular;
    background-color: $PrimaryButton-bg;
    font-size: 16px;
    padding: 10px 0;
    margin: 0 10px; 
}
.btn_small,
.btn_small:hover {
    @include btn_primary;
}

.btn_cancel,
.btn_cancel:hover,
.btn_cancel:active {
    @include btn_secondary;
}
.loader_img{
    max-width: 50px;
}