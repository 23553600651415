@import "../../../styles/standard.module.scss";
.custom_btn {
    border-radius: 3px;
}
.close_password {
    float: right;
    cursor: pointer;
    border: 2px solid #fafafa;
    position: relative;
    padding: 1px 7px;
    top: -20px;
    background-color: #fafafa;
    border-radius: 50%;
}
.close_password:before {
    content: "×";
    font-size: 15px;
    font-weight: bold;
    color: #333;
    text-decoration: none;
}
.modal_size{
    max-width: 550px;
}

.custom_heading{
    background-color: #0052cc;
  }

  .custom_heading h5,.custom_heading h5 button,.custom_heading  button ,.custom_heading .close{
        color: #fff !important;
  }

.custom_body
{
    overflow: hidden !important;
}
.custom_content{
  border-radius: 0.5em !important;
}
.sso_p{
    color: gray;
    font-weight: 700;
    padding: 50px !important;
}
.sso_p img{
    width: 220px;
    height: 110px;
    padding-bottom: 5px;
    margin-bottom: 15px;
} 
.sso_privacy {
    text-align: center;
    font-size: 13px;
    color: black;
    line-height: 12px;
}
.sso_privacy a{
    font-size: 13px;
}
.sso_privacy a:hover{
    font-size: 13px;
}
.custom_fade{
    opacity: 1 !important;
    background-color: #fff !important;
}