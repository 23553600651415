@import "../../../styles/standard.module.scss";
.success_confirmation {
    p {
        color: $PrimaryColor-text;
    }
    h1,
    h2 {
        color: $hint-success-text;
        font-family: $primary-font-bold;
    }
    .btn {
        color: #ffffff;
        border: 0;
        box-shadow: 0px 0px 0px 0 rgb(255 255 255 / 0%);
        background-color: #034ab2;
        display: block;
        text-align: center;
        font-size: 1.125rem;
        padding-top: 13px;
        padding-bottom: 13px;
        border-radius: 28px;
        &:hover {
            text-decoration: none;
        }
    }
    .warning {
        color: #de350b;
        overflow: hidden;
    }
    .custom_btn_resend {
        color: #0052cc;
        font-size: 14px;
        font-weight: 500;
    }
    .verify_img {
        height: 60px;
        width: 60px;
    }
    .otp_field {
        width: 38px;
        margin-right: 10px;
        padding-left: 12px;
        height: 40px;
        outline: none;
        border: none;
        border-bottom: 1px solid grey;
    }
}
