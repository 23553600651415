@import "../../../styles/standard.module.scss";

.activity_log {
    border-bottom:1px solid $bdr-color-tertiary;
    color: $PrimaryColor-text;
    font-size:14px;
    font-family: $secondary-font-regular;
    padding:12px 24px;
    margin:0;
}
.activity_log:last-child {
    border-bottom:0px !important;
}
.activity_log strong{
    font-family: $secondary-font-bold;
}