@import '../../../styles/standard.module.scss';
.tab_content_top{
    border-bottom: solid 1px #f2f2f2;
    margin-bottom: 15px;
}
.tab_content_hdg{
    padding: 24px 24px 16px;
    margin-bottom: 0;
    font-family: $secondary-font-regular;
    position: relative;
    min-height: 65px;
}
.tab_edit{
    color: $PrimaryColor-text;
    font-family: $secondary-font-regular;
    font-size: 14px;
    position: absolute;
    right: 24px;
    top: 24px;
    cursor: pointer;
    z-index:1000;
}
.tab_edit a,
.tab_edit a:hover{
    color: $PrimaryColor-text;
}
.tab_edit img{
    max-width: 20px;
    margin-right:3px;
}

.single_product_block {
    padding: 0 24px;
    margin: 0 15px;
}


.single_product_block .single-product {
    width: 100%;
    float: left;
    display: flex;
    padding-bottom: 20px;
}

.single_product_block .single-product .single-left-block {
    font-family: $secondary-font-bold;
    font-size: 16px;
    color: #333;
    width: 100px;
    display: inline-block;
    padding: 0;
    float: left;
}

.single_product_block .single-product .custom-control-label {
    width: 165px;
    padding-right: 0;
    margin-right: 0;
    font-family: $secondary-font-regular;
}

.single_product_block .single-product .single-right-block {
    font-family: $secondary-font-regular;
    font-size: 16px;
    color: #333;
    padding-left: 8px;
    width: calc(100% - 140px);
    float: left;
}

.label_spec{
    margin-bottom: 0;
    font-family:$secondary-font-bold;
    line-height: normal;
}
.prod_content_view{
    width: 100%;   
    padding: 10px 10px;
    appearance: none;
    -webkit-appearance: none;
    outline: 0;
    background: 0;
    border: 0;
    overflow: auto;
}
.prod_content_view p{
    margin-bottom: 0;
    font-family: $secondary-font-regular;
    font-size:14px;
}
 
.prod_upload_blk{
    background-color: rgba(147, 151, 158, 0.05);
    width:100%;
    color:#333333;
    font-family: $secondary-font-bold;
    font-size:16px;
    padding:15px 20px;
    margin-top: 16px;
}
.prod_upload_blk_alt{
    padding-left: 0;
    background:transparent !important;
    margin-top: 0;
    padding-top: 0;
}
.prod_upload_blk label{
    font-family: $secondary-font-bold;
    font-size:14px;
}
.prod_upload_blk ul{
    margin:10px 0 0 0;
    list-style-type: none;
    padding-left: 0;
}
.prod_list{
    margin: 0;
    padding: 0 0 0 0;
    color:#0052cc;
    font-family: $secondary-font-regular;
    font-size:12px;
    //background:url(../../../static/images/Image_Icons.svg) center left no-repeat;
}
.prod_list a{
    padding-right: 10px;
    color:#0052cc;
    text-decoration: none;
    padding-left: 8px;
    
}
.prod_list p{
    margin-bottom: 0;
    font-family: $secondary-font-regular;
    font-size:14px;
    color: #333;
}
.add_new_link{
    font-family: $secondary-font-bold;
    font-size:16px;
    color:#0052cc;
    padding-left: 10px;
    float:right;
    cursor: pointer;
}
.add_new_link img{
    margin-right: 5px;
    vertical-align: middle;
}
.search_terms{
    margin:20px 0;
    width:100%;
}
.search_list{
    float:left;
    margin-top: 20px;
    width:100%;
}
.search_list ul{
    margin:0;
    padding-left: 0;   
}
.search_list ul li{
    margin:0 20px 20px 0;    
    list-style-type: none;
    font-family: $secondary-font-bold;
    font-size:15px;
    background:#e5e5e5;
    padding:5px 10px 5px 15px;
    border-radius:21px;
    width: auto;
    float: left;
}
.search_list ul li img{
    padding-left: 10px;
}

.prod_content_view{
    width: 100%;   
    padding: 10px 10px;
    appearance: none;
    -webkit-appearance: none;
    outline: 0;
    background: 0;
    border: 0;
    overflow: auto;
}
.prod_content_view p{
    margin-bottom: 0;
    font-family: $secondary-font-regular;
    font-size:14px;
}
.prod_upload_blk_alt p{
    padding-left: 30px;
    font-family: $secondary-font-regular;
    font-size:14px;
    margin-bottom: 0;
    padding-top: 10px;
    padding-bottom: 10px;
}

.product_textarea{
    width: 100%;
    height: 122px;
    padding: 0 10px;
    appearance: none;
    -webkit-appearance: none;
    outline: 0;
    background: 0;
    border: solid 1px #c1c7d0;
    overflow: auto;
    font-family: $secondary-font-regular;
    font-size:12px;
}

.prod_content{
    width: 100%;   
    padding: 10px 10px;
    appearance: none;
    -webkit-appearance: none;
    outline: 0;
    background: 0;
    border: solid 1px #c1c7d0;
    overflow: auto;
}
.prod_content p{
    margin-bottom: 0;
    font-family: $secondary-font-regular;
    font-size:14px;
}
.add_new_link{
    font-family: $secondary-font-bold;
    font-size:16px;
    color:#0052cc;
    margin-left: 10px;
    margin-right:15px;
    padding:0;
    float:right;
}
.add_new_link img{
    margin-right: 5px;
    vertical-align: middle;
}
.prod_upload_blk{
    background-color: rgba(147, 151, 158, 0.05);
    width:100%;
    color:#333333;
    font-family: $secondary-font-bold;
    font-size:16px;
    padding:15px 20px;
    margin-top: 16px;
}
.search_terms{
    margin:0 0 20px 0;
    width:100%;
}
.search_list{
    float:left;
    margin-top: 20px;
    width:100%;
}
.search_list ul{
    margin:0;
    padding-left: 0;   
}
.search_list ul li{
    margin:0 20px 20px 0;    
    list-style-type: none;
    font-family: $secondary-font-bold;
    font-size:15px;
    background:#e5e5e5;
    padding:5px 10px 5px 15px;
    border-radius:21px;
    width: auto;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    &.input_li{
        height:32px;
    }
}
.search_list ul li img{
    padding-left: 10px;
}

.formSubmit{
    padding: 0 !important;
}
.btn_custom {
    height: 42px;
    border-radius: 2px;
    font-family: $secondary-font-regular;
    background-color: $PrimaryButton-bg;
    font-size: 16px;
}
.btn_small,
.btn_small:hover {
    @include btn_primary;
    margin-right: 32px;
}
.btn_small_cancel,
.btn_small_cancel:hover,
.btn_small_cancel:active {
    @include btn_secondary;    
}
.input_li{
    background-color:#fff !important;
}
.clear{
    clear: both;
}
[contentEditable]:focus {
    outline: 0px solid transparent;
}
