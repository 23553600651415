@import "../../../styles/standard.module.scss";

.mesurement_blk {
  font-size: 16px;
  color: $PrimaryColor-text;
  padding: 10px 10px;
  margin-bottom: 0;
  display: grid;
}
.mesurement_blk p {
  padding: 0;
  margin-bottom: 16px;
}
.measurement_heading {
  font-family: $secondary-font-bold;
  font-size: 24px;
  color: $PrimaryColor-text;
  line-height: normal;
}
.mesurement_blk label {
  font-family: $secondary-font-regular;
  font-size: 16px;
  color: $PrimaryColor-text;
  padding: 2px;
  margin-bottom: 0;
  margin-right: 5px;
}
.mesurement_blk span {
  font-size: 16px;
  color: $PrimaryColor-text;
  margin-bottom: 0;
}
.tab_edit {
  color: $PrimaryColor-text;
  font-family: $secondary-font-regular;
  font-size: 14px;
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 1000;
  cursor: pointer;
  img {
    width: 15px;
    margin-right: 5px;
  }
}

/*****TESTING PURPOSE ONLY ****/
.testing {
  width: 180px;
  position: fixed;
  right: 0;
  top: 50%;
  z-index: 10000;
  background-color: $Plain-bg;
  padding: 15px;
  span {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 10px;
    display: block;
  }
  button {
    font-size: 13px;
    display: block;
    margin-bottom: 10px;
  }
}

.custom_modal_size {
  max-width: 800px;
  margin-top: 8px;
}

.heading {
  position: relative;
  padding: 25px 25px 10px 25px;
  h3 {
    font-size: 18px;
    font-weight: bold;
  }
  span {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
}

.basic_info {
  padding: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.basic_info div {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
}

.basic_info_form {
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.basic_info_form div {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
  display: flex;
  align-items: center;
}
.subheading_2 {
  font-family: $primary-font-regular;
  font-size: 21px;
  color: $PrimaryColor-text;
  margin-bottom: 0;
}

.single_product_block {
  padding: 0 24px;
}
.patient_content {
  padding: 16px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.moreinfo_content,
.patient_content {
  padding: 20px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.moreinfo_content .col_spec,
.patient_content .col_spec {
  list-style: none;
  margin: 0 0 30px 0;
  padding: 0 16px 0 0;
  width: auto;
  display: flex;
  flex-wrap: nowrap;
}

.patient_content .basic_input,
.moreinfo_content .basic_input {
  width: 100%;
  height: 40px;
  padding: 0 5px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
}

.patient_content .select_wrapper,
.moreinfo_content .select_wrapper {
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
  span {
    font-family: $secondary-font-regular;
    font-size: 13px;
    color: $Plain-text;
  }
}

.patient_content .basic_select,
.moreinfo_content .basic_select {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  appearance: none;
  -webkit-appearance: none;
  outline: 0;
  background: 0;
  border: 0;
}
select::-ms-expand {
  display: none;
}

.label_spec {
  margin-bottom: 0;
  font-family: $secondary-font-bold;
  margin-top: 5px;
  font-weight: bold;
}

.content_spec {
  font-family: $secondary-font-regular;
  color: $PrimaryColor-text;
  display: inline-block;
}
.basic_input {
  width: 250px;
  height: 40px;
  border-radius: 2px;
  border: solid 1px #0052cc;
  background-color: #ffffff;
  color: #0052cc;
}
.basic_input::placeholder {
  color: #0052cc;
  margin-left: 6px;
}
.small_input {
  width: 68px;
  height: 40px;
  padding-left: 7px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
}

.btn_small,
.btn_small:hover {
  @include btn_primary;
}
.btn_small_cancel,
.btn_small_cancel:hover,
.btn_small_cancel:active {
  @include btn_secondary;
}
.basic_input:focus-visible {
  outline: none !important;
}
.btn_custom {
  border-radius: 2px;
  margin: 10px 5px;
  padding: 5px 0;
  margin-top: -19px;
}
.select_wrapper {
  height: 40px;
  background: url("../../../static/images/down-arrow.png") right 10px center
    no-repeat;
}
.custom_control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}
.custom_control_input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 16px;
  height: 16px;
  opacity: 0 !important;
}
.custom_control_label {
  position: relative;
  margin-top: 5px;
  margin-bottom: 0;
  vertical-align: top;
  font-family: $secondary-font-regular;
  font-size: 16px;
  color: $PrimaryColor-text;
  line-height: normal;
}

.custom_control_input.is_invalid ~ .custom_control_label::before,
.was_validated .custom_control_input:invalid ~ .custom_control_label::before {
  border-color: $PrimaryColor-text;
}
.custom_control_input.is_valid ~ .custom_control_label,
.was_validated .custom_control_input:valid ~ .custom_control_label {
  color: $Error;
}
.custom_control_input.is_valid:checked ~ .custom_control_label::before,
.was_validated
  .custom_control_input:valid:checked
  ~ .custom_control_label::before {
  border-color: $border-box;
  background-color: $CheckBox-bg;
}

.custom_control_label::after {
  position: absolute;
  left: -1.5rem;
  display: block;
  width: 16px;
  height: 16px;
  content: "";
}

.custom_control_label::before {
  border-radius: 50% !important;
}
.custom_control_inline {
  height: 22px;
}

.custom_control_inline .custom_control_label {
  font-family: $secondary-font-bold;
  color: $text-color;
  font-size: 14px;
}
.custom_control_input:checked ~ .custom_control_label::before {
  color: $Plain-text;
  border-color: $border-box;
  background-color: $CheckBox-bg;
  border-radius: 0;
  width: 16px;
  height: 16px;
}

.left_right {
  position: relative;
  z-index: 100000;
  text-align: center;
  > span {
    display: block;
    width: 25px;
    height: 25px;
    background-color: #888;
    color: $Plain-text;
    border-radius: 100%;
    font-size: 13px;
    line-height: 25px;
  }
  > span:first-child {
    position: absolute;
    top: 50px;
    left: -15px;
  }
  > span:last-child {
    position: absolute;
    top: 50px;
    right: -15px;
  }
}
.anatomy {
  width: 400px;
  margin: 15px auto;
  * {
    &:hover {
      cursor: auto !important;
      fill: #96a6bf !important;
    }
  }
}

.svg_outer {
  padding: 20px 10%;
  &:first-child {
    border-right: 1px solid #ddd;
  }
  rect {
    fill: #fff !important;
    stroke: #fff !important;
  }
}

.active {
  fill: #00c7e5 !important;
}

.reference_images {
  color: $PrimaryButton-bg;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0px 0 15px 0;
  font-weight: bold;
  font-size: 14px;
}

.anatomy_part {
  h5 {
    margin-left: 40px !important;
  }
}
.wound_block_text {
  height: 55px;
  padding: 15px;
  width: 100%;
  background-color: #f0f0f0;
  font-size: 18px;
}

.wound_block_text_override {
  height: 55px;
  padding: 15px;
  width: 100%;
  max-width: 661px;
  background-color: #f0f0f0;
  font-size: 18px;
}

.wound_block {
  margin-bottom: 0px;
  max-width: 500px;
  margin-top: 20px;
  h3 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  img {
    max-width: 250px;
  }
  .wound_block_text {
    margin-top: 20px;
    height: 55px;
    padding: 15px;
    width: 100%;
    background-color: #f0f0f0;
  }
  .wound_status {
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
    span {
      &:first-child {
        font-size: 14px;
      }
      &:last-child {
        position: absolute;
        right: 5px;
        top: 0px;
        img {
          max-width: 10px;
        }
      }
    }
  }
}

.tab_fix {
  box-shadow: none;
  margin-bottom: -7px;
}
.tab_border_fix {
  top: -7px;
  margin: 0;
  position: relative;
}

/* Image view */

.carousel_block {
  width: 720px;
  margin: 10px 0 25px 0;
  position: relative;
  //z-index: 100;
  h3 {
    font-size: 18px;
  }
  .carousel_head {
    position: relative;
    z-index: 150;
    em {
      font-weight: normal;
      color: #f35353;
    }
    h4 {
      font-size: 14px;
      font-weight: bold;
      display: inline-block;
      margin-top: 7px;
      &.disable_head {
        color: #aaa;
      }
      span {
        font-size: 13px;
        font-family: $secondary-font-regular;
      }
    }
    .list_fields {
      width: auto;
      display: inline-block;
      .display_percent {
        color: #2f75df;
        font-weight: normal;
        font-family: $secondary-font-bold;
        margin: 0px 10px;
      }
      input[type="text"] {
        background-color: #fff;
        border: 1px solid #aaa;
        max-width: 75px;
        font-size: 12px;
        padding: 5px;
        margin: 0px 10px;
        position: relative;
        z-index: 10000;
        &::placeholder {
          color: rgba(60, 60, 67, 0.6);
        }
      }
    }
  }
  .carousel_body {
    margin-top: 25px;
    img {
      &.disable_image {
        opacity: 0.4;
      }
    }
  }
  .carousel_body { // added for Override Image
    margin-top: 25px;
    img {
      &.undisable_image {
        opacity: 1;
      }
    }
  }

  .carousel_body {
    margin-top: 25px;
    img {
      &.normal_image {
        opacity: none
      }
    }
  }
}

/* List view tissue sction */

.tissue_list {
  margin: 10px 0 25px 0;
  .tissue_list_item {
    margin: 5px 0;
    padding: 10px 0;
    position: relative;
    border-bottom: 1px solid #eee;
    em {
      font-weight: normal;
      color: #f35353;
    }
    h4 {
      font-size: 14px;
      font-weight: bold;
      margin-left: 0;
      display: inline-block;
      margin-top: 7px;
      span {
        font-size: 13px;
        font-family: $secondary-font-regular;
      }
      &.disable_head {
        color: #aaa;
      }
    }

    h4 {  //added for override heading
      font-size: 14px;
      font-weight: bold;
      margin-left: 0;
      display: inline-block;
      margin-top: 7px;
      span {
        font-size: 13px;
        font-family: $secondary-font-regular;
      }
      &.undisable_head {
        color: #000;
      }
    }

    .list_fields {
      width: auto;
      display: inline-block;
      .display_percent {
        color: #2f75df;
        font-weight: normal;
        font-family: $secondary-font-bold;
        margin: 0px 10px;
      }
      input[type="text"] {
        background-color: #fff;
        border: 1px solid #aaa;
        max-width: 75px;
        font-size: 12px;
        padding: 5px;
        margin: 0px 10px;
      }
    }
  }
}

/* Styling checkbox */
.custom-control-input:checked ~ .custom_control_label_check::before,
.custom_control_label_check::before {
  border-radius: 2px !important;
  background-color: $Plain-bg !important;
  width: 23px;
  height: 23px;
  border: 1px solid #555555 !important;
}

.custom_control_label_check::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 23px;
  height: 23px;
  content: "";
}
.custom_control_input:checked ~ .custom_control_label_check::after {
  background-image: url(../../../static/images/check-mark.svg) !important;
  &:active {
    outline: none;
    border: none;
  }
}
.disable_gray::after,.disable_gray::before{
  background-color: #f2f2f2 !important;
  border: 1px solid #dbdbdb !important;
}

.custom_checkbox {
  position: relative;
  margin-left: 25px;
  margin-right: 5px;
  display: inline-block;
  vertical-align: top;
  z-index: 1;
  label {
    text-indent: -9999px;
    margin-right: 0;
  }
}

.disable_check:after {
  border: 1px solid #ddd !important;
}

/* Option group */
.basic_select {
  display: block;
  margin: 0;
  width: 100%;
  height: 40px;
  padding: 0 30px 0 10px;
  appearance: none;
  -webkit-appearance: none;
  outline: 0;
  border-radius: 2px;
  border: solid 0px $border-color;
  background: none;
  &.history_cause {
    option {
      font-size: 14px;
      font-weight: bold;
      color: #333;
    }
    optgroup {
      option {
        font-weight: normal;
      }
    }
  }
}
.custom_select {
  margin: 0;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  appearance: none;
  -webkit-appearance: none;
  outline: 0;
  border-radius: 2px;
  border: solid 1px $border-color;
  background: none;
  &.history_cause {
    option {
      font-size: 14px;
      font-weight: bold;
      color: #333;
    }
    optgroup {
      option {
        font-weight: normal;
      }
    }
  }
}
.btn_scondary,
.btn_scondary:hover,
.btn_scondary:active {
  width: 140px;
  background-color: $Body-bg !important;
  border: solid 1px $tertiary-text !important;
  color: $PrimaryColor-text !important;
  border-radius: 2px;
  font-family: $secondary-font-regular;
  font-size: $Typography-2;
}
.btn_primary,
.btn_primary:hover,
.btn_primary:active {
  width: 140px;
  background-color: $PrimaryButton-bg !important;
  border: solid 1px $PrimaryButton-bg !important;
  color: $Plain-bg !important;
  border-radius: 2px;
  font-size: $Typography-2;
}
.multi_select_label_fix {
  margin-right: 0 !important;
}
.multi_select_fix {
  flex-wrap: wrap !important;
}
.multi_select {
  position: relative;
  left: 5px;
  height: auto;
}

.tissue_head {
  padding: 15px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  position: relative;
  top: -7px;
  box-shadow: 0 18px 10px -10px #eee;
  h4 {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    span {
      font-family: Arial, Helvetica, sans-serif;
      color: #007aff;
      display: inline-block;
      width: 18px;
      height: 18px;
      text-align: center;
      line-height: 16px;
      border: 2px solid #007aff;
      border-radius: 100%;
      font-size: 13px;
      cursor: pointer;
    }
  }
  p {
    margin-bottom: 0;
    margin-top: 5px;
    font-size: 13px;
    color: #888;
    display: none;
  }
  .tissue_arrow {
    position: absolute;
    top: 15px;
    right: 15px;
    img {
      cursor: pointer;
    }
  }
}
.tissue_body {
  padding: 15px;
  h6{
    margin-bottom: 20px;
  }
}

.history_cause_fix {
  margin-bottom: 0px !important;
}

.bir_subline {
  color: #505f79 !important;
  font-family: $secondary-font-regular !important;
  font-size: 12px !important;
  display: block !important;
  font-weight: normal !important;
}

.main_loader{
  text-align: center;
  min-height: 400px;
  align-content: center;
  img{
    max-width: 50px;
  }
}


/*Toggle button Style*/
.toggle_switch {
  transform: scale(1.5, 1.3)!important;
  margin-right: 15px!important;
  transition: all 0.4s ease-in-out;
  left: 15px;
}
.toggle_switch::after{
  transition: transform .2s ease-out!important;
}
.toggle_shells_class {
  float: right;
}

.heading_toggle_view {
  margin-left: 230px;
}
.heading_toggle_view p{
  margin-bottom: -5px;
  font-size: 21px!important;
  font-family: Arial, Helvetica, sans-serif;
}
.heading_toggle_view .toggle_label{
  font-weight: 600;
  font-size: 21px!important;
}


.heading_toggle{
  margin-left: 230px;
}
.heading_toggle p{
  margin-bottom: -5px;
  font-size: 17px!important;
  font-family: Arial, Helvetica, sans-serif;
}
.heading_toggle .toggle_label{
  font-weight: 600;
  font-size: 18px!important;
}



// ZoomIn/Out 

.iconListView{
  position: absolute;
    z-index: 999;
    opacity: 1;
    top: 60px;
    left: 47%;
    transform: scale(1.2);
    cursor: pointer;
}

.iconListEdit{
  // position: absolute;
    z-index: 999;
    opacity: 1;
    top: 60px;
    left: 10%;
    transform: scale(1.2);
    cursor: pointer;
    margin-top: -7px;
    margin-right: 8px;
}

.iconHover:hover{
  color: #0000006b !important;
}

// .iconListEditOne{
//     position: absolute;
//     z-index: 999;
//     opacity: 1;
//     top: 60px;
//     left: 10%;
//     transform: scale(1.2);
//     cursor: pointer;
//     margin-top: -7px;
//     margin-right: 10px;
// }

.zoomIn_Out .patient_wound{
  max-width: 250px!important;
  max-height: 450px!important;
  overflow: hidden;
  transform-origin: 0px 0px;
  cursor: grab;
}









.woundImagecard{
  align-items: center;
  justify-content: center;
  padding-left: 35px;
}
.woundImagecardExtra{
  padding-right: 35px;
  margin-top: 5px;
}

.woundImagecard{
  @media (min-width: 1301px) {
    img{
      width: 72px;
      height: 83px;
      max-width: 72px;
      max-height: 83px;
      padding: 0 4px;
      object-fit: cover;
    }
  }
  @media only screen and (min-width: 1275px) and (max-width: 1300px) {
    img{
      width: 54px;
      height: 70px;
      max-width: 54px;
      max-height: 70px;
      padding: 0 3px;
      object-fit: cover;
    }
  }
  @media only screen and (min-width: 1100px) and (max-width: 1274px) {
    img{
      width: 35px;
      height: 42px;
      max-width: 35px;
      max-height: 42px;
      padding: 0 2px;
      object-fit: cover;
    }
  }
  @media only screen and (min-width: 1025px) and (max-width: 1099px) {
    img{
      width: 28px;
      height: 35px;
      max-width: 28px;
      max-height: 35px;
      padding: 0 2px;
      object-fit: cover;
    }
  } 
  @media only screen and (min-width: 992px) and (max-width: 1024px) {
    img{
      width: 24px;
      height: 30px;
      max-width: 24px;
      max-height: 30px;
      padding: 0 2px;
      object-fit: cover;
    }
  }  
  @media only screen and (min-width: 868px) and (max-width: 991px) {
    img{
      width: 23px;
      height: 41px;
      max-width: 23px;
      max-height: 41px;
      padding: 0 1px;
      object-fit: cover;
    }
  } 
  @media only screen and (min-width: 769px) and (max-width: 867px) {
    img{
      width: 20px;
      height: 35px;
      max-width: 20px;
      max-height: 35px;
      padding: 0 1px;
      object-fit: cover;
    }
  } 
}


.woundImagecardWithout{
  img{
    width: auto;
    height: 110px;
    padding: 0 5px;
  } 
}




.disabled{
  pointer-events: none;
  opacity: 0.3;
  background: none!important;
}
.notdisabled {
  pointer-events:visible;
  opacity: 1;    
}

// .hoverCard {
//   border: 1px solid #727272b0;
//   margin: 5px; //unhide
//   max-width: 290px;
//   background: none;
//   overflow: hidden;
//   opacity: 0.5;
//   transition: 0.2s ease-in-out;
//   position: relative;
// }



.notHhoverCard{
  border: 1px solid;
  // margin: 5px; //unhide
  // max-width: 290px;
  background: #00000017;
}

.hoverCard{
  border: 1px solid #4242427a;
  background: #00000017;
  // margin: 5px; //unhide
  // max-width: 290px;
  background: none;
  // background: #dedede9c;
  overflow: hidden;
  // opacity: 0.5;
  transition: 0.2s ease-in-out;
  position: relative;
  cursor: pointer!important;

  @media (min-width: 1301px) {
    max-height: 185px;
    height: 185px;
  }
  @media only screen and (min-width: 1275px) and (max-width: 1300px) {
    max-height: 175px;
    height: 175px;
  }
  @media only screen and (min-width: 1100px) and (max-width: 1274px) {
    max-height: 145px;
    height: 145px;
  }
  @media only screen and (min-width: 1025px) and (max-width: 1099px) {
    max-height: 135px;
    height: 135px;
  } 
  @media only screen and (min-width: 992px) and (max-width: 1024px) {
    max-height: 130px;
    height: 130px;
  } 
  @media only screen and (min-width: 769px) and (max-width: 991px) {
    max-height: 135px;
    height: 135px;
  }
}

.hoverCard:hover { 
  transition: 0.2s ease-in-out;
  overflow: hidden!important;
  opacity: 1;
  border: 5px solid #0075ff!important;
  background: none;
  z-index: 999;
  cursor: pointer!important;
  color: #000!important;
  box-shadow: 0 5px 10px #000;
  @media (min-width: 1301px) {
    img{
      width: 72px;
      height: 83px;
      max-width: 72px;
      max-height: 83px;
      padding: 0 4px;
      object-fit: cover;
    }
  }
  @media only screen and (min-width: 1275px) and (max-width: 1300px) {
    img{
      width: 54px;
      height: 70px;
      max-width: 54px;
      max-height: 70px;
      padding: 0 3px;
      object-fit: cover;
    }
  }
  @media only screen and (min-width: 1100px) and (max-width: 1274px) {
    img{
      width: 33px;
      height: 42px;
      max-width: 33px;
      max-height: 42px;
      padding: 0 2px;
      object-fit: cover;
    }
  }
  @media only screen and (min-width: 1025px) and (max-width: 1099px) {
    img{
      width: 26px;
      height: 35px;
      max-width: 26px;
      max-height: 35px;
      padding: 0 2px;
      object-fit: cover;
    }
  } 
  @media only screen and (min-width: 992px) and (max-width: 1024px) {
    img{
      width: 22px;
      height: 41px;
      max-width: 22px;
      max-height: 41px;
      padding: 0 2px;
      object-fit: cover;
    }
  }

  @media only screen and (min-width: 868px) and (max-width: 991px) {
    img{
      width: 23px;
      height: 41px;
      max-width: 23px;
      max-height: 41px;
      padding: 0 1px;
      object-fit: cover;
    }
  }
  
  @media only screen and (min-width: 769px) and (max-width: 867px) {
    img{
      width: 18px;
      height: 35px;
      max-width: 18px;
      max-height: 35px;
      padding: 0 1px;
      object-fit: cover;
    }
  } 
}

// .hoverCard:hover { 
//   border-radius: 5%;
//   transition: 0.2s ease-in-out;
//   overflow: hidden!important;
//   transform: scale(1.1) translateY(-10px);
//   opacity: 1;
//   border: 7px solid #6a6a6a;
//   background: rgb(128 128 128 / 99%);
//   color: #fff;
//   z-index: 999;
//   cursor: pointer!important;
// }


//unhide
.hoverCard:nth-child(1) {
  @media (min-width: 992px) {
  border-top-left-radius: 15px!important;
  }
  border-right: none;
  border-bottom: none;
}
.hoverCard:nth-child(2) { 
  @media (min-width: 992px) { 
  border-right: none;
  }
  border-bottom: none;
}
.hoverCard:nth-child(3) {
  @media (min-width: 992px) {
  border-top-right-radius: 15px!important;
  }  
  border-bottom: none;
}
.hoverCard:nth-child(4) {  
  @media (min-width: 992px) {
  border-right: none;
  }
  border-bottom: none;
}
.hoverCard:nth-child(5) {  
  border-right: none;
  border-bottom: none;
}
.hoverCard:nth-child(6) {  
  border-bottom: none;
}
.hoverCard:nth-child(7) {
  @media (min-width: 992px) {
  border-bottom-left-radius: 15px!important;
  }
  border-right: none;
}
.hoverCard:nth-child(8) { 
  @media (min-width: 992px) {
    border-right: none;
  }
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    
  }

}
.hoverCard:nth-child(9) {
  @media (min-width: 992px) {
  border-bottom-right-radius: 15px!important;
  }
}//unhide



.hoverCardOther {
  border: 1px solid #727272b0;
  // margin: 5px;
  max-width: 355px;
  background: none;
  // background: #dedede9c;
  overflow: hidden;
  transition: 0.2s ease-in-out;
  cursor: pointer!important;
  @media only screen and (min-width: 769px) and (max-width: 867px) {
    .woundImagecard{
      img{
        width: 18px;
        height: 35px;
        max-width: 18px;
        max-height: 35px;
        padding: 0 1px;
        object-fit: cover;
      }
    }
  }
}

.hoverCardOther:hover { 
  transition: 0.2s ease-in-out;
  overflow: hidden!important;
  opacity: 1;
  border: 5px solid #0075ff!important;
  background: none;
  cursor: pointer!important;
  z-index: 999;
  color: #000!important;
  box-shadow: 0 5px 10px #000;
  @media only screen and (min-width: 769px) and (max-width: 867px) {
    .woundImagecard{
      img{
        width: 16px;
        height: 35px;
        max-width: 16px;
        max-height: 35px;
        padding: 0 1px;
        object-fit: cover;
      }
    }
  }
}

// .hoverCardOther:hover { 
//   border-radius: 5%;
//   transition: 0.2s ease-in-out;
//   overflow: hidden!important;
//   transform: scale(1.1) translateY(-10px);
//   opacity: 1;
//   border: 7px solid #6a6a6a;
//   background: rgb(128 128 128 / 99%);
//   color: #fff;
//   cursor: pointer!important;
//   z-index: 999;
// }

//unhide
.hoverCardOther:nth-child(1) {
  border-top-left-radius: 5%!important;
  border-right: none;
  border-bottom: none;
}
.hoverCardOther:nth-child(2) {
  border-top-right-radius: 5%!important;
  border-bottom: none;
}
.hoverCardOther:nth-child(3) {
  border-bottom-left-radius: 5%!important;
  border-right: none;
}
.hoverCardOther:nth-child(4) {
  border-bottom-right-radius: 5%!important; 
}//unhide


// .hoverHoleImage:hover{
//   margin-top: -2px;
// }

.selectedFile{
  background: #0075ff!important;
  color: #fff!important;
  // border: 5px solid #1cd404!important;
  // border: 5px solid #0075ff;
}
.selectedFile:hover{
  background: #0075ff!important;
  color: #fff!important;
  // border: 5px solid #1cd404!important;
  // border: 5px solid #0075ff;
}

.selectedNotFile{
  border: 5px solid #00000017!important
}
.selectedFileRadio {
  border: 3px solid #0075ff!important;
  padding: 9px 10px 10px 18px!important;
  transition: 0.3s ease-in-out;
  box-shadow: 0 2px 8px #000000;
  // box-shadow: 0 1px 5px #000000;
  // transform: rotateX(360deg); 
  // transition: 0.9s ease-in-out;
}

.selectedSingleFile{
  // border: 5px solid #0075ff;
  background: #0075ff!important;
  color: #fff!important;
  // transition: 0.5s ease-in-out;
  // border: 5px solid #07acc5;
  // background: #00c7e5 !important;
  // background: #63ee50!important;
}
.selectedSingleFile:hover{
  // border: 7px solid #0075ff;
  color: #fff!important;
  border: none!important;
}

.unSelectedFileRadio{
  border: none!important;
}

// .notHhoverCardOther{
//   border: 1px solid;
//   margin: 5px;
//   max-width: 300px;
//   background: #00000017;
// }

.cardHeading {
  padding: 20px 5px 0px 5px; //unhide
  // padding: 12px 5px 5px 5px;
  text-align: center;
  h3{
    font-weight: bold;
    // font-size: 24px;
    font-size: 20px;
  }
}
.cardHeadingResVal{
  padding: 8px 5px 0px 5px;
  text-align: center;
  h3{
    font-weight: bold;
    // font-size: 24px;
    font-size: 20px;
  }
}

.cardHeadingHover {
  padding: 20px 5px 5px 5px;
  text-align: center;
  h3{
    font-weight: bold;
    font-size: 20px;
  }
}

.cardSubheading{
  padding: 0px; //unhide
  // padding: 15px 10px 0px 10px;
  text-align: center;
  
  @media (min-width: 1301px) {
    p{
      font-weight: 600;
      font-size: 14px;
    }
  }
  @media only screen and (min-width: 1275px) and (max-width: 1300px) {
    p{
      font-weight: 600;
      font-size: 12px;
    }
  }
  @media only screen and (min-width: 1100px) and (max-width: 1274px) {
    p{
      font-weight: 600;
      font-size: 11px;
    }
  }
  @media only screen and (min-width: 1025px) and (max-width: 1099px) {
    p{
      font-weight: 600;
      font-size: 11px;
    }
  }  
  @media only screen and (min-width: 992px) and (max-width: 1024px) {
    p{
      font-weight: 600;
      font-size: 8px;
    }
  }
  @media only screen and (min-width: 769px) and (max-width: 991px) {
    p{
      font-weight: 600;
      font-size: 10px;
    }
  }
}

.cardSubheadingHover {
  padding: 5px 5px 0px 5px;
  text-align: center;
  p{
    font-weight: 600;
    font-size: 14px;
  }
}


.cardRadio{
    border: 1px solid rgba(66, 66, 66, 0.4784313725);
    display: inline-flex;
    align-items: center;
    border-radius: 20px;
    padding: 10px 10px 10px 20px;
    opacity: 1;
    // margin-left: 15%;   
    transition: 0.2s ease-in-out;
  input{
    width: 22px;
    height: 24px;
  }
  h3{
    @media (min-width: 1301px) {
      font-size: 23px;
    }
    @media only screen and (min-width: 1025px) and (max-width: 1300px) {
      font-size: 20px;
    }
    @media only screen and (min-width: 769px) and (max-width: 1024px) {
      font-size: 18px;
    }
    // font-size: 23px;
    padding-top: 5px;
    padding-left: 15px;
    font-weight: bold;
  }
}

// .cardRadio:hover{
//   border: 3px solid #0075ff;
// }

.cardRadioDisabled{
  border: 1px solid rgba(66, 66, 66, 0.4784313725);
  display: inline-flex;
  align-items: center;
  border-radius: 20px;
  padding: 10px 10px 10px 20px;
  opacity: 0.5;
  // margin-left: 15%;
  input{
    width: 22px;
    height: 24px;
  }
  h3{
    font-size: 23px;
    padding-top: 5px;
    padding-left: 15px;
    font-weight: bold;
  }
}

.textBlack{
  color: #000;
}
.textWhite{
  color: #fff;
}


.tooltip {
  position: relative;
  display: inline-block; 
  float: right;
  top: -25px;
  padding: 5px;
}
.tooltip .tooltiptext {
  visibility: hidden;
  @media (min-width: 1301px) {
    width: 250px;
    font-size: 12px;
    height: 125px;
    overflow-y: scroll;
  }
  
  @media only screen and (min-width: 1275px) and (max-width: 1300px) {
    width: 160px;
    font-size: 11px;
    height: 125px;
    overflow-y: scroll;
  }
  
  @media only screen and (min-width: 1100px) and (max-width: 1274px) {
    width: 120px;
    font-size: 10px;
    height: 125px;
    overflow-y: scroll;
  }
  
  @media only screen and (min-width: 1025px) and (max-width: 1099px) {
    width: 110px;
    font-size: 9px;
    height: 105px;
    overflow-y: scroll;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1024px) {
    width: 100px;
    font-size: 8px;
    height: 105px;
    overflow-y: scroll;
  }
  
  @media only screen and (min-width: 769px) and (max-width: 991px) {
    width: 185px;
    font-size: 8px;
    height: 100px;
    overflow-y: scroll;
  }  
  background-color: white;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 7px 0;
  position: absolute;
  z-index: 1;
  font-family: $secondary-font-regular;
  font-weight: 500;
  letter-spacing: 0.01em;
  border: 1px solid #4f4f4f;
  right: 93%;
  top: 25%;
  box-shadow: 0 1px 0px 1px #000;
}

.tooltip .tooltiptextOther{
  visibility: hidden;
  @media (min-width: 1301px) {
    width: 250px;
    font-size: 12px;
    height: 125px;
    overflow-y: scroll;
  }
  
  @media only screen and (min-width: 1275px) and (max-width: 1300px) {
    width: 160px;
    font-size: 11px;
    height: 125px;
    overflow-y: scroll;
  }
  
  @media only screen and (min-width: 1100px) and (max-width: 1274px) {
    width: 120px;
    font-size: 10px;
    height: 125px;
    overflow-y: scroll;
  }
  
  @media only screen and (min-width: 1025px) and (max-width: 1099px) {
    width: 110px;
    font-size: 9px;
    height: 105px;
    overflow-y: scroll;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1024px) {
    width: 110px;
    font-size: 7px;
    height: 105px;
    overflow-y: scroll;
  }
  
  @media only screen and (min-width: 769px) and (max-width: 991px) {
    width: 180px;
    font-size: 6px;
    height: 100px;
    overflow-y: scroll;
  } 
  
  background-color: white;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 7px 0;
  position: absolute;
  z-index: 1;
  font-family: $secondary-font-regular;
  font-weight: 500;
  letter-spacing: 0.01em;
  border: 1px solid #4f4f4f;
  right: 101%;
  top: 25%;
  box-shadow: 0 1px 0px 1px #000;
}

.tooltip:hover .tooltiptext, .tooltip:hover .tooltiptextOther {
  visibility: visible;
}

.tooltip_list{
  top: -3px !important;
}



.errorTissueNull {
  font-weight: 600;
  font-size: 22px;
  margin-top: 10%;
  bottom: 0;
  // border: 2px solid #dc3545;
  // padding: 40px 0px;
  margin-left: 3%;
}





//added for save and cancel sticky

.withPhotoSticky{
  height: 785px;
  max-height: 785px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.withoutPhotoSticky{
  height: 385px;
  max-height: 385px;
  overflow-y: scroll;
  overflow-x: hidden;
}


.top_save_cancel {
  padding-left: 15px;
  margin-top: -58px;
  margin-bottom: 15px;
}


// .scroll_hide::-webkit-scrollbar {
//    display: none;
//   }
// .scroll_hide{
//   -ms-overflow-style: none; 
//   scrollbar-width: none 
// }

// .withPhotoSticky::-webkit-scrollbar {
//   display: none;
//  }

//  .withPhotoSticky{
//   -ms-overflow-style: none; 
//   scrollbar-width: none 
// }
//  .withoutPhotoSticky::-webkit-scrollbar {
//   display: none;
//  }

//  .withoutPhotoSticky{
//   -ms-overflow-style: none; 
//   scrollbar-width: none 
// }

//added for save and cancel sticky



.custom-switch .custom-control-label::before {
  left: -2.5rem!important;
  width: 1.95rem!important;
}
.custom-switch .custom-control-label::before {
  color: #fff!important;
  border-color: #007bff2b!important;
  background-color: #007bff8c!important;
}
.custom-control-label.characteristic_toggle_switch__2LVrE.characteristic_toggle_shells_class_special__3UvVT::after {
  background-color: #1e00ff!important;
  transform: translateX(0.75rem)!important;
}
.custom-control-label.characteristic_toggle_switch__2LVrE.characteristic_toggle_shells_class_special__3UvVT::after {
  top: calc(0.25rem + -1.1px)!important;
  left: calc(-2.25rem + -3px)!important;
  width: calc(1.35rem - 4px)!important;
  height: calc(1.4rem - 4px)!important;
  border-radius: 0.7rem!important;
}
.hist_cause_err {
  display: block;
  color: $Error !important;
  padding: 10px 0px 10px 0px;
  font-family: $secondary-font-regular !important;
  font-size: 14px!important;
}
.acc_btn_cust{
  padding: 5px 0px 2px 4px;
}

.acc_body_cust {
  padding: 0px 0px 0px 3px;
  margin-top: -11px;
  width: 95%;
}

.custom_content{
  height: 550px;
  overflow-y: scroll;
}
.newflowhead{
  color:#007bff;
}
/* new flow */
.newflowheadsub {
  margin-top: -34px;
  margin-bottom: 1px;
  font-size: 21px;
  span{
    color: #212529;
    font-size: 18px;
  }
}
.wound_left_block {
  width: 100%;
  padding: 0px 0px 5px 10px;

}
.wound_left_block_hr_line {
  overflow: hidden;
  border-bottom: 3px solid #dbdbdb;
  padding: 5px;

}
.wound_left_block, .figure {
  margin-left: 0;
  img {
    width: 190px;
    height: 222px;
    object-fit: contain;
}
}
.wound_left_block .figure {
  display: inline !important;
}
.wound_left_block .patient_details {
  width: 470px;
}
.patient_details {
  display: inline-block;
  margin-left: 10px;
  margin-bottom: -3px;
}
.patient_details p{
line-height:10px ;
display: flex;
}
.patient_details h4{
    display: contents;
  }
.wound_right_block {
  display: inline-block;
  padding-left: 0;
  width: 100%;
}

.wound_right_block .heading_wound {
  font-size: 16px;
  font-family: $secondary-font-bold;
  color: #333;
  margin-bottom: 20px;
}
.modal_close span{
  position: absolute;
    font-size: 30px;
    top: 10px;
    font-family: 'open_sansregular';
    color: black;
    font-weight: bolder;

}
.sign_lock,.sign_lock:hover,.sign_lock:focus,.sign_lock:not(:disabled):not(.disabled):active,.sign_lock:not(:disabled):not(.disabled):active:focus {
  height: 30px;
  padding: 7px 10px 5px 8px;
  border-radius: 2px;
  border: solid 1px #36b37e;
  background-color: #36b37e;
  font-size: 11px;
  color: #ffff;
  cursor:none !important;
  flex:none
}

.re_eval,.re_eval:hover,.re_eval:focus{
  width: 150px;
  height: 45px;
  padding: 8px 10px 10px 10px;
  background-color: #ffff;
  color: #0052cc;
  border: #0052CC 1px solid;
  border-radius: 2px;
  font-weight: 500;
  font-size: 15px;
}

.new_flow_prop_by label {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #505f79;
}
.new_flow_prop_by span {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333;
  margin-left: 4px;
}
.newflow_wound_ac{
  color:orangered !important;
}
.newflow_wound_ac_green{
  color:#15b471 !important;
}
.new_flow_photo_by  label{
  opacity: 0.77;
}
.new_flow_photo_by span {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #505f79;
  margin-left: 4px;
}
.custom_new_content{
  height: auto;
  overflow: hidden;
  max-height: 700px;
}
.custom_new_body{
  //min-height: 589px;
  max-height: 600px;
  overflow: auto;
}
.start_hist{
  visibility: hidden;
}
.anatomyPop{
  text-align: justify;
  display: inline-block;
}
.doc_as_new_pop{
  width: -webkit-fill-available;
  text-align: center;
}

.otherNamtItpar_txt{
  top: 82%;
    height: 44px;
    width: 100%;
    max-width: 661px;
    position: fixed;
}
.otherNamtItpar{
  display: ruby-text;

}

.yes_btn {
  width: 140px;
  border-radius: 0px;
}

.no_btn {
  width: 140px;
  border-radius: 0px;
  background-color: $Body-bg;
  color: $PrimaryColor-text;
  border: solid 1px $tertiary-text;
}
.no_btn:hover {
  background-color: $Body-bg;
  color: $PrimaryColor-text;
  border: solid 1px $tertiary-text;
}
.no_btn:active {
  background-color: $Body-bg !important;
  color: $PrimaryColor-text !important;
  border: solid 1px $tertiary-text !important;
}
.no_btn:focus {
  background-color: $Body-bg;
  color: $PrimaryColor-text;
  border: solid 1px $tertiary-text;
}
.nxt_btn_ad{
  margin-left: 100px !important;
}