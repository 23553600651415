@import '../../../styles/standard.module.scss';

.btn_clone,
.btn_clone:hover, .btn_clone:focus , .btn_clone:active{
    border: 1px solid $border-color;
    width: auto;
    height: 42px;
    border-radius: 2px;
    background-color: $Plain-bg;
    color: $PrimaryColor-text;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 10px;
    padding-right: 10px;   
}

@media (min-width: 1200px) {
    .admin_bg_bottom {
        background-color: #fafafa;
        height: calc(100% - 105px);
        padding: 32px;
        margin-top: 0;
    }
}

.admin_bg_bottom .custom_select {
    width: 260px;
    height: 42px;
    border-radius: 0;
}

.admin_heading {
    font-size: 36px;
    color: $PrimaryColor-text;
    font-family: $primary-font-bold;
    padding-left: 25px;
}
.tab_content {
    width: 100%;
}

.tab_pane {
    padding: 0 0 41px;
    box-shadow: 5px 10px 20px 0 rgba(191, 191, 191, 0.3);
    background-color: $Plain-text
}
.tab_content_top {
    border-bottom: solid 1px $accod-border;
    margin-bottom: 15px;
}

.tab_content_hdg {
    padding: 24px 24px 16px;
    margin-bottom: 0;
    font-family: $secondary-font-bold;    
    position: relative;
}
.tab_hdg{
    font-size: 24px;
    color: $PrimaryColor-text;
    width: auto;
    padding-right: 16px;
}
.tab_content_line{
    color: $PrimaryColor-text;
    font-family: $secondary-font-regular;
    font-size:14px;
}
.tab_content_subline {
    font-family: $primary-font-regular;
    font-size: 18px;
    display: block;
    margin-top:5px;
}
.tab_subtitle {
    font-size: 16px;
    font-family: $secondary-font-regular;
    margin-bottom: 0;
    display: block;
}

.single_product_block {
    padding: 0 24px;
}

.single_product_block .single_product {
    width: 100%;
    float: left;
    display: flex;
    padding-bottom: 20px;
}

.single_product_block .single_product .single_left_block {
    font-family: $secondary-font-bold;
    font-size: 16px;
    color: $PrimaryColor-text;
    width: 100px;
    display: inline-block;
    padding: 0;
    float: left;
}

.single_product_block .single_product .custom_control_label {
    width: 165px;
    padding-right: 0;
    margin-right: 0;
    font-family: $secondary-font-regular;
}

.single_product_block .single_product .single_right_block {
    font-family: $secondary-font-regular;
    font-size: 16px;
    color: $PrimaryColor-text;
    padding-left: 8px;
    width: calc(100% - 140px);
    float: left;
}
.patient_content,
.moreinfo_content{
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.patient_content  .col_spec,
.moreinfo_content .col_spec {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
    display: flex;
}

.moreinfo_content .basic_input {
    width: 100%;
    height: 40px;
    padding: 0 5px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-text
}

.patient_content .select_wrapper,
.moreinfo_content .select_wrapper {
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-text;
    height:40px;
}
.select_wrapper {
    background: url("../../../static/images/down-arrow.png") right 10px center no-repeat;
    border-radius: 2px;

    background-color: $Plain-bg;
    height: 40px;
}
.patient_content  .basic_select,
.moreinfo_content .basic_select{
    width: 100%;
    height: 40px;
    padding: 0 10px;
    appearance: none;
    -webkit-appearance: none;
    outline: 0;
    background: 0;
    border: 0;
}
.label_spec {
    margin-bottom: 0;
    font-family: $secondary-font-bold;
    line-height: normal;
}
.content_spec {
    font-family: $secondary-font-regular;
    color: $PrimaryColor-text;
}
.text_content{
    height: 144px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-text;
    //color:$border-color;
    font-size:16px;
    font-family: $secondary-font-regular;
    width:100%;
    padding:5px;
    text-align:left;
}
.btn_add,
.btn_add:hover {
    @include btn_primary;
    img{
        margin-right: 10px;
    }
}
.btn_custom {
    height: 42px;
    border-radius: 2px;
    font-family: $secondary-font-regular;
    background-color: $PrimaryButton-bg;
    font-size: 16px;
}
.btn_small,
.btn_small:hover {
    @include btn_primary;
    margin-right: 32px;
}
.btn_small_cancel,
.btn_small_cancel:hover,
.btn_small_cancel:active {
    @include btn_secondary;   
}
.custom_modal_size{
    max-width: 800px;
}
.custom_confirm_modal_size{
    max-width: 500px;
}
.basic_select.disabled {
    background-color: #dbdbdb !important;
    pointer-events: none;
    cursor:not-allowed;
}
.clone_status{
    padding: 0 20px;
    text-align: center;
}

.modal_btn_small,
.modal_btn_small:hover {
    @include btn_primary;
    margin-right: 32px;
}
.modal_btn_small_cancel,
.modal_btn_small_cancel:hover,
.modal_btn_small_cancel:active {
    @include btn_secondary;  
}