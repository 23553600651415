@import '../../../styles/standard.module.scss';
@import '../../../styles/common.module.scss';
.admin_meta{
    font-size: 18px;
    font-weight: normal;
    margin: 0px 10px 15px 15px;
    span{
        margin-right: 5px;
        b{
            font-weight: 600;
        }
    }
}

.edit_duplicate{
    text-align: right;
    a{
        margin: 0 5px;
        color: $filter-btn !important;
        font-weight: 500;
        img{
            max-width: 14px;
        }
        &:hover{
            color: $PrimaryColor-text !important;
            text-decoration: none;
        }
    }
}

.back_btn a {
    @include response-above(sm) {
        position: fixed;
        top:22px;
        left:25px;
        background:url(../../../static/images/back-arrow.png) center left no-repeat;
        text-indent: 999px;
    }
    @include response-above(lg) {
      position: static;
      top:auto;
      left:auto;
      color:$PrimaryColor-text !important;
      background:url(../../../static/images/left_arrow.png) center left no-repeat;
      padding-left: 10px;
      font-family: $secondary-font-regular;
      font-size:16px;
    }
  }