@import "../../../styles/standard.module.scss";

.popup {
  position: fixed;
  width: 100% !important;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10000;
}
.popup_inner h1 {
  margin: 0;
}
.close_btn {
  display: block;
  position: absolute;
  right: 18px;
  top: 12px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: #000;
  font-size: 15px;
  border: 0;
  cursor: pointer;
  line-height: 50%;
  background-color: transparent;
}
.ws_modal_xl {
  .ws_modal_footer {
    .cancel {
      float: right;
      padding: 8px 64px;
      background-color: #e8e8e8;
      font-size: 18px;
      border-color: transparent;
      color: #333333;
    }
  }
}
.ws_modal_xl.success {
  .modal_body {
    background-color: #ffffff;
    border: solid 1px #979797;
  }
}
.ws_modal_xl.delete_conf {
  > div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 800px;
    max-height: 90vh;
  }
  .ws_modal_body {
    background-color: #ffffff;
    border: solid 1px #979797;
    p {
      &:last-child {
        margin-bottom: 0px !important;
      }
    }
  }
  .ws_modal_footer {
    .success_align {
      width: 157px;
      margin: 0 auto;
      display: block;
    }
    .cancel {
      margin: 0.25rem;
    }
  }
}
.content_list_item {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
}

.confirmAdjustCls {
  max-width: 732px !important;
}

.scrollApply {
  max-height: 425px;
  overflow-y: auto;
}
.custom_title {
  margin: 0;
}
.ws_modal_content {
  border: 0 !important;
  .ws_modal_header {
    border: 0 !important;
  }
  .ws_modal_body {
    border: 0 !important;
  }
}
.main_content {
  text-align: center;
  img {
    margin-top: 25px;
  }
  h3 {
    font-size: 20px;
  }
}

.opps_txt {
  font-size: 36px;
  color: #e32200;
  font-family: $primary-font-bold;
  text-align: center;
  font-weight: 600 !important;
  margin-top: 36px;
  margin-bottom: 10px;
}
.opps_msg {
  color: #333;
  font-family: $primary-font-regular;
  font-size: 26px;
  margin-bottom: 35px;
}
.opps_btn {
  color: #fff;
  background-color: #0052cc;
  width: 140px;
  height: 50px;
  text-align: center;
  margin-bottom: 35px;
  border: 0px;
  border-radius: 2px;
}
