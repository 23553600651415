@import '../../../styles/standard.module.scss';
.assessment_modal{
    h5{
        font-size: 18px;
        font-weight: normal;
    }
}
.custom_modal_size {
    max-width: 780px;
}
.spacing {
    padding : 30px;
}
.upload_label {
    margin-right: 67px;
    font-family: $secondary-font-bold;
}
.custom_wrapper {
    width: 212px !important;
    height: 212px !important;
    object-fit: inherit !important;
    border-radius: 0px !important;
    display: inline-block;
    align-items: inherit !important;
    justify-content: inherit !important;
    margin-bottom: 48px;
}
.browse_btn {
    border-radius: 2px;
    border: solid 1px $bdr-color-tertiary;
    background-color: $body-bg-color;
    padding: 10px 0px;
    width: 140px;
    text-align: center;
    text-decoration: none !important;
    margin-left : 0px !important;
    font-family: $secondary-font-regular;
    font-weight: 500 !important;
    color: $PrimaryColor-text !important;
    margin-bottom: 56px !important;
}
.browse_btn:hover {
    cursor: pointer;
}
.btn_add, .btn_add:hover {
    width: 155px;
    height: 42px;
    margin: 0;
    padding: 9px 16px 9px 9px;
    border-radius: 2px;
    background-color: $PrimaryButton-bg;
    font-family: $secondary-font-regular;
    color: $Plain-bg;
    font-size: $Typography-2;
}



.label_spec {
    margin-bottom: 0;
    font-family: $secondary-font-bold;    
}
.col_spec {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
    display: flex;
    flex-wrap: wrap;    
}
.select_wrapper {
    border-radius: 2px;
    border: solid 1px $border-color;
    height:40px;
    background: url('../../../static/images/down-arrow.png') right 10px center no-repeat;
}
.basic_select {
    margin: 0;
    width: 100%;
    height: 40px;
    padding: 0 10px;
    appearance: none;
    -webkit-appearance: none;
    outline: 0;
    border-radius: 2px;
    border: solid 0px $border-color;
    background: none;
    option{
        font-size: 14px;
        font-weight: bold;
        color: $PrimaryColor-text;
    }
    optgroup{
        font-size: 14px;
        option{
            font-weight: normal;
        }
    }
}
.basic_textarea {
    width: 97.3%;
    height: 100px;
    padding: 10px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}

.btn_custom {
    padding: 0;
    height: 48px;
    border-radius: 2px;
    font-family: $secondary-font-regular;
    background-color: $PrimaryButton-bg;
    font-size: 18px;
}

.btn_small,
.btn_small:hover {
    width: 140px;
    height: 40px;
    font-family: $secondary-font-regular;
    background-color: $PrimaryButton-bg;
    color: $Plain-text;
    font-size: 16px;
}

.btn_small_cancel,
.btn_small_cancel:hover {
    width: 100px;
    height: 40px;
    font-family: $secondary-font-regular;
    background-color: $Plain-bg !important;
    border: 0;
    color: $PrimaryColor-text !important;
    font-size: 16px;
    margin-right: 16px;
}

.svg_outer {
    padding: 0px 10%;
    &:first-child{
        border-right: 1px solid #ddd;
    }
    rect{
        fill: #fff !important;
        stroke: #fff !important;
    }
}

.notes_label{
    span{
        font-family: $secondary-font-regular;
    }
}

.left_right{
    position: relative;
    z-index: 100000;
    text-align: center;
    >span{
        display: block;
        width: 25px;
        height: 25px;
        background-color: #888;
        color: $Plain-text;
        border-radius: 100%;
        font-size: 13px;
        line-height: 25px;
    }
    >span:first-child{
        position: absolute;
        top: 50px;
        left: -15px;
    }
    >span:last-child{
        position: absolute;
        top: 50px;
        right: -15px;
    }
}
// Styles for loader
.loader_position {
    margin-bottom: 10px;
}
.loader_img {
    max-width: 50px;
}


.basic_input {
    width: 100%;
    height: 40px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}


div[role=toolbar]{
    background: transparent !important;    
    
    button[name=select-tool-zoom-in],
    button[name=select-tool-zoom-out],
    button[name=unselect-tools],
    button[name=select-tool-pan],
    button[name=fit-to-viewer]{
        > svg{
            stroke-width: 0.01em !important;
            fill: #666 !important;
        }
        &:hover{
            > svg{
                fill: #aaa  !important;
            }
        }
        &:focus{
            > svg{
                fill: #111  !important;
            }
        }
    }
}

.view_wound_img{
    height: 740px !important;
    width: fit-content !important;
    min-width: 420px !important;
    min-height: 740px !important;
    object-fit: cover;
    text-align: center;
    margin: 0 auto;
    position: relative;
}

.no_btn {
    width: 140px;
    border-radius: 0px;
    background-color: $Body-bg;
    color: $PrimaryColor-text ;
    border: solid 1px $tertiary-text;
  }
  .no_btn:hover {
    background-color: $Body-bg;
    color: $PrimaryColor-text ;
    border: solid 1px $tertiary-text;
  }
  .no_btn:active {
    background-color: $Body-bg !important;
    color: $PrimaryColor-text !important;
    border: solid 1px $tertiary-text !important;
  }
  .no_btn:focus {
    background-color: $Body-bg;
    color: $PrimaryColor-text ;
    border: solid 1px $tertiary-text;
  }
  .img_alert{
    box-shadow: 5px 10px 20px 0 rgb(191 191 191 / 30%);
    border: solid 1px #e5e5e5;
    background-color: #7c7c7c;
    color: $Plain-text;
    padding: 10px 0px 0px 20px;
    border-radius: 3px;
  }

  .no_btn_discard{
    width: 140px;
    height: 27px;
    padding: 1px 5px 5px 5px;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $PrimaryColor-text;
    background-color: $Plain-bg;
    border-radius: 22px;
  }

  .no_btn_discard:hover, .no_btn_discard:active, .no_btn_discard:focus {
    width: 140px;
    height: 27px;
    padding: 1px 5px 5px 5px;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $PrimaryColor-text;
    background-color: $Plain-bg;
    border-radius: 22px;
  }
  .yes_btn {
    width: 140px;
    border-radius: 0px;
  }

  .next_or_label{
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $Plain-text;
  }

.hist_cause_err {
    display: block;
    color: $Error;
    padding-top: 4px;
    font-family: $secondary-font-regular;
    font-size: 14px;
}
.annotate_container {
    max-width: 574px;
    margin: 0 auto;
  }
  .annotate_container .label_spec span {
    color: $PrimaryColor-text !important;
  }


//added for measurement git animation loader
.btn_add_trace, .btn_add_trace:hover {
    width: 130px;
    height: 42px;
    margin: 0;
    padding: 9px 16px 9px 9px;
    border-radius: 2px;
    background-color: $PrimaryButton-bg;
    font-family: $secondary-font-regular;
    color: $Plain-bg;
    font-size: $Typography-2;
}

.no_btn_clr, .no_btn_clr:hover {
    width: 130px;
    height: 42px;
    margin: 0;
    padding: 9px 16px 9px 9px;
    border-radius: 2px;
    font-family: $secondary-font-regular;
    background-color: #fafafa!important;
    color: #333333!important;
    border: solid 1px #cccccc!important;
    font-size: $Typography-2;
}

.button_Right{
    position: absolute;
    z-index: 999;
    top: 34%;
    right: 0;
    overflow: hidden;
  }
  .button_Right_Block{  
    display: block!important;
    overflow: hidden;
  }
    
  //added for measurement git animation loader
  .history_form{
    text-align: initial !important;
  }

  .error_message {
	display: block;
	color: $Error !important;
	padding-top: 4px;
	font-family: $secondary-font-regular !important; 
	font-size:14px !important;
}
.acc_body_cust{
    width: 95%;
    padding: 1px 1px 3px 1px;
  border-top: 1px solid #dbdbdb;
}
.acc_head_cust{
  height: 50px;
  margin-bottom: -10px !important;
}
.acc_btn_cust{   
    padding: 0px 20px 20px 15px !important;
   font-family: $secondary-font-regular !important; 
   font-weight: unset !important;
   margin-top: -3px;
  }
  .acc_btn_cust::after{
    top: 17px !important;
  }
  .cause_icon{
    position: relative;
  }
  .cause_icon img{
    width: 20px;
    height: 20px;
    margin-top: -3px;
  }
  .acc_panel_cust{
    margin-top: 10px;
    height: 200px;
    min-height: 200px;
    overflow-y: scroll;
  }

  .basic_input::placeholder{
    color: #0052cc;
  }
  .other_name_dis{
    color: #c1c7d0;
    width: 99.5%;
    height: 40px;
    padding: 0 5px;
    border-radius: 2px;
    border: solid 1px #c1c7d0;
    background-color:#fafafa
  }
  .other_name_dis::placeholder{
    color: #c1c7d0;
  }
  .custom_control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
  .custom_control_input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 16px;
    height: 16px;
    opacity: 0 !important;
  }
  .custom_control_label {
    position: relative;
    margin-top: 5px;
    margin-bottom: 0;
    vertical-align: top;
    font-family: $secondary-font-regular;
    font-size: 16px;
    color: $PrimaryColor-text;
    line-height: normal;
    padding: 0px;
  }
  
  .custom_control_input.is_invalid ~ .custom_control_label::before,
  .was_validated .custom_control_input:invalid ~ .custom_control_label::before {
    border-color: $PrimaryColor-text;
  }
  .custom_control_input.is_valid ~ .custom_control_label,
  .was_validated .custom_control_input:valid ~ .custom_control_label {
    color: $Error;
  }
  .custom_control_input.is_valid:checked ~ .custom_control_label::before,
  .was_validated
    .custom_control_input:valid:checked
    ~ .custom_control_label::before {
    border-color: $border-box;
    background-color: $CheckBox-bg;
  }
  
  .custom_control_label::after {
    position: absolute;
    left: -1.5rem;
    display: block;
    width: 16px;
    height: 16px;
    content: "";
  }
  
  .custom_control_label::before {
    border-radius: 50% !important;
  }
  .custom_control_inline {
    height: 22px;
  }
  
  .custom_control_inline .custom_control_label {
    font-family: $secondary-font-bold;
    color: $text-color;
    font-size: 14px;
  }
  .custom_control_input:checked ~ .custom_control_label::before {
    color: $Plain-text;
    border-color: $border-box;
    background-color: $CheckBox-bg;
    border-radius: 0;
    width: 16px;
    height: 16px;
  }
  .btn_small,
.btn_small:hover {
    @include btn_primary;
    width:116px;
}
.btn_small_cancel,
.btn_small_cancel:hover {
    @include btn_secondary; 
}