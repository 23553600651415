@import "../styles/standard.module.scss";

.wound_dashboard {
  box-shadow: 5px 10px 20px 0 rgb(191 191 191 / 30%);
  background-color: #ffffff;
  margin-top: 40px;
  padding: 0 0 60px;
  margin-bottom: 20px;
}
.wound_form {
  padding: 40px 32px 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.wound_form .col_spec {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
  display: flex;
}
.wound_input {
  width: 100%;
  height: 40px;
  border-radius: 2px;
  border: solid 1px #c1c7d0;
  background-color: #ffffff;
  padding-left: 10px !important;
}

.wound_select {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border-radius: 2px;
  border: solid 1px #c1c7d0;
  background-color: transparent;
  appearance: none;
}
.select_payortype {
  background: #fff url("../static/images/down-arrow.png") right 10px bottom 10px
    no-repeat;
}
select:focus {
  outline: 0;
}

.btn_custom {
  height: 50px;
  border-radius: 2px;
  font-family: $secondary-font-regular;
  background-color: $PrimaryButton-bg;
  font-size: 18px;
}
.btn_small,
.btn_small:hover {
  @include btn_primary;
  margin-right: 32px;
}
.btn_small_cancel,
.btn_small_cancel:hover,
.btn_small_cancel:active {
  @include btn_secondary;
}
.no_matching_result {
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 200px;
  display: flex;
  font-weight: bold;
  font-size: 22px;
}
.overview_blk {
  background-color: rgba(80, 95, 121, 0.1);
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.overview_top {
  padding:15px 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.overview_title {
  color: #333;
  font-family: $secondary-font-bold;
  font-size: 22px;
  display: inline-block;
  margin: 0;
}
.down_spread {
  color: #0052cc;
  font-family: $secondary-font-bold;
  font-size: 16px;
  display: inline-block;
  cursor: pointer;
}
.down_spread label {
  color: #0052cc;
  text-decoration: none;
  margin-bottom: 0;
  margin-left: 10px;
}
.down_spread, .down_spread :hover {
  color: #0052cc;
  text-decoration: none;
  cursor: pointer;
}
.overview_btm {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.overview_btm p {
  width: 100%;
  margin: 0;
  border-bottom: 1px solid #cecfd0;
  color: #57585a;
  font-family: $secondary-font-regular;
  font-size: 15px;
  padding: 0 32px;
}
.overview_btm p label {
  width: 50%;
  border-right: 1px solid #cecfd0;
  margin-bottom: 0;
  padding: 5px 0;
}
.overview_btm p span {
  width: 50%;
  padding-left: 32px;
  font-family: $secondary-font-bold;
}
.overview_btm a{
  text-decoration: underline;
}

.overview_score {
  width: 100%;
  padding: 40px 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.overview_percentage {
  width: 200px;
  height: 140px;
  background-color: #0052cc;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  box-shadow: 0 18px 32px -4px rgba(136, 136, 136, 0.25);
}
.overview_color {
  background-color: #fff;
}
.dash_top {
  width: 100%;
  color: #ffffff;
  font-family: $secondary-font-bold;
  font-size: 16px;
  padding:10px 15px;
}
.dash_btm {
  width: 100%;
  color: #ffffff;
  font-size: 20px;
  font-family: $primary-font-bold;
  text-align: center;
  margin-bottom: 0;
  bottom: 0;
  position: absolute;
}
.dash_btm span {
  width: 50%;
  text-align: center;
  float: left;
  border-right: 1px solid #cecfd0;
  background-color: rgba(255, 255, 255, 0.1);
  opacity: 0.7;
}
.dash_btm span:last-child {
  border-right: 0;
}
.overview_color .dash_top {
  width: 100%;
  color: #505f79;
  font-family: $secondary-font-bold;
  font-size: 16px;
  padding: 15px;
}
.overview_color .dash_btm {
  width: 100%;
  color: #505f79;
  font-size: 30px;
  font-family: $primary-font-bold;
  text-align: center;
  margin-bottom: 0;
  bottom: 0;
  position: absolute;
}
.overview_color .dash_btm span {
  width: 45%;
  text-align: center;
  float: left;
  border-right: 1px solid #cecfd0;
  background-color: rgba(216, 216, 216, 0.3);
  opacity: 0.6;
}
.overview_percentage .dash_btm span {
  width: 45%;
}
.overview_percentage .dash_btm span:last-child {
  border-right: 0;
  width: 55%;
}
.dash_poa {
  color: #36b37e;
  margin-bottom: 0;
  display: block;
}
.dash_nonpoa {
  color: #e2b901;
  margin-bottom: 0;
  display: block;
  line-height: 160%;
}

.dash_stage {
  color: #e2b901;
  margin-bottom: 0;
  display: block;
  font-size: 12px;
  line-height: 160%;
}

.dash_color {
  color: #de350b;
}

.custom_control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.custom_control_input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 16px;
  height: 16px;
  opacity: 0;
}

.custom_control_label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
  font-family: $secondary-font-regular;
  font-size: 14px;
  color: #333333;
  font-weight: bold; 
}

.custom_checkbox {
  padding: 8px 30px;
  font-family: $secondary-font-regular;
  color: #808080;
  font-size: 16px;
  line-height: normal;
}

.custom_control_inline {
  display: inline !important;
}
.label_spec {
  margin-bottom: 0;
  font-family: $secondary-font-bold;
}

.overview_percentage {
  width: 200px;
  height: 140px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  box-shadow: 0 18px 32px -4px rgba(136, 136, 136, 0.25);
}
.overview_percentage:hover,
.overview_percentage.active {
  width: 200px;
  height: 140px;
  background-color: #0052cc;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  box-shadow: 0 18px 32px -4px rgba(136, 136, 136, 0.25);
}
.overview_percentage .dash_top {
  color: #505f79;
  cursor: pointer;
}
.overview_percentage:hover .dash_top,
.overview_percentage.active .dash_top {
  color: #fff;
}
.overview_percentage:hover .dash_top span,
.overview_percentage.active .dash_top span {
  color: #fff !important;
}

.overview_percentage .dash_btm {
  width: 100%;
  color: #ffffff;
  font-size: 20px;
  font-family: $primary-font-bold;
  text-align: center;
  margin-bottom: 0;
  bottom: 0;
  position: absolute;
  cursor: pointer;
  height:50px;
  line-height: 50px;
}
.overview_percentage:hover .dash_btm,
.overview_percentage .active .dash_btm {
  width: 100%;
  color: #ffffff;
  font-size: 20px;
  font-family: $primary-font-bold;
  text-align: center;
  margin-bottom: 0;
  bottom: 0;
  position: absolute;
  
}
.overview_percentage .dash_btm span {
  width: 50%;
  text-align: center;
  float: left;
  border-right: 1px solid #cecfd0;
  background-color: rgba(216, 216, 216, 0.3);
  color: #505f79;
}
.overview_percentage.active .dash_btm span {
  width: 50%;
  text-align: center;
  float: left;
  border-right: 1px solid #cecfd0;
  background-color: rgba(216, 216, 216, 0.3);
  color: #fff;
}
.overview_percentage .dash_btm span:last-child {
  width: 50%;
  border-right: 0;
}
.overview_percentage.active .dash_btm span:last-child {
  width: 50%;
  border-right: 0;
}

.overview_percentage:hover .dash_btm span {
  width: 50%;
  text-align: center;
  float: left;
  border-right: 1px solid #cecfd0;
  background-color: rgba(216, 216, 216, 0.3);
  color: #fff;
}
.overview_percentage:hover .dash_btm span:last-child {
  border-right: 0;
  width: 50%;
}
.bir_subline {
  color: #505f79 !important;
  font-family: $secondary-font-regular !important;
  font-size: 12px !important;
  display: block !important;
}
.form_span {
  color: #0052cc;
  margin-left: 10px;
  cursor: pointer;
}

//hyperlink
.commonCusrsor{
  cursor: pointer;
  color: #0056b3;
  text-decoration: underline;
}