@import "../../../styles/standard.module.scss";
@import "../../../styles/common.module.scss";
.back_btn a {
  @include response-above(sm) {
    position: fixed;
    top: 22px;
    left: 25px;
    background: url(../../../static/images/back-arrow.png) center left no-repeat;
    text-indent: 999px;
  }
  @include response-above(lg) {
    position: static;
    top: auto;
    left: auto;
    color: $PrimaryColor-text !important;
    background: url(../../../static/images/left_arrow.png) center left no-repeat;
    padding-left: 10px;
    font-family: $secondary-font-regular;
    font-size: 16px;
  }
}
.wound_dashboard {
  box-shadow: 5px 10px 20px 0 rgb(191 191 191 / 30%);
  background-color: #ffffff;
  margin-top: 40px;
  padding: 0 0 20px;
}

.platform_form {
  padding: 40px 32px 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.platform_form .col_spec {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
  display: flex;
}

.platform_content {
  padding: 30px 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.platform_support {
  border: 1px solid #cecfd0;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.platform_support:only-child {
  border: 1px solid #cecfd0 !important;
}
.platform_support:last-child {
  border-top: 0;
}

.platform_support .platform_support_left {
  background-color: #f2f3f4;
  border-right: 1px solid #cecfd0;
  font-family: $secondary-font-regular;
  font-size: 15px;
  color: #000;
  width: 50%;
  padding: 10px 0;
  padding-left: 30px;
}
.platform_support .platform_support_right {
  background-color: #fff;
  border-right: 1px solid #cecfd0;
  font-family: $secondary-font-bold;
  font-size: 15px;
  color: #2c282b;
  width: 25%;
  padding: 10px 0;
  text-align: center;
}
.platform_support .platform_support_right:last-child {
  border: 0;
}
.pa_subtitle {
  font-family: $primary-font-regular;
  font-size: 20px;
  color: #505f79;
}

.pa_score {
  width: 100%;
  padding: 20px 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.pa_percentage {
  width: 174px;
  height: 140px;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  box-shadow: 0 18px 32px -4px rgba(136, 136, 136, 0.25);
}
.pa_percentage:hover,
.pa_percentage.active {
  width: 174px;
  height: 140px;
  background-color: #0052cc;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  box-shadow: 0 18px 32px -4px rgba(136, 136, 136, 0.25);
}
.pa_percentage .dash_top {
  color: #505f79;
}
.pa_percentage:hover .dash_top,
.pa_percentage.active .dash_top {
  color: #fff;
}

.pa_percentage .dash_btm {
  width: 100%;
  color: #ffffff;
  font-size: 20px;
  font-family: $primary-font-bold;
  text-align: center;
  margin-bottom: 0;
  bottom: 0;
  position: absolute;
  cursor: pointer;
}
.pa_percentage:hover .dash_btm {
  width: 100%;
  color: #ffffff;
  font-size: 20px;
  font-family: $primary-font-bold;
  text-align: center;
  margin-bottom: 0;
  bottom: 0;
  position: absolute;
  cursor: pointer;
}
.pa_percentage .dash_btm span {
  width: 50%;
  text-align: center;
  float: left;
  border-right: 1px solid #cecfd0;
  background-color: rgba(216, 216, 216, 0.3);
  color: #505f79;
}
.pa_percentage .dash_btm span:last-child {
  width: 50%;
  border-right: 0;
}

.pa_percentage:hover .dash_btm span,
.pa_percentage.active .dash_btm span {
  width: 50%;
  text-align: center;
  float: left;
  border-right: 1px solid #cecfd0;
  background-color: rgba(216, 216, 216, 0.3);
  color: #fff;
}
.pa_percentage:hover .dash_btm span:last-child,
.pa_percentage.active .dash_btm span:last-child {
  border-right: 0;
  width: 50%;
}

.title_top {
  padding: 10px 30px;
  background-color: rgba(80, 95, 121, 0.1);
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
}
.bi_title {
  color: #333;
  font-family: $secondary-font-bold;
  font-size: 22px;
  display: inline-block;
  margin: 0;
}
.bi_subline {
  color: #505f79 !important;
  font-family: $secondary-font-regular !important;
  font-size: 12px !important;
  display: block !important;
}

.dash_top {
  width: 100%;
  color: #ffffff;
  font-family: $secondary-font-bold;
  font-size: 16px;
  padding: 10px 15px 15px 15px;
  cursor: pointer;
  line-height: normal;
}
.dash_btm {
  width: 100%;
  color: #ffffff;
  font-size: 20px;
  font-family: $primary-font-bold;
  text-align: center;
  margin-bottom: 0;
  bottom: 0;
  position: absolute;
  height:50px;
  line-height: 50px;
}
.dash_btm span {
  width: 50%;
  text-align: center;
  float: left;
  border-right: 1px solid #fff;
  background-color: rgba(255, 255, 255, 0.1);
  opacity: 0.7;
}
.down_spread {
  color: #0052cc !important;
  font-family: $secondary-font-bold;
  font-size: 16px;
  display: inline-block;
  text-decoration: none;
  margin-right: 8px;
  a {
    text-decoration: none;
    color: #0052cc !important;
  }
}
.down_pdf {
  cursor: pointer;
  color: #0052cc;
  font-family: $secondary-font-bold;
  font-size: 16px;
  display: inline-block;
  text-decoration: none;
  position: absolute;
  right: 0;
  top: 15px;
  margin-right: 30px;
  label {
    cursor: pointer;
    text-decoration: none;
  }
}
.label_spec {
  margin-bottom: 0;
  font-family: $secondary-font-bold;
  line-height: normal;
}
.wound_input {
  width: 100%;
  height: 40px;
  border-radius: 2px;
  border: solid 1px #c1c7d0;
  background-color: #ffffff;
  padding-left: 10px;
}
.calendar_right {
  position: absolute;
  right: 10px;
  top: 5px;
}
.overview_blk {
  background-color: rgba(80, 95, 121, 0.1);
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.btn_custom {
  height: 50px;
  border-radius: 2px;
  font-family: $secondary-font-regular;
  background-color: $PrimaryButton-bg;
  font-size: 18px;
}
.btn_small,
.btn_small:hover {
  @include btn_primary;
  margin-right: 32px;
}
.btn_small_cancel,
.btn_small_cancel:hover,
.btn_small_cancel:active {
  @include btn_secondary;
}
.empty {
  margin: 30px;
  position: relative;
  z-index: 1;
  .empty_heading {
    height: 76px;
    padding: 0px 10px;
    background-color: #505f79;
    line-height: 56px;
    color: #fff;
    position: relative;
    z-index: 7;
    font-size: 14px;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    span {
      margin: 0px 15px;
    }
  }
  .empty_body {
    min-height: 100px;
    box-shadow: 0px 0px 15px #eee;
    text-align: center;
    position: relative;
    z-index: 6;
    h5 {
      line-height: 100px;
      margin: 0;
    }
  }
}
.list_outer {
  margin: 18px 22px 40px 18px;
}
.header {
  padding: 50px;
  h6 {
    font-weight: normal;
    b {
      font-weight: 600;
    }
  }
}
.info_message {
  margin: 20px 0;
  text-align: center;
  img {
    max-width: 50px;
  }
  h3 {
    font-weight: 400;
  }
}

.loader_fix {
  text-align: center;
  margin: 10px 0;
  h3 {
    margin-bottom: 10px;
  }
  img {
    width: 50px;
    border-radius: 100%;
  }
}

.pa_percentage_pdf {
  width: 174px;
  height: 140px;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  border: 1px solid #bbb;
}
.pa_percentage_pdf .dash_top {
  color: #505f79;
}
.pa_percentage_pdf .dash_btm {
  width: 100%;
  color: #ffffff;
  font-size: 22px;
  line-height: 36px;
  font-family: $primary-font-bold;
  text-align: center;
  margin-bottom: 0;
  bottom: 0;
  position: absolute;
  cursor: pointer;
  border-top: 1px solid #bbb;
}
.pa_percentage_pdf .dash_btm span {
  width: 45%;
  text-align: center;
  float: left;
  border-right: 1px solid #cecfd0;
  background-color: rgba(216, 216, 216, 0.3);
  color: #505f79;
}
.pa_percentage_pdf .dash_btm span:last-child {
  width: 55%;
  border-right: 0;
}

.header {
  h1 {
    font-weight: normal;
  }
}
