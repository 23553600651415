@import '../../../styles/standard.module.scss';
/* vertical tabs */

.vertical_tab{
    width:100%;
    .react_tabs {   
        display: inline-block;
        margin-right: 10px;
        border-bottom:0;
        border:0; 
        width: 290px !important;   
        list-style: none;   
        background-color:$vertical-tab-bg;
        padding: 10px 0 0 10px;
    }
    .react_tabs_list{
        background-color:$Plain-bg;
        padding:10px 0 10px 15px;
        width:280px; 
        font-family: $secondary-font-regular; 
        font-size:15px;
        margin-bottom:10px;
        color:$PrimaryColor-text; 
        opacity: 0.7;    
        margin-right: 10px; 
        cursor: pointer;
        position: static;
        //transition: padding-left 0.4s ease-in;
    }
    .indication_btn{
            font-size: 11px;
            background-color: $review-pending;
            font-weight: bold;
            color: $Plain-text;
            padding: 5px 10px;           
            border-radius: 4px;
            float: none;
            width: auto;
            display: inline-block;
            margin-top: 10px;        
    }
    .react_tab_pane{
        width:calc(100% - 320px);        
        vertical-align: top;
        display: none;
    }
    .react_tab_pane>.active {
        display: block;  
        width: calc(100% - 350px);  
    }
    .react_tabs_list.selected{
        display: inline-block;
        padding:12px 0 12px 15px;
        width: 100%;
        box-shadow: none;
        background-color: $Plain-bg;
        font-family: $secondary-font-bold;   
        opacity: 1; 
        margin-right: 0;
        //transition: padding-left 0.3s ease-out;
    }
    .react_tab_pane.selected{
        padding: 10px;
        box-shadow: none;
        background-color: $Plain-bg;
        display: inline-block;
    }
    .custom-control-inline{
        display: inline  !important;
    }
}