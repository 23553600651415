@import '../../../styles/standard.module.scss';

.open {display:block}
.hide {display: none;}
.modal_title{
    font-size:28px;
    font-family: $primary-font-bold;
    color:$PrimaryColor-text;
    margin-top:5px;    
    span {
        font-family:$secondary-font-bold;
        font-size: 14px;
        color: #333;
        opacity: 0.7;
    }
}
.serviceErrrAccess{
    position: absolute;
    top:20px;
    left: 0;
    width:100%;
    height: 100%;
    background-color:rgba(255, 255, 255, 0.7);
    z-index: 1;
}
.modal_header{
    background-color:$modal-header-bg;
    padding-top:11px;
    padding-bottom: 11px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}
.access_error_size{
    max-width: 700px;
}
.check_left_block_menu{
    z-index:  0!important;;
}
.overlay_check{
    z-index: 10001;
}