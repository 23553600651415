@import "../../../styles/standard.module.scss";
.list_menu_postion {
  border: solid 1px #e7e7e7;
  background-color: $Plain-bg;
  box-shadow: 5px 10px 20px 0 rgba(191, 191, 191, 0.3);
  position: absolute;
  top: 42px;
  right: 0px;
  z-index: 1040;
  width: 219px;
  text-align: left;
}
.list_menu_postion div {
  color: $PrimaryColor-text;
  padding: 16px 0px 16px 23px;
  font-family: $secondary-font-regular;
  background-color: $Plain-bg;
}
.list_menu_postion div:hover {
  cursor: pointer;
  background-color: #e9ecef;
}
.list_menu_postion div:not(:last-child) {
  border-bottom: 1px solid $tertiary-text;
}
.custom_modal_size {
  max-width: 968px !important;
}
.modal_heading {
  font-size: 1.5rem;
  font-weight: 700;
}
.label_spec {
  color: $PrimaryColor-text;
  font-weight: 600;
  font-family: $secondary-font-bold;
  padding-top: 8px;
}
.input_field,
.input_field:focus {
  width: 150px;
  height: 40px;
  padding: 0 5px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
  outline: none;
}
.icon_align {
  justify-content: flex-start !important;
}
.btn_cancel,
.btn_cancel:hover,
.btn_cancel:active {
  @include btn_secondary;
}
.btn_save,
.btn_save:hover,
.btn_save:active {
  @include btn_primary;
  margin-right: 32px;
}
.small_input {
  max-width: 74px !important;
}
.small_space {
  margin-bottom: 70px;
}
.small_space_one {
  margin-bottom: 34px;
}
.general_error {
  margin-bottom: 10px;
  color: $Plain-text;
  border-radius: 3px;
  padding: 5px 15px;
  display: inline-block;
  background-color: $Error;
}

.select_wrapper {
  background: url("../../../static/images/down-arrow.png") right 10px center
    no-repeat;
  border-radius: 2px;
  background-color: $Plain-bg;
  height: 40px;
  border: solid 1px $border-color;
}
.basic_select {
  width: 100%;
  height: 40px;
  padding: 0 25px 0 10px;
  appearance: none;
  -webkit-appearance: none;
  outline: 0;
  background: 0;
  border: 0;
}
select::-ms-expand {
  display: none;
}
.bir_subline {
  color: #505f79 !important;
  font-family: $secondary-font-regular !important;
  font-size: 12px !important;
  display: block !important;
}
.label_cutom {
  font-family: $secondary-font-bold;
  font-size: 16px;
  font-weight: 600;
  color: $PrimaryColor-text;
}


.input_field_cust {
  margin-left: -38px;
}
.btn_custom {
  height: 50px;
  border-radius: 2px;
  font-family: $secondary-font-regular;
  background-color: $PrimaryButton-bg;
  font-size: 18px; 
  margin: 0 10px;
}

.btn_small,
.btn_small:hover {
  @include btn_primary;
  img{
      margin-right: 10px;
  }    
}
.btn_position{
margin-left:-84px;      
}
.media_align {
  top: -120px;
  // right: 30px;
  left: 73px;
  width: 400px;
  padding: 0 0 10px;
  border: solid 1px #e5e5e5;
  z-index: 1600;
}
.disabled{
  pointer-events: none;
}
.form_span {
  color: $secondary-text;
  margin-left: 10px;
  cursor: pointer;
}
.form_span.disabled {
  cursor: not-allowed;
}
.img_left {
  margin-right: -50px;
}
.list_custom{
  margin-left: -90px;
  display: inline-flex;

  // word-break: break-all;
}
.form_OR{
  margin-left: 240px;
  margin-top: -10px;
  padding-bottom: 15px;
}
.close_icon {
  cursor: pointer;
}
.Or_Align{
  margin-left: -24px;
  padding-bottom: 15px;
}

.error_message {
  display: block;
  color: #de350b;
  font-family: $secondary-font-regular;
  font-size: 14px;
}
.error_PDF{
  margin-top: -33px;
  margin-left: 15px;	   
}

.media_view{
  margin: 0px;
  ul{
      margin: 0;
      padding: 0;
      list-style-type: none;
      li{
        display: flex;
          &:last-child{
              border-bottom: 0;
          }
          a{
            line-height: 1em;
            height: 1em;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 3px;
              &:hover{
                color: #007bff;
                text-decoration: underline;
                  // font-weight: bold;
              }
          }
          > img{
            left: -8px;
            top: 5px;
          }
          >span{
            margin: -4px 2px 2px 2px;
              cursor: pointer;
              &:hover{
                  filter: brightness(70%);
              }
          }
          >span img{
            width: 18px;
            height: 18px;
        }
      }
  }
}

.errStar{
	color: $Error;
}
.disable_field{
	background-color: #dbdbdb !important;
  pointer-events: none;
}

.root_tag{
  position: relative;
  padding: 6px 0 0 6px;
  border: 1px solid #D1D1D1;
  border-radius: 1px;
  font-size: 13px;
  line-height: 1.2;
  width: 99%;
  height: 100px;
  margin-left: 0px;
  display: inline-table;
}

.input_icons {
  padding-left: 15px!important;
  padding-right: 0!important;
}

.x_icon {
  position: absolute;
  right: 18%;
  top: 8px;
  cursor: pointer;
}

.excla_icon{
  position: absolute;
  left: -18px;
  top: 0px;
  cursor: pointer;
  font-size: 24px;
}
.search_drop{  
  top: 0px;
    left: 48%;
    position: absolute;
    z-index: 999 !important;
    background-color: #ffffff;
    width: 42%;
}

.search_close_box {
  border: 1px solid $border-color;
  padding: 5px 5px;
  max-height: 250px;
  overflow-y: auto;
  
}
.search_close_box p {
  margin-bottom: -1px;
  padding: 6px;
  cursor: pointer !important;
}

.input_field_cust_num,
.input_field_cust_num:focus {
  height: 40px;
  padding: 0 5px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
  outline: none;
}