@import "../../../styles/standard.module.scss";
.tab_pane {
  padding: 0 0 41px;
  box-shadow: 5px 10px 20px 0 rgb(191 191 191 / 30%);
  background-color: $Plain-bg;
}
.container_wrapper {
  padding: 19px 24px;
  border-bottom: 1px solid $tab-border-bottom;
}
.heading {
  font-family: $secondary-font-bold;
  font-size: 24px;
  font-weight: 600;
  color: $PrimaryColor-text;
}
.edit_icon {
  display: flex;
  align-items: center;
}
.edit_icon:hover {
  cursor: pointer;
}
.label_cutom {
  font-family: $secondary-font-bold;
  font-size: 16px;
  font-weight: 600;
  color: $PrimaryColor-text;
}
.label_cutom span {
  color: $Error !important;
}
.select_wrapper {
  border: solid 1px $tertiary-text;
}
.input_field,
.input_field:focus {
  width: 248px;
  height: 40px;
  padding: 0 5px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
  outline: none;
}
.small_input {
  max-width: 74px !important;
}
.btn_container {
  margin-top: 73px;
  text-align: center;
}
.btn_cancel,
.btn_cancel:hover,
.btn_cancel:active {
  @include btn_secondary;
}
.btn_save,
.btn_save:hover,
.btn_save:active {
  @include btn_primary;
  margin-right: 32px;
}
.data_color {
  color: $PrimaryColor-text;
}
.icon_align {
  justify-content: flex-start !important;
}
.general_error {
  margin-bottom: 10px;
  color: $Plain-text;
  border-radius: 3px;
  padding: 5px 15px;
  display: inline-block;
  background-color: $Error;
}
.col_spec {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
  display: flex;
}
.label_spec {
  margin-bottom: 0;
  font-family: $secondary-font-bold;
  line-height: normal;
}
.label_spec span {
  color: $Error !important;
}
.banner_size {
  color: #96a6bf;
  font-size: 14px;
}
.img_fluid {
  max-width: 100%;
  height: 187px;
  width: 297px;
  object-fit: cover;
}
.content_spec {
  font-family: $secondary-font-regular;
  color: $PrimaryColor-text;
}
.banner_link {
  color: $secondary-text;
  font-size: 13px;
}
.banner_link:hover {
  color: #a6bcd3;
  text-decoration: underline;
  cursor: pointer;
}
.form_alert {
  display: inline-block !important;
}
.pos_delete {
  right: 0;
  position: absolute;
}
.add_banner_txt,
.add_banner_txt:hover,
.add_banner_txt:focus {
  color: $secondary-text !important;
  background-color: $Plain-bg !important;
  border: 0;
  font-size: 16px;
  font-family: $secondary-font-regular;
  font-weight: bold;
  margin-left: -5px;
}
.plusimg {
  padding-right: 10px;
}
.select_wrapper {
  background: url("../../../static/images/down-arrow.png") right 10px center
    no-repeat;
  border-radius: 2px;
  background-color: $Plain-bg;
  height: 40px;
  border: solid 1px $border-color;
}
.basic_select {
  width: 100%;
  height: 40px;
  padding: 0 25px 0 10px;
  appearance: none;
  -webkit-appearance: none;
  outline: 0;
  background: 0;
  border: 0;
}
select::-ms-expand {
  display: none;
}
