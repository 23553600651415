@import "../../../styles/standard.module.scss";
@import "../../../styles/common.module.scss";
.back_btn a {
  @include response-above(sm) {
    position: fixed;
    top: 22px;
    left: 25px;
    background: url(../../../static/images/back-arrow.png) center left no-repeat;
    text-indent: 999px;
  }
  @include response-above(lg) {
    position: static;
    top: auto;
    left: auto;
    color: $PrimaryColor-text !important;
    background: url(../../../static/images/left_arrow.png) center left no-repeat;
    padding-left: 10px;
    font-family: $secondary-font-regular;
    font-size: 16px;
  }
}
.btn_small,
.btn_small:hover {
  width: 130px;
  height: 45px;
  font-family: $secondary-font-regular;
  background-color: $PrimaryButton-bg;
  border: 1px solid $PrimaryButton-bg;
  color: $Plain-text;
  font-size: 16px;
  border-width: 1px;
}
.btn_small_cancel,
.btn_small_cancel:hover {
  width: 130px;
  height: 45px;
  font-family: $secondary-font-regular;
  background-color: $Plain-bg !important;
  border: 1px solid $PrimaryColor-text !important;
  color: $PrimaryColor-text !important;
  font-size: 16px;
}
.btn_custom {
  border-radius: 2px;
  margin: 10px 5px;
  padding: 5px 0;
}

.info_message {
  margin: 20px 0;
  text-align: center;
  img {
    max-width: 50px;
  }
  h3 {
    font-weight: 400;
  }
}

.loader_fix {
  text-align: center;
  margin: 10px 0;
  h3 {
    margin-bottom: 10px;
  }
  img {
    width: 50px;
    border-radius: 100%;
  }
}

.content > article {
  padding: 20px 50px;
  font-size: 16px;
  h2 {
    color: #f16278;
  }
  h4 {
    background-color: #eee;
    padding: 7px 15px;
    color: #777;
    font-size: 18px;
    margin: 0;
  }
}

.header {
  padding: 50px;
  h6 {
    font-weight: normal;
    b {
      font-weight: 600;
    }
  }
}
.score {
  p {
    text-align: center;
    display: inline-block;
    width: 100px;
    height: 100px;
    background-color: #f7f7f7;
    margin-right: 5px;
    border: 1px solid #ddd;
    font-size: 20px;
    padding-top: 13px;
    b {
      font-weight: 600;
    }
  }
}
.mini_hero {
  background-color: #f3f3f3;
  border: 1px solid #ddd;
  padding: 25px !important;
  margin: 0 50px;
  p {
    margin: 5px 0;
    font-size: 15px;
  }
}

.image {
  padding: 30px;
}
.image img {
  max-width: 250px;
}

.chart {
  .chart_width {
    width: 100%;
    max-width: 700px;
  }
  img {
    max-width: 300px;
  }
}

.tabley {
  table {
    margin: 0;
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    font-size: 14px;
  }

  th {
    border: 0;
  }

  tr:nth-child(odd) {
    background-color: #eee;
  }
}
.treatment_field {
  h5 {
    font-weight: 600;
    font-size: 15px;
    line-height: 15px;
    margin: 0;
    margin-bottom: 5px;
  }
  h6 {
    font-weight: normal;
    font-size: 13px;
    margin: 0;
    line-height: 13px;
    margin-bottom: 5px;
  }
  strong {
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.03em;
    color: #fff;
    background-color: #36b37e;
    padding: 3px 10px;
    border-radius: 4px;
  }
}
.logo {
  max-width: 50px;
  margin-bottom: 5px;
}

.overview_btm {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid #cecfd0;
}
.overview_btm p {
  width: 100%;
  margin: 0;
  border-bottom: 1px solid #cecfd0;
  color: #57585a;
  font-family: $secondary-font-regular;
  font-size: 15px;
  padding: 0 32px;
}
.overview_btm p label {
  width: 50%;
  border-right: 1px solid #cecfd0;
  margin-bottom: 0;
  padding: 5px 0;
}
.overview_btm p span {
  width: 50%;
  padding-left: 32px;
  font-family: $secondary-font-bold;
}

.overview_score {
  width: 100%;
  padding: 40px 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.overview_percentage {
  width: 200px;
  height: 140px;
  background-color: #0052cc;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  box-shadow: 0 18px 32px -4px rgba(136, 136, 136, 0.25);
}
.overview_color {
  background-color: #fff;
}
.dash_top {
  width: 100%;
  color: #ffffff;
  font-family: $secondary-font-bold;
  font-size: 16px;
  padding:10px 15px;
}
.dash_btm {
  width: 100%;
  color: #ffffff;
  font-size: 20px;
  font-family: $primary-font-bold;
  text-align: center;
  margin-bottom: 0;
  bottom: 0;
  position: absolute;
  height:50px;
  line-height:50px;
}
.dash_btm span {
  width: 50%;
  text-align: center;
  float: left;
  border-right: 1px solid #cecfd0;
  background-color: rgba(255, 255, 255, 0.1);
  opacity: 0.7;
}
.dash_btm span:last-child {
  border-right: 0;
}
.overview_color .dash_top {
  width: 100%;
  color: #505f79;
  font-family: $secondary-font-bold;
  font-size: 16px;
  padding: 15px;
}
.overview_color .dash_btm {
  width: 100%;
  color: #505f79;
  font-size: 20px;
  font-family: $primary-font-bold;
  text-align: center;
  margin-bottom: 0;
  bottom: 0;
  position: absolute;
  height:50px;
  line-height:50px;
}
.overview_color .dash_btm span {
  width: 50%;
  text-align: center;
  float: left;
  border-right: 1px solid #cecfd0;
  background-color: rgba(216, 216, 216, 0.3);
  opacity: 0.6;
}
.overview_percentage .dash_btm span {
  width: 50%;
}
.overview_percentage .dash_btm span:last-child {
  border-right: 0;
  width: 50%;
}
.dash_poa {
  color: #36b37e;
  margin-bottom: 0;
  display: block;
}
.dash_nonpoa {
  color: #e2b901;
  margin-bottom: 0;
  display: block;
  line-height: 160%;
}

.dash_stage {
  color: #e2b901;
  margin-bottom: 0;
  display: block;
  font-size: 12px;
  line-height: 160%;
}

.dash_color {
  color: #de350b;
}
.overview_percentage {
  width: 19%;
  height: 140px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  box-shadow: 0 18px 32px -4px rgba(136, 136, 136, 0.25);
  border: 1px solid #cecfd0;
  margin-bottom: 20px;
}
.overview_percentage:hover,
.overview_percentage.active {
  width: 19%;
  height: 140px;
  background-color: #0052cc;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  box-shadow: 0 18px 32px -4px rgba(136, 136, 136, 0.25);
}
.overview_percentage .dash_top {
  color: #505f79;
  cursor: pointer;
}
.overview_percentage:hover .dash_top,
.overview_percentage.active .dash_top {
  color: #fff;
}
.overview_percentage:hover .dash_top span,
.overview_percentage.active .dash_top span {
  color: #fff !important;
}

.overview_percentage .dash_btm {
  width: 100%;
  color: #ffffff;
  font-size: 20px;
  font-family: $primary-font-bold;
  text-align: center;
  margin-bottom: 0;
  bottom: 0;
  position: absolute;
  cursor: pointer;
}
.overview_percentage:hover .dash_btm,
.overview_percentage .active .dash_btm {
  width: 100%;
  color: #ffffff;
  font-size: 20px;
  font-family: $primary-font-bold;
  text-align: center;
  margin-bottom: 0;
  bottom: 0;
  position: absolute;
}
.overview_percentage .dash_btm span {
  width: 50%;
  text-align: center;
  float: left;
  border-right: 1px solid #cecfd0;
  background-color: rgba(216, 216, 216, 0.3);
  color: #505f79;
  padding: 0 5px;
}
.overview_percentage.active .dash_btm span {
  width: 50%;
  text-align: center;
  float: left;
  border-right: 1px solid #cecfd0;
  background-color: rgba(216, 216, 216, 0.3);
  color: #fff;
}
.overview_percentage .dash_btm span:last-child {
  width: 50%;
  border-right: 0;
}
.overview_percentage.active .dash_btm span:last-child {
  width: 50%;
  border-right: 0;
}

.overview_percentage:hover .dash_btm span {
  width: 50%;
  text-align: center;
  float: left;
  border-right: 1px solid #cecfd0;
  background-color: rgba(216, 216, 216, 0.3);
  color: #fff;
}
.overview_percentage:hover .dash_btm span:last-child {
  border-right: 0;
  width: 50%;
}
