@import "../../../styles/standard.module.scss";

.error_border {
	border: 1px solid $Error !important;
}
.error_message { 
	display: block;
	color: $Error;
	padding-top: 4px;
	font-family: $secondary-font-regular;
	font-size:14px;
}
.text_field_label {
	font-size: $Typography-2;
	color: $PrimaryColor-text;
	font-family: $secondary-font-regular;
}
.form_control:focus {
	box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%);
	border-radius: 2px;
}
.form_control {
	border-radius: 2px;
}
.eyeopen, .eyeclose{
    position: absolute;
    right: 10px;
    top: 6px;
}

.char_count{
    float: right;
    color: $hint-normal;
    font-family: $secondary-font-bold;
    font-size: 16px;
}
.char_count_font
{
	vertical-align: inherit;
}

.risk_content{
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .risk_content .col_spec {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
    display: flex;
  }
  .label_spec span{
    margin-bottom: 0;
    font-family: $secondary-font-regular;
    font-size: 16px;
    line-height: normal;
  }
  
  
  .risk_select_box{
    width: 100%;
  height: 40px;
  margin: 4px 0 7px 0px;
  padding: 0;
  border-radius: 2px;
  border: solid 0px $border-color;
  background-color: $Plain-bg;
  }
  .risk_select_box .dropdown_toggle{
    width:100% !important;
    background-color: $Plain-bg !important;
    color: $PrimaryColor-text !important;
    text-align: left !important;
    border-radius: 1px !important;
    border-color: $bdr-color-tertiary !important;
    margin-top: -5px;
  }
  .risk_drop_down{
    width: 100% !important;
    max-height: 320px !important;
    overflow-y: auto !important;
  }
  .risk_drop_down .risk_item_wrap{
    white-space:normal !important;
    padding:5px 10px !important;
  }
  .risk_select_box  .drop_arrow:after{
    border:0 !important;
    background:url("../../../static/images/down-arrow.png")  right  center no-repeat !important;
    position: absolute !important;
    right: 10px;
    top: 5px;
    width: 17px;
    height: 19px;
  }

  .cat{
    font-weight: bold;
  }
  .risk_select_box .dropdown_toggle > div{
    overflow: hidden;
  }
  .accordion_panel {
    border-top: 0px solid $accod-border;
    padding-left: 40px;
    padding-bottom: 3px;
  }

  .custom_control_input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 16px;
    height: 16px;
    opacity: 0;
  }
  .custom_control_label {
    position: relative;
    margin-top: 5px;
    margin-bottom: 0;
    vertical-align: top;
    font-family: $secondary-font-regular;
    font-size: 15px;
    color: $PrimaryColor-text;
    line-height: normal;
    padding: 4px;
  }
  .custom_control_input.is_invalid ~ .custom_control_label::before,
  .was_validated .custom_control_input:invalid ~ .custom_control_label::before {
    border-color: $PrimaryColor-text;
  }
  .custom_control_input.is_valid ~ .custom_control_label,
  .was_validated .custom_control_input:valid ~ .custom_control_label {
    color: $Error;
  }
  .custom_control_input.is_valid:checked ~ .custom_control_label::before,
  .was_validated
    .custom_control_input:valid:checked
    ~ .custom_control_label::before {
    border-color: $border-box;
    background-color: $CheckBox-bg;
  }
  .custom_control_label::after {
    position: absolute;
    top: 5px;
    left: -1.5rem;
    display: block;
    width: 16px;
    height: 16px;
    content: "";
  }
  .custom_control_label::before {
    border-radius: 50% !important;
    top: 7px;
  }
  .custom_control_inline {
    height: 22px;
  }
  
  .custom_control_inline .custom_control_label {
    font-family: $secondary-font-bold;
    color: $text-color;
    font-size: 14px;
  }
  .custom_control_input:checked ~ .custom_control_label::before {
    color: $Plain-text;
    border-color: $border-box;
    background-color: $CheckBox-bg;
    border-radius: 0;
    width: 16px;
    height: 16px;
    top: 5px;
  }
  .basic_input{
    width: 99.5%;
    height: 40px;
    padding: 0 5px;
    border-radius: 2px;
    border: solid 1px #0052cc;
    background-color: #ffffff;
    color: #0052cc;
  }

  /*Toggle button Style*/
.toggle_shells{
  padding-left: 55px;
}
.toggle_switch {
  transform: scale(1.4, 1.1);
}
.error_message {
	display: block;
	color: $Error !important;
	padding-top: 4px;
	font-family: $secondary-font-regular !important; 
	font-size:14px !important;
}
.acc_body_cust{
  width: 97%;
  padding: 2px 0px 1px 1px;
  border-top: 1px solid #dbdbdb;
}
.acc_head_cust{
  height: 50px;
  margin-bottom: -10px !important;
}
.acc_btn_cust{   
   padding: 16px 21px 19px 16px !important;
   font-family: $secondary-font-regular !important; 
   font-weight: unset !important;
  }
  .acc_btn_cust::after{
    top: 17px !important;
  }
  .cause_icon{
    position: relative;
  }
  .cause_icon img{
    width: 20px;
    height: 20px;
    margin-top: -3px;
  }
  .acc_panel_cust{
    margin-top: 10px;
    height: 200px;
    min-height: 200px;
    overflow-y: scroll;
  }

  .basic_input::placeholder{
    color: #0052cc;
  }
  .other_name_dis{
    color: #c1c7d0;
    width: 99.5%;
    height: 40px;
    padding: 0 5px;
    border-radius: 2px;
    border: solid 1px #c1c7d0;
    background-color:#fafafa
  }
  .other_name_dis::placeholder{
    color: #c1c7d0;
  }