/* polygon {
  stroke: #36b37e;
  fill: rgba(54, 179, 126, 0.32);
  stroke-width: 5px;
}
polyline {
  stroke: #36b37e;
  fill: rgba(54, 179, 126, 0.32);
  stroke-width: 5px;
}
.div-root-35 {
  border-radius: 50%;
  background-color: #36b37e;
  border: 3px solid #fff !important;
}
.makeStyles-fixedRegionLabel-10 {
  display: none;
}
.div-root-6 {
  display: none !important;
}
.div-root-14 {
  display: none !important;
}
.makeStyles-zoomIndicator-9 {
  display: none !important;
}
.makeStyles-canvas-8 {
  max-width: 800px;
}
.div-root-3 {
  max-width: 800px;
  margin: 0 auto;
}
polygon > .div-root-35{
  border-radius: 50%;
  background-color: #36b37e;
  border: solid 5px yellow !important;
} */
/* .div-root-37 {
  width: 8px;
  border: 2px solid #fff0 !important;
  height: 8px;
  z-index: 2;
  position: absolute;
} */
/* rect{
  stroke: #36b37e;
  fill: rgba(54, 179, 126, 0.32);
}
.div-root-7 {
    max-width: 574px;
    margin: 0 auto;
    height: 490px !important;
}
.div-root-5:focus-visible {
    outline: none !important;
}
.div-root-5:focus {
    outline: none !important;
}
.div-root-28,
.jss24,
.div-root-18,
.jss14,
.div-root-26,
.jss22,
.jss22.expanded,
.makeStyles-zoomIndicator-21,
.jss17,
.jss41,
.jss23,
.jss23.expanded,
.jss18 {
  display: none !important;
}
.div-root-19 {
  height: 490px !important;
}
.WithStyles\(ForwardRef\(Button\)\)-root-8:not(:last-child),
.jss7:not(:last-child) {
  display: none !important;
}
.makeStyles-regionInfo-50 {
  display: none !important;
}
.div-root-62 {
  border-radius: 50%;
}
.div-root-49{
  border-radius: 50%;
}
.div-root-16 .MuiSvgIcon-root {
  opacity: 0 !important;
  display: none !important;
}
.WithStyles\(ForwardRef\(Button\)\)-root-8:last-child,
.jss7:last-child {
  background-color: #0052cc !important;
  border-radius: 2px !important;
  position: absolute !important;
  right: 86px !important;
  top: 0px !important;
  width: 130px !important;
  padding: 10px 0px 8px 0px !important;
}
.WithStyles\(ForwardRef\(Button\)\)-root-8:last-child:hover,
.jss7:last-child:hover {
  background-color: #0052cc !important;
}
.WithStyles\(ForwardRef\(Button\)\)-root-8:last-child::before,
.jss7:last-child::before {
  content: 'Next' !important;
  color: #ffffff !important;
}
.WithStyles\(ForwardRef\(Button\)\)-root-8:last-child span ,
.jss7:last-child span {
  opacity: 0 !important;
  display: none !important;
}
.makeStyles-fixedRegionLabel-22 {
  display: none !important;
}
.div-root-47 {
  border-radius: 50%;
} */

rect {
  stroke: #36b37e;
  fill: rgba(54, 179, 126, 0.32);
  stroke-width: 5px;
}
polygon {
  stroke: #36b37e;
  fill: rgba(54, 179, 126, 0.32);
  stroke-width: 5px;

  border-radius: 50% !important;
  background-color: #36b37e;
  border: solid 5px yellow !important;
}

polyline {
  stroke: #36b37e;
  fill: rgba(54, 179, 126, 0.32);
  stroke-width: 5px;
}

.fullscreen {
  height: 740px !important;
  width: fit-content !important;
  min-width: 420px !important;
  min-height: 740px !important;
}
.fullscreen > div > div {
  min-width: 420px;  
}
.fullscreen > div > div > div {
  border-bottom: 0px !important;
  position: absolute;
  bottom: -62px;
  display: flex;
  justify-content: center;
  z-index: 0;
}
.fullscreen > div > div > div > :first-child {
  display: none;
}
.fullscreen > div > div > div + div {
  order: 1 !important;
  margin-top: 0 !important;
  display: inline-block;
  position: absolute;
  bottom: 0;
}
.fullscreen > div > div > div + div > div {
  display: block !important;
  min-width: 420px !important;
  min-height: 740px !important;
  position: relative  !important;
}
.fullscreen > div > div >  div > div > div {
  min-width: 420px !important;
  min-height: 740px !important;
}
.fullscreen button + button {
  background-color: #0052cc !important;
  width: 140px;
  border-radius: 2px;
  margin-left: 12px;
  height: 40px;
  z-index: 1100;
  order: 1 !important;
}
.fullscreen button + button span > div > div {
  display: none;
  color: #fff !important;
}
.fullscreen button + button::before {
  content: "Next";
  color: #fff !important;
  position: absolute;
  margin: 0 auto;
}
.fullscreen button::before {
  content: "Clear";
  position: absolute;
  margin: 0 auto;
  text-transform: capitalize !important;
}
.fullscreen button {
  background-color: #ffffff !important;
  border-radius: 2px;
  padding: 20px 0px;
  width: 140px;
  z-index: 1100;
  order: 2 !important;
  margin-left: 16px;
  border: 1px solid #ccc;
  position: initial !important;
  margin-bottom: 1px !important;
  height: 50px !important;
  color: #0052cc !important;
  opacity: 1!important;
  visibility: hidden;
}
button > span > div > div:first-child {
  display: none !important;
}

.fullscreen .MuiSvgIcon-root{
    display:none !important;
  }
.fullscreen canvas{
  max-width: 100% !important;
  object-fit: cover !important;
  opacity: -1 !important;
  width: 100vw!important; 
  height: 100vh!important; 
}
.fullscreen svg{
  width: 100vw!important; height: 100vh!important; 
}
.fullscreen >div>div>div:first-child{
  opacity: 0;
}
.fullscreen > div > div > div > div > div > div > div :first-child{
  width: 17px;
  height: 17px;
  border-color: #ffffff;
  background-color: #0052cc;
}