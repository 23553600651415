@import "../../../styles/standard.module.scss";

.wound_dashboard {
  box-shadow: 5px 10px 20px 0 rgb(191 191 191 / 30%);
  background-color: #ffffff;
  margin-top: 40px;
  padding: 0 0 60px;
  margin-bottom: 20px;
}
.wound_form {
  padding: 40px 32px 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.wound_form .col_spec {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
  display: flex;
}
.label_spec {
  margin-bottom: 0;
  font-family: $secondary-font-bold;
}
.wound_input {
  width: 100%;
  height: 40px;
  border-radius: 2px;
  border: solid 1px #c1c7d0;
  background-color: #ffffff;
  padding-left: 10px !important;
}
.wound_input:focus {
  outline: none;
}
.btn_custom {
  height: 50px;
  border-radius: 2px;
  font-family: $secondary-font-regular;
  background-color: $PrimaryButton-bg;
  font-size: 18px;
}
.btn_small,
.btn_small:hover,
.btn_small:active {
  width: 158px;
  height: 50px;
  font-family: $secondary-font-regular;
  background-color: $PrimaryButton-bg;
  color: $Plain-text;
  font-size: 16px;
  margin-right: 32px;
}
.btn_small_cancel,
.btn_small_cancel:hover,
.btn_small_cancel:active {
  width: 158px;
  height: 50px;
  font-family: $secondary-font-regular;
  background-color: $Plain-bg !important;
  border: 1px solid $bdr-color-tertiary !important;
  color: $PrimaryColor-text !important;
  font-size: 16px;
}
.wound_select {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border-radius: 2px;
  border: solid 1px #c1c7d0;
  background-color: transparent;
  appearance: none;
}
.form_span {
  color: #0052cc;
  margin-left: 10px;
  cursor: pointer;
}
.overview_top {
  padding: 30px 24px 18px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.overview_blk {
  background-color: rgba(80, 95, 121, 0.1);
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.overview_title {
  color: #333;
  font-family: $secondary-font-bold;
  font-size: 22px;
  display: inline-block;
  margin: 0;
}
.ua_score {
  width: 100%;
  padding: 20px 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.ua_percent {
  width: 150px;
  height: 100px;
  margin-top: 20px;
  background-color: #fff;
  color: #505f79;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  position: relative;
  box-shadow: 0 18px 32px -4px rgba(136, 136, 136, 0.25);
  margin-bottom: 25px;
}
.ua_percent:hover,
.ua_percent.active {
  width: 150px;
  height: 100px;
  background-color: #0052cc;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  position: relative;
  box-shadow: 0 18px 32px -4px rgba(136, 136, 136, 0.25);
  cursor: pointer;
}
.ua_percent:hover .ua_top,
.ua_percent.active .ua_top {
  color: #fff;
}
.ua_percent .ua_top {
  width: 100%;
  font-family: $secondary-font-bold;
  font-size: 16px;
  padding: 0 15px;
  text-align: center;
  color: #505f79;
}
.ua_percent .ua_top span {
  font-size: 14px;
  font-family: $secondary-font-regular;
  display: block;
  color: #596780;
}
.ua_percent:hover .ua_top span,
.ua_percent.active .ua_top span {
  font-size: 14px;
  font-family: $secondary-font-regular;
  display: block;
  color: #fff;
}
.ua_percent label {
  margin-bottom: 0px;
}
.ua_percent label:hover {
  cursor: pointer;
}
.down_spread a {
  color: #0052cc !important;
  font-family: $secondary-font-bold;
  font-size: 16px;
  display: inline-block;
}
.down_spread a:hover {
  cursor: pointer;
  color: #0052cc !important;
  text-decoration: none !important;
}
.overview_blk .ua_score{
	display: grid;
	grid-auto-flow : column;
	grid-column-gap: 10px;
	overflow-x: auto;
	}
  button{
    border: none;
    background-color: transparent;
  }
  .left_icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='rgb(105,105,105)' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
  }
  .right_icon{
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='rgb(105,105,105)' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
  }
  .carousel_wrap{
    width: 1200px;
    margin: 0 auto;
  }
  .custGroupAlign {
    margin-left: 69px;
    width: 300px;
    height: 45px;
  }
  .label_OR {
    margin-left: -357px;
    margin-top: 61px;
  }
  .disabled{
    pointer-events: none;
  }
  .error_message {
    display: block;
    color: #de350b;
    font-family: $secondary-font-regular;
    font-size: 14px;
    margin-left: 323px;
    margin-top: -40px;
  }