@import '../../../styles/standard.module.scss';
.list_menu_postion {
  border: solid 1px #e7e7e7;
  background-color: #fff;
  box-shadow: 5px 10px 20px 0 rgba(191, 191, 191, 0.3);
  position: absolute;
  top: 42px;
  right: 0px;
  z-index: 1040;
  width: 219px;
  text-align: left;
}
.list_menu_postion div {
  color: $PrimaryColor-text;
  padding: 16px 0px 16px 23px;
  font-family: $secondary-font-regular;
  background-color: #fff;
}
.list_menu_postion div:hover {
  cursor: pointer;
  background-color: #e9ecef;
}
.list_menu_postion div:not(:last-child) {
  border-bottom: 1px solid $tertiary-text;
}
.custom_modal_size {
  max-width: 968px !important;
}
.modal_heading {
  font-size: 1.5rem;
  font-weight: 700;
}
.label_spec {
  color: $PrimaryColor-text;
  font-weight: 600;
  font-family: $secondary-font-bold;
  padding-top: 8px;
}
.label_spec span {
 // display: none;
}
.input_field,
.input_field:focus {
  width: 248px;
  height: 40px;
  padding: 0 5px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
  outline: none;
}
// .first_child{
//   color:$border-color !important;
// }
// .first_child option:not(:first-of-type){
//    color: #333 !important;
// }
.icon_align {
  justify-content: flex-start !important;
}
.btn_cancel,
.btn_cancel:hover,
.btn_cancel:active {
  width: 140px;
  height: 42px;
  padding: 10px 0px 10px;
  border-radius: 2px;
  border: solid 1px $tertiary-text;
  background-color: $Body-bg;
  color: $PrimaryColor-text;
  font-family: $secondary-font-regular;
  font-size: $Typography-2;
}
.btn_save,
.btn_save:hover,
.btn_save:active {
  width: 140px;
  height: 42px;
  padding: 10px 0;
  border-radius: 2px;
  background-color: $secondary-text;
  font-family: $secondary-font-regular;
  font-size: $Typography-2;
  margin-right: 32px;
}
.small_input {
  max-width: 74px !important;
}
.small_space {
  margin-bottom: 70px;
}
.select_wrapper {
  border: solid 1px $tertiary-text;
}
.small_space_one {
  margin-bottom: 34px;
}
.general_error {
  margin-bottom: 10px;
  color: #fff;
  border-radius: 3px;
  padding: 5px 15px;
  display: inline-block;
  background-color: $Error;
}