@import '../../../styles/standard.module.scss';

.basic_info {
    padding: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.basic_info div {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
}

.basic_info_form {
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.basic_info_form div {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
    display: flex;
    align-items: center;
}
.subheading_2 {
    font-family: $primary-font-regular;
    font-size: 21px;
    color: $PrimaryColor-text;
    margin-bottom: 0;
}

.single_product_block {
    padding: 0 24px;
}
.patient_content {
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width:100%;
}

.moreinfo_content,
.patient_content {
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.moreinfo_content .col_spec,
.patient_content .col_spec {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
    display: flex;
    flex-wrap: wrap;    
}

.patient_content .basic_input,
.moreinfo_content .basic_input {
    width: 100%;
    height: 40px;
    padding: 0 5px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}

.patient_content .select_wrapper,
.moreinfo_content .select_wrapper {
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
    height:40px;
}

.patient_content .basic_select,
.moreinfo_content .basic_select {
    width: 100%;
    height: 40px;
    padding: 0 10px;
    appearance: none;
    -webkit-appearance: none;
    outline: 0;
    background: 0;
    border: 0;
}
select::-ms-expand {
    display: none;
}

.label_spec {
    margin-bottom: 0;
    font-family: $secondary-font-bold;    
}
.label_spec_modal{
    font-size: 14px;
}
.input_column_csName{
    margin-left: -3px;
}
.vertical_Code {
    border-bottom: solid 2px #1a51bf;
    margin-bottom: 24px;
    height: 6px;
  }
/* Below for error message alignment */
// .label_spec ~ span {
//     margin-left: 50%;
// }

.content_spec {
    font-family: $secondary-font-regular;
    color: $PrimaryColor-text;
    display: inline-block;
}
.basic_input {
    width: 250px;
    height: 40px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}
.small_input {
    width: 68px;
    height: 40px;    
    padding-left: 7px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}
.medium_input {
    width: 100px;
    height: 40px;    
    padding-left: 7px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}

.basic_select {
    width: 250px;
    height: 40px;
    padding: 0 10px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}


.btn_custom {
    height: 50px;
    border-radius: 2px;
    font-family: $secondary-font-regular;
    background-color: $PrimaryButton-bg;
    font-size: 18px;
}
.btn_small,
.btn_small:hover {
    @include btn_primary;
    margin-right: 32px;
}
.btn_small_cancel,
.btn_small_cancel:hover,
.btn_small_cancel:active {
    @include btn_secondary;    
}
.admission_upper{
    position: relative;
}
.admission_fix{
    position: absolute;
    top: 0;
    right: -7px;
}
.admission_date{
    padding: 7px;
    border: 1px solid $adm-color;
    width: 100px;
}
.select_wrapper {
    background: url('../../../static/images/down-arrow.png') right 10px center no-repeat;
}
.tab_edit {
    color: $PrimaryColor-text;
    font-family: $secondary-font-regular;
    font-size: 14px; 
    position: absolute;
    right: 24px;
    top: 65px;
    z-index:1000;
}
 
.tab_edit a,
.tab_edit a:hover {
    color: $PrimaryColor-text;
}
.general_error{ 
    margin-bottom: 20px;
    color: $Plain-text;
    border-radius: 3px;
    padding: 5px 15px;
    display: inline-block;
    background-color: $Error;
}
.admission_time_fix{
    margin-left: 5px;
}
.basic_input.disabled {
    background-color: $btn-disable !important;
}
.basic_select.disabled{
    background-color: $btn-disable !important;
}



.tab_content_top {
    border-bottom: solid 1px $tab-border-bottom;
    margin-bottom: 15px;
  }
  .customer_heading{
    padding-top: 4px;
    overflow-wrap:anywhere!important;
    width: auto;
    margin-left: 3px;
    h2{
        font-size: 20px;
    }
  }
  
.vertical_Code_role {
    margin-top: -5px;
    height: 42px;
    border-left: solid 1px #007bff;
    border-left-style: solid;
    // margin-left: -30px;
    // margin-right: 22px;
    margin: 0 15px;
  }

  .ne1_toggleSwitch{
    display: flex!important;
}
.toggle_shells{
    padding-left: 40px;
    padding-top: 3px;
  }
  .toggle_switch {
    // transform: scale(1.4, 1.1);
    transform: scale(1.5, 1.2);
    transition: 0.3s ease-in-out;
  }

  .cusTogg_content{
    margin-top: -16px;
    align-items: center;    
    width: auto;
    justify-content: space-between;
  }
  .comp_log{
    margin-top: -20px;
  }
  .label_spec_loglable{
    margin-right: 4px;
  }


//   Email
  .addValBtn{
    color: #0052cc;
    font-weight: 700;
    font-size: 16px;
    float:right;
    cursor: pointer;  
  }
  .deleteDomain {
    vertical-align: middle;
    border-style: none;
    width: 22px;
    height: 22px;
    padding: 6px;
    border-radius: 12px;
    border: solid 1px rgba(80, 95, 121, 0.5);
    background-color: #ffffff;
    cursor: pointer;
    margin-top: 10px;
    margin-left: -130px;
  }
  .error_message{
    display: block;
    color: #de350b;
    padding-top: 4px;
    font-family: $secondary-font-regular;
    font-size: 14px;
  }