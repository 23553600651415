body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

datalist {
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 150px !important;
  z-index: 10;
  background-color: #fff;
  border: 1px solid #ededed;
}

datalist option {
  padding: 0.3em 1em;
  color: #333;
  cursor: pointer;
  font-size: 16px;
}

/* option active styles */
datalist option:hover,
datalist option:focus {
  background-color: #0052cc !important;
  color: #fff;
}
.check_left_block_menu{
  z-index:  0!important;;
}
/**
 * <div class="react-tags">
 *   <div class="react-tags__selected">
 *     <button class="react-tags__selected-tag">
 *       <span class="react-tags__selected-tag-name" />
 *     </button>
 *   </div>
 *   <div class="react-tags__search">
 *     <div class="react-tags__search-wrapper">
 *       <input class="react-tags__search-input" />
 *       <div />
 *     </div>
 *     <div class="react-tags__suggestions">
 *       <ul>
 *         <li class="is-active">
 *           <mark />
 *         </li>
 *         <li class="is-disabled">
 *           <mark />
 *         </li>
 *       </ul>
 *     </div>
 *   </div>
 */
 .react-tags {
  position: relative;
  padding: 6px 0 0 6px;
  border: 1px solid #D1D1D1;
  border-radius: 1px;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-focused {
  border-color: #B1B1B1;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  border: 1px solid #0a91ec;
    border-radius: 2px;
    background: #0096fb;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
  color: white;
}

.react-tags__selected-tag:after {
  content: '\2715';
  color: white;
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #B1B1B1;
}

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}
.react-tags__search-wrapper {
  max-width: 675px;
}

@media screen and (min-width: 30em) {

  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }

}

.react-tags__search-input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__search-input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  display: block !important;
  z-index: 10200;
}

@media screen and (min-width: 30em) {

  .react-tags__suggestions {
    width: 240px;
  }

}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}
.toast_body{
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #36b37e;
      color: #fff;
  }
  .toast_body input[type="button"]{
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  color: #fff;
  }
  
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

#medRepo.full-page-container {
  background: #b7cfe0 !important;
}
.Toastify__close-button{
  color: rgb(255, 255, 255) !important;
  margin-top: 10px !important;
  margin-right: 10px !important;
  opacity: 1 !important;
}
.loader{
  text-align: center;
  padding: 150px 0;
  img{
      max-width: 50px;
      border-radius: 100%;
  }
}