@import "./standard.module.scss";

.left_block {
  background-color: $DarkBar-bg;
  @include response-above(sm) {
    display: none;
  }
  
  // @include response-above(lg) {
  //   width: 120px;
  //   margin-right: 20px;
  //   display: inline-block;
  //   z-index: 1;
  //   position: fixed;
  //   height: 100vh;
  // }
  @include response-above(lg) {
    width: 75px;
    margin-right: 20px;
    // display: inline-block;
    z-index: 1001;
    position: fixed;
    height: 100vh;
    left: 0;
  }
  @include response-above(xl) {
    width: 75px;
    margin-right: 20px;
    // display: inline-block;
    z-index: 1001;
    position: fixed;
    height: 100vh;
    left: 0;
  }
}

.right_block {
  width: 100%;
  @include response-above(sm) {
    width: 100%;
    padding-right: 0;
    background: #ffffff;
    height: auto;
    // overflow-y: auto;
  }
  // @include response-above(lg) {
  //   width: calc(100% - 140px);
  //   padding-right: 20px;
  //   z-index: 0;
  //   margin-left: 140px;
  // }
  @include response-above(lg) {
    width: 100%;
    padding: 0 20px;
    z-index: 0;
    background: #ffffff;
    height: auto;
    // padding-right: 20px;
      // margin-left: 140px;
  }
}

.title {
  font-size: $Heading-1;
  color: $PrimaryColor-text;
  font-family: $primary-font-bold;
}

.subheading {
  font-size: $Typography-2;
  color: $PrimaryColor-text;
  font-family: $secondary-font-regular;
}

.form_shadow {
  box-shadow: 5px 10px 20px 0 rgba(191, 191, 191, 0.3);
  -webkit-box-shadow: 5px 10px 20px 0 rgba(191, 191, 191, 0.3);
  -moz-box-shadow: 5px 10px 20px 0 rgba(191, 191, 191, 0.3);
  width: 450px;
  max-width: 450px;
  background-color: #fff;
  margin-bottom: 30px;
  @include responsive(lg) {
    .form-shadow {
      padding: 37px;
    }
  }
}

.logo_style {
  width: 420px;
  max-width: 420px;
  padding: 0;
  @include responsive(lg) {
    padding: 56px 0 48px 0;
  }
}

.link_style,
.link_style:hover {
  color: $secondary-text;
  font-size: $Typography-2;
  font-family: $secondary-font-bold;
}

.link_style2,
.link_style2:hover {
  color: $secondary-text !important;
  font-size: 12px;
  font-family: $secondary-font-bold;
}
.link_style3,
.link_style3:hover {
  color: $secondary-text;
  font-size: 14px;
  font-family: $secondary-font-bold;
}

.link_style4,
.link_style4:hover {
  color: $PrimaryColor-text;
  font-size: 14px;
  font-family: $secondary-font-bold;
}

.medline_block {
  @include response-above(sm) {
    background: #fff url(../static/images/bgPattern.png) bottom center no-repeat;
  }
  @include response-above(lg) {
    max-width: 1440px;
    background: #fff;
    // margin: 0 auto;
  }
}

.pass_condition li {
  width: 48%;
  display: inline-block;
}

.hint-normal-text {
  color: $hint-text;
}

.hint-success-text {
  color: $PrimaryColor-text;
}

.pass_condition li .normal {
  width: 6px;
  height: 6px;
  margin: 6px 8px 5px 0;
  background-color: $hint-text;
  display: inline;
  float: left;
  border-radius: 50%;
}

.pass_condition li .success {
  width: 6px;
  height: 6px;
  margin: 6px 8px 5px 0;
  background-color: $hint-success-text;
  display: inline;
  float: left;
  border-radius: 50%;
}

.mandatory {
  color: $Astrik;
}

.form-control::placeholder {
  color: $secondary-text;
  opacity: 1;
  font-family: $secondary-font-regular;
  font-size: 14px;
}

/* Common error */
.service_error {
  margin: 0px 15px;
  margin-bottom: 15px;
  color: #fff;
  font-size: 14px;
  padding: 7px 10px;
  border: 1px solid #de350b;
  border-radius: 5px;
  background-color: #de350b;
  min-width: -webkit-fill-available;
}
/* ==============  Admin Portal ==================== */

.heading-1 {
  font-size: $Heading-2;
  color: $PrimaryColor-text;
  margin-bottom: 0;
}

.heading-success {
  color: $Success-text;
  font-size: $Typography-1;
  margin-bottom: 0;
  font-weight: $Bold;
}
.heading-expired {
  color: $Error;
  font-size: $Typography-1;
  margin-bottom: 0;
  font-weight: $Bold;
}

.pass_condition {
  padding-left: 0;
  color: #8993a4;
  font-size: $Typography-4;
  list-style: none;
}

.img-fit-screen {
  object-fit: cover;
}
.subheading_1 {
  font-size: 12px;
  color: $PrimaryColor-text;
  font-family: $secondary-font-regular;
  vertical-align: top;
  width: 275px;
  display: inline-block;
}

/* Admin Portal */

.left-bg {
  background-color: $DarkBar-bg;
  width: 120px;
  padding: 0;
}

.left-admin-bg {
  background-color: $Midbar-bg;
  width: 220px;
}

.admin-logo {
  padding-top: 80px;
  text-align: center;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

.admin-hdg {
  color: $Plain-bg;
  text-align: center;
  padding: 80px 0 40px;
  border-bottom: 3px solid #383838;
  font-size: 28px;
  margin-bottom: 40px;
}

.admin-menu {
  padding-bottom: 20px;
}

.admin-menu-hdg {
  color: $Plain-bg;
  font-size: $Typography-2;
  padding-left: 32px;
}

.admin-menu ul {
  padding-left: 0;
}

.admin-menu ul li {
  list-style: none;
  padding-left: 32px;
  padding-bottom: 10px;
}

.admin-menu-list a {
  color: #aeaeae;
  font-size: $Typography-2;
}

.admin-menu-list a:hover,
.admin-menu-list a.active {
  color: $Astrik;
  text-decoration: underline;
}

.admin-left ul {
  padding-left: 0;
}

.admin-icon a {
  width: 100%;
  height: 96px;
  list-style: none;
  text-align: center;
  padding: 15px 0;
  margin: 0 auto;
  display: block;
}

.admin-icon a:hover,
.admin-icon a.active {
  width: 100%;
  height: 96px;
  background-color: $Midbar-bg;
  border-left: 2px solid red;
}

.admin-bg-top {
  background-color: $Plain-bg;
  height: 210px;
}

.admin-bg-bottom {
  background-color: $Body-bg;
  height: calc(100% - 210px);
}

.btn_custom {
  height: 42px;
  border-radius: 2px;
  font-family: $secondary-font-regular;
  background-color: $PrimaryButton-bg;
  font-size: 16px;
}
.btn_small,
.btn_small:hover {
   @include btn_primary;	
   
}
.btn_small_cancel,
.btn_small_cancel:hover,
.btn_small_cancel:active {
    @include btn_secondary;   
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
.close_btn {
  display: block;
  position: absolute;
  right: 19px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #000;
  font-size: 15px;
  border: 0;
  cursor: pointer;
  line-height: 50%;
  background-color: transparent;
  margin: 1px;
  img{
    width: 16px;
    height: 16px;
  }
}
.not_list_error,.not_list_error:hover{
  color: #fff;
  font-size: 14px;
  padding: 7px 10px;
  border: 1px solid #de350b;
  border-radius: 5px;
  background-color: #de350b !important;
  min-width: -webkit-fill-available;
}
.link_stylegray,
.link_stylegray:hover {
  color:  #8b8b8b !important;
  font-size: 12px;
  font-family: $secondary-font-bold;
}

.textField_Enter_Error{
  display: block;
  color: #de350b;
  margin-top: -8px;
  font-family: $secondary-font-regular;
  font-size: 14px;
  padding-bottom: 16px;
  // padding-left: 4px;
}
.custom_modal_size {
  max-width: 967px;
}