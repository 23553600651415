@import '../../../styles/standard.module.scss';
.signed{
    background-color:#000;
    padding:5px 10px;
    color:#fff;
    font-family: $secondary-font-regular !important;
    font-size:16px;
    line-height: 16px;
    margin:0 10px;
    position: relative;
    top: -5px;
}
.signned_sign{
    width:12px;
    height:12px;
    border-radius:50%;
    background-color:#36b37e;
    display: inline-block;
    margin-right: 5px;
}
 
.lock{
    border:1px solid #aaa;
    margin: 0px 5px;
    padding:5px 20px;
    color:#505f79;
    font-family: $secondary-font-regular !important;
    font-size:16px;
    position: relative;
    top: -5px;
}
.lock img{
    max-height: 15px;
    margin-right:10px;
}
.lock a, .lock a:hover{
    color:#505f79;
    text-decoration:none;
}
.sign_border_top{
    width: 100%;
    border-top: 1px solid #e0dddd;
    margin: 5px 0 0;
}
.modal_outer{
    position: relative;
}
.close{
    position: absolute;
    font-size: 15px;
    cursor: pointer;
    top: -10px;
    right: -10px;
}
.lock_error{
    font-size: 17px;
    line-height: 30px;
    font-weight: normal;
}


.re_eval{
    border:1px solid #0052cc;
    margin: 0px 5px;
    padding:5px 20px;
    color: #0052cc!important;
    font-family: $secondary-font-regular !important;
    font-size:16px;
    position: relative;
    top: -5px;
}

.re_eval a, .re_eval a:hover{
    color: #0052cc!important;
    text-decoration:none;
}
.modalparent{
    position: relative;
    border-radius: 9%;
  }
  .modal_heading{
    display: flex;
    justify-content: space-between;
    background:  #0052cc;
    border-bottom: 2px solid #737373
  }
  .modal_header{
    h3{
      color: #ffffff;
      font-size: 17px;
    }
  }
  .close_icon{
    float: right;
    font-size: 23px;
    font-weight: 700;
    color: #ffffff!important;
    opacity: 1!important;
  }
  .modal_footer{
      h3{
        font-size: 15px;
    font-weight: 600;
    color: #737373;
    text-align: center;
    width: 66%;
    margin-left: 17%;
    margin-top: -35px;
    }
  }
  .btn_custom {
    height: 43px;
    width: 150px;
    border-radius: 4px;
    font-family: $secondary-font-regular;
    background-color: $PrimaryButton-bg;
    font-size: $Typography-2;
    line-height: normal;
    padding: 0;
  }