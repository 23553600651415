@import "../../../styles/standard.module.scss";
@import "../../../styles/common.module.scss";

.btn_mobile {
  border: 1px solid tertiary-text;
  width: "auto";
  height: "42px";
  border-radius: "2px";
  background-color: "#fff";
}
.pending_assess {
  background-color: $pending-status;
  color: $black-color!important;
}
.pending_review {
  display: inline-block;
  background-color: $review-pending;
  color: $Plain-bg;
  border-radius: 4px;
  font-size: $Typography-4;
  width: 145px !important;
  text-align: center;
}
.clousre_text {
  font-size: 21px;
  font-family: $primary-font-bold;
  @include response-above(sm) {
    text-align: center;
  }
  @include response-above(xl) {
    text-align: left;
  }
}

.clousre_percentage {
  font-size: $Heading-1;
  font-family: $primary-font-bold;
}
.assess_link {
  color: $PrimaryButton-bg;
  font-weight: 600;
  font-size: 20px!important;
  // text-decoration: underline;
}
a:hover {
  text-decoration: none;
}
.assess_link:hover {
  cursor: pointer;
}
.accordion_panel {
  border-top: 1px solid $accod-border;
  padding: 42px 26px 16px 17px;
}
.closure_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20%; // added for Labels
  margin-right: 20%; // added for Labels
}

.re_evl_button {
  border: 1px solid $bdr-color-tertiary !important;
  padding: 10px 43px;
  @include responsive(lg) {
    padding: 10px 35px;
  }
}
.wound_img {
  width: 100%;
}
.green {
  color: $Success-text;
}
.assess_status {
  display: inline-block;
  color: $Plain-bg;
  border-radius: 4px;
  font-size: $Typography-31;
  width: 145px !important;
  text-align: center;
  font-family: $primary-font-regular !important;
  font-weight: 500;
}
.signed_locked {
  background-color: $hint-success-text;
}
.reviewed {
  background-color: $Success-text;
}
.modal_image {
  width: 47px;
  height: 33px;
  margin-top: 7px;
  margin-bottom: 6px;
  object-fit: cover;
}
// .complete_td:not(:last-child) {
//   border-bottom: 1px solid $tertiary-text;
// }
.complete_td:hover {
  cursor: pointer;
}
.complete_td td:first-child {
  padding-left: 20px;
}
.complete_td td {
  font-size: $Typography-3;
  color: $PrimaryColor-text;
}
.complete_td {
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
}
// .complete_th {
//   border-bottom: 1px solid $tertiary-text;
// }
.complete_th th {
  padding-bottom: 17px;
  color: $table-heading-color;
  padding-right: 10px;
}
.complete_th th:first-child {
  padding-left: 20px;
  
}
.custom_modal_size {
  // max-width: 967px;
  max-width: 1050px;
  @include responsive(lg) {
    margin-right: 30px;
    margin-left: 30px;
  }
}
.del_icon:hover {
  cursor: pointer;
}
.img_parent {
  position: relative;
}
.img_parent img {
  max-width: 100%;
  object-fit: cover;
}
.img_stage {
  position: absolute;
  color: $Plain-text;
  font-size: $Typography-31;
  background-color: rgba(51, 51, 51, 0.69);
  width: 100%;
  text-align: center;
  left: 0px;
  bottom: 0px;
  z-index: 998;
  text-transform: capitalize;
}
.modal_heading {
  font-size: 1.5rem;
  // font-weight: 700;
  font-family: $secondary-font-bold;
}
.sub_heading {
  font-size: 20px;
  color: $PrimaryColor-text;
  text-transform: capitalize;
  font-family: $secondary-font-regular;
  font-weight: 600;
}
.display {
  display: none;
}
.panel_heading {
  font-size: 1.313rem;
  color: $PrimaryColor-text !important;
  font-weight: 600;
}
.panel_heading > a {
  color: $secondary-text !important;
  text-decoration: underline;
  font-family: $primary-font-bold;
  font-size: 21px;
}
.panel_heading > a:hover {
  color: $secondary-text !important;
}
.detaile div {
  line-height: 1.44;
}
.wound_pic_size {
  max-width: 162px;
}
.body_heading {
  font-family: $secondary-font-regular;
  display: flex;
  @include responsive(lg) {
    flex-direction: column-reverse;
  }
}
.assess_stage {
  @include responsive(lg) {
    margin-bottom: 5px;
  }
}
.assess_label {
  font-family: $secondary-font-bold;
}
.assess_content {
  font-family: $secondary-font-regular;
}
.full_height {
  height: 100%;
}
//added label
.closure_ctr {
  top: -10%;
}

.closure_ctr {
  @include responsive(lg) {
    padding-left: 0px;
  }
}
.no_btn {
  width: 140px;
  border-radius: 0px;
  background-color: $Body-bg;
  color: $PrimaryColor-text;
  border: solid 1px $bdr-color-tertiary;
}
.no_btn:hover {
  background-color: $Body-bg;
  color: $PrimaryColor-text;
  border: solid 1px $bdr-color-tertiary;
}
.no_btn:active {
  background-color: $Body-bg !important;
  color: $PrimaryColor-text !important;
  border: solid 1px $bdr-color-tertiary !important;
}
.no_btn:focus {
  background-color: $Body-bg;
  color: $PrimaryColor-text;
  border: solid 1px $bdr-color-tertiary;
}
.yes_btn {
  width: 140px;
  border-radius: 0px;
}
.no_data_content {
  border-top: 1px solid $accod-border;
  font-family: $secondary-font-regular;
  font-size: $Typography-1;
}
.caplize_name {
  text-transform: capitalize;
}
.btn_custom {
  border-radius: 2px;
  margin: 10px 5px;
  padding: 5px 0;
}
.btn_small_cancel,
.btn_small_cancel:hover {
  width: 130px;
  height: 45px;
  font-family: $secondary-font-regular;
  background-color: $bg-alternate !important;
  border: 1px solid $bdr-color-tertiary !important;
  color: $PrimaryColor-text !important;
  font-size: 16px;
}
.admission_status {
  color: $PrimaryColor-text;
  font-family: $secondary-font-regular;
  font-size: $Typography-2;
}
.admission_status_space:not(:last-child) {
  padding-bottom: 32px !important;
}
.tr_space {
  padding: 4px;
}
.display_inline_flex {
  display: inline-flex;
}
.custom_control_label {
  position: relative;
  margin-top: 5px;
  margin-bottom: 0;
  vertical-align: top;
  font-family: $secondary-font-regular;
  font-size: 14px;
  color: $PrimaryColor-text;
  line-height: normal;
}
.close_icon {
  position: absolute;
  right: 20px;
  top: 2px;
  z-index: 1090;
}
.parent {
  position: relative;
}
.custom_control_input {
  z-index: 1;
}
.patient_sub_img {
  width: 144px;
  height: 144px;
  object-fit: cover;
}
.customSize {
  max-width: 618px !important;
  height: 214px;
}
.info_icon {
  margin-top: -3px !important;
  position: absolute;
}
.form_alert {
  // display: inline-block !important;
  margin: 12px 12px 15px 12px;
  max-width: 371px;
  width: 371px;
}

.btn_wound {
  width: 225px;
  height: 40px;
  font-family: $secondary-font-regular;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $Plain-text;
  background-color: $PrimaryButton-bg;
  border: solid 1px $tertiary-text;
}
.no_btn_wound {
  width: 225px;
  height: 40px;
  font-family: $secondary-font-regular;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $secondary-text;
  background-color: $Plain-bg;
  border: solid 1px $PrimaryButton-bg;
}
.pastwound_scroll {
  overflow: auto;
  max-height: 317px;
}
.pastwound_panel {
  border-top: 1px solid $bdr-color-alternate;
  padding: 15px;
  .img_parent {
    width: 144px;
    height: 144px;
  }
  .img_parent img {
    height: 100%;
  }
}

.pdf_link {
  color: #0052cc;
  font-weight: 600;
}
.custom_heading {
  border-bottom: 1px solid $bdr-color-alternate !important;
}
.loader_fix {
  text-align: center;
  margin: 10px 0;
  img {
    width: 50px;
    border-radius: 100%;
  }
}
.current_wound_panel {
  padding: 15px;
  .img_parent {
    width: 144px;
    height: 144px;
  }
  .img_parent img {
    height: 100%;
  }
}
.patient_sub_img:hover {
  cursor: pointer;
}
.dis_patient_sub_img {
  width: 144px;
  height: 144px;
  object-fit: cover;
}
.dis_patient_sub_img:hover {
  cursor: default;
}
.del_icon_desable,
.del_icon_desable:hover {
  opacity: 0.5;
  cursor: none;
  background: #dddddd;
}

.cust_current_photo_modal_size {
  max-width: 1024px;
  @include responsive(lg) {
    margin-right: 30px;
    margin-left: 30px;
  }
}

//Added for Labels
.assess_content_label{
  font-family: $secondary-font-regular!important;
}
// .assess_label_Label {
//   font-family: $secondary-font-regular!important;
//   font-size: 18px!important;
//   font-weight: 500!important;
// }
.assess_content_woundAcq{
  font-size: 17px!important;
}
.POAColor{
  color:#15b471;
  font-weight: bold;
  font-size: 17px!important;
}
.NONPOAColor{
  color:orangered;
  font-weight: bold;
  font-size: 16px!important;
}




// Graybox subDetails
.assess_content_subDetails{
  background-color: rgb(0 0 0 / 15%);;
  padding: 9px 0px;
  font-weight: bold;
}
.assess_label_subDetails{
  font-size: 16px!important;
}
.assess_label_subDetail_label{
  font-size: 16px!important;
  font-weight: bold!important;
}
.assess_label_subDetail_labelout{
  font-size: 16px!important;
  font-weight: normal;
}
.right_Arrow{
  margin-left: 10px;
}
.assement_all_link{
  padding: 10px 15px;
  background-color: #ffffff;
  border-radius: 10px;
  margin-left: 5%;
}


//SubLabel

.sub_label_content{
  background-color: rgb(202 202 202 / 29%);
  color: #0052cc;
  padding: 4px 15px 7px 13px;
  font-size: 17px;
  font-weight: 500;
  border-radius: 5px;
  font-family: Segoe UI, Tahoma, sans-serif;
}
.sub_label_content_None{
  color: #626262;
  padding-left: 5px;
  font-size: 17px;
  font-weight: 500;
  border-radius: 5px;
  font-family: Segoe UI, Tahoma, sans-serif;
}
.closureColor {  
  font-weight: bold;
  font-size: 20px!important;
  display: block;
  margin-top: -5px;
}

.closureColor_green {
  color:#15b471;
}
.closureColor_red {
  color: orangered;
}
.closureColor_grey {
  color: grey;
}


//clear Icon

.x_icon {
  position: absolute;
  cursor: pointer;
  margin-left: -15px;
  margin-top: -1px;
}

.x_icon_whole {
  position: absolute;
  cursor: pointer;
  margin-left: 10px;
  margin-top: -3px;
}

.notdisplayed {
  display: none;
}

.displayed {
  height: 20px;
  margin: auto;
}



.LabelMain, .form_field {
  display: flex;
}

.services {
  margin-top: -6px;
  margin-left: 10px;
}

.labelList:focus-visible {
  border: none!important;
  outline: none!important;
}
.labelList {
  border: none!important;
  outline: none!important;
  border-radius: 5px!important;
  padding: 5px 20px 6px 5px!important;
  color: #0052cc!important;
  text-align: center!important;
  display: inline-block;
  visibility: hidden;
  white-space: pre;
}

.first_division {
  padding: 5px 10px 5px 10px;
  text-align: center;
  display: flex;
  background-color: rgb(70 70 70 / 15%) !important;
  border-radius: 5px!important;
  color: #0052cc!important;
  margin-right: 15px;
}

.add_btn {
  display: flex;  
  background-color: #000;
  color: #fff;
  font-size: 13px;
  padding: 6px 15px;
  margin-top: -3px;
  height: 35px;
  border-radius: 5px;
}

.classifi_content {
  font-weight: 500;
  font-size: 16px;
}


// PDF Modal 
.custom_modal_size_pdf {
  max-width: 650px;
  @include responsive(lg) {
    margin-right: 30px;
    margin-left: 30px;
  }
}

.modalparent{
  position: relative;
  border-radius: 5%;
}
.modal_heading_pdf{
  display: flex;
  justify-content: space-between;
  background:  #0052cc;
  border-bottom: 2px solid #737373
}
.modal_header_pdf{
  h3{
    color: #ffffff;
    font-size: 23px;
  }
}
.close_icon_pdf{
  float: right;
  font-size: 42px;
  font-weight: 700;
  color: #ffffff!important;
  opacity: 1!important;
}
.modal_footer{
    h2{
      font-size: 26px;
      font-weight: 700;
      color: #737373;
      text-align: center;
  }
}
.loader{
  text-align: center;
  padding: 150px 0;
  img{
      max-width: 50px;
      border-radius: 100%;
  }
  
}


.Three_Dots_Panel{
  cursor: pointer;
}
.Three_Dots_Panel_History{
  cursor: pointer;
  padding: 0 15px;
  margin-right: 10px;
}
.threeDotsTooltip{
  position: absolute;
  top: 110%;
  width: 180px;
  // height: 133px;
  height: 90px;
  right: 63%;
  box-shadow: 0 0 10px 5px #e6e6e6;
  background-color: #ffffff;
}
.threeDotsTooltipNotReEvelSeq{
  height: 133px!important;
}
// Asseesment History Page
.threeDotsTooltipHistory{
  position: absolute;
  // top: 50%;
  width: 180px;
  height: 183px;
  right: 8%;
  box-shadow: 0 0 10px 5px #a19f9f;
  background-color: #ffffff;
}
// Asseesment History Page
.lockSection, .mergeSection, .deleteSection, .newWoundSection {
  align-items: center;
  justify-content: space-between;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  color: #000000a1;
  padding: 10px 3px;
  img{
    width: 24px;
    margin-right: 11px;
  }
  p{
    margin-bottom: 0!important;
  }  
}
.lockSection{
  background-color: #f4f5f7;
}

.lockSection:hover, .mergeSection:hover, .deleteSection:hover, .newWoundSection:hover{
  background-color: #0052cc;
  cursor: pointer;
  color: #ffffff;
}

.mergeSectionDisabled{
  pointer-events: none;
  opacity: 0.4;
  align-items: center;
  justify-content: space-between;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  color: #000000a1;
  padding: 10px 3px;
  img{
    width: 24px;
    margin-right: 11px;
  }
  p{
    margin-bottom: 0!important;
  }
}


.modal_box_Merge_Confirmation {  
  h5{
    visibility: hidden;
  }
}
.custom_modal_size_Merge_Confirmation{
  max-width: 450px;
}
.createManualPopup{
  padding: 30px 20px 10px;
  h1{
    font-size: 22px;
    text-align: center;
  }
}

.btn_custom {
  height: 50px;
  border-radius: 2px;
  font-family: $secondary-font-regular;
  background-color: $PrimaryButton-bg;
  font-size: 18px;
}
.btn_small,
.btn_small:hover {
  @include btn_primary;
  margin-right: 32px;
}
.btn_small_cancel,
.btn_small_cancel:hover,
.btn_small_cancel:active {
  @include btn_secondary;    
}

.toggle_shells{
  input:checked + .toggle_switch::before {
      color: #fff;
      border: 2px solid #0bca17 !important;
      background-color: #0bca17!important;
      box-shadow: 0 0px 2px #000000;
      // width: 32px!important;
    }
    input:checked + .toggle_switch::after {
      color: #fff;
      border-color: #ffffff!important;
      background-color: #ffffff!important;
      // transform: translateX(.92rem)!important;
    }
}

.toggle_shells_disabled{
  pointer-events: none;
  opacity: 0.4;
}


.recordTypeBatch{
  border: 1px solid #0052cc;
  border-radius: 5px;
  padding: 3px 6px 4px 6px;
  color: #0052cc;
}

.deleteToastMsg {
  margin: 14px 12px 0px 12px!important;
  width: 240px!important;
}