@import '../../../styles/standard.module.scss';
.back_btn a {
    @include response-above(sm) {
        position: fixed;
        top:22px;
        left:25px;
        background:url(../../../static/images/back-arrow.png) center left no-repeat;
        text-indent: 999px;
    }
    @include response-above(lg) {
      position: static;
      top:auto;
      left:auto;
      color:$PrimaryColor-text !important;
      background:url(../../../static/images/left_arrow.png) center left no-repeat;
      padding-left: 10px;
      font-family: $secondary-font-regular;
      font-size:16px;
    }
  }

/*new assessment*/


.btn_add, .btn_add:hover {
    width: 171px;
    height: 42px;
    margin: 0;
    padding: 9px 16px 9px 9px;
    border-radius: 2px;
    background-color: $PrimaryButton-bg;
    font-family: $secondary-font-regular;
    color: $Plain-bg;
    font-size: $Typography-2;
}
.btn_new_risk, .btn_new_risk:hover {
    width: 220px;
    height: 42px;
    margin: 0;
    padding: 9px 16px 9px 9px;
    border-radius: 2px;
    background-color: $PrimaryButton-bg;
    font-family: $secondary-font-regular;
    color: $Plain-bg;
    font-size: $Typography-2;
}
.dropdown_select_align {
    width: 240px;
}
.p_link {
    cursor: pointer;
    padding-top: 10px;
    padding-bottom: 12px;
}
.dropdown_heading2 {
    font-family: $secondary-font-bold;
    color: $PrimaryColor-text;
    padding-left: 22px;
}
.btn_custom{
    height: 56px;
    border-radius: 2px;
    font-family: $secondary-font-regular;
    background-color: $PrimaryButton-bg;
    font-size: $Typography-2;
}
.btn_mobile,
.btn_mobile:hover, .btn_mobile:focus , .btn_mobile:active{
    border: 1px solid $border-color !important;
    width: auto;
    height: 42px;
    border-radius: 2px;
    background-color: $Plain-bg !important;
    color: $PrimaryColor-text !important;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 10px;
    padding-right: 10px;   
}

.wound_address{
    @include response-above(md) {
        align-items:initial !important;
        justify-content: space-between !important;
        display:block !important;
        padding-top:90px;
    }
    @include response-above(xl) {
        align-items: flex-end !important;
        justify-content: space-between !important;
        display:flex !important;
        padding-top:0;
    }
    
}

.ipad_wound {   
    @include responsive(lg) {
        width:100%;
    }
  }
 
  .custom_modal_size {
    max-width: 767px;
}
.spacing {
    padding : 40px 24px;
}
.upload_label {
    margin-right: 67px;
    font-family: $secondary-font-bold;
}
.custom_wrapper {
    width: 212px !important;
    height: 212px !important;
    object-fit: inherit !important;
    border-radius: 0px !important;
    display: inline-block;
    align-items: inherit !important;
    justify-content: inherit !important;
    margin-bottom: 48px;
}
.browse_btn {
    border-radius: 2px;
    border: solid 1px #cccccc;
    background-color: #fafafa;
    padding: 10px 0px;
    width: 140px;
    text-align: center;
    text-decoration: none !important;
    margin-left : 0px !important;
    font-family: $secondary-font-regular;
    font-weight: 500 !important;
    color: #333333 !important;
    margin-bottom: 56px !important;
}
.browse_btn:hover {
    cursor: pointer;
}


.hide {
    display: none;
}
.modal_title {
    font-size: 12px !important;
}


// Patient Card Details
.patient_details_blk{ 
    margin-top: -15px;
}
  