@import "../../../styles/standard.module.scss";

.error_border {
	border: 1px solid $Error !important;
}
.error_message {
	display: block;
	color: $Error !important;
	padding-top: 4px;
	font-family: $secondary-font-regular;
	font-size:14px !important;
}

.ManualIndimationtooltip{
	opacity: 0.5 !important;
    color: #bfbfbf !important;
}