.file_upload{
    display: none;
}
.import_block {
    width: auto;
    height: 42px;    
    margin: 0 8px; 
    cursor: pointer;
    border: 0;
    background: transparent;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    label{
        cursor: pointer;
        margin-bottom: 0;
    }
    img{
        margin-right:10px;
    }
}

.import_block_disabled {
    width: auto;
    height: 42px;    
    margin: 0 8px; 
    cursor: pointer;
    border: 0;
    background: transparent;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    opacity: 0.3!important;
    position: relative;
    label{
        cursor: pointer;
        margin-bottom: 0;
        font-size: 19px;
        color: #757575;
        font-weight: 500;
    }
    img{
        margin-right:10px;
        // width: 23px;
    }
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 230px;
    font-size: 15px;
    font-weight: 500;
    height: 34px;
    position: absolute;
    border: 2px solid #000000;
    left: 39px;
    top: -38px;
    padding: 4px 5px 0px 4px;
    // line-height: 1.1em;
    border-radius: 5px;
    color: #000000;     
    // z-index: 999;
}
  .tooltip:hover .tooltiptext, .tooltip:hover .tooltiptextOther {
    visibility: visible;
  }