@import '../../../styles/standard.module.scss';
.page_show {
    margin-bottom: 0;
    font-family:$secondary-font-bold;
    font-size: 14px;
    line-height: 40px;
    margin-right: 20px;
}
.page_item {
    line-height: 30px;
    font-family: $secondary-font-regular;
    font-size: 14px;
    color: $text-color;
    padding: 0 5px;
}
.page_link,
.page_link:hover {
    color: $PrimaryColor-text;
    font-family:$secondary-font-bold;
    font-size: 14px;
    background: $Plain-bg;
}
.page_link:hover {
    color: $Plain-text;
    font-family:$secondary-font-bold;
    font-size: 14px;
    background: $PrimaryButton-bg;
}
.page_item.active {
	.page_link {
		background-color:$PrimaryButton-bg !important;
		border-color: $PrimaryButton-bg !important;
		color: $Plain-text !important;
	}
}
.clear_both {
    clear: both;
}
.nopagination {
    display: none;
}