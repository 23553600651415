@import '../../../styles/standard.module.scss';

.password_hints {
	padding-left: 0;	
	font-size: 12px;
	list-style: none;
	
	.fail_condition {
		color: $hint-text;
        width: 48%;
		display: inline-block;
		span {
			width: 12px;
            height: 12px;
            border-radius: 2px;
            background-color: $hint-normal;
			margin: 2px 8px 5px 0;			
			display: inline;
			float: left;			
		}
	}
	.pass_condition {
		color: $PrimaryColor-text;
        width: 48%;
		display: inline-block;
		span {
			width: 12px;
            height: 12px;
            border-radius: 2px;
            background: $hint-success-text url('../../../static/images/tick_arrow.png') center center no-repeat;  
			margin: 2px 8px 5px 0;			
			display: inline;
			float: left;
		}
	}
}