@import "../../../styles/standard.module.scss";

.error_border {
	border: 1px solid $Error !important;
}
.error_message {
	display: block;
	color: $Error;
	padding-top: 4px;
	font-family: $secondary-font-regular;
	font-size:14px;
}
.error_message2 {
	display: block;
	color: $Error;
	padding-top: 4px;
	font-family: $secondary-font-regular;
	font-size:14px;
	white-space: nowrap !important;
  }
  
// .astrick {
// 	color: $Astrik;
// }
.text_field_label {
	font-size: $Typography-2;
	color: $PrimaryColor-text;
	font-family: $secondary-font-regular;
}
.text_field_label span{
	color: $Error;
}	
.form_control:focus {
	box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%);
	border-radius: 2px;
}
.form_control {
	border-radius: 2px;
}
.eyeopen, .eyeclose{
    position: absolute;
    right: 10px;
    top: 6px;
}

.char_count{
    float: right;
    color: $hint-normal;
    font-family: $secondary-font-bold;
    font-size: 16px;
}
.char_count_font
{
	vertical-align: inherit;
}
.x_icon{
	position: absolute;
    right: 2%;
    top: 8px;
    cursor: pointer;
	color: black;
}