@import '../../../styles/standard.module.scss';
.react_tabs {
  border-bottom: 1px solid transparent;
  list-style: none;
  width: auto;
  display: flex;
  padding-left: 0;
  //white-space: nowrap;
  margin-bottom: -2px;
  @include response-above(sm) {
      margin-top:0; 
      margin-left:22px;
      margin-right:22px;
  }
  @include response-above(lg) {
    margin-top:0 !important; 
    margin-left:0;
    margin-right:0;
  }
}

.react_tab_pane {
  padding: 0 0 0px;
  box-shadow: 5px 10px 20px 0 rgba(191, 191, 191, 0.3);
  background-color: $Plain-bg;
  max-width:100%; 
  display:none;
  &.selected{
      display:block;
      position: relative;
      margin-bottom:20px; 
  }  
  @include response-above(sm) { 
    margin-left:22px;
    margin-right:22px;
  }
  @include response-above(lg) {     
    margin-left:0;
    margin-right:0;
  }
}
.react_tabs_list {
  color: $tab-text;
  border-color: transparent;
  //border-bottom: solid 2px $hint-normal;
  width:200px;
  text-align: center;
  padding-bottom: 8px;
  font-family:$secondary-font-regular;
 // display: flex;
  justify-content: center;
  align-items: end;
  &:last-child{
    margin-right: 0;
    
  }
  &.selected, &:hover{
    color: $tab-active;
    background-color: transparent;
    border-color: transparent;
    border-bottom: solid 5px $tab-active;
    cursor:pointer;
    // margin-bottom: 0px; //added
    // margin-top: -7px;  //added
     //commented
    span{
      background-color: $PrimaryButton-bg;
      border-radius: 14px;
      font-size:12px;
      color: $Plain-text;
      //padding: 0 9px;
      border: solid 0.5px $PrimaryButton-bg;
    }
  }
  span{
    border: solid 0.5px $tab-border;
    border-radius: 14px;
    background-color: $Plain-bg;
    font-size:12px;
    color: $tab-text;
    padding: 0 9px;
    margin-left: 6px;
    display: inline-block;
  }
}
.react_tabs_list:not(:last-child) {
  // margin-right: 36px;
  @include responsive(lg) {
    margin-right: 0px;
  } 
}
.fixed_width{
  max-width: 100% !important;
}
.tissueview_fix{
  float: left;
  margin-top: -37px  !important;
}
.tissueview_outer{
  margin-top: 80px;
}
.wound_report_view{
  color: $tab-text;
  border-color: transparent;
  border:solid 1px $tab-border;
  background-color: rgba(146, 149, 155, 0.1);
  width:auto;
  text-align: center;
  padding: 8px 15px;
  font-family:$secondary-font-bold;
  // display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  span{
    border: solid 0.5px $tab-border;
    border-radius: 14px;
    background-color: $Plain-bg;
    font-size:12px;
    color: $tab-text;
    padding: 0 9px;
    margin-left: 6px;
    display: inline-block;
  }
  &:last-child{
    margin-right: 0;
  }
  &.selected, &:hover{
    color: $secondary-text;
    background-color: transparent;
    border-color: transparent;
    border:solid 1px $tab-border;
    border-bottom: transparent;
    cursor:pointer;
    span{
      background-color: $PrimaryButton-bg;
      border-radius: 14px;
      font-size:12px;
      color: $Plain-text;
      padding: 0 9px;
      border: solid 0.5px $PrimaryButton-bg;
    }
  }
  

}

.wound_report_view:not(:last-child) {
  margin-right: 0;
  border-right: 0;
  @include responsive(lg) {
    margin-right: 0px;
  } 
  
}

.hist_cause_list {
  color: $tab-text;
  border-color: transparent;
  //border-bottom: solid 2px $hint-normal;
  width:200px;
  text-align: center;
  padding-bottom: 8px;
  font-family:$secondary-font-regular;
  //display: flex;
  justify-content: center;
  align-items: end;
  font-size: 16px;
  &:last-child{
    margin-right: 0;
  }
  &.selected, &:hover{
    color: $tab-active;
    background-color: transparent;
    border-color: transparent;
    border-bottom: solid 5px $tab-active;
    cursor:pointer;
  }
}
.hist_cause_list:not(:last-child) {
  margin-right: 36px;
  @include responsive(lg) {
    margin-right: 0px;
  } 
  
}

.hr_Line {
width: 100%;
height: 2px;
background-color: grey;
opacity: 0.5;
// margin-top:-31px;

margin-bottom: 21px
}