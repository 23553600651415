@import "../../../styles/standard.module.scss";

.error_border {
  border: 1px solid $Error !important;
}
.error_message {
  display: block;
  color: $Error;
  padding-top: 4px;
  font-family: $secondary-font-regular;
  font-size: 14px;
}
.text_field_label {
  font-size: $Typography-2;
  color: $PrimaryColor-text;
  font-family: $secondary-font-regular;
}
.form_control:focus {
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%);
  border-radius: 2px;
}
.form_control {
  border-radius: 2px;
}
.eyeopen,
.eyeclose {
  position: absolute;
  right: 10px;
  top: 6px;
}

.char_count {
  float: right;
  color: $hint-normal;
  font-family: $secondary-font-bold;
  font-size: 16px;
}
.char_count_font {
  vertical-align: inherit;
}

.risk_content {
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .col_spec {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
    display: flex;
  }
}

.label_spec span {
  margin-bottom: 0;
  font-family: $secondary-font-regular;
  font-size: 16px;
  line-height: normal;
}

.risk_select_box {
  width: 100%;
  height: 40px;
  margin: 4px 0 7px 0px;
  padding: 0;
  border-radius: 2px;
  border: solid 0px $border-color;
  background-color: $Plain-bg;
  .dropdown_toggle {
    width: 100% !important;
    background-color: $Plain-bg !important;
    color: $PrimaryColor-text !important;
    text-align: left !important;
    border: 1px solid #c1c7d0;
    border-radius: 2px;
    min-height: 38px;
  }
}

.risk_drop_down {
  width: 100% !important;
  max-height: 320px !important;
  overflow-y: auto !important;
  .risk_item_wrap {
    white-space: normal !important;
    padding: 5px 10px !important;
  }
}

.risk_select_box .drop_arrow:after {
  border: 0 !important;
  background: url("../../../static/images/down-arrow.png") right center
    no-repeat !important;
  position: absolute !important;
  right: 10px;
  top: 10px;
  width: 17px;
  height: 19px;
}
