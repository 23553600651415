@import '../../../styles/standard.module.scss';
.media_hdg{
    background-color: rgba(80, 95, 121, 0.05);
    color: #505f79;
    font-family: $secondary-font-bold;
    font-size: 18px;
    padding: 11px 20px 11px 20px;
}
.media_btm {
    padding: 20px;
}
.no_shadow{
    box-shadow: none;
}