#Abdomen_Center,  #Abdomen_Left, #Abdomen_Right,
#Chest_Center, #Chest_Right, #Chest_Left,
#Arm_Upper_Left_Anterior, #Arm_Upper_Right_Anterior,
#Shoulder_Right_Anterior, #Shoulder_Left_Anterior, #Neck_Anterior,
#Perineum_Anterior,#Ear_Right, #Forehead_Anterior, #Head_Posterior_Head_Anterior,  
#Cubital_Fossa_Right_Anterior, #Cubital_Fossa_Left_Anterior, 
#Arm_Lower_Right_Anterior, #Arm_Lower_Left_Anterior,
#Groin_Right_Anterior, #Groin_Left_Anterior,
#Leg_Upper_Right_Anterior, #Leg_Upper_Left_Anterior, #Knee_Right_Anterior, 
#Knee_Left_Anterior, #Leg_Lower_Right_Anterior, #Leg_Lower_Left_Anterior,
#Foot_Right_Dorsal, #Foot_Left_Dorsal, #Toes_Right,
#Toes_Left, #Fingers_Right, #Fingers_Left,
#Hand_Right_Palmer, #Hand_Left_Palmer, #Wrist_Right_Anterior,
#Wrist_Left_Anterior, #Perineum_Anterior_Alt
{
    cursor:pointer;
    fill:#96a6bf;
}
#Eye_Right, #Nose_Anterior, #Lips_Anterior,  #Chin_Anterior,  #Ear_Right, #ForeHead_Right{
    cursor:pointer;
    fill:#7a94bb;
}
#Abdomen_Center:hover,  #Abdomen_Left:hover, #Abdomen_Right:hover,
#Chest_Center:hover, #Chest_Right:hover, #Chest_Left:hover,
#Arm_Upper_Left_Anterior:hover, #Arm_Upper_Right_Anterior:hover,
#Shoulder_Right_Anterior:hover, #Shoulder_Left_Anterior:hover, #Neck_Anterior:hover,
#Perineum_Anterior:hover,#Ear_Right:hover, #Forehead_Anterior:hover, #Head_Posterior_Head_Anterior:hover, 
#Eye_Right:hover, #Nose_Anterior:hover, #Chin_Anterior:hover, #Lips_Anterior:hover,
#Cubital_Fossa_Right_Anterior:hover, #Cubital_Fossa_Left_Anterior:hover, 
#Arm_Lower_Right_Anterior:hover, #Arm_Lower_Left_Anterior:hover,
#Groin_Right_Anterior:hover, #Groin_Left_Anterior:hover,
#Leg_Upper_Right_Anterior:hover, #Leg_Upper_Left_Anterior:hover, #Knee_Right_Anterior:hover, 
#Knee_Left_Anterior:hover, #Leg_Lower_Right_Anterior:hover, #Leg_Lower_Left_Anterior:hover,
#Foot_Right_Dorsal:hover, #Foot_Left_Dorsal:hover, #Toes_Right:hover,
#Toes_Left:hover, #Fingers_Right:hover, #Fingers_Left:hover,
#Hand_Right_Palmer:hover, #Hand_Left_Palmer:hover, #Wrist_Right_Anterior:hover,
#Wrist_Left_Anterior:hover,  #Ear_Right:hover, #ForeHead_Right:hover, #Knee_Right_Medial:hover,
#Knee_Left_Medial:hover, #Ankle_Right_Medial:hover, #Ankle_Left_Medial:hover, #Perineum_Anterior_Alt:hover
{
    cursor:pointer;
    fill:#00c7e5;
}

/* human body back posture svg tags */

#Ear_Left, #Head_Posterior, #Perianal_Posterior,
#Heel_Right_Posterior, #Heel_Left_Posterior, #Eye_Left
{
    cursor:pointer;
    fill:#7a94bb;
}
#Knee_Right_Lateral, #Knee_Left_Lateral, #Knee_Right_Medial, #Knee_Left_Medial, 
#Ankle_Right_Medial, #Ankle_Left_Medial, #Foot_Right_Plantar, #Foot_Left_Plantar,
#Ankle_Right_Lateral, #Ankle_Left_Lateral
{
    cursor:pointer;
}
#Head_Posterior, #Forehead_Anterior_Back, #ForeHead_Left, #Neck_Posterior,
#Shoulder_Right_Posterior, #Shoulder_Left_Posterior,#Perianal_Posterior, #Back_Upper_Posterior_Center,
#Back_Lower_Posterior_Center, #Back_Upper_Right_Posterior, #Back_Upper_Left_Posterior,
#Arm_Upper_Right_Posterior, #Arm_Upper_Left_Posterior, #Back_Lower_Right_Posterior,
#Back_Lower_Left_Posterior, #Elbow_Right_Posterior, #Elbow_Left_Posterior,
#Arm_Lower_Right_Posterior, #Arm_Lower_Left_Posterior, #Wrist_Right_Posterior,
#Wrist_Left_Posterior, #Buttock_Right_Posterior, #Buttock_Left_Posterior, #Sacral_Coccyx,
#Wrist_Right_Anterior, #Hand_Left_Dorsal, #Hand_Right_Dorsal, #Leg_Upper_Right_Posterior,
#Knee_Right_Posterior, #Knee_Left_Posterior, #Leg_Lower_Right_Posterior, #Leg_Lower_Left_Posterior,
 #Hip_Right_Lateral, #Hip_Left_Lateral, #Face_Anterior

{
    cursor:pointer;
    fill:#96a6bf;
}
#Ear_Left:hover, #Head_Posterior:hover, #ForeHead_Left:hover, #Neck_Posterior:hover,
#Shoulder_Right_Posterior:hover, #Shoulder_Left_Posterior:hover, #Back_Upper_Posterior_Center:hover,
#Back_Lower_Posterior_Center:hover, #Back_Upper_Right_Posterior:hover, #Back_Upper_Left_Posterior:hover,
#Arm_Upper_Right_Posterior:hover, #Arm_Upper_Left_Posterior:hover, #Back_Lower_Right_Posterior:hover,
#Back_Lower_Left_Posterior:hover, #Elbow_Right_Posterior:hover, #Elbow_Left_Posterior:hover,
#Arm_Lower_Right_Posterior:hover, #Arm_Lower_Left_Posterior:hover, #Wrist_Right_Posterior:hover,
#Wrist_Left_Posterior:hover, #Buttock_Right_Posterior:hover, #Buttock_Left_Posterior:hover, #Sacral_Coccyx:hover,
#Perianal_Posterior:hover, #Wrist_Right_Anterior:hover, #Hand_Left_Dorsal:hover, #Hand_Right_Dorsal:hover,
#Leg_Upper_Right_Posterior:hover, #Knee_Right_Posterior:hover, #Knee_Left_Posterior:hover,
#Leg_Lower_Right_Posterior:hover, #Leg_Lower_Left_Posterior:hover, #Ankle_Right_Lateral:hover,
#Ankle_Left_Lateral:hover, #Heel_Right_Posterior:hover, #Heel_Left_Posterior:hover, 
#Hip_Right_Lateral:hover, #Hip_Left_Lateral:hover, #Face_Anterior:hover, #Eye_Left:hover,
#Knee_Right_Lateral:hover, #Knee_Left_Lateral:hover, #Foot_Right_Plantar:hover, #Foot_Left_Plantar:hover, #Leg_Upper_Left_Posterior:hover,
#Forehead_Anterior_Back:hover, #Shape:hover
{
    cursor:pointer;
    fill:#00c7e5;
}
.active{
    fill:#00c7e5 !important;    
}
