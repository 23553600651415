@import "../../../styles/standard.module.scss";

.tab_content_top {
    border-bottom: solid 1px #f2f2f2;
    margin-bottom: 15px;
}
.tab_content_hdg {
    padding: 24px 24px 16px;
    margin-bottom: 0;
    font-family: $secondary-font-regular;
    position: relative;
    min-height: 75px;
}

.basic_info {
    padding: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.basic_info div {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
}

.basic_info_form {
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.basic_info_form div {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
    display: flex;
    align-items: center;
}
.subheading_2 {
    font-family: $primary-font-regular;
    font-size: 21px;
    color: $PrimaryColor-text;
    margin-bottom: 0;
}

.single_product_block {
    padding: 0 24px;
}
.patient_content {
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.moreinfo_content,
.patient_content {
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.moreinfo_content .col_spec,
.patient_content .col_spec {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
    display: flex;
    flex-wrap: wrap;
}

.patient_content .basic_input,
.moreinfo_content .basic_input {
    width: 100%;
    height: 40px;
    padding: 0 5px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}

.patient_content .select_wrapper,
.moreinfo_content .select_wrapper {
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}

.patient_content .basic_select,
.moreinfo_content .basic_select {
    width: 100%;
    height: 40px;
    padding: 0 10px;
    appearance: none;
    -webkit-appearance: none;
    outline: 0;
    background: 0;
    border: 0;

}
select::-ms-expand {
    display: none;
}

.label_spec {
    margin-bottom: 0;
    font-family: $secondary-font-bold;
    line-height: normal;
}

.content_spec {
    font-family: $secondary-font-regular;
    color: $PrimaryColor-text;
    display: inline-block;
}
.basic_input {
    width: 250px;
    height: 40px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}
.small_input {
    width: 68px;
    height: 40px;
    padding-left: 7px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}
.medium_input {
    width: 100px;
    height: 40px;
    padding-left: 7px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}

.basic_select {
    width: 250px;
    height: 40px;
    padding: 0 10px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}

.btn_custom {
    height: 50px;
    border-radius: 2px;
    font-family: $secondary-font-regular;
    background-color: $PrimaryButton-bg;
    font-size: 18px;
}
.btn_small,
.btn_small:hover {
    @include btn_primary;
   
}
.btn_small_cancel,
.btn_small_cancel:hover {
    @include btn_secondary;
}

.tab_edit {
    color: $PrimaryColor-text;
    font-family: $secondary-font-regular;
    font-size: 14px;
    position: absolute;
    right: 24px;
    top: 24px;
    cursor: pointer;
    z-index: 1000;
}

.tab_edit a,
.tab_edit a:hover {
    color: $PrimaryColor-text;
}
.tab_edit img {
    max-width: 20px;
    margin-right: 3px;
}
.general_error {
    margin-bottom: 20px;
    color: #fff;
    border-radius: 3px;
    padding: 5px 15px;
    display: inline-block;
    background-color: $Error;
}
.tab_edit_disable {
    pointer-events: none;
    cursor: not-allowed;
}
.basic_input.disabled {
    background-color: #dbdbdb !important;
}
.basic_select.disabled {
    background-color: #dbdbdb !important;
}
.input_field,
.input_field:focus {
    width: 100%;
    height: 40px;
    padding: 0 5px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
    outline: none;
}
.small_input {
    max-width: 74px !important;
}
.btn_container {
    margin-top: 50px;
    text-align: center;
}
.btn_cancel,
.btn_cancel:hover,
.btn_cancel:active {
    @include btn_secondary;
}
.btn_save,
.btn_save:hover,
.btn_save:active {
    @include btn_primary;
    width:auto;
    img{
        margin-right: 10px;
    }
}
.deleteAttr {
    vertical-align: middle;
    border-style: none;
    width: 22px;
    height: 22px;
    padding: 6px;
    border-radius: 12px;
    border: solid 1px rgba(80, 95, 121, 0.5);
    background-color: #ffffff;
    cursor: pointer;
    margin-top: 16px;
    margin-left: -15px;
}
.content_center {
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: CENTER;
    width: 100%;
    display: flex;
    min-height: 100px;
    font-family: $secondary-font-bold;
}
.input_data_list:focus {
    border-color: #3399ff;
}

.input_data_list::-webkit-search-cancel-button,
.input_data_list::-webkit-clear-button {
    -webkit-appearance: none;
    background-image: url("../../../static/images/down-arrow.png");
    color: rgba(0, 0, 0, 0.54);
    cursor: pointer;
    margin-right: 0;
    width: 10px;
    height: 5px;
}

.input_data_list::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    background-image: url("../../../static/images/down-arrow.png");
    width: 10px;
    height: 5px;
    cursor: pointer;
    border-radius: 50%;
    right: 0px;
    position: absolute !important;
}
.error_message{
    display: block;
    color: #de350b;
    padding-top: 4px;
    font-family: $secondary-font-regular;
    font-size: 14px;
}
.error_border {
	border: 1px solid $Error !important;
}
.form_alert {
    display: inline-block !important;
  }
.addValBtn{
    color: #0052cc;
    font-weight: 700;
    font-size: 16px;
    float:right;
    cursor: pointer;  
}