@import "../../../styles/standard.module.scss";

.subheading_2 {
  font-family: $primary-font-regular;
  font-size: 21px;
  color: $PrimaryColor-text;
  margin-bottom: 0;
}
.create_patient {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
  display: flex;
  flex-wrap: wrap;
}
.col_spec {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
  display: flex;
}
.basic_input {
  width: 100%;
  height: 40px;
  padding: 0 5px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
}
.label_spec {
  margin-bottom: 0;
  font-family: $secondary-font-bold;
}
.btn_small {
  width: 140px;
  height: 42px;
  font-family: $secondary-font-regular;
  background-color: $PrimaryButton-bg;
  color: $Plain-bg;
  font-size: $Typography-2;
}
.btn_custom {
  height: 42px;
  border-radius: 2px;
  font-family: $secondary-font-regular;
  background-color: $PrimaryButton-bg;
  font-size: $Typography-2;
  padding: 4px;
}
.custom_modal_size {
  max-width: 967px;
}
.custom_label_spec {
  width: 170px;
  padding-left: 15px;
}



.tooltip {
  position: relative;
  display: inline-block; 
  float: right;
  top: -25px;
  padding: 5px;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 250px;
  font-size: 13px;
  height: 50px;
  // overflow-y: scroll; 
  text-align: center;
  background-color: white;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 7px 0;
  position: absolute;
  z-index: 1;
  font-family: $secondary-font-regular;
  font-weight: 500;
  letter-spacing: 0.01em;
  border: 1px solid #4f4f4f;
  right: -85px;
  top: -60px;
  box-shadow: 0 1px 0px 1px #000;
}
.tooltip .tooltiptextExternal {
  visibility: hidden;
  width: 250px;
  font-size: 13px;
  height: 50px;
  // overflow-y: scroll; 
  text-align: center;
  background-color: white;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 7px 0;
  position: absolute;
  z-index: 1;
  font-family: $secondary-font-regular;
  font-weight: 500;
  letter-spacing: 0.01em;
  border: 1px solid #4f4f4f;
  right: -85px;
  top: -50px;
  box-shadow: 0 1px 0px 1px #000;
}


.tooltip:hover .tooltiptext, .tooltip:hover .tooltiptextExternal {
  visibility: visible;
}

.tooltip_list{
  // top: -3px !important;
  top: -58px !important;
  left: -125px;
}