@import "../../../../src/styles/standard.module.scss";

@include response-above(sm) {
  .admin_subheading {
    font-size: $Heading-2;
    font-family: $primary-font-regular;
    color: $Plain-text;
  }
  .header {
    position: fixed;
    width: 100%;
    z-index: 1000;
    display: flex;
  }
  .custom_nav {
    background-color: $PrimaryColor-text;
    width: 100%;
  }
  .admin_heading {
    display: none;
  }
  .left_menu {
    width: auto;
  }
}
@include response-above(lg) {
  .admin_subheading {
    font-size: $Heading-1;
    font-family: $primary-font-regular;
    color: $PrimaryColor-text;
  }
  .header {
    position: relative;
    margin-left: 0;
    display: flex;
    padding: 0;
  }
  .custom_nav {
    background-color: transparent;
    padding: 8px 0px 25px 0px;
  }
  .admin_heading {
    font-size: 36px;
    color: $PrimaryColor-text;
    font-family: $primary-font-bold;
    padding-left: 0;
    margin-bottom: 0;
    display: inline-flex;
  }
}
.medline_menu {
  width: 240px;
}
.medline_user {
  font-family: $secondary-font-bold;
  color: $PrimaryColor-text;
  padding-left: 10px;
}
.p_link {
  cursor: pointer;
  text-decoration: none;
}
.link:hover {
  cursor: pointer;
  text-decoration: none;
  background-color: $menu-link;
}
.list_menu {
  color: $PrimaryColor-text;
  padding: 16px 0px 16px 23px;
  font-family: $secondary-font-regular;
  background-color: $Plain-bg;
  box-shadow: 5px 10px 20px 0 rgba(191, 191, 191, 0.3);
}
.medline_menu a {
  background: none !important;
  padding-left: 0px !important;
  text-indent: 0px !important;
}
.medline_menu a:hover {
  text-decoration: none;
  background-color: $menu-link;
}

.tab_hdg {
  font-size: 24px;
  color: $PrimaryColor-text;
  width: auto;
  padding-right: 16px;
}
.tab_content_line {
  color: $PrimaryColor-text;
  font-family: $secondary-font-regular;
  font-size: 14px;
}
.tab_content_subline {
  font-family: $primary-font-regular;
  font-size: 18px;
  display: block;
  margin-top: 5px;
}
.tab_content_subline strong {
  font-family: $secondary-font-bold;
  font-weight: normal;
}
.user_picture {
  width: 49px;
  height: 49px;
  border-radius: 50%;
}
.userpic_blk {
  border: 0;
  background-color: transparent;
  padding-right: 0;
}


.main_logo{
  padding: 40px 0 30px 0;
}
.medline_log{
  margin-right: 25px;
  a{
    background: none !important;
  }
  img{
    cursor: pointer;
  }  
}
.menu_log{
  margin-right: 20px;
  cursor: pointer;
  img{
    width: 25px;
  }
}
.menu_content{
  cursor: pointer;
  p{
    font-size: 18px;
    color: #002d72;
    margin: 0 auto;
    font-weight: 400;
  }
}