@import '../../../styles/standard.module.scss';

.error_border {
	border: 1px solid $Error !important;
}
.error_message {
	color: $Error;
	padding-top: 4px;
        font-family: $secondary-font-regular;
        font-size:14px;
}
// .astrick {
// 	color: $Astrik;
// }
.text_field_label {
	font-size: $Typography-2;
	color: $PrimaryColor-text;
	font-family: $secondary-font-regular;
}
.form_control:focus {
	box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%);
	border-radius: 2px;
}
.form_control {
	border-radius: 2px;
}
.sel_width {
  width: 64px;
  display: inline-block;
  border: 0;
  padding: 0;
  height: 36px;
}
.sel_width:focus {
outline: none;
}
.phone_input {
  display: inline-block;
  border: 0;
  height: 35px;
  width: 65%;
  margin-left: 0px;
  padding-right: 1px;
} 

