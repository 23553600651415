@import "../../../styles/standard.module.scss";
.subheading_2 {
  font-family: $primary-font-regular;
  font-size: 21px;
  color: $PrimaryColor-text;
  margin-bottom: 0;
}
.create_patient {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
  display: flex;
  flex-wrap: wrap;
}
.col_spec {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
  display: flex;
}
.basic_input {
  width: 100%;
  height: 40px;
  padding: 0 5px;
  border-radius: 2px;
  border: solid 1px $border-color-light;
  background-color: $Plain-bg;
}
.label_spec {
  margin-bottom: 0;
  font-family: $secondary-font-bold;
}
.btn_small {
  width: 140px;
  height: 42px;
  font-family: $secondary-font-regular;
  background-color: $PrimaryButton-bg;
  color: $Plain-bg;
  font-size: $Typography-2;
}
.btn_custom {
  height: 42px;
  border-radius: 2px;
  font-family: $secondary-font-regular;
  background-color: $PrimaryButton-bg;
  font-size: $Typography-2;
  padding: 4px;
}
.custom_modal_size {
  max-width: 967px;
}
.error_message {
  font-family: $secondary-font-regular !important;
  font-size: 14px;
  color: $Error !important;
}
.error_border_color {
  border: 1px solid $Error !important;
}
.bir_subline {
  color: #505f79 !important;
  font-family: $secondary-font-regular !important;
  font-size: 12px !important;
  display: block !important;
}
