@import "../../../styles/common.module.scss";

.btn_custom {
  height: 42px;
  border-radius: 2px;
  font-family: $secondary-font-regular;
  background-color: $PrimaryButton-bg;
  font-size: 16px;
}
.btn_small,
.btn_small:hover {
  @include btn_primary;
  margin-right: 32px;
}
.btn_small_cancel,
.btn_small_cancel:hover,
.btn_small_cancel:active {
  @include btn_secondary;
  margin: 0px !important;
}

.tab_pane {
  padding: 0 0 41px;
  box-shadow: 5px 10px 20px 0 rgba(191, 191, 191, 0.3);
  background-color: $Plain-text;
}
.tab_content_top {
  border-bottom: solid 1px $tab-border-bottom;
  margin-bottom: 15px;
}

.tab_content_hdg {
  padding: 24px 24px 16px;
  margin-bottom: 0;
  font-family: $secondary-font-bold;
  position: relative;
}
.tab_hdg {
  font-size: 24px;
  color: $PrimaryColor-text;
  width: auto;
  padding-right: 16px;
}

.tab_content_line {
  color: $PrimaryColor-text;
  font-family: $secondary-font-regular;
  font-size: 14px;
}

.tab_content_subline {
  font-family: $primary-font-regular;
  font-size: 18px;
  display: block;
  margin-top: 5px;
}
.tab_edit {
  color: $PrimaryColor-text;
  font-family: $secondary-font-regular;
  font-size: 14px;
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 1000;
  cursor: pointer;
}
.tab_edit a,
.tab_edit a:hover {
  color: $PrimaryColor-text;
}

.tab_edit img {
  max-width: 20px;
  margin-right: 3px;
}

.single_product_block {
  padding: 0 24px;
}

.single_product_block .single_product {
  width: 100%;
  float: left;
  display: flex;
  padding-bottom: 20px;
}

.single_product_block .single_product .single_left_block {
  font-family: $secondary-font-bold;
  font-size: 16px;
  color: $PrimaryColor-text;
  width: 100px;
  display: inline-block;
  padding: 0;
  float: left;
}

.single_product_block .single_product.custom_control_label {
  width: 165px;
  padding-right: 0;
  margin-right: 0;
  font-family: $secondary-font-regular;
}

.single_product_block .single_product .single_right_block {
  font-family: $secondary-font-regular;
  font-size: 16px;
  color: $PrimaryColor-text;
  padding-left: 8px;
  width: calc(100% - 140px);
  float: left;
}

.moreinfo_content,
.patient_content {
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.moreinfo_content .col_spec,
.patient_content .col_spec {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
  display: flex;
}

.patient_content .basic_input,
.moreinfo_content .basic_input {
  width: 100%;
  height: 40px;
  padding: 0 5px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-text;
}
.basic_input:focus {
  outline: 0 !important;
}
.patient_content .select_wrapper,
.moreinfo_content .select_wrapper {
  border-radius: 2px;
  background-color: $Plain-text;
}

.patient_content .basic_select,
.moreinfo_content .basic_select {
  width: 100%;
  height: 40px;
  padding: 0 5px !important;
  appearance: none;
  -webkit-appearance: none;
  outline: 0;
  background: 0;
  border-radius: 2px;
  border: solid 1px $border-color;
}

.select_content {
  width: 250px;
  display: inline-block;
  margin-top: 25px;
  margin-right: 18px;
}

.select_content .select_wrapper {
  border-radius: 2px;
  background-color: $Plain-text;
  border: solid 1px $border-color;
}

.basic_select {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border-radius: 2px;
  border: solid 1px $border-color;  
  background-color: transparent;
  appearance: none;
}

.label_spec {
  margin-bottom: 0;
  font-family: $secondary-font-bold;
}

.content_spec {
  font-family: $secondary-font-regular;
  color: $PrimaryColor-text;
  display: inline-block;
}

.content_spec ul {
  padding-left: 0;
}

.content_spec ul li {
  list-style: none;
}

.content_edit_spec {
  font-family: $secondary-font-regular;
  background-color: $body-bg-color;
  border: solid 1px $bdr-color-tertiary;
  color: $PrimaryColor-text;
}

.calendar_right {
  position: absolute;
  right: 10px;
  top: 5px;
}
.col_center {
  display: flex;
  align-items: center;
}
.d_none {
  display: none !important;
}
.d_show {
  display: block !important;
}
.select_wrapper {
  background: url("../../../static/images/down-arrow.png") right 10px top 10px
    no-repeat;
  border-radius: 2px;
  background-color: $Plain-bg;
  min-height: 40px;
}
.custom_label_spec {
  width: 170px;
  padding-left: 15px;
}
.subheading_2 {
  font-family: $primary-font-regular;
  font-size: 21px;
  color: $PrimaryColor-text;
  margin-bottom: 0;
}
.facility_conts {
  margin-left: -4px;
}

.no_btn {
  width: 140px;
  border-radius: 0px;
  background-color: $Body-bg;
  color: $PrimaryColor-text;
  border: solid 1px $tertiary-text;
}
.no_btn:hover {
  background-color: $Body-bg;
  color: $PrimaryColor-text;
  border: solid 1px $tertiary-text;
}
.no_btn:active {
  background-color: $Body-bg !important;
  color: $PrimaryColor-text !important;
  border: solid 1px $tertiary-text !important;
}
.no_btn:focus {
  background-color: $Body-bg;
  color: $PrimaryColor-text;
  border: solid 1px $tertiary-text;
}
.yes_btn {
  width: 140px;
  border-radius: 0px;
}
.first_child {
  color: $border-color-light !important;
}
.first_child option:not(:first-of-type) {
  color: $PrimaryColor-text !important;
}

.customSize {
  max-width: 618px !important;
  height: 214px;
}

.btn_custom_disable {
  width: 158px;
  height: 45px;
  border-radius: 2px;
  font-family: $secondary-font-regular;
  background-color: $PrimaryButton-bg;
  font-size: 18px;
  opacity: 0.3;
  cursor: not-allowed !important;
}
.info_icon {
  cursor: pointer;
}
.loader{
  text-align: center;
  padding: 150px 0;
  img{
      max-width: 50px;
      border-radius: 100%;
  }
}	