@import "../../../styles/standard.module.scss";

.wound_dashboard {
  box-shadow: 5px 10px 20px 0 rgb(191 191 191 / 30%);
  background-color: #ffffff;
  margin-top: 40px;
  padding: 0 0 20px;
}
.wound_form {
  padding: 40px 32px 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.wound_form .col_spec {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
  display: flex;
}
.label_spec {
  margin-bottom: 0;
  font-family: $secondary-font-bold;
}
.wound_input {
  width: 100%;
  height: 40px;
  border-radius: 2px;
  border: solid 1px #c1c7d0;
  background-color: #ffffff;
  padding-left: 10px !important;
}
.wound_input:focus {
  outline: none;
}
.btn_custom {
  height: 50px;
  border-radius: 2px;
  font-family: $secondary-font-regular;
  background-color: $PrimaryButton-bg;
  font-size: 18px;
}
.btn_small,
.btn_small:hover,
.btn_small:active {
  width: 158px;
  height: 50px;
  font-family: $secondary-font-regular;
  background-color: $PrimaryButton-bg;
  color: $Plain-text;
  font-size: 16px;
  margin-right: 32px;
}
.btn_small_cancel,
.btn_small_cancel:hover,
.btn_small_cancel:active {
  width: 158px;
  height: 50px;
  font-family: $secondary-font-regular;
  background-color: $Plain-bg !important;
  border: 1px solid $bdr-color-tertiary !important;
  color: $PrimaryColor-text !important;
  font-size: 16px;
}
.wound_select {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border-radius: 2px;
  border: solid 1px #c1c7d0;
  background-color: transparent;
  appearance: none;
}
.form_span {
  color: #0052cc;
  margin-left: 10px;
  cursor: pointer;
}
.overview_top {
  padding: 30px 30px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.down_spread {
  color: #0052cc;
  font-family: $secondary-font-bold;
  font-size: 16px;
  display: inline-block;
  a {
    text-decoration: none;
    color: #0052cc !important;
  }
}
.down_spread:hover {
  cursor: pointer;
  color: #0052cc;
  text-decoration: none;
  a {
    text-decoration: none;
    color: #0052cc !important;
  }
}
.empty {
  margin: 30px;
  position: relative;
  z-index: 1;
  .empty_heading {
    height: 56px;
    padding: 0px 10px;
    background-color: #505f79;
    line-height: 56px;
    color: #fff;
    font-size: 14px;
    position: relative;
    z-index: 7;
    span {
      margin: 0px 15px;
    }
  }
  .empty_body {
    min-height: 100px;
    box-shadow: 0px 0px 15px #eee;
    text-align: center;
    position: relative;
    z-index: 6;
    h5 {
      line-height: 100px;
      margin: 0;
    }
  }
}
.list_outer {
  margin: 20px 20px;
}
.bir_subline {
  color: #505f79 !important;
  font-family: $secondary-font-regular !important;
  font-size: 12px !important;
  display: block !important;
  font-weight: normal !important;
}

.loader_position {
  margin-bottom: 10px;
}
.loader_img {
  max-width: 50px;
}