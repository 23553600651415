@import '../../../styles/standard.module.scss';

.basic_info {
    padding: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.basic_info div {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
}

.basic_info_form {
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.basic_info_form div {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
    display: flex;
    align-items: center;
}
.subheading_2 {
    font-family: $primary-font-regular;
    font-size: 21px;
    color: $PrimaryColor-text;
    margin-bottom: 0;
}

.single_product_block {
    padding: 0 24px;
}
.patient_content {
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width:100%;
}

.moreinfo_content,
.patient_content {
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.moreinfo_content .col_spec,
.patient_content .col_spec {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
    display: flex;
    flex-wrap: wrap;    
}

.patient_content .basic_input,
.moreinfo_content .basic_input {
    width: 100%;
    height: 40px;
    padding: 0 5px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}

.patient_content .select_wrapper,
.moreinfo_content .select_wrapper {
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
    height:40px;
}

.patient_content .basic_select,
.moreinfo_content .basic_select {
    width: 100%;
    height: 40px;
    padding: 0 30px 0 10px;
    appearance: none;
    -webkit-appearance: none;
    outline: 0;
    background: 0;
    border: 0;
}
select::-ms-expand {
    display: none;
}

.label_spec {
    margin-bottom: 0;
    font-family: $secondary-font-bold;
    
}

/* Below for error message alignment */
// .label_spec ~ span {
//     margin-left: 50%;
// }

.content_spec {
    font-family: $secondary-font-regular;
    color: $PrimaryColor-text;
    display: inline-block;
}
.basic_input {
    width: 250px;
    height: 40px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}
.small_input {
    width: 68px;
    height: 40px;    
    padding-left: 7px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}
.medium_input {
    width: 100px;
    height: 40px;    
    padding-left: 7px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}

.basic_select {
    width: 250px;
    height: 40px;
    padding: 0 10px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}


.btn_custom {
    height: 42px;
    border-radius: 2px;
    font-family: $secondary-font-regular;
    background-color: $PrimaryButton-bg;
    font-size: 16px;
}
.btn_small,
.btn_small:hover {
   @include btn_primary;
	margin-right: 32px;
   
}
.btn_small_cancel,
.btn_small_cancel:hover,
.btn_small_cancel:active {
    @include btn_secondary;   
}
.admission_upper{
    position: relative;
}
.admission_fix{
    position: absolute;
    top: 0;
    right: -7px;
}
.admission_date{
    padding: 7px;
    border: 1px solid $adm-color;
    width: 100px;
}
.select_wrapper {
    background: url('../../../static/images/down-arrow.png') right 10px center no-repeat;
}
.tab_edit {
    color: $PrimaryColor-text;
    font-family: $secondary-font-regular;
    font-size: 14px; 
    position: absolute;
    right: 24px;
    top: 65px;
    z-index:1000;
}
 
.tab_edit a,
.tab_edit a:hover {
    color: $PrimaryColor-text;
}
.general_error{ 
    margin-bottom: 20px;
    color: $Plain-text;
    border-radius: 3px;
    padding: 5px 15px;
    display: inline-block;
    background-color: $Error;
}
.admission_time_fix{
    margin-left: 5px;
}
// .form_label_fix .label_spec{
//     margin-top: 7px;
// }


.patient_content_mrn_field{
    background-color: #e4eaf1;
    padding: 20px 0px 2px 0px;
    max-width: 97%;
    margin-bottom: 20px;
    margin-top: 5px;
    margin-left: 17px;
}
.col_spec_mrn{
    margin-left: 22%!important;
}