@import "../../../styles/standard.module.scss";
.heading_bold {
  font-family: $secondary-font-bold;
  font-size: 16px;
  color: $PrimaryColor-text;
}
.dropdown_check_align {
  width: 100%;
  padding: 10px;
  border-radius: 0;
  max-height: 300px;//changed for TravelNurse from 200px
  overflow: auto;
  margin-top: 0px;
}
.col_spec {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
  display: flex;
}
.dropdown_check_align .custom_checkbox:last-child {
  border-bottom: 0;
}
.dropdown_check_align .custom_checkbox {
  padding: 8px 30px;
  font-family: $secondary-font-regular;
  color: $PrimaryColor-text;
  font-size: 16px;
  line-height: normal;
}
.multi_wrapper {
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-text;
  min-height: 40px;
  height: auto;
}
.basic_input {
  width: 100%;
  height: 30px;
  padding: 0 5px;
  border-radius: 2px;
  border: none !important;
  background-color: $Plain-bg;
}
.label_spec {
  margin-bottom: 0;
  font-family: $secondary-font-bold;
}
.shift_blk {
  padding-left: 24px;
}
.chip {
  display: inline-block !important;
  position: relative;
  padding: 4px 40px 4px 10px;
  background: #0096fb;
  border-radius: 3px;
  align-items: center;
  color: $Plain-text;
  white-space: nowrap;
  margin: 5px 5px 0;
  width: calc(100% - 10px);
  overflow: hidden;
  text-overflow: ellipsis;
}
.close_icon {
  position: absolute;
  right: 5px;
  top: 6px;
  cursor: pointer;
}
.notFound {
  padding: 10px;
}
.basic_input:focus {
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%) !important;
}

.select_wrapper {
  background: url("../../../static/images/down-arrow.png") right 10px center
    no-repeat;
  border: 0;
  color: #00000094;
  text-align: left;
  padding-left: 5px;
}
.sold_to_left {
  padding-left: 6px;
}
.error_border {
	border: 1px solid $Error !important;
}
.error_message {
	display: block;
	color: $Error;
	padding-top: 4px;
	font-family: $secondary-font-regular;
	font-size:14px;
}

.astrick{
	color: $Error;
}

.disableOrg{
  pointer-events: none;
  background-color: #dbdbdb;
  border-radius: 0;
}