@import "../../../styles/standard.module.scss";


.patient_details_blk{   
    @include response-above(xl) {
        padding: 0 0 15px;
        margin-bottom: 10px;
        background-image: linear-gradient(180deg, #0052cc, rgb(54 135 255));
        align-items: center;
    }
    @include response-above(md) {
        margin-bottom: 10px;        
        display:flex;
        background-image: linear-gradient(180deg, #0052cc, rgb(54 135 255));
        padding: 30px 0 25px 10px;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        align-items: center;
    }      
}
.patient_details_blk_scroll{
    @include response-above(xl) {
        padding: 0 0 15px;
        margin-bottom: 10px;
        background-image: linear-gradient(180deg, #0052cc, rgb(54 135 255));
        align-items: center;
    }
    @include response-above(md) {
        margin-bottom: 10px;        
        display:flex;
        background-image: linear-gradient(180deg, #0052cc, rgb(54 135 255));
        padding: 15px 0 15px 10px;
        align-items: center;
        // border-top-left-radius: 25px;
        // border-top-right-radius: 25px;
    }
}

.patient_details_blk{
    .patient_image{
        width:100px;    
        height: 100px;
        display: inline-block;
        vertical-align: top;   
        // margin-top: -42px;
        cursor: pointer;
    }
    .patient_image_admin{
        cursor: context-menu !important; 
    }
}

.patient_details_blk_scroll{
    .patient_image{
        width:100px;    
        height: 100px;
        display: inline-block;
        vertical-align: top;   
        cursor: pointer;
        // margin-top: -42px;
    }
    .patient_image_admin{
        cursor: context-menu !important; 
    }
}

.patient_image img{
    border-radius:50%;
    width:100%;    
    height: 100%;
}
.patient_details{
    display: inline-block;
    padding-left: 17px;
        /* width:calc(100% - 120px);*/
   width: calc(100% - 100px);
    // width: 70%;
   color: white;
   margin-top: -7px;
}

.patient_details p{
  margin-bottom: 0;
  font-family: $secondary-font-regular;
  white-space: nowrap;
}
.patient_details p label{
    margin-bottom: 0;
    margin-right: 5px;
    font-weight: bold;
    font-family:$secondary-font-bold;
}
.patient_details h4{
    font-family:$secondary-font-bold;
    font-size:24px;
    color: #ffffff;
    cursor: pointer;
    text-decoration: underline;    
}
.patient_details a{
    color: #ffffff;
    cursor: pointer;
}
.patient_details a:hover{
    color: #ffffff;
    text-decoration: underline;
}
.patient_details small{
    font-family: $secondary-font-regular;
    font-size:14px;
    padding-right: 15px;
}

.patient_details_admin{
    h4{
        cursor:context-menu!important;
        text-decoration: none!important;   
    }
}

.btn_custom_with{
    border: 1px solid #c1c7d0 !important;
    width: auto;
    height: 30px;
    border-radius: 15px;
    background-color: #ffffff !important;
    color: #333333 !important;
    padding-top: 2px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: -2px; 
}

.btn_custom_with_moreInfo{
    border: 1px solid #0052cc !important;
    width: auto;
    height: 30px;
    border-radius: 15px;
    background-color: #ffffff !important;
    color: #0052cc !important;
    // padding-top: 1px;
    padding-bottom: 7px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: -2px; 
    font-size: 14px;  
    padding-top: 3px; 
    font-weight: 500;
}
.btn_custom_with_diabetic, .btn_custom_with_diabetic:hover{
    border: 1px solid #de350b !important;
    width: auto;
    height: 30px;
    // border-radius: 15px;
    background-color: #de350b !important;
    color: #ffffff !important;
    padding-top: 2px;
    padding-bottom: 7px;
    padding-left: 13px;
    padding-right: 13px;
    cursor: context-menu!important;
    box-shadow: 0px 1px 3px 0px white;
}

.labelSize{
    font-size: 17px;
}
.labelSize_one{
    font-size: 15px;
}
.vertical_Code {
    border-left: solid 2px #ffffff;
    margin-top: 5px;
    height: 18px;
    margin: 5px 7px 0 7px;
}
.vertical_Code_one {
    border-left: solid 2px #ffffff;
    margin-top: 5px;
    height: 16px;
    margin: 0 7px 0 7px;
}
.patient_mobile_card{
    color: #fff;
    display: flex;
    align-items: self-start;
    padding-top: 15px;
    height: 45px;
    margin-right: 35px; 
    cursor: pointer;   
    p{
        padding-left: 8px;
        font-size: 16px;
        font-weight: 500;
    }
}

.patient_mobile_card:hover{
    color: #fff;
    display: flex;
    align-items: self-start;
    padding-top: 15px;
    height: 45px;
    svg{
        animation: shake 0.5s;
        animation-iteration-count: infinite;
    }    
    p{
        padding-left: 8px;
        text-decoration: underline;
        font-size: 16px;
        font-weight: 500;
    }
}

@keyframes shake {
    0% { transform: translateX(0) }
    25% { transform: translateY(-1px) }
    35% { transform: translateY(-1px) rotate(17deg) }
    55% { transform: translateY(-1px) rotate(-17deg) }
    65% { transform: translateY(-1px) rotate(17deg) }
    75% { transform: translateY(-1px) rotate(-17deg) }
    100% { transform: translateY(0) rotate(0) }
  }
