@import "../../../styles/standard.module.scss";

.viewMode_container {
  padding-top: 20px;
  color: $PrimaryColor-text;
  padding-right: 24px;
}
.lab_container {
  font-size: $Typography-2;
  font-family: $secondary-font-regular;
}
.edit_icon {
  font-size: $Typography-3;
  font-family: $secondary-font-regular;
}
.edit_icon:hover {
  cursor: pointer;
}
.tab_edit {
  color: $PrimaryColor-text;
  font-family: $secondary-font-regular;
  font-size: 14px;
  position: absolute;
  right: 24px;
  cursor: pointer;
  z-index: 1000;
}

.tab_edit a,
.tab_edit a:hover {
  color: $PrimaryColor-text;
}

.tab_edit img {
  max-width: 20px;
  margin-right: 3px;
}
.e_icon_space {
  margin-right: 5px;
}
.botton_spac {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
  display: flex;
  font-size: $Typography-2;
  color: $PrimaryColor-text;
  font-family: $secondary-font-regular;
}
.basic_select {
  width: 100%;
  height: 40px;
  padding: 0 0 0 10px;
  border-radius: 2px;
  border: solid 1px #c1c7d0;
  background-color: transparent;
  appearance: none;
  outline: none;
}
.label_spec {
  font-size: $Typography-2;
  padding-top: 0px;
  padding-bottom: 0px;
}
.select_wrapper div:first-of-type {
  border: solid 1px #c1c7d0;
  background: url("../../../static/images/down-arrow.png") right 10px center
    no-repeat;
  border-radius: 2px;
  background-color: $Plain-bg;
  min-height: 40px;
  height: auto;
}
.btn_cancel,
.btn_cancel:hover,
.btn_cancel:active {
  @include btn_secondary;
}
.btn_save,
.btn_save:hover,
.btn_save:active {
  @include btn_primary;
  margin-right: 32px;
}
.bir_subline {
  color: #505f79 !important;
  font-family: $secondary-font-regular !important;
  font-size: 12px !important;
  display: block !important;
}
