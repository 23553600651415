@import "../../../styles/standard.module.scss";
@import "../../../styles/common.module.scss";
.accordion_panel {
  border-top: 1px solid $accod-border;
}

.risk_block {
  padding: 20px 10px;
  float: left;
  width: 100%;
  border-bottom: 1px solid #fafafa;
}

.score_left {
  width: 125px;
  display: inline-block;
  float: left;
}

.score_left p {
  margin-bottom: 0;
}

.score_right {
  width: calc(100% - 125px);
  display: inline-block;
  float: left;
  padding-left: 30px;
}

.score_right .risk_label {
  cursor: pointer;
}
.score_right p {
  margin-bottom: 0;
}
.score_right .risk_label label {
  color: #333;
  font-family: $secondary-font-bold;
  font-size: 21px;
}

.score_right .risk_label span {
  color: #36b37e;
  font-family: $secondary-font-bold;
  font-size: 21px;
}

.risk_mild label {
  color: #333 !important;
}

.risk_mild span {
  color: #ffc400 !important;
}

.risk_severe span {
  color: #de350b !important;
}
.score_left p label {
  display: block;
  margin-bottom: 0;
  color: #4a4a4a;
  font-family: $secondary-font-bold;
  font-size: 70px;
  text-align: center;
  line-height: 100%;
}

.score_left p span {
  display: block;
  margin-bottom: 0;
  color: #4a4a4a;
  font-family: $secondary-font-bold;
  font-size: 18px;
  text-align: center;
}

.score_right p label {
  display: inline-block;
  margin-bottom: 0;
  color: #333333;
  font-family: $secondary-font-bold;
  font-size: 16px;
  text-align: center;
  line-height: 100%;
}

.custom_modal_size {
  max-width: 967px;
}

.riskAssesmentContent {
  font-family: $secondary-font-regular;
  font-size: 16px;
  color: #333;
  padding: 24px 10px;
  margin-bottom: 0;
}
.riskAssesmentContent p {
  padding: 0;
  margin-bottom: 16px;
}

.riskAssesmentContent label {
  font-family: $secondary-font-regular;
  font-size: 16px;
  color: #333;
  padding: 0;
  margin-bottom: 0;
  margin-right: 5px;
}
.riskAssesmentContent span {
  font-family: $secondary-font-bold;
  font-size: 16px;
  color: #333;
  margin-bottom: 0;
}

.risk_severe label {
  color: #333 !important;
}

.risk_moderate label {
  color: #333 !important;
}

.risk_moderate span {
  color: #fa9e00 !important;
}

.risk_high label {
  color: #333 !important;
}

.risk_high span {
  color: #f56076 !important;
}

.no_data_content {
  border-top: 1px solid $accod-border;
  font-family: $secondary-font-regular;
  font-size: $Typography-1;
}
.loader{
  text-align: center;
  padding: 150px 0;
  img{
      max-width: 50px;
      border-radius: 100%;
  }
  
}	