@import '../../../styles/standard.module.scss';

.tab_content_top{
    border-bottom: solid 1px #f2f2f2;
    margin-bottom: 15px;
}
.tab_content_hdg{
    padding: 24px 24px 16px;
    margin-bottom: 0;
    font-family: $secondary-font-regular;
    position: relative;
}

.basic_info {
    padding: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.basic_info div {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
}

.basic_info_form {
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.basic_info_form div {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
    display: flex;
    align-items: center;
}
.subheading_2 {
    font-family: $primary-font-regular;
    font-size: 21px;
    color: $PrimaryColor-text;
    margin-bottom: 0;
}

.single_product_block {
    padding: 0 24px;
}
.patient_content {
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width:100%;
}

.moreinfo_content,
.patient_content {
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.moreinfo_content .col_spec,
.patient_content .col_spec {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
    display: flex;
    flex-wrap: wrap;
}

.patient_content .basic_input,
.moreinfo_content .basic_input {
    width: 100%;
    height: 40px;
    padding: 0 5px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}

.patient_content .select_wrapper,
.moreinfo_content .select_wrapper {
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}

.patient_content .basic_select,
.moreinfo_content .basic_select {
    width: 100%;
    height: 40px;
    padding: 0 10px;
    appearance: none;
    -webkit-appearance: none;
    outline: 0;
    background: 0;
    border: 0;
}
select::-ms-expand {
    display: none;
}

.label_spec {
    margin-bottom: 0;
    font-family: $secondary-font-bold;
    line-height: normal;
}

.content_spec {
    font-family: $secondary-font-regular;
    color: $PrimaryColor-text;
    display: inline-block;
}
.basic_input {
    width: 250px;
    height: 40px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}
.small_input {
    width: 68px;
    height: 40px;    
    padding-left: 7px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}
.medium_input {
    width: 100px;
    height: 40px;    
    padding-left: 7px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}

.basic_select {
    width: 250px;
    height: 40px;
    padding: 0 10px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}


.btn_custom {
    height: 50px;
    border-radius: 2px;
    font-family: $secondary-font-regular;
    background-color: $PrimaryButton-bg;
    font-size: 18px;
}
.btn_small,
.btn_small:hover {
    width: 158px;
    height: 50px;
    font-family: $secondary-font-regular;
    background-color: $PrimaryButton-bg;
    color: $Plain-text;
    font-size: 16px;
    margin-right: 32px;
}
.btn_small_cancel,
.btn_small_cancel:hover,
.btn_small_cancel:active {
    width: 158px;
    height: 50px;
    font-family: $secondary-font-regular;
    background-color: $Plain-bg;
    border: 1px solid $bdr-color-tertiary !important;
    color: $PrimaryColor-text;
    font-size: 16px;
   
}

.tab_edit {
    color: $PrimaryColor-text;
    font-family: $secondary-font-regular;
    font-size: 14px;
    position: absolute;
    right: 24px;
    top: 24px;
    cursor: pointer;
    z-index:1000;
}
.custom_modal_size{
    max-width: 800px;
}
.req_email_custom_modal_size{
    max-width: 600px;
}
.basic_input.disabled {
    background-color: #dbdbdb !important;
}
 
.tab_edit a,
.tab_edit a:hover {
    color: $PrimaryColor-text;
}
.tab_edit img{
    max-width: 20px;
    margin-right:3px;
}

.custom_control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
}
.custom_control_input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 16px;
    height: 16px;
    opacity: 0;
}
.custom_control_label {
    position: relative;
    margin-top: 5px;
    margin-bottom: 0;
    vertical-align: top;
    font-family:$secondary-font-regular;
    font-size: 14px;
    color: $PrimaryColor-text;
    line-height: normal;
}
.custom_control_input.is_invalid~.custom_control_label::before,
.was_validated .custom_control_input:invalid~.custom_control_label::before {
    border-color: $PrimaryColor-text;
}
.custom_control_input.is_valid~.custom_control_label,
.was_validated .custom_control_input:valid~.custom_control_label {
    color: $Error;
}
.custom_control_input.is_valid:checked~.custom_control_label::before,
.was_validated .custom_control_input:valid:checked~.custom_control_label::before {
    border-color: $border-box;
    background-color: $CheckBox-bg;
}
.custom_control_label::after {
    position: absolute;
    top: 11px;
    left: -1.5rem;
    display: block;
    width: 16px;
    height: 16px;
    content: "";
}


.custom_control_label::before {
    border-radius: 50% !important;
    top: 11px;
}
.custom_control_inline {
    height: 22px;
}

.custom_control_inline .custom_control_label {
    font-family: $secondary-font-bold;
    color: $text-color;
    font-size: 14px;
}
.custom_control_input:checked~.custom_control_label::before {
    color: $Plain-text;
    border-color: $border-box;
    background-color: $CheckBox-bg;
    border-radius: 0;
    width: 16px;
    height: 16px;
    top: 11px;
} 
.disable_check, .disable_check:hover, .disable_check:active,.disable_check:focus{
    background-color: rgb(191 191 191 / 30%);
    cursor: no-drop!important;
}

.warning{
    text-align: center;
    padding: 10px 20px;
    border: 1px solid #ff0000;
    border-radius: 5px;
    display: inline-block;
    margin-right: 80px !important;
    h6{
        margin: 0;
        font-size: 14px;
    }
    button{
        border-radius: 3px;
        margin-left: 10px;
        padding: 3px 10px;
        font-size: 12px;
        &:first-child{
            color: #fff;
            border: 1px solid $PrimaryButton-bg;
            background-color: $PrimaryButton-bg;
        } 
        &:last-child{
            border: 1px solid #ccc;
            background-color: #fcfcfc;
        }
    }
}
.no_btn {
    width: 140px;
    border-radius: 0px;
    background-color: $Body-bg;
    color: $PrimaryColor-text;
    border: solid 1px $tertiary-text;
  }
  .no_btn:hover {
    background-color: $Body-bg;
    color: $PrimaryColor-text;
    border: solid 1px $tertiary-text;
  }
  .no_btn:active {
    background-color: $Body-bg !important;
    color: $PrimaryColor-text !important;
    border: solid 1px $tertiary-text !important;
  }
  .no_btn:focus {
    background-color: $Body-bg;
    color: $PrimaryColor-text;
    border: solid 1px $tertiary-text;
  }
  .yes_btn {
    width: 140px;
    border-radius: 0px;
  } 
  .user_status {
    font-size: $Typography-1;
    font-weight: normal;
  }

/*Toggle button Style*/

  .toggle_shells{
    margin-top: 0;
  }
  .toggle_switch {
    transform: scale(1.6, 1.3);
    margin-left: 16px;
  }
  .toggle_button_status {
    text-align: left;
    font-size: 13px;
    margin-left: -42px;
    font-weight: 500;
  }
  .toggle_switch::after{
    transition: transform .2s ease-out!important;
  }
  .loader{
    text-align: center;
    padding: 150px 0;
    img{
        max-width: 50px;
        border-radius: 100%;
    }
    
  }	