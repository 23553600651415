@import '../../../styles/standard.module.scss';
.down_spread{
    color:#0052cc;
    font-family: $secondary-font-bold;
    font-size:16px;
    display: inline-block;
}
.down_spread label a, .down_spread label a:hover{
    color:#0052cc;
    margin-bottom: 0;
    margin-left: 10px;
    text-decoration: none;
}
.excel, .excel:hover{
    padding-left: 10px;
    color:#0052cc;
    margin-bottom: 0;
    margin-left: 10px;
    text-decoration: none;
    cursor: pointer;
}
.no_matching_result {
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 200px;
    display: flex;
    font-weight: bold;
    font-size: 22px;
  }
