@import '../../../styles/standard.module.scss';
@import '../../../styles/common.module.scss';

.body_heading {
  font-family: $secondary-font-bold;
  font-size: 1.5rem;
  margin-bottom: 2px;
  width:100%;
}
.assess_label {
  font-family: $secondary-font-bold;
  font-size: $Typography-1;
}
.tab_container {
  margin-bottom: 55px;
  padding-left: 20px; 
  @include response-above(md) {
    align-items:initial !important;
    justify-content: space-between !important;
    display:block !important;
    padding-top:90px;    
  }
  @include response-above(lg) {
      align-items: flex-end !important;
      justify-content: space-between !important;
      display:flex !important;
      padding-top:0;    
  }
}
.btn_custom {
  height: 42px;
  border-radius: 2px;
  font-family: $secondary-font-regular;
  background-color: $PrimaryButton-bg;
  font-size: $Typography-2;
  line-height: normal;
  padding: 0;
}
.clone_btn {
  font-size: $Typography-2;
  border-radius: 2px;
  border: solid 1px $bdr-color-tertiary;
  background-color: $body-bg-color;
  padding: 7px 0px;
  width: 140px;
  text-align: center;
  text-decoration: none !important;
  margin-left : 0px !important;
  margin-bottom: 0px !important;
  font-family: $secondary-font-regular;
  font-weight: 500 !important;
  color: $PrimaryColor-text !important;
}
.clone_btn:hover {
  border: solid 1px $bdr-color-tertiary;
  background-color: $body-bg-color;
  text-decoration: none !important;
  color: $PrimaryColor-text !important;
}
.clone_btn:active {
  border: solid 1px $bdr-color-tertiary !important;
  background-color: $body-bg-color !important;
  text-decoration: none !important;
  color: $PrimaryColor-text !important;
}
.clone_btn:focus {
  border: solid 1px $bdr-color-tertiary !important;
  background-color: $body-bg-color !important;
  text-decoration: none !important;
  color: $PrimaryColor-text !important;
}
.bell_icon {
  max-width: 18px;
  margin-right: 27px;
}
.bell_icon:hover {
  cursor: pointer;
}
.back_btn a {
  @include response-above(sm) {
      position: fixed;
      top:22px;
      left:25px;
      background:url(../../../static/images/back-arrow.png) center left no-repeat;
      text-indent: 999px;
  }
  @include response-above(lg) {
    position: static;
    top:auto;
    left:auto;
    color:$PrimaryColor-text !important;
    background:url(../../../static/images/left_arrow.png) center left no-repeat;
    padding-left: 10px;
    font-family: $secondary-font-regular;
    font-size:16px;
  }
}

.camel_case {
  text-transform: capitalize;
}

.assess_content{
  padding: 0px;
}

.assess_link,
.assess_link:hover {
  color: $PrimaryButton-bg;
  font-weight: 600;
  text-decoration: none;//changed
  cursor: pointer;
  font-size: 20px;
}

// Added for Labels
.POAColor{
  color:limegreen;
  font-weight: bold;
  font-size: 17px!important;
}
.NONPOAColor{
  color:orangered;
  font-weight: bold;
  font-size: 16px!important;
}

// Graybox SubDetails

.assess_content_subDetails{
  background-color: rgb(0 0 0 / 15%);;
  padding: 10px 0px;
  font-weight: bold;
}
.assess_label_subDetails{
  font-size: 16px!important;
}
.assess_label_subDetail_label{
  font-size: 16px!important;
  font-weight: bold!important;
}
.assess_label_subDetail_labelout{
  font-size: 16px!important;
  font-weight: normal;
}
.right_Arrow{
  margin-left: 10px;
}
.assement_all_link{
  padding: 10px 15px;
  background-color: #ffffff;
  border-radius: 10px;
  margin-left: 25%;
}

.PDFButton {
  float: right;
  margin-top: -1%;
}


//SubLabel
.sub_label_content{
  background-color: rgb(140 140 140 / 29%);
  color: #0052cc;
  padding: 3px 30px 5px 5px;
  font-weight: bold;
} 

.sub_label_content_hover{
  background-color: rgb(140 140 140 / 29%);
  color: #0052cc;
  padding: 3px 7px 5px 5px;
  font-weight: bold;
  transition: 0.1s;
}

.label_spec {
  font-family: $secondary-font-bold;
  font-size: 1.125rem;
}


//clear Icon
.x_icon {
  cursor: pointer;
  margin-left: 5px;  
}

.x_icon_whole {
  cursor: pointer;  
}


//hover class
.notdisplayed {
  display: none;
}

.displayed {
  margin: auto;
}



.LabelMain, .form_field {
  display: flex;
  height: 40px;
}

.form_field input {
  color: #0052cc!important;
  font-weight: 500!important;
}

.services {
  margin-top: -3px;
}
.services:first-child {
  margin-right: 20px;
}

.labelList:focus-visible {
  border: none!important;
  outline: none!important;
}
.labelList {
  border: none!important;
  outline: none!important;
  border-radius: 5px!important;
  padding: 5px 20px 6px 5px!important;
  color: #0052cc!important;
  text-align: center!important;
  display: inline-block;
  visibility: hidden;
  white-space: pre;

}
.first_division {
  padding: 5px 10px 5px 10px;
  text-align: center;
  display: flex;
  background-color: rgb(70 70 70 / 15%) !important;
  border-radius: 5px!important;
  color: #0052cc!important;
}

.add_btn {
  display: flex;  
  background-color: #0052cc;
  color: #fff;
  font-size: 13px;
  padding: 6px 15px;
  margin-top: -3px;
  height: 35px;
  border-radius: 5px;
}


.tags{
  display: flex;
  width: 100%;
  border-radius: 5px;
  color: black;
  border: none;
  margin-top: -5px;
}
.tags:hover{
// background-color: #f4f4f4;
margin-top: -5px;
margin-left: -4px;
// background-color: #c6c6c6cc;
border: 2px solid #0151c9;
padding: 8px 0 8px;
}

.tagsCompleted{
  display: flex;
  width: 100%;
  border-radius: 5px;
  color: black;
  border: none;
}

.tagsCompleted :hover {
  background-color: #c6c6c6cc;
}

.tagsValue {
  padding: 6px;
  background-color: #c6c6c6cc;
}

.single_tag{
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0 10px 0;
  padding: 3px 10px 4px 17px;
  border-radius: 5px;
  white-space: nowrap;
  color: #0151c9;
  font-weight: 500;
  background-color: #F4F5F7;
  font-size: 17px;
}


.single_tag:hover {
  // background-color: #ffffff4a;
}


input{
  // border: none;
  // padding: 10px;
}

input:focus{
  outline: none;
}
.labelInput {
  font-weight: 500;
}
input.labelInput {
  margin-top: -5px!important;
  border: none;
  padding: 10px;
  font-size: 17px;
}
// .labelInput:hover {
//   background-color: #c6c6c6cc;
// }
.single_tag i{
  display: flex;
  padding: 6px;
  border: none;
  background-color: unset;
  cursor: pointer;
  color: #000;
}

.delteWhole i{
  display: flex;
  padding: 6px;
  border: none;
  background-color: unset;
  cursor: pointer;
  color: #000;
}

.delteWhole {
  margin-top: 4px;
  padding-left: 7px;
  padding-right: 8px;
}

.errorLabel{
  color: red;
  margin-bottom: 5px;
}

.classifi_content {
  font-size: 17px;
}


.patient_heading_details p{
  margin-bottom: 0;
  font-family: $secondary-font-regular;
  white-space: nowrap;
}
.patient_heading_details p label{
    margin-bottom: 0;
    margin-right: 5px;
    font-weight: bold;
    font-family:$secondary-font-bold;
}
.patient_heading_details h4{
    font-family:$secondary-font-bold;
    font-size:27px;
}
.patient_heading_details .straight_line{
  margin-top: -6px;
}



// Loaded for until window loadding
.spinner {
  align-items: center;
  background-color: white;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 91vw;
  overflow: hidden!important;
  position: absolute;
  // top: -20%;
  left: 7%;
  top: -18%;
}
// .loading {
//   -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
//   animation: sk-scaleout 1.0s infinite ease-in-out;  
//   background-color: black;
//   border-radius: 100%;
//   height: 10em;
//   width: 10em;
//   overflow: hidden!important;
// }



// @keyframes sk-scaleout {
//   0% {
//     -webkit-transform: scale(0);
//     transform: scale(0);
//   }
//   100% {
//     -webkit-transform: scale(1.0);
//     opacity: 0;
//     transform: scale(1.0);
//   }
// }


.loading {
  border: 1px solid #f3f3f3; 
  border-top: 5px solid #1e76f7;
  border-radius: 50%;
  width: 10em;
  height: 10em;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

// Loaded for until window loadding


// PDF Modal 

.modalparent{
  position: relative;
  border-radius: 5%;
}
.modal_heading{
  display: flex;
  justify-content: space-between;
  background:  #0052cc;
  border-bottom: 2px solid #737373
}
.modal_header{
  h3{
    color: #ffffff;
    font-size: 23px;
  }
}
.close_icon{
  float: right;
  font-size: 42px;
  font-weight: 700;
  color: #ffffff!important;
  opacity: 1!important;
}
.modal_footer{
    h2{
      font-size: 26px;
      font-weight: 700;
      color: #737373;
      text-align: center;
  }
}




// Patient Card Details
.patient_details_blk{   
  @include response-above(xl) {
      padding: 0 0 15px;
      margin-bottom: 10px;
      // background:transparent;
      background-image: linear-gradient(180deg, #0052cc, rgb(54 135 255));
      align-items: center;
      // position: -webkit-sticky;
      // position: sticky;
      // top: 0;
  }
  @include response-above(md) {
      // padding: 15px 0;
      margin-bottom: 10px;        
      display:flex;
      background-image: linear-gradient(180deg, #0052cc, rgb(54 135 255));
      padding: 28px 0 25px 10px;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      align-items: center;
      // max-width: 770px;
      // position: -webkit-sticky;
      // position: sticky;
      // top: 0;
      // background-color: yellow;
  }  
}

.patient_image{
  width:100px;    
  height: 100px;
  display: inline-block;
  vertical-align: top;   
  // margin-top: -42px;
  cursor: pointer;
}
.patient_image img{
  border-radius:50%;
  width:100%;    
  height: 100%;
}
.patient_details{
  display: inline-block;
  // margin-left: 11px;
  padding-left: 17px;
                  /* width:calc(100% - 120px);*/
 width: calc(100% - 100px);
  // width: 70%;
 color: white;
 margin-top: -3px;
}
.patient_details p{
margin-bottom: 0;
font-family: $secondary-font-regular;
white-space: nowrap;
}
.patient_details p label{
  margin-bottom: 0;
  margin-right: 5px;
  font-weight: bold;
  font-family:$secondary-font-bold;
}
.patient_details h4{
  font-family:$secondary-font-bold;
  font-size:24px;
  color: #ffffff;
  text-decoration: underline;
  cursor: pointer;
}
.patient_details a{
  color: #ffffff;
  cursor: pointer;
}
.patient_details a:hover{
  color: #ffffff;
  text-decoration: underline;
  
}
.patient_details small{
  font-family: $secondary-font-regular;
  font-size:14px;
  padding-right: 15px;
}

.btn_custom_with{
  border: 1px solid #c1c7d0 !important;
  width: auto;
  height: 30px;
  border-radius: 15px;
  background-color: #ffffff !important;
  color: #333333 !important;
  padding-top: 2px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: -2px; 
}

.btn_custom_with_moreInfo{
  border: 1px solid #0052cc !important;
  width: auto;
  height: 30px;
  border-radius: 15px;
  background-color: #ffffff !important;
  color: #0052cc !important;
  // padding-top: 1px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: -2px; 
  font-size: 14px;  
  padding-top: 3px; 
  font-weight: 500;
}
.btn_custom_with_diabetic, .btn_custom_with_diabetic:hover{
  border: 1px solid #de350b !important;
  width: auto;
  height: 30px;
  // border-radius: 15px;
  background-color: #de350b !important;
  color: #ffffff !important;
  padding-top: 2px;
  padding-bottom: 7px;
  padding-left: 13px;
  padding-right: 13px;
  cursor: context-menu!important;
  box-shadow: 0px 1px 3px 0px white;
}

.labelSize{
  font-size: 17px;
}
.labelSize_one{
  font-size: 15px;
}
.vertical_Code {
  border-left: solid 2px #ffffff;
  margin-top: 5px;
  height: 18px;
  margin: 5px 7px 0 7px;
}
.vertical_Code_one {
  border-left: solid 2px #ffffff;
  margin-top: 5px;
  height: 16px;
  margin: 0 7px 0 7px;
}
.patient_mobile_card{
  color: #fff;
  display: flex;
  align-items: self-start;
  padding-top: 15px;
  height: 45px;
  margin-right: 35px;
  cursor: pointer;    
  p{
      padding-left: 8px;
      font-size: 16px;
      font-weight: 500;
  }
}

.patient_mobile_card:hover{
  color: #fff;
  display: flex;
  align-items: self-start;
  padding-top: 15px;
  height: 45px;
  svg{
      animation: shake 0.5s;
      animation-iteration-count: infinite;
  }    
  p{
      padding-left: 8px;
      text-decoration: underline;
      font-size: 16px;
      font-weight: 500;
  }
}


@keyframes shake {
  0% { transform: translateX(0) }
  25% { transform: translateY(-1px) }
  35% { transform: translateY(-1px) rotate(17deg) }
  55% { transform: translateY(-1px) rotate(-17deg) }
  65% { transform: translateY(-1px) rotate(17deg) }
  75% { transform: translateY(-1px) rotate(-17deg) }
  100% { transform: translateY(0) rotate(0) }
}
.main_detail_loader{
  text-align: center;
    min-height: 600px;
    align-content: center;
  img{
    max-width: 50px;
  }
}
.label_disabled{
  pointer-events: none;
  opacity: 0.4;
}