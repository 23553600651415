// Style guide formats

// Color formats
$PrimaryColor-text: #333333;
$PrimaryButton-bg: #0052cc;
$SecondaryButton-bg: #fafafa;
$Success-text:#36b37e;
$Astrik: #f16278;
$Error: #de350b;
$DarkBar-bg: #313131;
$Midbar-bg: #404040;
$Body-bg: #fafafa;
$Plain-bg: #ffffff;
$Plain-text:#ffffff;
$bg-alternate: #fcfcfc;
$CheckBox-bg: #00c7e5;
$secondary-text: #0052cc;
$tertiary-text: #cccccc;
$hint-text: #b3b3b3;
$hint-success-text: #36b37e;
$filter-btn : #666666;
$admin-border : #383838;
$hint-normal:#999999;
$border-box:#d2d2d2;
$border-color-light:#808080;
$thead-bg:#505f79;
$trow-left:#f0f0f0;
$border-color:#c1c7d0;
$adm-color:#aaaaaa;
$body-bg-color:#fafafa;
$tab-border-bottom:#f2f2f2;
$tab-active:#e25c71;
$accod-border : #f2f2f2;
$pending-status : #ffc200;
$review-pending : #0065ff;
$text-color:#6a6a6a;
$vertical-tab-bg:#efefef;
$btn-fetch:#36b37e;
$bg-color-secondary: #ffc200;
$bg-color-primary:#0065ff;
$bg-color-alert:#de350b;
$bg-color-success:#00875a;
$bdr-color-alternate: #d8d8d8;
$bdr-color-tertiary: #cccccc;
$table-heading-color:#2c2c2c;
$tab-text:#57585a;
$tab-border:#cdcdcd;
$measure-surface:#00dd7f;
$measure-length:#0dfaff;
$measure-width:#0089ff;
$black-color:#000000;
$btn-disable:#dbdbdb;
$border-auto: #d1d1d1;
$menu-color:#aeaeae;
$menu-link:#e9ecef;
$menu-border:#f16278;
$menu-prime:#282626;
$modal-header-bg:#f4f4f4;
$tab-border: #CECFD0;


// Size formats
$Heading-1: 2.25rem; // 36px
$Heading-2: 1.75rem; // 28px
$Typography-1: 1.125rem; // 18px
$Typography-2: 1rem; // 16px
$Typography-3: 0.875rem; // 14px
$Typography-4: 0.75rem; // 12px
$Typography-31:0.90rem;// 15px

// font weight
$Bold: 600;

// media query breack points

@mixin responsive($breakPoint) {
	@if $breakPoint == xl {
		@media only screen and (max-width: 1200px) {
			@content;
		}
	}
	@if $breakPoint == lg {
		@media only screen and (max-width: 992px) {
			@content;
		}
	}
	@if $breakPoint == md {
		@media only screen and (max-width: 768px) {
			@content;
		}
	}
	@if $breakPoint == sm {
		@media only screen and (max-width: 600px) {
			@content;
		}
	}
	@if $breakPoint == xs {
		@media only screen and (max-width: 360px) {
			@content;
		}
	}
}
@mixin response-above($breakPoint) {
	@if $breakPoint == xl {
		@media only screen and (min-width: 1200px) {
			@content;
		}
	}
	@if $breakPoint == lg {
		@media only screen and (min-width: 992px) {
			@content;
		}
	}
	@if $breakPoint == md {
		@media only screen and (min-width: 768px) {
			@content;
		}
	}
	@if $breakPoint == sm {
		@media only screen and (min-width: 600px) {
			@content;
		}
	}
	@if $breakPoint == xs {
		@media only screen and (min-width: 360px) {
			@content;
		}
	}
}

// fonts

@font-face {
	font-family: "globerbold";
	src: url("../static/fonts/glober-bold-webfont.eot");
	src: url("../static/fonts/glober-bold-webfont.eot?#iefix")
			format("embedded-opentype"),
		url("../static/fonts/glober-bold-webfont.woff2") format("woff2"),
		url("../static/fonts/glober-bold-webfont.woff") format("woff"),
		url("../static/fonts/glober-bold-webfont.svg#globerbold") format("svg");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "globerextrabold";
	src: url("../static/fonts/glober-extrabold-webfont.eot");
	src: url("../static/fonts/glober-extrabold-webfont.eot?#iefix")
			format("embedded-opentype"),
		url("../static/fonts/glober-extrabold-webfont.woff2") format("woff2"),
		url("../static/fonts/glober-extrabold-webfont.woff") format("woff"),
		url("../static/fonts/glober-extrabold-webfont.svg#globerextrabold")
			format("svg");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "globerregular";
	src: url("../static/fonts/glober-regular-webfont.eot");
	src: url("../static/fonts/glober-regular-webfont.eot?#iefix")
			format("embedded-opentype"),
		url("../static/fonts/glober-regular-webfont.woff2") format("woff2"),
		url("../static/fonts/glober-regular-webfont.woff") format("woff"),
		url("../static/fonts/glober-regular-webfont.svg#globerregular")
			format("svg");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "open_sansbold";
	src: url("../static/fonts/opensans-bold-webfont.eot");
	src: url("../static/fonts/opensans-bold-webfont.eot?#iefix")
			format("embedded-opentype"),
		url("../static/fonts/opensans-bold-webfont.woff2") format("woff2"),
		url("../static/fonts/opensans-bold-webfont.woff") format("woff"),
		url("../static/fonts/opensans-bold-webfont.svg#open_sansbold") format("svg");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "open_sansregular";
	src: url("../static/fonts/opensans-regular-webfont.eot");
	src: url("../static/fonts/opensans-regular-webfont.eot?#iefix")
			format("embedded-opentype"),
		url("../static/fonts/opensans-regular-webfont.woff2") format("woff2"),
		url("../static/fonts/opensans-regular-webfont.woff") format("woff"),
		url("../static/fonts/opensans-regular-webfont.svg#open_sansregular")
			format("svg");
	font-weight: normal;
	font-style: normal;
}

$primary-font-regular: "globerregular";
$primary-font-bold: "globerbold";
$primary-font-extrabold: "globerextrabold";

$secondary-font-regular: "open_sansregular";
$secondary-font-bold: "open_sansbold";

html, body{
	background-color: $body-bg-color;
	height:100%;
}
.label_spec span{
    color:#de350b !important; 
}

@mixin btn_primary {
	width: 140px;
    height: 42px;
    font-family: $secondary-font-regular;
    background-color: $PrimaryButton-bg;
	border-radius: 2px;
    color: $Plain-text;
	font-size: 16px;
    align-items: center;
    justify-content: center;
    display: inline-flex;
}
@mixin btn_secondary {
	width: 140px;
    height: 42px;
    font-family: $secondary-font-regular;
    background-color: $SecondaryButton-bg !important;
    border: 1px solid $bdr-color-tertiary !important;
    color: $PrimaryColor-text !important;
	border-radius: 2px;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    display: inline-flex;
}
select:disabled{
    background-color: $btn-disable !important;
}