@import "../../../styles/standard.module.scss";
.form_inline .custom-control-inline {
  height: 22px;
}
.del_block {
  display: inline-block;
  font-family: $secondary-font-regular;
  color: #6a6a6a;
  font-size: 14px;
  padding: 0 10px 0 0;
  padding-left: 20px;
  cursor: pointer;
}
.select_all img,
.del_block img {
  margin-right: 10px;
}
.import_block {
  width: auto;
  height: 42px;
  line-height: 42px;
  margin: 0 8px;
  cursor: pointer;
  border: 0;
  background: transparent;
  label {
    cursor: pointer;
  }
  img {
    margin-right: 10px;
  }
}
.btn_add,
.btn_add:hover {
  @include btn_primary;
  width: 160px;
  padding: 10px 5px;
  img {
    margin-right: 5px;
  }
}

//added for pending & acc locked button
.btn_addextra{
    height: 41px;
    font-family: $secondary-font-regular;
    background-color: #fff;
    border-radius: 2px;
    color: #545454;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    width: 160px;
    padding: 10px 5px;
    border: 1px solid #0000003d;
    margin-top: -1px;
    font-weight: 500;
    img {
      margin-right: 5px;
    }
}

.btn_addextrahover, .btn_addextra:hover{
  height: 41px;
  font-family: $secondary-font-regular;
  background-color: #5c5c5c;
  border-radius: 2px;
  color: #ffffff;
  font-size: 15px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  width: 160px;
  padding: 10px 5px;
  border: 1px solid #5c5c5c;
  margin-top: -1px;
  img {
    margin-right: 5px;
  }
}
//added for pending & acc locked button

.facility_pos {
  // width: calc(100% - 154px) !important;
  margin-right: 35px;
}
/* .createitem_block .btn_add,
.createitem_block .btn_add:hover {
  width: auto;
} */
.search_icon_image {
  top: 8px;
  left: 11px;
}
.pl40 {
  padding-left: 40px;
  height: 40px;
  border-radius: 2px;
}
.select_height {
  height: 40px;
  border-radius: 2px;
}
.hide {
  display: none;
}
.file_upload {
  display: none;
}
.ipadtop {
  @include response-above(sm) {
    padding-top: 90px !important;
  }
  @include response-above(lg) {
    padding-top: 1.5rem !important;
  }
}
.ipadm_l {
  @include responsive(lg) {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
.no_btn {
  width: 140px;
  border-radius: 0px;
  background-color: $Body-bg;
  color: $PrimaryColor-text;
  border: solid 1px $tertiary-text;
}
.no_btn:hover {
  background-color: $Body-bg;
  color: $PrimaryColor-text;
  border: solid 1px $tertiary-text;
}
.no_btn:active {
  background-color: $Body-bg !important;
  color: $PrimaryColor-text !important;
  border: solid 1px $tertiary-text !important;
}
.no_btn:focus {
  background-color: $Body-bg;
  color: $PrimaryColor-text;
  border: solid 1px $tertiary-text;
}

.yes_btn {
  width: 140px;
  border-radius: 0px;
}
.no_data_content {
  border-top: 1px solid $accod-border;
  font-family: $secondary-font-regular;
  font-size: $Typography-1;
}
.custom_control_label {
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  vertical-align: top;
  font-family: $secondary-font-regular;
  font-weight: bold;
  font-size: 16px;
  color: $PrimaryColor-text;
  line-height: normal;
}
.custom_control_input.is_invalid ~ .custom_control_label::before,
.was_validated .custom_control_input:invalid ~ .custom_control_label::before {
  border-color: $PrimaryColor-text;
}
.custom_control_input.is_valid ~ .custom_control_label,
.was_validated .custom_control_input:valid ~ .custom_control_label {
  color: $Error;
}
.custom_control_input.is_valid:checked ~ .custom_control_label::before,
.was_validated
  .custom_control_input:valid:checked
  ~ .custom_control_label::before {
  border-color: $border-box;
  background-color: $CheckBox-bg;
}
.custom_control_label::after {
  position: absolute;
  top: 11px;
  left: -1.5rem;
  display: block;
  width: 16px;
  height: 16px;
  content: "";
}
.custom_control_label::before {
  border-radius: 50% !important;
  top: 11px;
}
.custom_control_inline {
  height: 22px;
}
.custom_control_inline .custom_control_label {
  font-family: $secondary-font-bold;
  color: $text-color;
  font-size: 14px;
}
.custom_control_input:checked ~ .custom_control_label::before {
  color: $Plain-text;
  border-color: $border-box;
  background-color: $CheckBox-bg;
  border-radius: 0;
  width: 16px;
  height: 16px;
  top: 11px;
}

.select_wrapper {
  background: url("../../../static/images/down-arrow.png") right 10px center
    no-repeat;
  border-radius: 2px;
  background-color: $Plain-bg;
  height: 40px;
  border: solid 1px $border-color;
}
.basic_select {
  width: 100%;
  height: 40px;
  padding: 0 25px 0 10px;
  appearance: none;
  -webkit-appearance: none;
  outline: 0;
  background: transparent;
  border: 0;
}
select:focus {
  background: transparent !important;
}
select::-ms-expand {
  display: none;
}
.wound_input {
  width: 100%;
  height: 40px;
  border-radius: 2px;
  border: solid 1px #c1c7d0;
  background-color: #ffffff;
  padding-left: 10px !important;
}

.form_span {
  color: $secondary-text;
  cursor: pointer;
}
.form_span:hover {
  cursor: pointer;
}

.customer_Search_position {
  position: absolute;
  left: 0;
  top: -75px;
  width: 344px;
  z-index: 1001;
}
.error_message2 {
  white-space: nowrap !important;
}

.customer_search_parent {
  position: relative;
  width: 100%;
}

// .col_spec{
//   list-style: none;
//   margin: 0 0 20px 0;
//   padding: 0 16px 0 0;
//   width: auto;
//   display: flex;
// }
.min_height {
  min-height: auto;
}
.min_height_external{
  min-height: auto;
}
// .min_height_null {
//   min-height: 0!important;
// }
.loader_parent {
  position: relative;
  width: 100%;
}
.loader_child {
  position: absolute;
  left: 45%;
  top: -75px;
  z-index: 1050;
}

.disableButton{
  background-color: #c6c8ca!important;
}

.searchBarSelect{
  height: 40px;
  width: 200px;
  background-color: #f0f0f0;
  border-radius: 2px;
  border: 1px solid #c1c7d0;
  padding: 7px;
}