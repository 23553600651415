@import "../../../styles/standard.module.scss";

.tab_content_top {
  border-bottom: solid 1px $tab-border-bottom;
  margin-bottom: 15px;
}
.tab_content_hdg {
  padding: 24px 24px 16px;
  margin-bottom: 0;
  font-family: $secondary-font-regular;
  position: relative;
}
.tab_edit {
  color: $PrimaryColor-text;
  font-family: $secondary-font-regular;
  font-size: 14px;
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
  z-index: 1000;
}

.tab_edit a,
.tab_edit a:hover {
  color: $PrimaryColor-text;
}
.tab_edit img {
  max-width: 20px;
  margin-right: 3px;
}
.single_product_block {
  padding: 0 24px;
}
.patient_content {
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.col_spec {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
  display: flex;
}
.label_spec {
  margin-bottom: 0;
  font-family: $secondary-font-bold;
  white-space: nowrap;
}
.btn_small {
  width: 140px;
  height: 42px;
  font-family: $secondary-font-regular;
  color: $Plain-bg;
  font-size: $Typography-2;
}
.btn_custom {
  height: 42px;
  border-radius: 2px;
  font-family: $secondary-font-regular;
  font-size: $Typography-2;
  padding: 4px;
}

.patient_content .select_wrapper,
.moreinfo_content .select_wrapper {
  border-radius: 2px;
  border: solid 1px $border-color;
  /*min-height: 40px;
    max-height:80px;*/
  background: url("../../../static/images/down-arrow.png") right 10px bottom
    10px no-repeat;
  position: relative;
  left: 1px;
  top: -1px;
  bottom: 0;
  align-self: flex-start;
  /*height: 40px;*/
}
.basic_select {
  font-family: $secondary-font-regular;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border-radius: 2px;
  background-color: transparent;
  appearance: none;
  -webkit-appearance: none;
  outline: 0;
  border: 0;
}
.display_flex {
  display: flex;
}
.form_span {
  color: $secondary-text;
  margin-left: 10px;
  cursor: pointer;
}
.img_left {
  margin-left: 10px;
}
.btn_small,
.btn_small:hover {
  @include btn_primary;
  margin-right: 32px;
}
.btn_small_cancel,
.btn_small_cancel:hover {
  @include btn_secondary;
}
.custom_modal_size {
  max-width: 967px;
}

.basic_input {
  width: 100%;
  height: 40px;
  padding: 0 5px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
}
.basic_input_core_des {
  width: 100%;
  height: 40px;
  padding: 0 5px;
  border-radius: 2px;
  border: solid 0px $border-color;
  background-color: $Plain-bg;
  transition: 0.6s ease;  
}
.basic_input_core_foc { 
  outline: none !important;
  border-bottom-color: #f6f6f6;
  border-top-color: #f6f6f6;
  border-bottom-width: 2px;
  border-top-width: 0px;
  transition: 0.6s ease;  
}


.basic_input_core_des:focus,.basic_input_core_des:focus-visible { 
  outline: none !important;
  border-bottom-color: #007bff;
  border-top-color: #f6f6f6;
 // box-shadow: 0px 5px 5px -7px #f6f6f6;
  transition: 0.6s ease; 
  border-bottom-width: 1px;
  border-top-width: 0px; 
}
.input_block {
  width: calc(100% - 36px) !important;
  float: right;
  font-family: $secondary-font-regular;
}

.custom_control_input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 16px;
  height: 16px;
  opacity: 0;
}
.custom_control_input.is_invalid ~ .custom_control_label::before,
.was_validated .custom_control_input:invalid ~ .custom_control_label::before {
  border-color: $PrimaryColor-text;
}
.custom_control_input.is_valid ~ .custom_control_label,
.was_validated .custom_control_input:valid ~ .custom_control_label {
  color: $Error;
}
.custom_control_input.is_valid:checked ~ .custom_control_label::before,
.was_validated
  .custom_control_input:valid:checked
  ~ .custom_control_label::before {
  border-color: $border-box;
  background-color: $CheckBox-bg;
}
.custom_control_input:checked ~ .custom_control_label::before {
  color: $Plain-text;
  border-color: $border-box;
  background-color: $CheckBox-bg;
  border-radius: 0;
  width: 16px;
  height: 16px;
  top: 11px;
}
.pos_delete_form:first-child {
  left: 5px;
}
.pos_delete_form, .pos_fetch_downarrow, .pos_fetch_uparrow {
  cursor: pointer;
}
.pos_fetch_downarrow, .pos_fetch_uparrow {
  img {
    height: 16px;
    width: 16px;
  }
}

.dropdown_align_add {
  width: auto;
  white-space: nowrap;
}
.dropdown_heading_add {
  font-family: $secondary-font-regular;
  color: $PrimaryColor-text;
  font-size: 16px;
  padding: 8px 16px;
  margin-bottom: 0;
}
.dropdown_heading_add span,
.dropdown_heading_add span:hover {
  color: $PrimaryColor-text;
  cursor: pointer;
}
.display_inline_flex {
  display: inline-flex;
}
.singleSelect .col_spec_img .plus_icon {
  display: none;
}
.singleSelect .col_spec_img:last-child .plus_icon {
  display: block;
}
.multiSelect .col_spec_img .plus_icon {
  display: none;
}
.multiSelect .col_spec_img:last-child .plus_icon {
  display: block;
}

.btn_mobile {
  border: 1px solid $bdr-color-tertiary;
  width: auto;
  height: 42px;
  border-radius: 2px;
  background-color: $Plain-bg;
}
.sequence_arrow {
  padding: 5px;
  cursor: pointer;
}
.next_question {
  width: 144px;
  height: 42px;
  margin: 0;
  padding: 9px 16px 9px 9px;
  border-radius: 2px;
  background-color: $PrimaryButton-bg;
  font-family: $secondary-font-regular;
  color: $Plain-text;
  font-size: 16px;
}
.next_question:hover {
  width: 144px;
  height: 42px;
  margin: 0;
  padding: 9px 16px 9px 9px;
  border-radius: 2px;
  background-color: $PrimaryButton-bg;
  font-family: $secondary-font-regular;
  color: $Plain-text;
  font-size: 16px;
}
.question_blk select:disabled,
.question_blk input:disabled {
  background-color: #dbdbdb;
  opacity: 0.59;
}

.question_blk {
  width: 100%;
  box-shadow: 5px 10px 20px 0 #2e2c2c4c;
  border: solid 1px #e5e5e5;
  position: relative;
  margin-bottom: 40px;
}
.question_blk .question_top {
  background-color: rgba(119, 121, 126, 0.07);
  padding: 10px 20px 10px 50px;
}
.question_heading {
  font-family: $secondary-font-bold;
  font-size: 20px;
  color: #383838;
  margin-bottom: 0;
  padding: 0px;
  white-space: nowrap;
}
.up_down_list {
  float: right;
  width: 50%;
  text-align: right;
  margin-bottom: 0;
}
.border_left_0 {
  border-left: 0 !important;
}
.type_label_spec {
  margin-bottom: 0;
  font-family: $secondary-font-bold;
  line-height: normal;
  width: 75px;
  background-color: #f6f6f6;
  display: inline;
  float: left;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
}
.type_basic_input {
  height: 40px;
  padding: 0 0px;
  border-radius: 2px;
  border: 0;
  background-color: #ffffff;
  width: calc(100% - 40px) !important;
  float: right;
  font-family: $secondary-font-regular;
}
.type_basic_select {
  width: calc(100% - 75px);
  height: 40px;
  padding: 0 10px;
  appearance: none;
  -webkit-appearance: none;
  outline: 0;
  background: 0;
  border: 0;
}
.type_rule_select {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  appearance: none;
  -webkit-appearance: none;
  outline: 0;
  background: 0;
  border: 0;
}
.label_in_spec {
  float: left;
  color: #505f79;
  font-family: $secondary-font-bold;
  font-size: 16px;
  margin-right: 40px;
}

.form_custom_check {
  border: 1px solid $border-color;
  border-radius: 2px;
  padding: 0;
}
.custom_check_bg {
  background-color: #f6f6f6;
  display: inline;
  float: left;
  height: 43px;
  width: 35px;
  padding: 0 0 0 10px;
  pointer-events: none;
  position: relative;
  bottom: 0px;
}
.pos_close_form {
  left: 15px;
  position: absolute;
}
.btn_custom_branch {
  font-family: $secondary-font-regular;
  font-size: 14px;
  width: auto;
  height: 32px;
  border-radius: 100px;
  border: 1px solid $bdr-color-tertiary;
  padding: 0 34px 0 14px;
  background: url("../../../static/images/down-arrow.png") right 10px center
    no-repeat;
    white-space: nowrap;
}
.btn_custom_branch_answer {
  font-family: $secondary-font-regular;
  font-size: 14px;
  width: auto;
  height: 32px;
  border-radius: 100px;
  border: 1px solid $bdr-color-tertiary;
  padding: 0 15px 0 15px;
}
.form_block1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.question_add_new {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: $PrimaryButton-bg;
}
.btn-transparent {
  background: transparent;
}
.dropdown_branch_add {
  font-family: $secondary-font-regular;
  color: $PrimaryColor-text;
  font-size: 14px;

  margin-bottom: 0;
}
.dropdown_branch_add a {
  color: $PrimaryColor-text;
  text-decoration: none;
  padding: 8px 16px;
  width: 100%;
  float: left;
  display: block;
}
.dropdown_branch_add a:hover {
  color: $PrimaryColor-text;
  text-decoration: none;
  padding: 8px 16px;
  background-color: #f2f7ff;
}

.btn_custom_rule {
  font-family: $secondary-font-regular;
  font-size: 14px;
  width: auto;
  height: 32px;
  border-radius: 100px;
  border: 1px solid $bdr-color-tertiary;
  padding: 0 10px 0 10px;
  background: $body-bg-color;
  margin-left: 10px;
  margin-top: 3px;
  white-space: nowrap;
}
.form_custom_check_disabled {
  background-color: rgba(219, 219, 219, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  float: left;
  height: 40px;
}

.product_sub_block {
  background-color: rgba(80, 95, 121, 0.09);
  padding: 25px 0 0;
  margin: 0 25px 25px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.btn_fetch,
.btn_fetch:hover,
.btn_fetch:focus {
  border-radius: 2px;
  background-color: $PrimaryButton-bg;
  font-family: $secondary-font-regular;
  color: $Plain-text;
  font-size: 14px;
}

.close_form {
  width: 41px;
}
.form_text_blk {
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
  color: $black-color;
  font-family: $secondary-font-regular;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0 10px;
  padding: 0 10px;
  width: 125px;
  float: left;
  display: inline-block;
}
.up_down_list a {
  margin-right: 10px;
}
.up_down_list a:last-child {
  margin-left: 15px;
}
.custom_control_label {
  position: relative;
  margin-top: 5px;
  margin-bottom: 0;
  vertical-align: top;
  font-family: $secondary-font-regular;
  font-size: 14px;
  color: $PrimaryColor-text;
  line-height: normal;
}
.custom_control_label::after {
  position: absolute;
  top: 11px;
  left: -1.5rem;
  display: block;
  width: 16px;
  height: 16px;
  content: "";
}
.custom_control_label::before {
  border-radius: 50% !important;
  top: 11px;
}

.custom_control_inline {
  height: 22px;
}

.custom_control_inline .custom_control_label {
  font-family: $secondary-font-bold;
  color: $text-color;
  font-size: 14px;
}

.hide_outline:focus {
  border: 0px !important;
  outline: 0 !important;
}
/* .custom_control_label_t0::before {
    border-radius: 50% !important;
    top: 0px;
}
.custom_control_label_t0::after {
    border-radius: 50% !important;
    top: 0px;
} */
.custom_checkbox .custom_control_label::before {
  border-radius: 0 !important;
}
.form_span.disabled {
  cursor: not-allowed;
}
span.disabled,
div.disabled {
  pointer-events: none;
}
div.disabled {
  background-color: $btn-disable !important;
  opacity: 0.59;
}
.prod_select_search {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 0;
  padding: 0 35px 0 10px;
  cursor: pointer;
  min-height: 40px;
}
.product_selector_items {
  width: 100%;
  padding: 10px;
  border-radius: 0;
  max-height: 250px;
  overflow: auto;
}
.product_selector_items ul {
  margin: 0;
  padding: 0;
}
.product_selector_items ul {
  margin: 0;
  padding: 0 0 0 5px;
  list-style: none;
}
.product_selector_items ul li {
  cursor: pointer;
  margin: 0;
  padding: 0 0 0 0;
  list-style: none;
}
.select_wrapper {
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg !important;
  /*height: 40px;*/
}
.select_wrapper {
  background: url("../../../static/images/down-arrow.png") right 10px center
    no-repeat;
}
.select_wrapper_pro {
  height: 40px;
}
.error_message {
  display: block;
  color: $Error;
  font-family: $secondary-font-regular;
  font-size: 14px;
}
.product_attribute {
  background-color: $btn-disable;
  opacity: 0.59;
}
.product_attribute input {
  background-color: transparent;
  pointer-events: none;
}

.product_attribute .custom_check_bg {
  background-color: transparent;
  pointer-events: none;
}
.form_modal_text {
  font-size: 16px;
  font-family: $secondary-font-regular;
  color: $PrimaryColor-text;
  text-align: center;
  margin-bottom: 10px;
}
.edit_textarea {
  width: 100%;
  float: left;
  border: solid 1px #c1c7d0;
  height: 150px;
  padding: 10px;
  font-size: 16px;
  font-family: $secondary-font-regular;
  color: $PrimaryColor-text;
}
.text_content {
  width: 89%;
  height: 150px;
}
.display_none {
  display: none;
}
.error_message {
  display: block;
  color: #de350b;
  font-family: $secondary-font-regular;
  font-size: 14px;
}
.sucessmsg {
  position: fixed;
  top: 10%;
  right: 10%;
  z-index: 1;
}
.pl_32 {
  padding-left: 32px;
}
.yes_btn {
  width: 140px;
  border-radius: 0px;
}
.no_btn {
  width: 140px;
  border-radius: 0px;
  background-color: $Body-bg;
  color: $PrimaryColor-text;
  border: solid 1px $tertiary-text;
}
.no_btn:hover {
  background-color: $Body-bg;
  color: $PrimaryColor-text;
  border: solid 1px $tertiary-text;
}
.no_btn:active {
  background-color: $Body-bg !important;
  color: $PrimaryColor-text !important;
  border: solid 1px $tertiary-text !important;
}
.no_btn:focus {
  background-color: $Body-bg;
  color: $PrimaryColor-text;
  border: solid 1px $tertiary-text;
}
.pr_90 {
  padding-right: 90px;
}
.root_tag{
  position: relative;
  padding: 6px 0 0 6px;
  border: 1px solid #D1D1D1;
  border-radius: 1px;
  font-size: 13px;
  line-height: 1.2;
  width: 89%;
  height: 100px;
  margin-left: 0px;
  margin-bottom: 10px;
  display: inline-table;
}

.custom_modal_size_family {
  max-width: 1080px;
}
.form_data {
  height: 45px;
}
.btn_customs {
  height: 40px;
  border-radius: 2px;
  font-family: $secondary-font-regular;
  background-color: $PrimaryButton-bg;
  font-size: 16px;
  padding: 0px 10px;
  margin-right: 20px;
  white-space: nowrap;
}
.btn_smalls {
  img{
    margin-right: 10px;
  }
}
.media_align {
  top: 112px;
  right: 0px;
  left: 520px;
  width: 400px;
  padding: 0 0 10px;
  border: solid 1px #e5e5e5;
  z-index: 1600;
}
.list_view {
  display: flex;
  gap: 7px;
  align-items: center;
  flex-wrap: wrap;
}
.media_view{
  margin: 0px;
  ul{
      margin: 0;
      padding: 0;
      list-style-type: none;
      li{
        display: flex;
          &:last-child{
              border-bottom: 0;
          }
          a{
            display: inline-block;
            max-width: 125px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            margin-top: auto;
              &:hover{
                  color: #3058db;
                  text-decoration: none;
                  font-weight: bold;
              }
          }
          > img{
              margin-right: 10px;
              max-width: 25px;
              margin-top: auto;
          }
          >span{
              margin-left: 10px;
              cursor: pointer;
              margin-top: auto;
              &:hover{
                  filter: brightness(70%);
              }
          }
          >span img{
            width: 18px;
            height: 18px;
        }
      }
  }
}
.btn_custom_disable,
.btn_custom_disable:hover,
.btn_custom_disable:active {
    opacity: 0.3;
    cursor: not-allowed !important;
    height: 40px;
    border-radius: 2px;
    background-color: $secondary-text;
    font-family: $secondary-font-regular;
    font-size: 16px;
    padding: 0px 10px;
    margin-right: 20px;
    white-space: nowrap;
}