@import "../../../styles/standard.module.scss";

.subheading_2 {
    font-family: $primary-font-regular;
    font-size: 21px;
    color: $PrimaryColor-text;
    margin-bottom: 0;
}
.create_patient {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
    display: flex;
    flex-wrap: wrap;
}
.col_spec {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
    display: flex;
}
.basic_input {
    width: 100%;
    height: 40px;
    padding: 0 5px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}
.label_spec {
    margin-bottom: 0;
    font-family: $secondary-font-bold;
}
.btn_small {
    width: 140px;
    height: 42px;
    font-family: $secondary-font-regular;
    background-color: $PrimaryButton-bg;
    color: $Plain-bg;
    font-size: $Typography-2;
}
.btn_custom {
    height: 50px;
    border-radius: 2px;
    font-family: $secondary-font-regular;
    background-color: $PrimaryButton-bg;
    font-size: $Typography-1;
    padding: 4px;
    margin-right: 16px;
}
.btn_small,
.btn_small:hover {
    @include btn_primary;
}
.btn_small_cancel,
.btn_small_cancel:hover {
    @include btn_secondary;
}
.basic_input:focus-visible {
    outline: none !important;
}

.select_wrapper {
    background: url("../../../static/images/down-arrow.png") right 10px center no-repeat;
    border-radius: 2px;

    background-color: $Plain-bg;
    height: 40px;
}
.basic_select {
    width: 254px;
    height: 40px;
    padding: 0 10px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}

.textarea_box,
.textarea_box:focus {
    width: 100% !important;
    border: 1px solid $border-color;
    outline: none;
    padding: 10px;
}

.text_content {
    max-height: 150px;
    min-width: 577px;
    height: 150px;
    overflow: auto;
}

.general_error {
    margin-bottom: 10px;
    color: $Plain-text;
    border-radius: 3px;
    padding: 5px 15px;
    display: inline-block;
    background-color: $Error;
}
