@import "../styles/standard.module.scss";

.title_top {
  padding: 10px 30px;
  background-color: rgba(80, 95, 121, 0.1);
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.bi_title {
  color: #333;
  font-family: $secondary-font-bold;
  font-size: 22px;
  display: inline-block;
  margin: 0;
}

.bi_arrow_bg {
  border: 1px solid #ccc !important;
  background: #f6f6f6 url("../static/images/ArrowRight.svg") left 5px center
    no-repeat;
  display: inline;
  float: left;
  height: 42px;
  width: 40px;
  padding: 0;
}

.bi_arrow_right_bg {
  border: 1px solid #ccc !important;
  background: #f6f6f6 url("../static/images/ArrowLeft.svg") left 5px center
    no-repeat;
  display: inline;
  float: left;
  height: 42px;
  width: 40px;
  padding: 0;
}

.bimulti_dropdown {
  width: 100% !important;
  max-height: 170px !important;
  overflow: hidden !important;
  overflow-y: auto !important;
  padding: 5px 0 0;
  border-radius: 0 !important;
}

.bimulti_dropdown strong {
  width: 100% !important;
  font-family: $secondary-font-regular !important;
  font-size: 16px !important;
  display: block;
}
.bimulti_dropdown span {
  width: 100% !important;
  font-family: $secondary-font-regular !important;
  font-size: 16px !important;
  display: block;
}

.bi_select_blk {
  width: 100%;
  border: 1px solid #c1c7d0;
  padding: 10px 5px;
  font-family: $secondary-font-regular !important;
  font-size: 14px !important;
  display: inline-block !important;
  // background: url('../images/down-arrow.png') right+10px top+10px  no-repeat;
}
.bi_select_heading {
  font-family: $secondary-font-regular !important;
  font-size: 14px !important;
  font-weight: bold;
  padding-right: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bi_select_text {
  font-family: $secondary-font-regular !important;
  font-size: 14px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bimulti_dropdown .dropdown-item {
  padding: 10px 0 10px 10px !important;
  border-bottom: 1px solid #c8c7cc !important;
  font-size: 14px !important;
}

.bimulti_dropdown .dropdown-item.active,
.bimulti_dropdown .dropdown-item:active,
.bimulti_dropdown .dropdown-item:hover,
.bimulti_dropdown .dropdown-item:focus {
  background-color: transparent !important;
}

.bimulti_dropdown_txt {
  max-height: 300px !important;
}

.wound_dashboard {
  box-shadow: 5px 10px 20px 0 rgb(191 191 191 / 30%) !important;
  background-color: #ffffff !important;
  // margin-top: 40px !important;
  padding: 0 0 20px !important;
}
.moreinfo_content,
.patient_content {
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.col_spec,
.col_spec {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
  display: flex;
  flex-wrap: wrap;
}
.basic_input {
  width: 100%;
  height: 40px;
  padding: 0 5px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
}

.wound_form {
  padding: 40px 15px 41px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.wound_form .col_spec {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
  display: flex;
}
.wound_input {
  width: 100%;
  height: 40px;
  border-radius: 2px;
  border: solid 1px #c1c7d0;
  background-color: #ffffff;
  padding-left: 10px !important;
}

.wound_select {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border-radius: 2px;
  border: solid 1px #c1c7d0;
  background-color: transparent;
  appearance: none;
}

.select_wrapper {
  // background: url("../../../static/images/down-arrow.png") right 10px center no-repeat;
  background: url("../static/images/down-arrow.png") right 10px center no-repeat;

  border-radius: 2px;
  border: 1px solid #cccccc !important;
  background-color: $Plain-bg;
  min-height: 40px;
  max-height: 80px;
}

.custom_control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.custom_checkbox {
  position: relative;
  margin-left: 25px;
  margin-right: 5px;
  display: inline-block;
  vertical-align: top;
  label {
    // text-indent: -9999px;
    margin-right: 0;
    color: #505f79;
  }
}

.custom_control_inline {
  height: 22px;
  align-items: center;
}

.custom_control_inline .custom_control_label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
  font-family: $secondary-font-regular;
  font-size: 14px;
  color: #333333;
  font-weight: bold;
  padding-top: 1px
}

.subheading_2 {
  font-family: $primary-font-regular;
  font-size: 21px;
  color: $PrimaryColor-text;
  margin-bottom: 0;
}

.type_basic_input {
  height: 40px;
  padding: 0 5px;
  border-radius: 2px;
  border: 0;
  background-color: #ffffff;
  width: calc(100% - 50px) !important;
  float: right;
  font-family: $secondary-font-regular;
}
.type_basic_input:focus {
  outline: 0;
  border: 0;
}

.input_block {
  width: calc(100% - 36px) !important;
  float: right;
  font-family: $secondary-font-regular;
}

.label_spec {
  margin-bottom: 0;
  font-family: $secondary-font-bold;
}
.assessment_lspac, .wound_lspac{
  padding-left: 0;
  padding-top: 5px;
}
.treatement_lspac {
  padding-left: 0;
  padding-top: 40px;
}

.primary_Save {
}

.secondary_Reset {
}

.secondary_Reset,
.secondary_Reset:hover,
.secondary_Reset:active {
  @include btn_secondary;
}
.primary_Save,
.primary_Save:hover,
.primary_Save:active {
  @include btn_primary;
  margin-right: 32px;
}

.non_PoA {
  padding-left: 144px;
}

.label_spec {
  margin-bottom: 0;
  font-family: $secondary-font-bold;
}

.organizationalView {
  border: 1px solid $border-color !important;
}
.multiselectDescription {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333;
  padding-top: 5px;
}
.multiselectDescription:hover {
  color: #fff !important;
}
.multiselectDescriptionSearch {
  border: none !important;
  outline: 0px solid #000000 !important;
  color: #6c757d !important;
  height: 20px;
}
.multiselectDescriptionSearch:focus {
  border: none !important;
  outline: none !important;
  color: #6c757d !important;
}
input[type="button"]::before {
  content: "SelectDDDDDDDDDDDDDDDDDDDD";
}
.form_span {
  color: $secondary-text;
  cursor: pointer;
}
.form_span:hover {
  cursor: pointer;
}
.customer_details {
  margin-bottom: -14px;
  font-family: $secondary-font-regular;
  white-space: nowrap;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  }

  .boxShadowDiv{
    box-shadow: 0 0px 5px #000;
    padding-top: 24px !important;
    margin-top: -24px;
  }

  .toggle_shells{
    padding-left: 58px;
    padding-top: 3px;
  }
  .toggle_switch {
    // transform: scale(1.4, 1.1);
    transform: scale(1.5, 1.2);
    transition: 0.3s ease-in-out;
  }

  .grayoutLabel{
    color: #bcbcbc!important;
  }
  .grayoutLabelScore{
    opacity: 0.6!important;
  }