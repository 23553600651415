@import '../../../styles/standard.module.scss';
@import '../../../styles/common.module.scss';
.back_btn a {
    @include response-above(sm) {
        position: fixed;
        top:22px;
        left:25px;
        background:url(../../../static/images/back-arrow.png) center left no-repeat;
        text-indent: 999px;
    }
    @include response-above(lg) {
        position: static;
        top:auto;
        left:auto;
        color:$PrimaryColor-text !important;
        background:url(../../../static/images/left_arrow.png) center left no-repeat;
        padding-left: 10px;
        font-family: $secondary-font-regular;
        font-size:16px;
    }
}
.btn_small,
.btn_small:hover {
    width: 130px;
    height: 45px;
    font-family: $secondary-font-regular;
    background-color: $PrimaryButton-bg;
    border: 1px solid $PrimaryButton-bg;
    color: $Plain-text;
    font-size: 16px;
    border-width: 1px;
}
.btn_small_cancel,
.btn_small_cancel:hover {
    width: 130px;
    height: 45px;
    font-family: $secondary-font-regular;
    background-color: $Plain-bg !important;
    border: 1px solid $PrimaryColor-text !important;
    color: $PrimaryColor-text !important;
    font-size: 16px; 
}
.btn_custom {
    border-radius: 2px;
    margin: 10px 5px;
    padding: 5px 0;
}

.info_message{
    margin: 20px 0;
    text-align: center;
    img{
        max-width: 50px;
    }
    h3{
        font-weight: 400;
        span{
            display: block;
            margin-top: 10px;
            font-size: 18px;
        }
    }
}

.loader_fix{
  text-align: center;
  margin: 10px 0;
  p{
      margin-bottom: 10px;
  }
  img{
    width:50px;
    border-radius: 100%;
  }
}

.content > article{
    padding: 20px 50px;
    font-size: 16px;
    h6{
        font-size: 1.1rem;
        font-weight: bold;
    }
    h2{
        color: #f16278;
    }
    h4{
        background-color: #eee;
        padding: 7px 15px;
        color: #777;
        font-size: 18px;
        margin: 0;
    }
}

.header{
    padding: 50px;
    h1{
        font-size: 3.0rem; //added for PDF Changes
    }
    h6{
        font-weight: normal;
        b{
            font-weight: 600;
        }
    }
}

// Graybox SubDetails    //added for PDF Changes
.assess_content {
    font-family: $secondary-font-regular;
  }
.assess_content_subDetails{
    background-color: rgb(0 0 0 / 15%);;
    padding: 10px 0px;
    font-weight: bold;
  }
  .assess_label_subDetails{
    font-size: 15px!important;
  }
  .assess_label_subDetail_label{
    font-size: 16px!important;
    font-weight: bold!important;
  }
  .assess_label_subDetail_labelout{
    font-size: 16px!important;
    font-weight: 500;
    color: #000;
  }
  .right_Arrow{
    margin-left: 10px;
  }
  .assement_all_link{
    padding: 10px 15px;
    background-color: #ffffff;
    border-radius: 10px;
    margin-left: 25%;
  }
  
  .PDFButton {
    float: right;
    margin-top: -1%;
  }

  .assess_label {
    font-family: $secondary-font-bold;
    font-size: 16px;
  }

  .sub_label_content{
    background-color: rgb(140 140 140 / 29%);
    color: #0052cc;
    padding: 3px 11px 5px 10px;
    font-weight: bold;
  }

  .POAColor{
    color:limegreen;
    font-weight: bold;
    font-size: 17px!important;
  }
  .NONPOAColor{
    color:orangered;
    font-weight: bold;
    font-size: 16px!important;
  }

//   .closureColor {  
//     font-size: 16px!important;
//     display: block;
//   }
  
  .closureColor_green {
    color:#15b471!important;
    font-size: 16px;
  }
  .closureColor_red {
    color: orangered!important;
    font-size: 16px;
  }
  .closureColor_grey {
    color: grey!important;
    font-size: 18px;
  }

  .closurePercentage_green {
    color:#15b471!important;
    font-size: 14px;
    margin-left: -5px;
  }
  .closurePercentage_red {
    color: orangered!important;
    font-size: 14px;
    margin-left: -5px;
  }

  .border_head {
    border: 1px solid darkgrey!important;
    padding: 25px 0px 5px!important;
    background: #c8c8c857!important;
}
.chart_box {
    padding-top: 20px;
    margin-left: -16px;
}
.chart_box_green {
    color:#15b471!important;
    padding-right: 2px;
  }
  .chart_box_red {
    color: orangered!important;
    padding-right: 2px;
  }






// .score{
//     margin-bottom: 10px;
//     >div {
//         position: relative;
//         text-align: center;
//         // display: inline-block;
//         float: left;
//         width: 100px;
//         height: 100px;
//         background-color: #f7f7f7;
//         margin-right: 5px;
//         border: 1px solid #ddd;
//         font-size: 18px;
//         padding: 10px 0px;
//         > span{
//             position: relative;
//             font-size: 19px;
//             display: block;
//         }
//     }
// }
.score .score_part{
    margin-bottom: 10px;
    position: relative;
    text-align: center;
    // display: inline-block;
    float: left;
    width: 100px;
    height: 100px;
    background-color: #f7f7f7;
    margin-right: 5px;
    border: 1px solid #ddd;
    font-size: 18px;
    padding: 18px 0px;
    > span{
            position: relative;
            font-size: 19px;
            display: block;           
        }
}
.score .percentage_part{
    margin-bottom: 10px;
    position: relative;
    text-align: center;
    // display: inline-block;
    float: left;
    width: 105px;
    height: 100px;
    background-color: #f7f7f7;
    margin-right: 5px;
    border: 1px solid #ddd;
    font-size: 18px;
    padding: 10px 0px 15px 0px;
    > span{
        position: relative;
        font-size: 18px;
        display: block;
        line-height: 25px;
        font-weight: 600;
    }
}

.score_part{
    margin-top: 1px;
}
.percentage_part{
    margin-top: 1px;
}





.mini_hero{
    background-color: #f3f3f3;
    border: 1px solid #ddd;
    padding: 25px 15px !important;
    margin: 0 50px;
    h6{
        font-size: 1.1rem;
    }
    p{
        margin: 5px 0;
        font-size: 16px;
    }
}

// .min {
//     font-size: 1.1rem!important;
//     h6{
//         font-size: 1.1rem;
//         font-weight: bold;
//     }
// }
.image{
    padding: 30px
}
.image img{
    max-width: 250px;
}

.chart{
    .chart_width{
        width: 100%;
        max-width: 700px;
    }
}

.tabley{ 
    table {
        margin: 0;
        border-collapse: collapse;
        width: 100%;
        table-layout: fixed
    }
    
    td, th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
        font-size: 14px;
    }

    th{
        border: 0;
    }
    
    tr:nth-child(odd) {
        background-color: #eee;
    }
}
.treatment_field{
    h5{
        font-weight: 600;
        font-size: 15px;
        line-height: 15px;
        margin: 0;
        margin-bottom: 5px;
    }
    h6{
        font-weight: normal;
        font-size: 13px;
        margin: 0;
        line-height: 13px;
        margin-bottom: 5px;
    }
    strong{
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.03em;
        color: #fff;
        background-color: #36b37e;
        padding: 3px 10px;
        border-radius: 4px;
    }
}
.customer_block{
    > img{
        max-width: 70px;
        float: left;
        margin-top: 8px;
    }
    > div{
        margin-left:95px;
    }
}
.no_logo{
    width: 70px;
    height: 70px;
    float: left;
    background-color: #eee;
    outline: 1px solid #ccc;
    line-height: 70px;
    text-align: center;
    font-size: 13px;
    color: #000;
    margin-top: 5px;
}
.wound_image{
    // height: 180px;
    margin-top: -50px;
    img{
        height: 100%;
        // width: 160px; //added
        width: 275px; //added
    }
}


//signature line/date
.signatureLine{
    border: 1px solid #8a8a8a;
}
.signatureTitle{
    p{
        font-size: 21px;
        font-weight: 400;
    }    
}


//Notes Section
.entered_notes {
    margin-bottom: 10px;
    border-bottom: 1px solid $tab-border-bottom;
  }
  .post_details {
    color: #767676;
    font-size: $Typography-3;
    margin-bottom: 5px;
  }
  .notes_text {
    color: $PrimaryColor-text;
    font-family: $secondary-font-regular;
    padding-bottom: 10px;
  }
  .creater_name {
    text-transform: capitalize;
  }