@import "../../../styles/standard.module.scss";

.tab_content_top {
  // border-bottom: solid 1px $tab-border-bottom;
  border-bottom: solid 1px #dedede;
  margin-bottom: 15px;
}
.tab_content_hdg {
  padding: 24px 24px 24px;
  margin-bottom: 0;
  font-family: $secondary-font-regular;
  position: relative;
}
.tab_content_ne1Status{
    margin-top: -11px;
    font-size: 24px;
}
.basic_info {
  padding: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.basic_info div {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
}

.basic_info_form {
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.basic_info_form div {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
  display: flex;
  align-items: center;
}
.subheading_2 {
  font-family: $primary-font-regular;
  font-size: 21px;
  color: #737373;
  margin-bottom: 0;
  text-decoration-line: underline;
}

.single_product_block {
  padding: 0 24px;
}
.patient_content {
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.moreinfo_content,
.patient_content {
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.moreinfo_content .col_spec,
.patient_content .col_spec {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
  display: flex;
}

.patient_content .basic_input,
.moreinfo_content .basic_input {
  width: 100%;
  height: 40px;
  padding: 0 5px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
}

.patient_content .select_wrapper,
.moreinfo_content .select_wrapper {
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
  height: 40px;
}

.patient_content .basic_select,
.moreinfo_content .basic_select {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  appearance: none;
  -webkit-appearance: none;
  outline: 0;
  background: 0;
  border: 0;
}
select::-ms-expand {
  display: none;
}

.label_spec {
  margin-bottom: 0;
  font-family: $secondary-font-bold;
}

.content_spec {
  font-family: $secondary-font-regular;
  color: $PrimaryColor-text;
  display: inline-block;
}
.basic_input {
  width: 250px;
  height: 40px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
}
.small_input {
  width: 68px;
  height: 40px;
  padding-left: 7px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
}

.basic_select {
  width: 250px;
  height: 40px;
  padding: 0 10px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
}

.btn_custom {
  height: 50px;
  border-radius: 2px;
  font-family: $secondary-font-regular;
  background-color: $PrimaryButton-bg;
  font-size: 16px;
}
.btn_small,
.btn_small:hover {
  @include btn_primary;
  margin-right: 32px;
}
.btn_small_cancel,
.btn_small_cancel:hover,
.btn_small_cancel:active {
  @include btn_secondary;
}

.select_wrapper {
  background: url("../../../static/images/down-arrow.png") right 10px center
    no-repeat;
}
.tab_edit {
  color: $PrimaryColor-text;
  font-family: $secondary-font-regular;
  font-size: 14px;
  position: absolute;
  right: 24px;
  top: 17px;
  z-index: 1000;
  cursor: pointer;
}

.tab_edit a,
.tab_edit a:hover {
  color: $PrimaryColor-text;
}

.form_btn {
  width: 100%;
}
.patient_img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 100%;
}
.file_hide {
  display: none;
}
.custom_file_upload {
  border: 1px solid $border-color;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  margin-bottom: 0;
}
.custom_control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}
.custom_control_input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 16px;
  height: 16px;
  opacity: 0;
}
.custom_control_label {
  position: relative;
  margin-top: 5px;
  margin-bottom: 0;
  vertical-align: top;
  font-family: $secondary-font-regular;
  font-size: 14px;
  color: $PrimaryColor-text;
  line-height: normal;
}
.custom_control_input.is_invalid ~ .custom_control_label::before,
.was_validated .custom_control_input:invalid ~ .custom_control_label::before {
  border-color: $PrimaryColor-text;
}
.custom_control_input.is_valid ~ .custom_control_label,
.was_validated .custom_control_input:valid ~ .custom_control_label {
  color: $Error;
}
.custom_control_input.is_valid:checked ~ .custom_control_label::before,
.was_validated
  .custom_control_input:valid:checked
  ~ .custom_control_label::before {
  border-color: $border-box;
  background-color: $CheckBox-bg;
}
.custom_control_label::after {
  position: absolute;
  top: 11px;
  left: -1.5rem;
  display: block;
  width: 16px;
  height: 16px;
  content: "";
}
.custom_control_label::before {
  border-radius: 50% !important;
  top: 11px;
}
.custom_control_inline {
  height: 22px;
}

.custom_control_inline .custom_control_label {
  font-family: $secondary-font-bold;
  color: $text-color;
  font-size: 14px;
}
.custom_control_input:checked ~ .custom_control_label::before {
  color: $Plain-text;
  border-color: $border-box;
  background-color: $CheckBox-bg;
  border-radius: 0;
  width: 16px;
  height: 16px;
  top: 11px;
}
.neg_margin {
  margin-top: -33px !important;
}

.profile_label_fix {
  margin-top: 15px;
}
.tab_edit img {
  max-width: 20px;
  margin-right: 3px;
}
.tab_edit_disable {
  pointer-events: none;
  cursor: not-allowed;
}
.basic_input_column {
  display: flex;
}
.no_btn {
  width: 140px;
  border-radius: 0px;
  background-color: $Body-bg;
  color: $PrimaryColor-text;
  border: solid 1px $tertiary-text;
}
.no_btn:hover {
  background-color: $Body-bg;
  color: $PrimaryColor-text;
  border: solid 1px $tertiary-text;
}
.no_btn:active {
  background-color: $Body-bg !important;
  color: $PrimaryColor-text !important;
  border: solid 1px $tertiary-text !important;
}
.no_btn:focus {
  background-color: $Body-bg;
  color: $PrimaryColor-text;
  border: solid 1px $tertiary-text;
}
.yes_btn {
  width: 140px;
  border-radius: 0px;
}
.addValBtn{
  color: #0052cc;
  font-weight: 700;
  font-size: 16px;
  float:right;
  cursor: pointer;  
}

.deleteDomain {
  vertical-align: middle;
  border-style: none;
  width: 22px;
  height: 22px;
  padding: 6px;
  border-radius: 12px;
  border: solid 1px rgba(80, 95, 121, 0.5);
  background-color: #ffffff;
  cursor: pointer;
  margin-top: 8px;
  margin-left: -15px;
}
.error_message{
  display: block;
  color: #de350b;
  padding-top: 4px;
  font-family: $secondary-font-regular;
  font-size: 14px;
}


/*Toggle button Style*/
.ne1_toggleSwitch{
    display: flex!important;
}
.toggle_shells{
    padding-left: 40px;
    padding-top: 3px;
  }
  .toggle_switch {
    // transform: scale(1.4, 1.1);
    transform: scale(1.5, 1.2);
    transition: 0.3s ease-in-out;
  }

  .col_spec_poa_left{
    margin-left: -80px!important;
  }


  .vertical_Code_role {
    margin-top: -6px;
    height: 42px;
    border-left: solid 1px #007bff;
    border-left-style: solid;
    margin-left: -75px;
    margin-right: 35px;
  }

  .shif_sold_button {
    width: auto;
    border-radius: 25px;
    background-color: #007bff!important;
    color: #fff!important;
    padding: 7px 20px !important;
    margin-top: -6px;
    cursor: pointer!important;
    // newadded for hover color
    display: inline-block;
    background: linear-gradient(to left, #005dea 50%, rgb(29, 2, 163) 50%) right;
    background-size: 200%;
    transition: .5s ease-out
  }


  .select_Check {
    background: #0052cc;
    color: #fff;
    padding: 3px 10px 4px;
    border-radius: 20px;
    font-size: 12px;
    align-items: center;
    max-width: 235px;
    height: 43px;
    justify-content: space-evenly;
    margin: 0 10px 10px 0;
    cursor: pointer;
    // newadded for hover color
    display: inline-block;
    background: linear-gradient(to left, #005dea 50%, rgb(29, 2, 163) 50%) right;
    background-size: 200%;
    transition: .5s ease-out
  }

  .shif_sold_button:hover{    
    background-position: left;  // newadded for hover color
  }

  // Popup Screen Style

  .custom_heading_ne1{
    background-color: #031ec9!important;
  }
  .modal_title_modal{
    color: #ffffff!important;
    margin-top: 0px!important;
  }
  .close_button_modal{
    color: #ffffff!important;
    opacity: 1!important;
  }

  .headerSetting{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn_small_cancel_modal{
    border: 1px solid #031ec9 !important;
    color: #031ec9!important;
  }
  

  .confrimationPopupButton{
    background-color: #09a664;
    color: #fff;
    align-items: center;
    align-self: start;
    height: 30px;
    width: 75px;
    padding-left: 8px;
    padding-top: 1px;
    margin-left: -20px;
    border-radius: 5px !important;
    margin-top: 1px;
  }
  .confrimationPopupTick{
    p{
      padding-top: 3px;      
    }
  }



  .ne1_left{
    border-right: 1px solid #dedede;
  }