.search-wrapper {
  border: 0px !important;
}
.search-wrapper span.chip {
  display: inline-block !important;
  white-space: pre-wrap;
  position: relative;
  padding-right: 20px !important;
  border-radius: 3px !important;
}
.icon_cancel {
  position: absolute !important;
  right: 0 !important;
}
.search-wrapper input[type="text"] {
  width: 100% !important;
  font-size: 1rem;
}

#clinicianId .search-wrapper,
#assessmentTypeId .search-wrapper,
#photoTypeId .search-wrapper,
#woundTypesId .search-wrapper,
#woundProgressId .search-wrapper,
#woundClassificationId .search-wrapper,
#bodyAnatomyId .search-wrapper,
#woundAgeId .search-wrapper,
#tissueTypesId .search-wrapper,
#infectedId .search-wrapper,
#openAreaId .search-wrapper,
#yellowFibrinId .search-wrapper,
#exudateTypeId .search-wrapper,
#biofilimPresentId .search-wrapper,
#exudateAmountId .search-wrapper,
#dressingAppearanceId .search-wrapper,
#debridementId .search-wrapper,
#modalitiesId .search-wrapper,
#additionalCareId .search-wrapper,
#primaryDressingId .search-wrapper,
#secondaryDressingId .search-wrapper,
#compressionId .search-wrapper,
#woundCleansersId .search-wrapper,
#skinprotectantId .search-wrapper,
#facility .search-wrapper,
#customerGroupSelection .search-wrapper,
#surveyName .search-wrapper,
#listViewMenu .search-wrapper,
#woundType .search-wrapper,
#typeOfDevice .search-wrapper{
  border-radius: 2px;
  border: solid 1px #c1c7d0 !important;
  background: url("../../../static/images/down-arrow.png") right 10px bottom 10px
    no-repeat;
  min-height: 40px;
}
.optionListContainer{
  padding-bottom: 20px !important;
}
.optionContainer li {
  padding: 10px 10px 10px 15px !important;
  background-color: transparent !important;
  color: inherit !important;

}
#woundType .optionListContainer, #customerGroupSelection .optionListContainer, #surveyName .optionListContainer{
  padding-bottom: 0 !important;
}
.optionContainer li input[type="checkbox"] {
  margin-left: 15px !important;
}
.optionContainer li input[type="checkbox"]::after {
 display: none !important;
}

#productManager .search-wrapper {
  border-radius: 2px;
  border: solid 1px #c1c7d0 !important;
  min-height: 40px;
}

#primaryDressingId ul li:not(.option),#secondaryDressingId ul li:not(.option),#woundCleansersId ul li:not(.option),#skinprotectantId ul li:not(.option),#compressionId ul li:not(.option){
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 10px 0px 10px 10px !important;
  /* background-color: #f2f2f2; */
  color: #000;
  background-color:rgba(80, 95, 121, 0.1) !important;
}
#primaryDressingId ul li.option,#secondaryDressingId ul li.option,#woundCleansersId ul li.option,#skinprotectantId ul li.option,#compressionId ul li.option{
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  padding: 5px 10px 0px 34px !important;
}
ul li.option:hover{
  color: #fff !important;
  background-color: #0096fb !important;
}
#primaryDressingId ul li.option:hover,#secondaryDressingId ul li.option:hover,#woundCleansersId ul li.option:hover,#skinprotectantId ul li.option:hover,#compressionId ul li.option:hover, 
#additionalCareId ul li.option:hover, #modalitiesId ul li.option:hover, #debridementId ul li.option:hover, #dressingAppearanceId  ul li.option:hover{
  color: #fff !important;
  background-color: #0096fb !important;
}
#primaryDressingId.searchBox,#secondaryDressingId.searchBox,#woundCleansersId.searchBox,#skinprotectantId.searchBox,#compressionId.searchBox{
  opacity: 0 !important;
  border: none !important;;
}
#primaryDressingId.search-wrapper.searchBox:focus,#secondaryDressingId.searchBox:focus,#woundCleansersId.searchBox:focus,#skinprotectantId.searchBox:focus,#compressionId.searchBox:focus{
  opacity: 0 !important;
  border: none !important;
  outline: 0px solid #000 !important;
}

#primaryDressingId ul.optionContainer li,#secondaryDressingId ul.optionContainer li,
#woundCleansersId ul.optionContainer li,#skinprotectantId ul.optionContainer li,
#compressionId ul.optionContainer li{
  padding: 10px 10px 10px 35px !important;
}
#primaryDressingId ul.optionContainer li input[type="checkbox"],#secondaryDressingId ul.optionContainer li input[type="checkbox"],
#woundCleansersId ul.optionContainer li input[type="checkbox"],#skinprotectantId ul.optionContainer li input[type="checkbox"],
#compressionId ul.optionContainer li input[type="checkbox"]{
  margin-left: -25px !important;
}

#primaryDressingId ul.optionContainer li:hover p,#secondaryDressingId ul.optionContainer li:hover p,
#woundCleansersId ul.optionContainer li:hover p,#skinprotectantId ul.optionContainer li:hover p,
#compressionId ul.optionContainer li:hover p{
  color:#fff !important;
}

#primaryDressingId .search-wrapper span.chip, #secondaryDressingId .search-wrapper span.chip, #compressionId .search-wrapper span.chip,
#woundCleansersId .search-wrapper span.chip, #skinprotectantId .search-wrapper span.chip
{
  display: block !important;
}

#woundType .optionContainer li{
   padding-left: 45px !important;
   
}

#woundType .optionContainer li input[type="checkbox"] {
  margin-left: -25px !important;
}
/* 
.searchBox{
  position:relative
}

.searchBox:before{
  content: 'Select' !important;
  position:absolute;
  top: 0;
  left: 0;
} */
/* #matCodeMul .optionContainer{
  margin-top: 40px;

} */

/* #matCodeMul .search-wrapper{
    position: absolute;
} */

 #matCodeMul .search-wrapper {
  background: white;
  border: 1px solid #c1c7d0 !important;
} 
#matCodeMul .search-wrapper span.chip {
  display: inline-block !important;
  white-space: pre-wrap;
  position: relative;
  padding-right: 20px !important;
  border-radius: 1px !important;
}
/* #matCodeMul .icon_cancel {
  position: absolute !important;
  right: 0 !important;
} */
#matCodeMul .search-wrapper input[type="text"] {
  width: 100% !important;
  font-size: 1rem;
}
#matCodeMul input{
  background: white;
  }
  #matCodeMul .multiselect-container input[type="text"] {
    position: relative;
    text-align: left;
    width: 100%;
    /* border: 1px solid #c1c7d0; */
    
  }

  #materialNumber .search-wrapper {
    border:1px solid #cccccc !important;
  }