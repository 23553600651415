@import "../../../styles/standard.module.scss";

.image_siz {
  // width: 340px; 
  // height: 440px;
  // object-fit: fill;
    width: 100%;
    max-width: 100%;
    object-fit: cover;   
}
.btn_scondary,
.btn_scondary:hover,
.btn_scondary:active {
  width: 100px;
  background-color: $Body-bg !important;
  border: solid 1px $tertiary-text !important;
  color: $PrimaryColor-text !important;
  border-radius: 2px;
  font-family: $secondary-font-regular;
  font-size: $Typography-2;
}
.btn_primary,
.btn_primary:hover,
.btn_primary:active {
  width: 100px;
  background-color: $PrimaryButton-bg !important;
  border: solid 1px $PrimaryButton-bg !important;
  color: $Plain-bg !important;
  border-radius: 2px;
  font-size: $Typography-2;
  margin-right: 16px;
}
.notes_conatiner {
  padding: 21px 21px 28px;
}
.notes_title {
  color: $PrimaryColor-text;
  font-family: $secondary-font-bold;
}
.notes_sub_title {
  font-size: 13px;
  color: $PrimaryColor-text;
}
.textarea_box,
.textarea_box:focus {
  width: 100% !important;
  border: 1px solid $border-color;
  outline: none;
  padding: 10px;
}
.notes_board {
  border: solid 1px $tertiary-text;
  padding: 10px;
  margin-top: 20px;
  max-height: 270px;
  overflow: auto;
}
.post_details {
  color: #767676;
  font-size: $Typography-3;
  margin-bottom: 5px;
}
.notes_text {
  color: $PrimaryColor-text;
  font-family: $secondary-font-regular;
  padding-bottom: 10px;
}
.entered_notes:not(:last-child) {
  margin-bottom: 10px;
  border-bottom: 1px solid $tab-border-bottom;
}
.creater_name {
  text-transform: capitalize;
}
.notes_container {
  border: 1px solid $border-color;
  max-height: 270px;
  overflow: auto;
  padding: 22px 12px 12px;
  margin-top: 20px;
}



//ZoomIN/Out 

.iconListEdit{
  position: absolute;
  z-index: 999;
  opacity: 1;
  top: 11px;
  left: 35px;
  transform: scale(1.2);
  cursor: pointer;
  margin-top: -7px;
  margin-right: 6px;
  background: #000000e0;
  width: 356px;
  padding: 8px 0;
}


.iconHover:hover{
  color: #999999 !important;
}

.patient_wound {
  max-width: 100%!important;
  overflow: hidden;
  transform-origin: 0px 0px;
  cursor: grab;
}