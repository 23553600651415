@import "../../../styles/standard.module.scss";

.tab_content_top{
  border-bottom: solid 1px #a9a9a9;
  margin-bottom: 15px;
  margin-top: -15px;
}
.tab_content_hdg{
  padding: 24px 24px 16px;
  margin-bottom: 0;
  font-family: $secondary-font-regular;
  position: relative;
}

.basic_info {
  padding: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.basic_info div {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
}

.basic_info_form {
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.basic_info_form div {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
  display: flex;
  align-items: center;
}
.subheading_2 {
  font-family: $primary-font-regular;
  font-size: 21px;
  color: $PrimaryColor-text;
  margin-bottom: 0;
}

.single_product_block {
  padding: 0 24px;
}
.patient_content {
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.moreinfo_content,
.patient_content {
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.moreinfo_content .col_spec,
.patient_content .col_spec {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
  display: flex;
  flex-wrap: wrap;
}

.patient_content .basic_input,
.moreinfo_content .basic_input {
  width: 100%;
  height: 40px;
  padding: 0 5px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
}

.patient_content .select_wrapper,
.moreinfo_content .select_wrapper {
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
  height: 40px;
}

.patient_content .select_wrapper_role,
.moreinfo_content .select_wrapper_role {
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
  height: 40px;
  margin-left: -25px;
}

.patient_content .basic_select,
.moreinfo_content .basic_select {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  appearance: none;
  -webkit-appearance: none;
  outline: 0;
  background: 0;
  border: 0;
}
select::-ms-expand {
  display: none;
}

.label_spec {
  margin-bottom: 0;
  font-family: $secondary-font-bold;
}

/* Below for error message alignment */
// .label_spec ~ span {
//     margin-left: 50%;
// }


.content_spec {
  font-family: $secondary-font-regular;
  color: $PrimaryColor-text;
  display: inline-block;
}
.basic_input {
  width: 250px;
  height: 40px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
}
.small_input {
  width: 68px;
  height: 40px;
  padding-left: 7px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
}
.medium_input {
  width: 100px;
  height: 40px;
  padding-left: 7px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
}

.basic_select {
  width: 250px;
  height: 40px;
  padding: 0 10px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
}

.btn_custom {
  height: 50px;
  border-radius: 2px;
  font-family: $secondary-font-regular;
  background-color: $PrimaryButton-bg;
  font-size: 16px;
}
.btn_small,
.btn_small:hover {
  @include btn_primary;
  margin-right: 32px;
}
.btn_small_cancel,
.btn_small_cancel:hover,
.btn_small_cancel:active {
  @include btn_secondary;
}
.admission_upper {
  position: relative;
}
.admission_fix {
  position: absolute;
  top: 0;
  right: -7px;
}
.admission_date {
  padding: 7px;
  border: 1px solid $adm-color;
  width: 100px;
}
.select_wrapper {
  background: url("../../../static/images/down-arrow.png") right 10px center
    no-repeat;
}
.select_wrapper_role {
    background: url("../../../static/images/down-arrow.png") right 10px center
      no-repeat;
  }

.tab_edit {
  color: $PrimaryColor-text;
  font-family: $secondary-font-regular;
  font-size: 14px;
  position: absolute;
  right: 24px;
  top: 65px;
  z-index: 1000;
}

.tab_edit a,
.tab_edit a:hover {
  color: $PrimaryColor-text;
}
.general_error {
  margin-bottom: 20px;
  color: #fff;
  border-radius: 3px;
  padding: 5px 15px;
  display: inline-block;
  background-color: $Error;
}
.admission_time_fix {
  margin-left: 5px;
}
.basic_input.disabled {
  background-color: #dbdbdb !important;
}

.basic_select.disabled {
  background-color: #dbdbdb !important;
}
.hidden {
  display: none !important;
}
.int_str {
  background: #dbdbdb;
  border: 1px solid #c1c7d0;
  border-radius: 2px;
  padding: 7px 5px !important;
  color: #6b6b6b;
}

/*Adding Checkbox remove_icon */

.input_icons {
  padding-left: 0!important;
  padding-right: 0!important;
}

.x_icon {
  position: absolute;
  right: 22%;
  top: 8px;
  cursor: pointer;
}

.excla_icon{
  position: absolute;
  left: -18px;
  top: 0px;
  cursor: pointer;
  font-size: 24px;
}

.tooltip {
  // position: relative;
  display: inline-block; 
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 105px;
  background-color: white;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 7px 0;
  position: absolute;
  z-index: 1;
  font-size: 12px;
  font-family: $secondary-font-regular;
  font-weight: 500;
  letter-spacing: 0.01em;
  border: 1px solid #4f4f4f;
  right: 285%;
  top: -85%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}



.close_icon {  
  cursor: pointer;
  display: inline-flex;
  // margin-top: -2px;
  // padding-right: 15px;
  margin-top: 4px;
  padding-left: 7px;
  padding-right: 7px;
}


//added new

.vertical_Code {
  border-left: solid 2px #636363;
    margin-left: -25px;
    margin-top: 5px;
    height: 30px;
}

.close_icon_list {
  cursor: pointer;
  display: inline-flex;  
  margin-top: -2px;
  // padding-left: 4px;
  padding-right: 7px;
}

/*Toggle button Style*/
.toggle_shells{
  // margin-left: 35px;
  padding-left: 55px;
}
.toggle_switch {
  transform: scale(1.4, 1.1);
  // margin-left: 16px;
}
.toggle_switch_place{
    margin-left: -55px;
}

/*Error Message Facilities*/
.error_CustNum{
  display: inline-flex;
  margin-left: 25%;
  margin-top: -40px;
}

.error_message {
  display: block;
  color: #de350b;
  font-family: $secondary-font-regular;
  font-size: 14px;
  margin-left: -35px;
  margin-top: -10px;
}
.error_message_IncExc {
  display: block;
  color: #de350b;
  font-family: $secondary-font-regular;
  font-size: 14px;
  // margin-left: 0px;
  margin-top: -10px;
}

/*Error Message Default*/
.error_CustDefu{
  margin-right: 27%;
  margin-top: -35px;
  font-size: 15px!important;
  font-weight: 500;
}


/*CustomerNumber Suggestions*/
.search_drop{  
  top: -21px;
  left: 35%;
  position: absolute;
  z-index: 999 !important;
  background-color: #ffffff;
  width: 46%;
}

.search_close_box {
  border: 1px solid $border-color;
  padding: 10px 5px;
  max-height: 250px;
  overflow-y: auto;
  
}
.search_close_box p {
  margin-bottom: -1px;
  padding: 6px;
  cursor: pointer !important;
}



















//dropdown
.basic_input_domain{
    width: 100%;
    height: 40px;
    padding: 0 5px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
    margin-left: -32px
  }
  .basic_input_email{
    width: 100%;
    height: 80px;
    padding: 0 5px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
  }
  //Specific Input
.basic_input_sepcific {
  width: 100%;
  height: 40px;
  // padding: 0 5px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
  padding: 0;
}
  
  .basic_select {
    width: 215px;
    height: 40px;
    padding: 0 10px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
    // margin-left: -25px;
  }
  .basic_select_role {
    width: 100%;
    height: 40px;
    padding: 0 10px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
    margin-left: -21px;
  }
  .vertical_Code {
    border-left: solid 1px #007bff;
    margin-top: -5px;
    height: 48px;
    margin-left: -35px;
    border-left-style: solid;
  }
  .vertical_Code_role {
    margin-top: -4px;
    height: 48px;
    border-left: solid 1px #007bff;
    border-left-style: solid;
    margin-left: -50px;
  }
  
  /*CustomerNumber Suggestions*/
  .search_drop{  
    top: -21px;
    left: 26%;
    position: absolute;
    z-index: 999 !important;
    background-color: #ffffff;
    width: 30%;
    margin-left: 2px;
  }
  
  .search_close_box {
    border: 1px solid $border-color;
    padding: 10px 5px;
    max-height: 250px;
    overflow-y: auto;
    
  }
  .search_close_box p {
    margin-bottom: -1px;
    padding: 6px;
    cursor: pointer !important;
  }
  .x_icon {
    position: absolute;
    right: 46%;
    top: 8px;
    cursor: pointer;
  }


  .search_drop_domain{
    top: -21px;
    left: 41%;
    position: absolute;
    z-index: 999 !important;
    background-color: #ffffff;
    width: 55%;
    padding-left: 1px;
  }
  
  .disabled_Column {
    background-color: #dbdbdb!important;
  }
  
  
  
  
  // For SSO
  .label_spec_Sso{
      margin-bottom: 0;
      font-family: $secondary-font-bold; 
      float: left;
  }
  .astrick{
      color: #de350b
  }
  
  .custom_control_input__Sso {
      position: absolute;
      left: 0;
      z-index: -1;
      width: 16px;
      height: 16px;
      opacity: 0;
    }
    .col_form_label {
      padding-top: calc(-1.625rem + 1px)!important;
    }
    .custom_control_input__Sso.is_invalid ~ .custom_control_label_Sso::before,
    .was_validated .custom_control_input__Sso:invalid ~ .custom_control_label_Sso::before {
      border-color: $PrimaryColor-text;
    }
    .custom_control_input__Sso.is_valid ~ .custom_control_label_Sso,
    .was_validated .custom_control_input__Sso:valid ~ .custom_control_label_Sso {
      color: $Error;
    }
    .custom_control_input__Sso.is_valid:checked ~ .custom_control_label_Sso::before,
    .was_validated
      .custom_control_input__Sso:valid:checked
      ~ .custom_control_label_Sso::before {
      border-color: $border-box;
      background-color: $CheckBox-bg;
    }
    .custom_control_input__Sso:checked ~ .custom_control_label_Sso::before {
      color: $Plain-text;
      border-color: $border-box;
      background-color: $CheckBox-bg;
      border-radius: 0;
      width: 16px;
      height: 16px;
      top: 11px;
    }
  
    .custom_control_label_Sso {
      position: relative;
      margin-top: 5px;
      margin-bottom: 0;
      vertical-align: top;
      font-family: $secondary-font-regular;
      font-size: 14px;
      color: $PrimaryColor-text;
      // line-height: normal;
      line-height: 1.5;
      margin-bottom: 0;
      font-size: inherit;
    }
    .custom_control_label_Sso::after {
      position: absolute;
      top: 11px;
      left: -1.5rem;
      display: block;
      width: 16px;
      height: 16px;
      content: "";
    }
    .custom_control_label_Sso::before {
      border-radius: 50% !important;
      top: 11px;
    }
    
    .custom_control_inline {
      height: 22px;
    }
    
    .custom_control_inline .custom_control_label_Sso {
      font-family: $secondary-font-bold;
      color: $text-color;
      font-size: 14px;
    }
    
    .hide_outline:focus {
      border: 0px !important;
      outline: 0 !important;
    }
    /* .custom_control_label_Sso_t0::before {
        border-radius: 50% !important;
        top: 0px;
    }
    .custom_control_label_Sso_t0::after {
        border-radius: 50% !important;
        top: 0px;
    } */
    .custom_checkbox .custom_control_label_Sso::before {
      border-radius: 0 !important;
    }
  
    .btn_cancel{
      background-color: #fafafa !important;
      border: 1px solid #cccccc !important;
      color: #333333 !important;
      border-radius: 2px;
    }
  
    .heading_Domain{
      padding: 10px 0px;
      border-bottom: 1px solid #0052cc;
      width: 100%;
    }
  
    /*Toggle button Style*/
  .toggle_shells{
    // margin-left: 35px;
    padding-left: 55px;
  }
  .toggle_switch {
    transform: scale(1.4, 1.1);
    // margin-left: 16px;
  }



  //multiple Email
  .add_new_link{
    font-family: $secondary-font-bold;
    font-size:16px;
    color:#0052cc;
    padding-left: 10px;
    float:right;
    cursor: pointer;
}
.add_new_link img{
    margin-right: 5px;
    vertical-align: middle;
}
.search_terms{
    margin:20px 0;
    width:100%;
    align-items: center;
}
.search_terms_excl{
  margin: 10px 0 30px 0;
  width:100%;
  align-items: center;
}

.col_spec_unBorder{
  border: none!important;
  font-size: 14px!important;
}
.main_list{
    float:left;
    // margin-top: 20px;
    max-width: 382px;
    // width: auto;
    height: auto;
    border: 1px solid silver;
    margin-left: -11px;
    padding-bottom: 25px;
    min-height: 50px;
}
.main_list_disabled{
  background-color: #dbdbdb !important; 
  min-height: 50px; 
  margin-left: -12px;
  max-width: 382px;
}
.search_list ul{
    margin:0;
    padding-left: 0;   
}

.search_list ul li{
  // margin:0 20px 20px 0;    
  list-style-type: none;
  font-family: $secondary-font-bold;
  font-size:14px;
  background:#e5e5e5;
  // padding:5px 10px 5px 15px;
  padding: 4px 10px 5px 10px;
  border-radius:21px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: -13px;
  margin-top: 9px;
  margin-right: 18px;
  &.input_li{
      height:32px;
  }
}

.search_list ul li img{
  padding-left: 10px;
  cursor: pointer;
}


// .input_li{
// background-color:#fff !important;
// }

// .input_li ul{
//   margin:0;
//   padding-left: 0;   
// }

.input_li{
  // margin:0 20px 20px 0;    
  list-style-type: none;
  font-family: $secondary-font-bold;
  font-size:12px;
  background-color:#fff !important;
  // padding:5px 10px 5px 15px;
  border-radius:21px;
  width: 100%;
  float: left;
  // margin-left: -11px;
  margin-top: 10px;
  height: 12px
}

.input_li img{
  padding-left: 10px!important;
  padding-top: 17px;
  display: none;
}






.and_or_buttons{
  display: flex;
  font-size: 18px;
  font-weight: bold;
  align-items: center;
  padding-right: 5px;
}

.and_or_block{
  input[type=radio]{
    accent-color: #007bff!important;
  }
}

// .and_or_block{
//   // input[type=radio]:disabled {
//   //   accent-color: #dbdbdb!important;
//   //   background-color:  #dbdbdb!important;
//   //   color:  #dbdbdb!important;
//   // }
//   .radio[disabled] {
//     background-color: #5e5e5e;
// }
// }

.and_or_radio{
  width: 24px!important;
  height: 18px!important;
  margin-top: -6px!important;
  margin-right: 18px!important;
}