@import "../../../styles/standard.module.scss";
@import url('https://fonts.googleapis.com/css?family=glober+Sans');

/* .left_bg {
  background-color: $DarkBar-bg;
  padding: 0;
  display: inline-flex;
  flex-direction: column;
  vertical-align: top;
  width:120px;
  height: 1100px;
  z-index:1040;
} */


@media only screen and (max-width: 998px) {
  .first_left_menu{
    height: 100vh;
    // position: absolute;
    top: 0;
    padding-top: 10px;
    // margin-left: -15px;
    width: 270px;
    background-color: #fff;
    flex-direction: column;
    vertical-align: top;
    transition: width 0.5s!important;
    transition-timing-function: ease-in-out!important;
    overflow-x: hidden;
    overflow-y: auto;
    visibility: visible;
    box-shadow: 5px 2px 15px 0 rgba(45, 45, 45, 0.2); 
  }
}

@media only screen and (min-width: 999px) and (max-width: 1200px) {
  .first_left_menu{
    height: 100vh;
    // position: absolute;
    top: 0;
    padding-top: 10px;
    // margin-left: -5px;
    width: 270px;
    background-color: #fff;
    flex-direction: column;
    vertical-align: top;
    transition: width 0.5s!important;
    transition-timing-function: ease-in-out!important;
    overflow-x: hidden;
    overflow-y: auto;
    visibility: visible;
    box-shadow: 5px 2px 15px 0 rgba(45, 45, 45, 0.2); 
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1350px) {
  .first_left_menu{
    height: 100vh;
    // position: absolute;
    top: 0;
    padding-top: 10px;
    // margin-left: -1px;
    width: 275px;
    background-color: #fff;
    flex-direction: column;
    vertical-align: top;
    transition: width 0.5s!important;
    transition-timing-function: ease-in-out!important;
    overflow-x: hidden;
    overflow-y: auto;
    visibility: visible;
    box-shadow: 5px 2px 15px 0 rgba(45, 45, 45, 0.2); 
  }
}

@media only screen and (min-width: 1351px) and (max-width: 1539px) {
  .first_left_menu{
    height: 100vh;
    // position: absolute;
    top: 0;
    padding-top: 10px;
    // margin-left: -1px;
    width: 285px;
    background-color: #fff;
    flex-direction: column;
    vertical-align: top;
    transition: width 0.5s!important;
    transition-timing-function: ease-in-out!important;
    overflow-x: hidden;
    overflow-y: auto;
    visibility: visible;
    box-shadow: 5px 2px 15px 0 rgba(45, 45, 45, 0.2); 
  }
}
@media only screen and (min-width: 1540px) and (max-width: 1705px) {
  .first_left_menu{
    height: 100vh;
    // position: absolute;
    top: 0;
    padding-top: 10px;
    // margin-left: -102px;
    width: 295px;
    background-color: #fff;
    flex-direction: column;
    vertical-align: top;
    transition: width 0.5s!important;
    transition-timing-function: ease-in-out!important;
    overflow-x: hidden;
    overflow-y: auto;
    visibility: visible;
    box-shadow: 5px 2px 15px 0 rgba(45, 45, 45, 0.2); 
  }
}

@media only screen and (min-width: 1706px) and (max-width: 1894px) {
  .first_left_menu{
    height: 100vh;
    // position: absolute;
    top: 0;
    padding-top: 10px;
    // margin-left: -205px;
    width: 300px;
    background-color: #fff;
    flex-direction: column;
    vertical-align: top;
    transition: width 0.5s!important;
    transition-timing-function: ease-in-out!important;
    overflow-x: hidden;
    overflow-y: auto;
    visibility: visible;
    box-shadow: 5px 2px 15px 0 rgba(45, 45, 45, 0.2); 
  }
}

@media only screen and (min-width: 1895px) {
  .first_left_menu{
    height: 100vh;
    // position: absolute;
    top: 0;
    padding-top: 10px;
    // margin-left: -238px;
    width: 320px;
    background-color: #fff;
    flex-direction: column;
    vertical-align: top;
    transition: width 0.5s!important;
    transition-timing-function: ease-in-out!important;
    overflow-x: hidden;
    overflow-y: auto;
    visibility: visible;
    box-shadow: 5px 2px 15px 0 rgba(45, 45, 45, 0.2); 
  }
}




@media only screen and (max-width: 1200px) {
  .left_admin_bg {
    background-color: #ffffff;
    display: inline-flex;
    flex-direction: column;
    vertical-align: top;
    width: 0px;
    transition: width 0.5s;
    transition-timing-function: ease-out;
    position: absolute;
    left: 255px;
    bottom: 0;
    height: 50vh;
    // padding-top: 10px;
    margin: 0% auto 50%;
    box-shadow: 11px 2px 15px 0 rgba(45, 45, 45, 0.2);  
  }
}
@media only screen and (min-width: 1201px) and (max-width: 1350px) {
  .left_admin_bg {
    background-color: #ffffff;
    display: inline-flex;
    flex-direction: column;
    vertical-align: top;
    width: 0px;
    transition: width 0.5s;
    transition-timing-function: ease-out;
    position: absolute;
    left: 270px;
    bottom: 0;
    height: 50vh;
    margin: 0% auto 55%;
    box-shadow: 11px 2px 15px 0 rgba(45, 45, 45, 0.2);  
  }
}

@media only screen and (min-width: 1351px) and (max-width: 1539px) {
  .left_admin_bg {
    background-color: #ffffff;
    display: inline-flex;
    flex-direction: column;
    vertical-align: top;
    width: 0px;
    transition: width 0.5s;
    transition-timing-function: ease-out;
    position: absolute;
    left: 280px;
    bottom: 0;
    height: 50vh;
    margin: 0% auto 70%;
    box-shadow: 11px 2px 15px 0 rgba(45, 45, 45, 0.2);  
  }
}

@media only screen and (min-width: 1540px) and (max-width: 1705px)  {
  .left_admin_bg {
    padding-top: 10px;
    background-color: #ffffff;
    display: inline-flex;
    flex-direction: column;
    vertical-align: top;
    width: 0px;
    transition: width 0.5s;
    transition-timing-function: ease-out;
    position: absolute;
    left: 295px;
    bottom: 0;
    height: 46vh;
    margin: 0% auto 100%;
    box-shadow: 11px 2px 15px 0 rgba(45, 45, 45, 0.2);  
  }
}

@media only screen and (min-width: 1706px) and (max-width: 1894px) {
  .left_admin_bg {
    padding-top: 10px;
    background-color: #ffffff;
    display: inline-flex;
    flex-direction: column;
    vertical-align: top;
    width: 0px;
    transition: width 0.5s;
    transition-timing-function: ease-out;
    position: absolute;
    left: 295px;
    bottom: 0;
    height: 44vh;
    // margin: 0% auto 100%;
    top: 38%;
    box-shadow: 11px 2px 15px 0 rgba(45, 45, 45, 0.2);  
  }
}

@media only screen and (min-width: 1895px) {
  .left_admin_bg {
    padding-top: 12px;
    background-color: #ffffff;
    display: inline-flex;
    flex-direction: column;
    vertical-align: top;
    width: 0px;
    transition: width 0.5s;
    transition-timing-function: ease-out;
    position: absolute;
    left: 305px;
    bottom: 0;
    top: 35%;
    height: 40vh;
    // margin: 0% auto 100%;
    top: 27%;
    box-shadow: 11px 2px 15px 0 rgba(45, 45, 45, 0.2);  
  }
}

// .left_admin_bg_active {
//   @include response-above(lg) {
//     left: 295px!important;
//     width: 620px;
//     transition: width 0.5s;
//     transition-timing-function: ease-out;
//     overflow-x: hidden;
//     overflow-y: auto;
//   }
//   @include response-above(sm) {
//     left: 270px!important;
//     width: 620px;
//     transition: width 0.5s;
//     transition-timing-function: ease-out;
//     overflow-x: hidden;
//     overflow-y: auto;
//   }
// }

@media only screen and (max-width: 998px) {
  .left_admin_bg_active {
    left: 250px;
    width: 620px;
    transition: width 0.5s;
    transition-timing-function: ease-out;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
@media only screen and (min-width: 999px) and (max-width: 1200px) {
  .left_admin_bg_active {
    left: 255px;
    width: 620px;
    transition: width 0.5s;
    transition-timing-function: ease-out;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
@media only screen and (min-width: 1201px) and (max-width: 1350px) {
  .left_admin_bg_active {
    left: 270px;
    width: 620px;
    transition: width 0.5s;
    transition-timing-function: ease-out;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 1351px) and (max-width: 1539px) {
  .left_admin_bg_active {
    left: 280px;
    width: 620px;
    transition: width 0.5s;
    transition-timing-function: ease-out;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 1540px) and (max-width: 1705px) {
  .left_admin_bg_active {
    left: 295px;
    width: 620px;
    transition: width 0.5s;
    transition-timing-function: ease-out;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 1706px) and (max-width: 1894px) {
  .left_admin_bg_active {
    left: 295px;
    width: 620px;
    transition: width 0.5s;
    transition-timing-function: ease-out;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 1895px) {
  .left_admin_bg_active {
    left: 305px;
    width: 620px;
    transition: width 0.5s;
    transition-timing-function: ease-out;
    overflow-x: hidden;
    overflow-y: auto;
  }
}



.dummy_element {
  position: absolute;
  left: 0px;
  height: 100%;
  background-color: red;
  width: 10px;
}
.admin_logo {
  padding-top: 30px;
  text-align: center;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

    .menu_logo {
      padding-top: 5px;
      text-align: center;
      padding-bottom: 30px;
      padding-left: 20px;
      padding-right: 20px;
      cursor: pointer;      
      img {
          width: 28px;
      }
      p{
        font-size: 11px;
        padding-top: 6px;
        color: #133d7d;
        font-weight: 600;
      }
    }

    .LogoImage_Second_slide {
      padding-top: 30px;
      
      img {
        width: 82px;
        margin-left: 18px;
      }
    }
    .CloseIcon_Second_slide{
      padding-top: 20px;
      cursor: pointer;
      transition: width 0.5s;
      transition-timing-function: ease-out;
      img {
        width: 18px;
        margin-right: 8px;
      }
    }
    .main_list_icon{
      // position: fixed;
      margin-top: 25px;
      ul{
        list-style: none;
        // margin-left: -40px;
        display: contents;
      }      
    }
    .main_list_icon_admin{
      // margin-left: -6px;
      // margin-top: -12px;
    }
    


.left_admin_bg_active .admin_menu {
  padding-top: 0;
  padding-bottom: 0;

  width: 205px;
  overflow: hidden;
}
.admin_menu {
  padding-top: 0;
  padding-bottom: 0;

  width: 0px;
  overflow: hidden;
  transition: width 0.5s;
  transition-timing-function: ease-out;
}
.admin_menu:last-child {
  border-bottom: 0;
}
.admin_menu_hdg {
  color: $Plain-bg;
  font-size: 16px;
  font-family: $secondary-font-bold;
  padding-left: 17px;
  width: 205px;
  height: 30px;  
  line-height: 30px;
  overflow: hidden;
}
.admin_menu > ul {
  padding-left: 0;
  margin-bottom: 0;
  width: 205px;
  overflow: hidden;
}
.admin_menu > ul li {
  list-style: none;
  
}
.admin_menu_list a {
  // color: $menu-color;
  // color: #002d72;
  // font-size: 14px;
  // font-family: $secondary-font-regular;
  padding-left: 17px;  
  height: 36px;
  line-height: 35px;
  font-family: 'globerregular';
  font-weight: 500;
  font-size: 17px;
  color: rgb(94, 108, 132);
}
.admin_menu_list a:hover {
  // color: $Astrik;
  // color: #002d72;
  text-decoration: none;
  background-color: #deebff;
  // font-family: 'globerregular';
  // font-weight: 500;
  // font-size: 16px;
  // color: rgb(94, 108, 132);
}

// .admin_left {
//   // width: 100%;
//   // padding-bottom: 100px;
//   // // border-bottom: solid 3px $DarkBar-bg;
//   // height: 100vh;

//   // padding-bottom: 100px;
//     // height: 100vh;
//     // position: absolute;
//     // top: 0;
//     // left: -42px;
//     // width: 0px;
//     // // display: inline-flex;
//     // background-color: #fff;
//     // flex-direction: column;
//     // vertical-align: top;
//     // transition: width 0.5s!important;
//     // // transition-delay: 5s;
//     // transition-timing-function: ease-in-out!important;
//     // visibility: hidden;
// //     -moz-animation: showHide 5s ease-in alternate infinite; /* Firefox */
// //         -webkit-animation: showHide 5s ease-in alternate infinite; /* Safari and Chrome */
// //         -ms-animation: showHide 5s ease-in alternate infinite; /* IE10 */
// //         -o-animation: showHide 5s ease-in alternate infinite; /* Opera */
// //         animation: showHide 5s ease-in alternate infinite;
// }

.fade_in_left_move{
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

.fade_in_right_move{
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@keyframes slideInLeft{from{transform:translate3d(-100%,0,0);visibility:visible}to{transform:translate3d(0,0,0)}}
@-webkit-keyframes slideInLeft{from{transform:translate3d(-100%,0,0);visibility:visible}to{transform:translate3d(0,0,0)}}

@keyframes slideInRight{from{transform:translate3d(100%,0,0);visibility:visible}to{transform:translate3d(0,0,0)}}
@-webkit-keyframes slideInRight{from{transform:translate3d(100%,0,0);visibility:visible}to{transform:translate3d(0,0,0)}}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%,0,0);
    transform: translate3d(100%,0,0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%,0,0);
    transform: translate3d(100%,0,0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.horizontalRow{
  width: 140px;
  margin-top: -10px;
  margin-left: 11px;
}
.admin_left > ul {
  padding-left: 0;
  list-style: none;
}

/* .admin_left ul li:last-child{
  margin-top: 250px;
} */
.admin_fixed {
  position: fixed;
  width: 120px;
  bottom: 0;
}
.admin_submenu{
  position: absolute;
  // top:50px;
  overflow-y:auto;
  display: inline-flex;
}

// .admin_icon{
//   position: fixed;
//   bottom: 0;
// }
.admin_icon > a {
  width: 100%;
  height: auto;
  list-style: none;
  text-align: left;
  padding: 20px 10px 20px 15px;
  margin: 0 auto;
  display: inline-flex;
  color: $tertiary-text;
  border-left: 5px solid transparent;
}
.admin_icon > a:hover{
  width: 100%;
  height: auto;
  background-color: #deebff;
  color: $menu-color;
}
.active{
  width: 100%;
  height: auto;
  background-color: #deebff;
  color: $menu-color;
}

.admin_icon{
  img{
    padding-left: 0!important;
    margin-left: 0!important;
    width: 20px;
    margin-top: -5px;
  }
}
.admin_menu_text {
  display: block;
  font-size: 17px;
  padding: 1px 15px 0px;
  color: #002d72;
  font-weight: 500;
  font-family: 'globerregular';
}
.logout_admin {
  width: 100%;
  margin-bottom: 30px;
}
.logout_admin a {
  width: 100%;
  height: auto;
  text-align: center;
  padding: 10px 0;
  margin: 0 auto;
  display: block;
  color: $Plain-bg;
}
.left_menu {
  width: auto;
}

.left_menu::-webkit-scrollbar {
  width: 0px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

@media only screen and (min-width: 768px) {
  .admin_menu_hdg {
    // color: $Plain-bg;
    color: #133d7d;
    font-size: 14px;
    padding-left: 17px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .admin_menu ul li {
    padding-left: 0;
    display: flex;
  }
}
@media only screen and (min-width: 1200px) {
  .admin_menu ul li a{
    padding-left: 17px;
    padding-right: 17px;
    width:100%;
  }
  .admin_menu_hdg {
    // color: $Plain-bg;
    color: #002d72;
    font-size: 19px;
    // font-family: $secondary-font-bold;
    padding-left: 17px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: 'globerregular';
    font-weight: 600;
  }
  .left_menu {
    position: relative;
    // box-shadow: 5px 2px 8px 0 rgba(45, 45, 45, 0.2);
    background-color: #fafafa;
    height: 100vh;
  }
}


.btn_cont{
  width: 160px !important;
  height: 42px;
  float: left;
   border-radius: 5px !important;
   padding-top: 8px;
}
.btn_signout{
  background-color: #ffff!important;
  color: #0052cc;
  font-size: 16px;
  font-family: $secondary-font-bold;
  width: 160px !important;
  height: 42px;
  float: left;
  border-radius: 5px !important;
}

.btn_signout,
.btn_signout:hover,
.btn_signout:active {
  font-family: $secondary-font-regular;
  border: 1px solid #0052cc !important;
  border-radius: 2px;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  color: #0052cc !important;
  font-weight: 900;
}
.custom_heading{
  background-color: #0052cc !important;
}
.custom_heading h5,.custom_heading h5 button,.custom_heading  button ,.custom_heading .close{
   color: #fff !important;
}
.con_sign_img{
  width: 50px;
  height: 55px;
  margin-right: 10px;
}
.con_sign_content{
  font-size: 19px;
  color: gray;
  font-family:$secondary-font-regular;
}
.con_sign_time{
  font-size: 42px;
  color: #b3b3b3;
  font-family: $secondary-font-regular;
}

.cont_sign_main{
  display: inline-block;
  text-align: justify;
}
.custom_body
{
  overflow: hidden !important;
}
.custom_content{
  border-radius: 0.5em !important;
}
.custom_session_modal_size {
  max-width: 512px;
}
.toast_body{
  background: #36b37e;
  color: #ffffff
}
.toast_body > .Toastify__close-button{
  color: rgb(255, 255, 255);
  background-color: #ffffff !important;
  margin-bottom: 10px !important;
  opacity: 0 !important;
}
.down_arrowgif{
  width: 25px;
}