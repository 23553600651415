@import '../../../styles/standard.module.scss';
.success_modal {
  width: 444px;
}
.parent {
  position: relative;
}
.modal_contr {
  padding: 50px 40px 50px 50px;
}
.status_heading {
  font-size: $Heading-1;
  font-family: $primary-font-bold;
  color: #36b37e;
  margin-bottom: 8px;
}
.msg_content {
  font-family: $secondary-font-regular;
  color: $PrimaryColor-text;
}
.continue_btn,
.continue_btn:hover,
.continue_btn:active {
  height: 42px;
  padding: 10px 0;
  border-radius: 2px;
  background-color: $secondary-text;
  font-family: $secondary-font-regular;
  font-size: $Typography-2;
}