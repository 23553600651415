@import '../../../styles/standard.module.scss';

.custom_btn_primary {
  background-color: $PrimaryButton-bg ;
  font-size: $Typography-2;
  padding-top: 10px;
  padding-bottom: 10px;
}

.custom_btn_primary,
.custom_btn_primary:hover,
.custom_btn_primary:focus,
.custom_btn_primary:not(:disabled):not(.disabled):active,
.custom_btn_primary:not(:disabled):not(.disabled):active:focus {
  color: $Plain-bg;
  // border: 0; {ADA purpose it is commented}
  box-shadow: 0px 0px 0px 0 rgba(255, 255, 255, 0);
  background-color: $PrimaryButton-bg;
}

