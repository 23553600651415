@import '../../../styles/standard.module.scss';
.custom_modal_size{
    max-width: 800px;
}
.single_product_block {
    padding: 0 24px;
}
.patient_content{
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.moreinfo_content .col_spec,
.patient_content .col_spec {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
    display: flex;
}
.label_spec {
    margin-bottom: 0;
    font-family: $secondary-font-bold;
    line-height: normal;
}
.patient_content
.basic_input{
    width: 100%;
    height: 40px;
    padding: 0 5px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}
.basic_input.disabled {
    background-color: $btn-disable !important;
}
.btn_small,
.btn_small:hover {
    width: 158px;
    height: 42px;
    font-family: $secondary-font-regular;
    background-color: $PrimaryButton-bg;
    color: $Plain-bg;
    font-size: $Typography-2;
}
.btn_custom {
    height: 42px;
    border-radius: 2px;
    font-family: $secondary-font-regular;
    background-color: $PrimaryButton-bg;
    font-size: $Typography-2;
    line-height: normal;
    padding: 0;
}

.loader_img{
    max-width: 50px;
}