@import '../../../styles/standard.module.scss';
/* vertical tabs */

.vertical_tab{
    width:100%;
    position: relative;
    .react_tabs {   
        display: inline-block;
        margin-right: 10px;
        border-bottom:0;
        border:0;    
        list-style: none;   
        background-color:$vertical-tab-bg;
        padding: 10px 0 0 10px;
    }
    .react_tabs_Intervention {   
        display: inline-block;
        margin-right: 10px;
        border-bottom:0;
        border:0;    
        list-style: none;   
        background-color:$vertical-tab-bg;
        padding: 80px 0 0 10px;
    }
    .react_tabs_list{
        background-color:$Plain-bg;
        padding:10px 0 10px 15px;
        width:280px; 
        font-family: $secondary-font-regular; 
        font-size:16px;
        margin-bottom:10px;
        color:$PrimaryColor-text; 
        opacity: 0.7;    
        margin-right: 10px; 
        cursor: pointer;
        position: relative;
        transition: padding-left 0.4s ease-in;
        img{
            width: 13px;
            height: 13px;
            position: absolute;
            top:50%;
            transform: translateY(-50%);
            right: 15px;
        }
        span{
            position: absolute;
            top:50%;
            transform: translateY(-50%);
            right: 10px;
            font-size: 11px;
            background-color: #ff991f;
            font-weight: normal;
            color: #000;
            padding: 2px 5px;
            text-shadow: 0px 0px 1px #111;
            border-radius: 4px;
        }
    }

    

    .react_tab_pane{
        width:calc(100% - 320px);
        display: inline-block;
        vertical-align: top;
    }
    .react_tab_pane>.active {
        display: block;    
    }
    .react_tab_pane{
        padding: 0 0;
        box-shadow: none;
        background-color: $Plain-bg;
        display: none;
    }
    .react_tabs_list.selected{
        display: inline-block;
        padding:12px 0 12px 15px;
        width: 100%;
        box-shadow: none;
        background-color: $Plain-bg;
        font-family: $secondary-font-bold;   
        opacity: 1; 
        margin-right: 0;
        transition: padding-left 0.3s ease-out;
        span{
            display: none;
        }
        img{
            display: none;
        }
    }
    .react_tab_pane.selected{
        padding: 30px 10px 10px 10px;
        box-shadow: none;
        background-color: $Plain-bg;
        display: inline-block;
    }
    .custom-control-inline{
        display: inline  !important;
    }
}

.intervention_blk{
    position: absolute;
    background: #000;
    width: 300px;
    padding: 10px;
    height: 70px;
    left: 0px;
    z-index: 1;
}
.intervention_blk .select_heading{
    color: $admin-border;
    width: 220px !important;   
    background: #fff url(../../../static/images/down_triangle.svg) right 10px center no-repeat !important;  
    height:50px;
    display: inline-flex;
    line-height: 50px;
    vertical-align: top;
    padding: 0 10px;
    border-radius: 4px;
}
.intervention_blk .new_select_blk{
  width:100% !important;
  background-image: none;
  padding:0;
  border:0;  
}
.intervention_blk .dropdown-menu.show{
    width:100%;
}
.intervention_blk .select_btn{
    width:50px;
    height:50px;
    display: inline-flex;
    margin-left: 10px;
}
.intervene_dropdown p{
    padding:10px;
    margin-bottom: 0;
}
.intervene_dropdown label{
    width: 100%!important;
    font-family: $secondary-font-regular !important;
    font-size:16px !important; 
    display: inline-block;  
    font-weight: bold;
    margin-bottom: 0;
}
.intervene_dropdown strong{
    width: 100%!important;
    font-family: $secondary-font-regular !important;
    font-size:13px !important; 
    display: inline-block;  
}
.intervene_dropdown span{
    width: 100%!important;
    font-family: $secondary-font-regular !important;
    font-size:13px !important;  
    display: block;   
}
.intervene_dropdown{
    top: 47px;
    width: 280px;
    max-height: 275px;
    overflow-y: auto;
    overflow-x: hidden;
}
.intervene_dropdown:hover{
    cursor: pointer;
}
.select_btn img:hover{
    cursor: pointer;
}
.select_heading:hover {
    cursor: pointer;
}
.selectedTick{
    display:block !important;
}
.loader{
    text-align: center;
    padding: 150px 0;
    img{
        max-width: 50px;
        border-radius: 100%;
    }
  }

//Wound Left
  .wound_block {
    margin-bottom: 0px;
    max-width: 500px;
    margin-top: 20px;
    h3 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    img {
        max-width: 250px;
    }
}