@import '../../../styles/standard.module.scss';

.tab_content_top{
    border-bottom: solid 1px #f2f2f2;
    margin-bottom: 15px;
}
.tab_content_hdg{
    padding: 24px 24px 16px;
    margin-bottom: 0;
    font-family: $secondary-font-regular;
    position: relative;
}
.basic_info {
    padding: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.basic_info div {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
}

.basic_info_form {
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.basic_info_form div {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
    display: flex;
    align-items: center;
}
.subheading_2 {
    font-family: $primary-font-regular;
    font-size: 21px;
    color: $PrimaryColor-text;
    margin-bottom: 0;
}

.single_product_block {
    padding: 0 24px;
}
.patient_content {
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width:100%;
}

.moreinfo_content,
.patient_content {
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.moreinfo_content .col_spec,
.patient_content .col_spec {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
    display: flex;
}

.patient_content .basic_input,
.moreinfo_content .basic_input {
    width: 100%;
    height: 40px;
    padding: 0 5px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}

.patient_content .select_wrapper,
.moreinfo_content .select_wrapper {
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
    height:40px;
}

.patient_content .basic_select,
.moreinfo_content .basic_select {
    width: 100%;
    height: 40px;
    padding: 0 10px;
    appearance: none;
    -webkit-appearance: none;
    outline: 0;
    background: 0;
    border: 0;
}
select::-ms-expand {
    display: none;
}

.label_spec {
    margin-bottom: 0;
    font-family: $secondary-font-bold;

}

.content_spec {
    font-family: $secondary-font-regular;
    color: $PrimaryColor-text;
    display: inline-block;

}
.basic_input {
    width: 250px;
    height: 40px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}
.small_input {
    width: 68px;
    height: 40px;
    padding-left: 7px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}

.basic_select {
    width: 250px;
    height: 40px;
    padding: 0 10px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}


.btn_custom {
    height: 50px;
    border-radius: 2px;
    font-family: $secondary-font-regular;
    background-color: $PrimaryButton-bg;
    font-size: 18px;
}
.btn_small,
.btn_small:hover {
    @include btn_primary;
    margin-right: 32px;
}
.btn_small_cancel,
.btn_small_cancel:hover,
.btn_small_cancel:active {
    @include btn_secondary;    
}
.select_wrapper {
    background: url('../../../static/images/down-arrow.png') right 10px center no-repeat;
}
.tab_edit {
    color: $PrimaryColor-text;
    font-family: $secondary-font-regular;
    font-size: 14px;
    position: absolute;
    right: 24px;
    top: 24px;
    z-index:1000;
    cursor: pointer;
}

.tab_edit a,
.tab_edit a:hover {
    color: $PrimaryColor-text;
}

.form_btn{
    width: 100%;
}
.patient_img{
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 100%;
}
.file_hide{
    display: none;
}
.custom_file_upload {
    border: 1px solid $border-color;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    margin-bottom: 0;
}
.custom_control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
}
.custom_control_input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 16px;
    height: 16px;
    opacity: 0;
}
.custom_control_label {
    position: relative;
    margin-top: 5px;
    margin-bottom: 0;
    vertical-align: top;
    font-family:$secondary-font-regular;
    font-size: 14px;
    color: $PrimaryColor-text;
    line-height: normal;
}
.custom_control_input.is_invalid~.custom_control_label::before,
.was_validated .custom_control_input:invalid~.custom_control_label::before {
    border-color: $PrimaryColor-text;
}
.custom_control_input.is_valid~.custom_control_label,
.was_validated .custom_control_input:valid~.custom_control_label {
    color: $Error;
}
.custom_control_input.is_valid:checked~.custom_control_label::before,
.was_validated .custom_control_input:valid:checked~.custom_control_label::before {
    border-color: $border-box;
    background-color: $CheckBox-bg;
}
.custom_control_label::after {
    position: absolute;
    top: 11px;
    left: -1.5rem;
    display: block;
    width: 16px;
    height: 16px;
    content: "";
}
.custom_control_label::before {
    border-radius: 50% !important;
    top: 11px;
}
.custom_control_inline {
    height: 22px;
}

.custom_control_inline .custom_control_label {
    font-family: $secondary-font-bold;
    color: $text-color;
    font-size: 14px;
}
.custom_control_input:checked~.custom_control_label::before {
    color: $Plain-text;
    border-color: $border-box;
    background-color: $CheckBox-bg;
    border-radius: 0;
    width: 16px;
    height: 16px;
    top: 11px;
} 
.neg_margin{
    margin-top: -33px !important;
} 

.profile_label_fix{
    margin-top: 15px;
}
.tab_edit img{
    max-width: 20px;
    margin-right:3px;
}
.tab_edit_disable{
    pointer-events: none;
    cursor:not-allowed;
}
.basic_input_column{
    display: flex;
}



.organizaion_1, .organizaion_2, .organization_top {
    margin-bottom: 10px;
    margin-left: 9px;
}

.heading_bold {
    font-family: $secondary-font-bold;
    font-size: 16px;
    color: $PrimaryColor-text;
    margin-bottom: 5px;
}

.normal_text {
    font-family: $secondary-font-regular;
    font-size: 16px;
    color: $PrimaryColor-text;
    margin-bottom: 0;
}

.org_close_box {
    border: 1px solid $border-color;
    float: left;
    padding:  0;
    width:calc(100% + 15px);
}
.org_close_box p {
    margin-bottom: 0;
    padding: 12px 40px 12px 12px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
}
.org_items{
    width:100%;
    padding-right:40px !important;
    color:$PrimaryColor-text;
}
.org_items:hover, .org_items.active, .org_items:active{
    background-color: #f1f1f1;
    color:$PrimaryColor-text;
}
.col_spec_drop{
    position: absolute;
    padding: 10px 5px;
    margin-left: 185px;
    margin-top: 40px;
    display: inline-flex;

}
.org_items .close_icon{
    cursor: pointer;
    display: inline-flex;
    position: absolute;
    right: 10px;
    top: 15px;
   // background: url('../../../../src/static/images/Close_Normal.svg') 0 0 no-repeat;
}

.organization_content .label_spec {
    display: inline;
    width: 200px;
    float: left;
}

.org_drop{
    top: -39px;
    left: -7px;
}
.form_alert{display: inline-block;}

.organization_top a,.organizaion_1 a,.organizaion_2 a{
    color: #0052cc;
}
.normal_text_primary {
    font-family: $secondary-font-regular;
    font-size: 16px;
    color: $PrimaryColor-text;
    margin-bottom: 0;
    margin-left: -18px;  
}
.text_shipto{
    font-family: $secondary-font-regular;
    font-size: 16px;
    color: $PrimaryColor-text;
    margin-bottom: 0;
    margin-left: 52px;
}
.loader{
    text-align: center;
    padding: 150px 0;
    img{
        max-width: 50px;
        border-radius: 100%;
    }
  }