@import "../../../styles/standard.module.scss";

.image_siz {
  max-width: 100%;
  object-fit: cover;
  width: -webkit-fill-available;
}
.btn_custom {
  height: 42px;
  border-radius: 2px;
  font-family: $secondary-font-regular;
  background-color: $PrimaryButton-bg;
  font-size: $Typography-2;
  line-height: normal;
  padding: 0;
}
.custom_modal_size {
  max-width: 780px;
}
.next_btn_sapce {
  margin-top: 37px;
  width: 140px;
}
.annotate_container {
  max-width: 574px;
  margin: 0 auto;
}
.annotate_container .label_spec span {
  color: $PrimaryColor-text !important;
}
.measured_img {
  max-width: 574px;
  margin: 0 auto !important;
}
.btn_place {
  position: absolute;
  top: -36px;
  right: 98px;
  z-index: 10000;
}
.input_field {
  width: 248px;
  border: solid 1px #c1c7d0;
  padding: 9px 7px;
}
.input_field:focus {
  outline: none;
}
.assess_heading {
  font-family: $secondary-font-bold;
  font-size: 24px;
}
.details_container {
  font-family: $secondary-font-regular;
  font-size: $Typography-1;
}
.edit_btn {
  font-size: $Typography-3;
  height: 24px;
}
.edit_btn:hover {
  cursor: pointer;
}
.cm_font {
  font-family: $secondary-font-regular;
}
.parent {
  position: relative;
  padding-bottom: 50px;
}
.confirmation {
  background-color: #ffffff;
  width: 725px;
  border: 1px solid #f16278;
  padding: 16px 17px;
  border-radius: 5px;
  font-family: $secondary-font-regular;
  color: $thead-bg;
  font-weight: 600;
  margin: 0 auto;
}
.yes_btn,
.yes_btn:hover,
.yes_btn:active {
  background-color: $Body-bg !important;
  border: solid 1px $tertiary-text !important;
  font-size: $Typography-3;
  color: $PrimaryColor-text !important;
  border-radius: 2px;
  padding: 4px 12px 6px 13px;
  margin: 0px 12px;
}
.no_btn,
.no_btn:hover,
.no_btn:active {
  background-color: $PrimaryButton-bg !important;
  border: solid 1px $PrimaryButton-bg !important;
  font-size: $Typography-3;
  color: $Plain-bg !important;
  border-radius: 2px;
  padding: 4px 12px 6px 13px;
  margin: 0px 12px;
}
.confirmation_container {
  position: fixed;
  top: 100px;
  left: 0px;
  z-index: 10001;
  width: 100%;
  height: 100%;
}
.overlay_class {
  width: 100%;
  height: 100%;
  background-color: rgb(191 191 191 / 53%);
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1051;
}
.loader_position {
  min-height: 170px;
  margin: 0 auto;
}
.loader_img {
  margin-top: 50px;
  max-width: 100px;
}

//added for measurement git animation loader
.loader_gif{
  margin-top: 50px;
  width: 260px;
  max-width: 270px
}
.loader_text{
  h3{
    font-size: 22px;
    font-weight: 700;
  }
  p{
    font-size: 18px;
    color: #747474;
    font-weight: 600;
  }
  
}
//added for measurement git animation loader


.label_spec {
  padding-top: 12px !important;
  font-size: $Typography-1;
  font-family: $secondary-font-regular;
}
.cancel_btn,
.cancel_btn:hover,
.cancel_btn:active {
  @include btn_secondary;
}
.save_custom,
.save_custom:hover,
.save_custom:active {
  @include btn_primary;
  margin-right: 32px;
}
.btns_top_space {
  margin-top: 150px;
}
.retrace_posr {
  position: relative;
  overflow: hidden;
}
.retrace_blk {
  background-color: #423939;
  color: #ffff;
  width: 100%;
  height: 60px;
  padding-left: calc(35% - 100px);
}
.retrace_blk button {
  width: 130px;
  height: 40px;
  background-color: #fff;
  color: #333333;
  font-family: "open_sansbold";
  font-size: 15px;
  border-radius: 100px;
  margin-top: 12px;
  border: solid 1px #cccccc;
}
.measure_surface {
  width: 100%;
  border-left: 7px solid $measure-surface;
  background-color: rgba(0, 221, 127, 0.05);
  font-family: $secondary-font-regular;
  padding: 5px 5px 5px 15px !important;
  margin-bottom: 0 !important;
  color: $PrimaryColor-text;
}
.measure_length {
  width: 100%;
  border-left: 7px solid $measure-length;
  background-color: rgba(0, 137, 255, 0.05);
  font-family: $secondary-font-regular;
  padding: 5px 5px 5px 15px !important;
  margin-bottom: 0 !important;
  color: $PrimaryColor-text;
}
.measure_width {
  width: 100%;
  border-left: 7px solid $measure-width;
  background-color: rgba(0, 221, 127, 0.05);
  font-family: $secondary-font-regular;
  padding: 5px 5px 5px 15px !important;
  margin-bottom: 0 !important;
  color: $PrimaryColor-text;
}
.measure_items {
  border-left: 7px solid $Plain-bg;
  padding: 5px 5px 5px 15px !important;
  margin-bottom: 0 !important;
  color: $PrimaryColor-text;
}
.loader{
  text-align: center;
  padding: 150px 0;
  img{
      max-width: 50px;
      border-radius: 100%;
  }
}
.btn_add,
.btn_add:hover {
  width: 130px;
  height: 42px;
  // margin: 0;
  padding: 9px 16px 9px 9px;
  border-radius: 2px;
  background-color: $PrimaryButton-bg;
  font-family: $secondary-font-regular;
  color: $Plain-bg;
  font-size: $Typography-2;

  // float: right;
  // margin-right: -15px!important;
}

.no_btn_clr {
  width: 130px;
  height: 42px;
  border-radius: 0px;
  padding: 9px 16px 9px 9px;
  background-color: $Body-bg;
  color: $PrimaryColor-text ;
  border: solid 1px $tertiary-text;

  // float: left;
  // margin-left: -15px!important;
}
.no_btn_clr:hover {
  background-color: $Body-bg;
  color: $PrimaryColor-text ;
  border: solid 1px $tertiary-text;
}
.no_btn_clr:active {
  background-color: $Body-bg !important;
  color: $PrimaryColor-text !important;
  border: solid 1px $tertiary-text !important;
}
.no_btn_clr:focus {
  background-color: $Body-bg;
  color: $PrimaryColor-text ;
  border: solid 1px $tertiary-text;
}
.img_alerts{
  box-shadow: 5px 10px 20px 0 rgb(191 191 191 / 30%);
    border: solid 1px #e5e5e5;
    background-color: #7c7c7c;
    color: #ffffff;
    padding: 12px 12px 12px 20px;
    border-radius: 3px;
}

//added for Info Icon information View Screen

.tooltip {
  position: relative;
  display: inline-block; 
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 350px;
  background-color: white;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 7px 0;
  position: absolute;
  z-index: 1;
  font-size: 12px;
  font-family: $secondary-font-regular;
  font-weight: 500;
  letter-spacing: 0.01em;
  border: 1px solid #4f4f4f;
  left: 85%;
  top: -122%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}


//added for Info Icon information Edit Screen
.tooltip_Edit {
  position: relative;
  display: inline-block; 
}
.tooltip_Edit .tooltiptext {
  visibility: hidden;
  width: 350px;
  background-color: white;
  color: #000!important;
  text-align: center;
  border-radius: 6px;
  padding: 7px 0;
  position: absolute;
  z-index: 1;
  font-size: 12px;
  font-family: $secondary-font-regular;
  font-weight: 500;
  letter-spacing: 0.01em;
  border: 1px solid #4f4f4f;
  left: 80%;
  top: -165%;
}

.tooltip_Edit:hover .tooltiptext {
  visibility: visible;
}


//ZoomIN/Out 

.iconListEdit{
  position: absolute;
  z-index: 999;
  opacity: 1;
  top: 11px;
  left: 35px;
  transform: scale(1.2);
  cursor: pointer;
  margin-top: -7px;
  margin-right: 6px;
  background: rgb(0 0 0 / 78%);
  width: 356px;
  padding: 8px 0;
}

.iconHover:hover{
  color: #999999 !important;
}

.patient_wound {
  max-width: 100%!important;
  overflow: hidden;
  transform-origin: 0px 0px;
  cursor: grab;
}


//added for measurement git animation loader
.button_Right{
  position: absolute;
  z-index: 999;
  top: 34%;
  right: 0;
  overflow: hidden;
}
.button_Right_Block{  
  display: block!important;
  overflow: hidden;
}


// .button_Left_Clear{
//   position: absolute;
//   z-index: 999;
//   top: 35%;
//   left: 0;
//   overflow: hidden;
// }
// .button_Right_Next{
//   position: absolute;
//   z-index: 999;
//   top: 35%;
//   right: 0;
//   overflow: hidden;
// }

//added for measurement git animation 
.dim_man_p{
  margin-top: 12px;
}
.dim_man_btn{
  width: auto;
    border-radius: 25px;
    background-color: #007bff !important;
    color: #fff !important;
    padding: 7px 20px !important;
    margin-top: -6px;
    cursor: pointer !important;
    display: inline-block;
    background: linear-gradient(270deg, #005dea 50%, #1d02a3 0) 100%;
    background-size: 200%;
    transition: .5s ease-out;
}
.dim_man_btn:hover{
  background-position: 0;
}