@import "../../../styles/standard.module.scss";

.spacing {
  padding: 10px;
}
.upload_label {
  margin-right: 67px;
  font-family: $secondary-font-bold;
}
.custom_wrapper {
  width: 212px !important;
  height: 212px !important;
  object-fit: inherit !important;
  border-radius: 0px !important;
  display: inline-block;
  align-items: inherit !important;
  justify-content: inherit !important;
  margin-bottom: 48px;
}
.browse_btn {
  border-radius: 2px;
  border: solid 1px $bdr-color-tertiary;
  background-color: $body-bg-color;
  padding: 10px 0px;
  width: 140px;
  text-align: center;
  text-decoration: none !important;
  margin-left: 0px !important;
  font-family: $secondary-font-regular;
  font-weight: 500 !important;
  color: $PrimaryColor-text !important;
  margin-bottom: 56px !important;
}
.browse_btn:hover {
  cursor: pointer;
}
.btn_add,
.btn_add:hover {
  width: 155px;
  height: 42px;
  margin: 0;
  padding: 9px 16px 9px 9px;
  border-radius: 2px;
  background-color: $PrimaryButton-bg;
  font-family: $secondary-font-regular;
  color: $Plain-bg;
  font-size: $Typography-2;
}
.label_spec {
  margin-bottom: 0;
  font-family: $secondary-font-bold;
}
.col_spec {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
  display: flex;
  flex-wrap: wrap;
}
.select_wrapper {
  border-radius: 2px;
  border: solid 1px $border-color;
  height: 40px;
  background: url("../../../static/images/down-arrow.png") right 10px center
    no-repeat;
}
.basic_select {
  margin: 0;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  appearance: none;
  -webkit-appearance: none;
  outline: 0;
  border-radius: 2px;
  border: solid 0px $border-color;
  background: none;
  option {
    font-size: 14px;
    font-weight: bold;
    color: #333;
  }
  optgroup {
    font-size: 14px;
    option {
      font-weight: normal;
    }
  }
}
.basic_textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
}
.btn_custom {
  padding: 0;
  width: 140px;
  height: 48px;
  border-radius: 2px;
  font-family: $secondary-font-regular;
  background-color: $PrimaryButton-bg;
  font-size: 18px;
}
.btn_small,
.btn_small:hover {
  width: 140px;
  height: 48px;
  font-family: $secondary-font-regular;
  background-color: $PrimaryButton-bg;
  color: $Plain-text;
  font-size: 16px;
  margin-right: 32px;
}
.svg_outer {
  padding: 0px 10%;
  &:first-child {
    border-right: 1px solid #ddd;
  }
  rect {
    fill: #fff !important;
    stroke: #fff !important;
  }
}
.notes_label {
  span {
    font-family: $secondary-font-regular;
  }
}
.left_right {
  position: relative;
  z-index: 100000;
  text-align: center;
  > span {
    display: block;
    width: 25px;
    height: 25px;
    background-color: #888;
    color: $Plain-text;
    border-radius: 100%;
    font-size: 13px;
    line-height: 25px;
  }
  > span:first-child {
    position: absolute;
    top: 50px;
    left: -15px;
  }
  > span:last-child {
    position: absolute;
    top: 50px;
    right: -15px;
  }
}
.loader_position {
  margin-bottom: 10px;
}
.loader_img {
  max-width: 50px;
}
.basic_input {
  width: 250px;
  height: 40px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
}
.btn_small_cancel,
.btn_small_cancel:hover,
.btn_small_cancel:active {
  width: 140px;
  height: 48px;
  font-family: $secondary-font-regular;
  background-color: $Plain-bg !important;
  border: 1px solid $bdr-color-tertiary !important;
  color: $PrimaryColor-text !important;
  font-size: $Typography-2;
}
.custom_modal_size {
  max-width: 967px;
}
.label_bold {
  font-family: $secondary-font-bold;
}

.risk_content {
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.risk_content .col_spec {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
  display: flex;
}
.label_spec span {
  margin-bottom: 0;
  font-family: $secondary-font-regular;
  font-size: 16px;
  line-height: normal;
}

.risk_select_box {
  width: 100%;
  height: 40px;
  margin: 4px 0 0 0px;
  padding: 0;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
}
.risk_select_box .dropdown_toggle {
  width: 100% !important;
  background-color: $Plain-bg !important;
  color: $PrimaryColor-text !important;
  text-align: left !important;
}
.risk_drop_down {
  width: 100% !important;
  max-height: 320px !important;
  overflow-y: auto !important;
}
.risk_drop_down .risk_item_wrap {
  white-space: normal !important;
  padding: 5px 10px !important;
}
.risk_select_box .drop_arrow:after {
  border: 0 !important;

  background: url("../../../static/images/down-arrow.png") right 10px center
    no-repeat !important;
}
.astrickStar{
  color: $Error;
}
