@import "../styles/standard.module.scss";

.tab_content_top {
  border-bottom: solid 1px $tab-border-bottom;
  margin-bottom: 15px;
}

.tab_content_hdg {
  padding: 18px 24px 16px;
  margin-bottom: 0;
  font-family: $secondary-font-bold;
  position: relative;
}
.tab_hdg {
  font-size: 24px;
  color: $PrimaryColor-text;
  width: auto;
  padding-right: 16px;
}
.tab_content_line {
  color: $PrimaryColor-text;
  font-family: $secondary-font-regular;
  font-size: 14px;
}
.tab_content_subline {
  font-family: $primary-font-regular;
  font-size: 18px;
  display: block;
  margin-top: 5px;
}
.tab_content_subline strong {
  font-family: $secondary-font-bold;
}
.wound_select {
  width: 100%;
  height: 40px;
  padding: 0 30px 0 10px;
  border-radius: 2px;
  border: solid 1px #c1c7d0;
  background-color: transparent;
  appearance: none;
}

.select_single {
  width: auto;
  height: 40px;
  border-radius: 2px;
  border: solid 1px #c1c7d0;
  background-color: transparent;
  appearance: none;
  display: flex;
  overflow: hidden;
}

.label_spec {
  margin-bottom: 0;
  font-family: $secondary-font-bold;
}

.col_spec {
  list-style: none;
  margin: 40px 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
  display: flex;
}
.select_wrapper {
  background: #fff url("../static/images/down-arrow.png") right 10px bottom 10px
    no-repeat;
}
.border_container {
  border: solid 1px #c1c7d0;
  padding: 5px 10px;
}

.wound_input {
  width: 100%;
  height: 40px;
  border-radius: 2px;
  border: solid 1px #c1c7d0;
  background-color: #ffffff;
  padding-left: 10px !important;
}
.wound_input:focus {
  outline: none;
}
.form_span {
  color: #0052cc;
  margin-left: 10px;
  cursor: pointer;
}
