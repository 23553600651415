@import '../../../styles/standard.module.scss';
.accod_btn {
  padding: 24px 21px 19px 26px;
  font-family: $primary-font-bold;
}
.accod_heading[aria-expanded="true"] .accod_btn::after{
  content: "";
  height: 10px;
  width: 10px;
  position: absolute;
  margin-right: 12px;
  right: 16px;
  top: 30px;
  border-bottom: 3px solid $PrimaryColor-text;
  border-right: 3px solid $PrimaryColor-text;
  // transform: rotate(45deg);
  transform: rotate(-135deg);
  border-radius: 2px;
}
.accod_heading[aria-expanded="false"] .accod_btn::after {
  content: "";
  height: 10px;
  width: 10px;
  position: absolute;
  margin-right: 12px;
  right: 16px;
  top: 22px;
  border-bottom: 3px solid $PrimaryColor-text;
  border-right: 3px solid $PrimaryColor-text;  
  // transform: rotate(-135deg);
  transform: rotate(45deg);
  border-radius: 2px;
}
.hide[idcontrol="1"] {
  display: none !important;
}
.accod_heading:focus {
  outline : none;
}
.accod_body {
  box-shadow: 5px 10px 20px 0 rgba(191, 191, 191, 0.3);
}
.accod_heading {
  font-weight: 500;
}
.show_body[idcontrol="1"] {
  display: block !important;
}
.show_bodys{
  display: block !important;
}
.accod_heading[aria-expanded="true"] .accod_btn[idcontrol="1"]::after {
  content: "";
  height: 10px;
  width: 10px;
  position: absolute;
  margin-right: 12px;
  right: 16px;
  top: 30px;
  border-bottom: 3px solid $PrimaryColor-text;
  border-right: 3px solid $PrimaryColor-text;
  // transform: rotate(-135deg);
  transform: rotate(45deg);
  border-radius: 2px;
}
.accod_heading[aria-expanded="false"] .accod_btn[idcontrol="1"]::after {
  content: "";
  height: 10px;
  width: 10px;
  position: absolute;
  margin-right: 12px;
  right: 16px;
  top: 30px;
  border-bottom: 3px solid $PrimaryColor-text;
  border-right: 3px solid $PrimaryColor-text;
  // transform: rotate(45deg);
  transform: rotate(-135deg);
  border-radius: 2px;
}
.accod_heading_formulary:focus {
  outline : none;
}
.accod_heading_formulary {
  font-weight: bold;
  background-color: white;
  color: gray;
}
.accod_btn_formulary {
  padding: 10px 0px 8px 5px;
  font-family: $secondary-font-regular;
  background-color: white;
  color: gray;
  font-size: 17px;
}
.accod_heading_formulary[aria-expanded="true"] .accod_btn_formulary[idcontrol="1"]::after {
  content: "";
  height: 10px;
  width: 10px;
  position: absolute;
  margin-right: 12px;
  right: 16px;
  top: 15px;
  border-bottom: 3px solid gray;
  border-right: 3px solid gray;
  // transform: rotate(-135deg);
  transform: rotate(45deg);
  border-radius: 2px;
  color: gray;
}
.accod_heading_formulary[aria-expanded="false"] .accod_btn_formulary[idcontrol="1"]::after {
  content: "";
  height: 10px;
  width: 10px;
  position: absolute;
  margin-right: 12px;
  right: 16px;
  top: 15px;
  border-bottom: 3px solid gray;
  border-right: 3px solid gray;
  // transform: rotate(45deg);
  transform: rotate(-135deg);
  border-radius: 2px;
  color: gray;
}

.accod_heading_formulary[aria-expanded="true"] .accod_btn_formulary::after{
  content: "";
  height: 10px;
  width: 10px;
  position: absolute;
  margin-right: 12px;
  right: 16px;
  top: 15px;
  border-bottom: 3px solid gray;
  border-right: 3px solid gray;
  // transform: rotate(45deg);
  transform: rotate(-135deg);
  border-radius: 2px;
  color: gray;
}
.accod_heading_formulary[aria-expanded="false"] .accod_btn_formulary::after {
  content: "";
  height: 10px;
  width: 10px;
  position: absolute;
  margin-right: 12px;
  right: 16px;
  top: 15px;
  border-bottom: 3px solid gray;
  border-right: 3px solid gray;
  // transform: rotate(-135deg);
  transform: rotate(45deg);
  border-radius: 2px;
  color: gray;
}

.accod_heading[aria-expanded="true"] .accod_btns::after{
  content: "";
  height: 10px;
  width: 10px;
  position: absolute;
  margin-right: 12px;
  right: 16px;
  top: 30px;
  border-bottom: 3px solid $PrimaryColor-text;
  border-right: 3px solid $PrimaryColor-text;
  // transform: rotate(45deg);
  transform: rotate(-135deg);
  border-radius: 2px;
}
.accod_heading[aria-expanded="false"] .accod_btns::after {
  content: "";
  height: 10px;
  width: 10px;
  position: absolute;
  margin-right: 12px;
  right: 16px;
  top: 30px;
  border-bottom: 3px solid $PrimaryColor-text;
  border-right: 3px solid $PrimaryColor-text;  
  // transform: rotate(-135deg);
  transform: rotate(45deg);
  border-radius: 2px;
}