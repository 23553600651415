@import "../../../styles/standard.module.scss";

.subheading_1 {
  font-size: 16px;
  color: #808080;
  font-family: $secondary-font-regular;
}
@media (min-width: 992px) {
  .form_shadow {
    padding: 37px;
  }
  .navbar-expand-lg {
    justify-content: space-between;
  }
}

.form_shadow {
  box-shadow: 5px 10px 20px 0 rgba(191, 191, 191, 0.3);
  -webkit-box-shadow: 5px 10px 20px 0 rgba(191, 191, 191, 0.3);
  -moz-box-shadow: 5px 10px 20px 0 rgba(191, 191, 191, 0.3);
  width: 450px;
  max-width: 450px;
  background-color: #ffffff;
}

.ne1_block {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 4px;
  box-shadow: 5px 10px 20px 0 rgba(191, 191, 191, 0.3);
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ne_lft {
  width: 440px !important;
  text-align: left !important;
  padding-left: 80px;
}
.ne_hdg {
  font-family: $primary-font-bold;
  font-size: 23px;
  color: #505f79;
}
.ne_sub_title {
  font-family: $primary-font-bold;
  font-size: 20px;
  color: #f16278;
}
.ne_cont {
  font-family: $secondary-font-regular;
  font-size: 12px;
  color: #383838;
  text-align: justify;
}
.ne_list {
  font-family: $primary-font-bold;
  font-size: 12px;
  color: #505f79;
  padding: 0;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}
.ne_list li {
  list-style: none;
  display: inline-block;
}

.ne_btn,
.ne_btn:hover {
  width: 228px;
  height: 45px;
  padding: 0;
  border-radius: 22px;
  background-color: #0052cc;
  font-family: $secondary-font-regular;
  font-size: 17px;
  color: #fff;
  display: block;
  margin: 0 auto;
}
.ne_btn a {
  color:#fff !important;
}
.ne_btn a:hover {
  text-decoration: none;
}