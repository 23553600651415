@import "../../../styles/standard.module.scss";

.tab_content_top {
    border-bottom: solid 1px #f2f2f2;
    margin-bottom: 15px;
}
.tab_content_hdg {
    padding: 24px 24px 16px;
    margin-bottom: 0;
    font-family: $secondary-font-regular;
    position: relative;
    min-height: 65px;
}

.basic_info {
    padding: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.basic_info div {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
}

.basic_info_form {
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.basic_info_form div {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
    display: flex;
    align-items: center;
}
.subheading_2 {
    font-family: $primary-font-regular;
    font-size: 21px;
    color: $PrimaryColor-text;
    margin-bottom: 0;
}

.single_product_block {
    padding: 0 24px;
}
.patient_content {
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.moreinfo_content,
.patient_content {
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.moreinfo_content .col_spec,
.patient_content .col_spec {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
    display: flex;
    flex-wrap: wrap;
}

.patient_content .basic_input,
.moreinfo_content .basic_input {
    width: 100%;
    height: 40px;
    padding: 0 5px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}

.patient_content .select_wrapper,
.moreinfo_content .select_wrapper {
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}

.patient_content .basic_select,
.moreinfo_content .basic_select {
    width: 100%;
    height: 40px;
    padding: 0 10px;
    appearance: none;
    -webkit-appearance: none;
    outline: 0;
    background: 0;
    border: 0;
}
select::-ms-expand {
    display: none;
}

.label_spec {
    margin-bottom: 0;
    font-family: $secondary-font-bold;
    line-height: normal;
}

.content_spec {
    font-family: $secondary-font-regular;
    color: $PrimaryColor-text;
    display: inline-block;
}
.basic_input {
    width: 250px;
    height: 40px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}
.small_input {
    width: 68px;
    height: 40px;
    padding-left: 7px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}
.medium_input {
    width: 100px;
    height: 40px;
    padding-left: 7px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}

.basic_select {
    width: 250px;
    height: 40px;
    padding: 0 10px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}

.btn_custom {
    height: 50px;
    border-radius: 2px;
    font-family: $secondary-font-regular;
    background-color: $PrimaryButton-bg;
    font-size: 18px;
}
.btn_small,
.btn_small:hover {
    width: 158px;
    height: 50px;
    font-family: $secondary-font-regular;
    background-color: $PrimaryButton-bg;
    color: $Plain-text;
    font-size: 16px;
}
.btn_small_cancel,
.btn_small_cancel:hover {
    width: 158px;
    height: 50px;
    font-family: $secondary-font-regular;
    background-color: $Plain-bg;
    border: 1px solid $bdr-color-tertiary !important;
    color: $PrimaryColor-text;
    font-size: 16px;
    margin-right: 16px;
}

.tab_edit {
    color: $PrimaryColor-text;
    font-family: $secondary-font-regular;
    font-size: 14px;
    position: absolute;
    right: 24px;
    top: 24px;
    cursor: pointer;
    z-index: 1000;
}

.tab_edit a,
.tab_edit a:hover {
    color: $PrimaryColor-text;
}
.tab_edit img {
    max-width: 20px;
    margin-right: 3px;
}
.general_error {
    margin-bottom: 20px;
    color: #fff;
    border-radius: 3px;
    padding: 5px 15px;
    display: inline-block;
    background-color: $Error;
}
.tab_edit_disable {
    pointer-events: none;
    cursor: not-allowed;
}
.basic_input.disabled {
    background-color: #dbdbdb !important;
}
.basic_select.disabled {
    background-color: #dbdbdb !important;
}
.input_field,
.input_field:focus {
    width: 100%;
    height: 40px;
    padding: 0 5px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
    outline: none;
}
.small_input {
    max-width: 74px !important;
}
.btn_container {
    margin-top: 73px;
    text-align: center;
}
.btn_small,
.btn_small:hover {
    @include btn_primary;
    margin-right: 32px;
}
.btn_cancel,
.btn_cancel:hover,
.btn_cancel:active {
    @include btn_secondary;    
}
.btn_save,
.btn_save:hover,
.btn_save:active {
    @include btn_primary;
    width:auto;
    img{
        margin-right: 10px;
    }
}
.deleteAttr {
    vertical-align: middle;
    border-style: none;
    width: 22px;
    height: 22px;
    padding: 6px;
    border-radius: 12px;
    border: solid 1px rgba(80, 95, 121, 0.5);
    background-color: #ffffff;
    margin-top: 10px;
    cursor: pointer;
}
.content_center {
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: CENTER;
    width: 100%;
    display: flex;
    min-height: 100px;
    font-family: $secondary-font-bold;
}

.input_data_list::-webkit-calendar-picker-indicator {
    display: none !important;
}
.compet_blk {
    width: 100%;
    border: 1px solid #e5e5e5;
    margin-bottom: 20px;
}
.compet_blk .compet_top {
    background-color: rgba(147, 151, 158, 0.05);
    width: 100%;
    color: #333333;
    font-family: $secondary-font-bold;
    font-size: 16px;
    padding: 15px 20px;
}
.compet_top_title {
    font-family: $secondary-font-bold;
    font-size: 16px;
}

.compet_top .close {
    float: right;
    cursor: pointer;
}
.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
}
.compet_btm ul {
    margin: 15px;
    list-style-type: none;
    padding-left: 0;
}

.compet_list {
    margin: 0;
    padding: 0 0 0 30px;
    color: #0052cc;
    font-family: $secondary-font-regular;
    font-size: 14px;
    background: url("../../../static/images/Image_Icons.svg") center left no-repeat;
}
.compet_list a {
    color: #0052cc;
    text-decoration: none;
}

.btn_custom_disable,
.btn_custom_disable:hover,
.btn_custom_disable:active {
    width: 160px;
    height: 42px;
    padding: 10px 0;
    border-radius: 2px;
    background-color: $secondary-text;
    font-family: $secondary-font-regular;
    font-size: $Typography-2;
    opacity: 0.3;
    cursor: not-allowed !important;
}
.media_align {
    right: 20px;
    left: auto;
    width: 400px;
    padding: 0 0 10px;
    border: solid 1px #e5e5e5;
}

.media_hdg {
    background-color: rgba(80, 95, 121, 0.05);
    color: #505f79;
    font-family: $secondary-font-bold;
    font-size: 20px;
    padding: 11px 20px 11px 20px;
}
.media_align label {
    display: block;
    color: #333333;
    font-family: $secondary-font-bold;
    font-size: 14px;
}
.media_align span {
    font-family: $secondary-font-regular !important;
    font-size: 12px !important;
}
.media_btm {
    padding: 20px;
}

.media_upload {
    background-color: #f6f6f6;
    padding: 40px 10px;
    margin-bottom: 20px;
}
.media_upload button {
    display: block;
    text-align: center;
    margin: 0 auto;
}
.media_upload span {
    display: block;
    text-align: center;
    margin: 0 auto;
}

.choose_file_btn,
.choose_file_btn:hover {
    width: 113px;
    height: 32px;
    padding: 5px 0 3px;
    border: solid 1px #cccccc;
    background-color: #fafafa;
    color: black;
}
.loader{
    text-align: center;
    img{
        max-width: 50px;
        border-radius: 100%;
    }
}

.plusimg{
    padding-right:5px;
}
.form_alert {
    display: inline-block !important;
  }