@import "../../../../src/styles/standard.module.scss";

.medline_menu_not {
    right: 71px !important;
    width: 380px;
}
.medline_user {
    font-family: $secondary-font-bold;
    color: $PrimaryColor-text;
    padding-left: 10px;
}
.p_link {
    cursor: pointer;
}

.notification_block .dropdown-item {
    position: relative;
    width: auto;
    margin: 0 24px;
    padding: 0 8px;
}
.notification_block_dropdown_item {
    position: relative;
    width: auto;
    margin: 0 11px;
    padding: 5px 5px;
    margin-bottom: 4px;
    border-radius: 5px;
}

.notification_block_dropdown_item:hover {
    background-color: rgba(0, 199, 229, 0.1);
}
.notification_unread{
    background-color: #fff;
}
.mandatory {
    color: #f16278;
}
.mins_ago {
    display: block;
    font-size: 12px;
    width: 100%;
}
.notification_items a.dropdown_item_a {
    position: absolute !important;
    top: 10px !important;
    right: 20px !important;
    text-indent: 0% !important;
    background: none !important;
}
.alert_note {
    width: 22px!important;
    height: 22px;
    padding: 1px 1px 1px 0px;
    background-color: #de350b;
    text-align: center;
    border-radius: 50%;
    position: absolute;
    top: 0px;
    margin-left: -12px;
    font-family: $secondary-font-bold;
    font-size: 12px;
    color: #fff;
}

.notification_items{
    max-height:180px;
    overflow:auto;
}

.btn_circle{
    background-color:#0052cc;
    border:1px solid #66a4ff;
    width:10px;
    height:10px;
    border-radius:50%;
    padding:0;
    position: absolute;
    top: 15px;
    right: 5px;
}
.btn_circle_normal{
    background-color:#ffffff;
    border:1px solid #66a4ff;
    width:10px;
    height:10px;
    border-radius:50%;
    padding:0;
    position: absolute;
    top: 15px;
    right: 5px;
}
.noty_icon{
    cursor: pointer;
    width: 32px;
    // height: 20px;
}
.noty_blk{
    border:0;
    background-color: transparent;
}
.notification_block span{
    max-width:300px;
    white-space: normal !important;
}