@import "../../../styles/standard.module.scss";

.basic_info {
  padding: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.basic_info div {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
}

.basic_info_form {
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.basic_info_form div {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
  display: flex;
  align-items: center;
}
.subheading_2 {
  font-family: $primary-font-regular;
  font-size: 21px;
  color: $PrimaryColor-text;
  margin-bottom: 0;
}

.single_product_block {
  padding: 0 24px;
}
.patient_content {
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.moreinfo_content,
.patient_content {
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.moreinfo_content .col_spec,
.patient_content .col_spec {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
  display: flex;
  flex-wrap: wrap;
}

.patient_content .basic_input,
.moreinfo_content .basic_input {
  width: 100%;
  height: 40px;
  padding: 0 5px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
}

.patient_content .select_wrapper,
.moreinfo_content .select_wrapper {
  border-radius: 2px;
  // border: solid 1px $border-color;
  background-color: $Plain-bg;
  height: auto;
}

.patient_content .basic_select,
.moreinfo_content .basic_select {
  width: 100%;
  height: 40px;
  padding: 0 25px 0 10px;
  appearance: none;
  -webkit-appearance: none;
  outline: 0;
  background: 0;
  border: solid 1px $border-color;
}
select::-ms-expand {
  display: none;
}

.label_spec {
  margin-bottom: 0;
  font-family: $secondary-font-bold;
}

/* Below for error message alignment */
// .label_spec ~ span {
//     margin-left: 50%;
// }

.content_spec {
  font-family: $secondary-font-regular;
  color: $PrimaryColor-text;
  display: inline-block;
}
.basic_input {
  width: 250px;
  height: 40px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
}
.small_input {
  width: 68px;
  height: 40px;
  padding-left: 7px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
}
.medium_input {
  width: 100px;
  height: 40px;
  padding-left: 7px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
}

.basic_select {
  width: 250px;
  height: 40px;
  padding: 0 10px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
}

.btn_custom {
  height: 50px;
  border-radius: 2px;
  font-family: $secondary-font-regular;
  background-color: $PrimaryButton-bg;
  font-size: 18px;
}
.btn_small,
.btn_small:hover {
  @include btn_primary;
  margin-right: 32px;
}
.btn_small_cancel,
.btn_small_cancel:hover,
.btn_small_cancel:active {
  @include btn_secondary;
}
.admission_upper {
  position: relative;
}
.admission_fix {
  position: absolute;
  top: 0;
  right: -7px;
}
.admission_date {
  padding: 7px;
  border: 1px solid $adm-color;
  width: 100px;
}
.select_wrapper {
  background: url("../../../static/images/down-arrow.png") right 10px top 10px
    no-repeat;
}

.tab_edit {
  color: $PrimaryColor-text;
  font-family: $secondary-font-regular;
  font-size: 14px;
  position: absolute;
  right: 24px;
  top: 65px;
  z-index: 1000;
}

.tab_edit a,
.tab_edit a:hover {
  color: $PrimaryColor-text;
}
.general_error {
  margin-bottom: 20px;
  color: #fff;
  border-radius: 3px;
  padding: 5px 15px;
  display: inline-block;
  background-color: $Error;
}
.admission_time_fix {
  margin-left: 5px;
}
.basic_input.disabled {
  background-color: #dbdbdb !important;
}
.basic_select.disabled {
  background-color: #dbdbdb !important;
}
.custom_control_input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 16px;
  height: 16px;
  opacity: 0;
}
.custom_control_label {
  position: relative;
  margin-top: 10px;
  margin-bottom: 0;
  vertical-align: top;
  font-family: $secondary-font-regular;
  font-size: 14px;
  color: $PrimaryColor-text;
  line-height: normal;
}

.custom_control_input.is_invalid ~ .custom_control_label::before,
.was_validated .custom_control_input:invalid ~ .custom_control_label::before {
  border-color: $PrimaryColor-text;
}
.custom_control_input.is_valid ~ .custom_control_label,
.was_validated .custom_control_input:valid ~ .custom_control_label {
  color: $Error;
}
.custom_control_input.is_valid:checked ~ .custom_control_label::before,
.was_validated
  .custom_control_input:valid:checked
  ~ .custom_control_label::before {
  border-color: $border-box;
  background-color: $CheckBox-bg;
}
.custom_control_label::after {
  position: absolute;
  top: 9px;
  left: -1.5rem;
  display: block;
  width: 16px;
  height: 16px;
  content: "";
}
.custom_control_label::before {
  border-radius: 50% !important;
  top: 9px;
}
.custom_control_input:checked ~ .custom_control_label::before {
  color: $Plain-text;
  border-color: $border-box;
  background-color: $CheckBox-bg;
  border-radius: 0;
  width: 16px;
  height: 16px;
  top: 9px;
}
.bir_subline {
  color: #505f79 !important;
  font-family: $secondary-font-regular !important;
  font-size: 12px !important;
  display: block !important;
}
.btn_add{
  background-color: #0052cc;  
  height: 37px;
  width: 37px;
  padding: 0px 0px 3px 0px;
  display: inline-block;
  position: relative;
  margin-left: -135px;
  }
  .textPosition{
    margin-left: 55px;
    float: right;
  }
  .btn_first{
    position: relative;
    margin-left:-160px;
  }
  .btn_text{
    float: right;
      margin-left: 5px;
      margin-right: -222px;
  }