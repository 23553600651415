@import '../../../styles/standard.module.scss';
// .img_preview_outer{
//     width: 60px;
//     height: 60px;
//     position: relative;
//     img{
//         width: 60px;
//         height: 60px;
//         object-fit: cover;
//         border-radius: 100%;
//     }
//     .overlay{
//         position: absolute;
//         top: 0;
//         left: 0;
//         border-radius: 100%;
//         width: 100%;
//         height: 100%;
//         background: rgba(0, 0, 0, 0.3);
//         display: none;
//         transition: .15s ease;
//         label{
//             display: block;
//             width: 60px;
//             height: 60px;
//             text-align: center;
//             line-height: 60px;
//             color: #fff;
//         }
//         input{
//             display: none;
//         }
//         img{
//             cursor: pointer;
//             width: 20px;
//             height: 20px;
//             border-radius: 0;
//         }
//     }
//     &:hover{
//         .overlay{
//             transition: .15s ease;
//             display: block;
//         }
//     }
// }

// .file_hide{
//     display: none;
// }

// .custom_file_upload {
//     border: 1px solid $border-color;
//     display: inline-block;
//     padding: 6px 12px;
//     cursor: pointer;
//     margin-bottom: 0;
// }

.picture_icon_block{
    display: flex;   
    align-items: center;
    justify-content: center;
    margin-top: -5px;
    img{
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 100%;
    }
    label{
        margin-left: 10px;
        cursor: pointer;
        color: #6099f0;
        font-size: 14px;
        line-height: 14px;
        font-weight: 600;
        text-decoration: underline;
        display: block;
    }
    input[type=file]{
        display: none;
    }
}

.error_border {
	border: 1px solid $Error !important;
}
.error_message {
	display: block;
	color: $Error;
	padding-top: 4px;
	font-family: $secondary-font-regular;
	font-size:14px;
}