@import '../../../styles/standard.module.scss';

.error_message {
  color: $Error;
  padding-top: 4px;
  font-family: $secondary-font-regular;
	font-size:14px;
}

.custom_check_box {
  width: 16px;
  height: 16px;
}

/* custome checkbox */
  
  .custom-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
  
  .custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
  }
  
  .custom-control-input.is-invalid~.custom-control-label::before,
  .was-validated .custom-control-input:invalid~.custom-control-label::before {
    border-color: $border-color;
  }
  
  .custom-control-input.is-valid~.custom-control-label,
  .was-validated .custom-control-input:valid~.custom-control-label {
    color: $Error;
  }
  
  .custom-control-input.is-valid:checked~.custom-control-label::before,
  .was-validated .custom-control-input:valid:checked~.custom-control-label::before {
    border-color: $border-box;
    background-color:$CheckBox-bg !important;
  }
  
  .custom-control-input:checked~.custom-control-label::before {
    color: $Plain-bg;
    border-color: $border-box;
    background-color:$CheckBox-bg !important;
  }
  
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat;
  }
  
  /* custome checkbox */
  
  .sel-width {
    width: 75px;
    display: inline-block;
    border: 0;
    padding: 0;
    height: auto;
  }
  
  .phone-input {
    width: calc(100% - 85px);
    display: inline-block;
    border: 0;
    padding: 0;
    height: auto;
  }
  

  .custom_control_input {
    &:checked~.custom_control_label {
      &::before {
        color: $Plain-text;
         border-color: $border-box;
        background-color:$CheckBox-bg !important;
      }
    }
  }

  .custom_control{
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }

  .custom_checkbox .custom_control_label::before {
    border-radius: 0;
}
.index_remove{
  z-index: 0 !important;
  margin-left: -5px !important;
}