@import "../../../styles/standard.module.scss";
.tab_pane {
  padding: 0 0 41px;
  box-shadow: 5px 10px 20px 0 rgb(191 191 191 / 30%);
  background-color: $Plain-bg;
  margin-top: -30px; // added new
}
.container_wrapper {
  padding: 19px 24px;
  border-bottom: 1px solid $tab-border-bottom;
}
.heading {
  font-family: $secondary-font-bold;
  font-size: 24px;
  font-weight: 600;
  color: $PrimaryColor-text;
}
.edit_icon:hover {
  cursor: pointer;
}
.label_cutom {
  font-family: $secondary-font-bold;
  font-size: 16px;
  font-weight: 600;
  color: $PrimaryColor-text;
}
.select_wrapper {
  border: solid 1px $tertiary-text;
}
.input_field,
.input_field:focus {
  width: 180px;
  height: 40px;
  padding: 0 5px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
  outline: none;
}
.small_input {
  max-width: 74px !important;
}
.btn_container {
  margin-top: 73px;
  text-align: center;
}
.btn_cancel,
.btn_cancel:hover,
.btn_cancel:active {
  width: 140px;
  height: 42px;
  padding: 10px 0px 10px;
  border-radius: 2px;
  border: solid 1px $tertiary-text !important;
  background-color: $Body-bg !important;
  color: $PrimaryColor-text !important;
  font-family: $secondary-font-regular;
  font-size: $Typography-2;
}
.btn_save,
.btn_save:hover,
.btn_save:active {
  width: 140px;
  height: 42px;
  padding: 10px 0;
  border-radius: 2px;
  margin-right: 32px;
  background-color: $secondary-text;
  font-family: $secondary-font-regular;
  font-size: $Typography-2;
}
.data_color {
  color: $PrimaryColor-text;
}
.icon_align {
  justify-content: flex-start !important;
}
.general_error {
  margin-bottom: 10px;
  color: $Plain-text;
  border-radius: 3px;
  padding: 5px 15px;
  display: inline-block;
  background-color: $Error;
}
.avatar_icon {
  max-width: 67px;
  height: 67px;
  border-radius: 50%;
}
.form_alert {
  display: inline-block !important;
}
.label_cutom span {
  color: $Error !important;
}
.bir_subline {
  color: #505f79 !important;
  font-family: $secondary-font-regular !important;
  font-size: 12px !important;
  display: block !important;
}
.wound_input {
  width: 100%;
  height: 40px;
  border-radius: 2px;
  border: solid 1px #c1c7d0;
  background-color: #ffffff;
  padding-left: 10px !important;
}
.form_span {
  color: #0052cc;
  left:411px;
  cursor: pointer;
  bottom: 36px;
}
.org_cust{
  margin-left: 82px;
}
.pic_cust{
  margin-left: 134px;
}
.menu_icon{
  margin-left: 139px;
}
.label_OR{
  margin-left: 300px;
  top: -12px;
}
.org_cutom{
  margin-left: 64px;
}
.org_view{
  // margin-left: 158px;
  // margin-top: -37px;
  margin-left: 19px;
  margin-top: -6px;
}
.org_input{
  top: 3px;
  margin-left: -3px;
}
.pdf_custom{
  margin-left:-34px;
  // word-break: break-all;
}
.subheading{
  
    font-family: $primary-font-regular;
    font-size: 21px;
    color: $PrimaryColor-text;
}
.form_span.disabled,.disabled{
  cursor: not-allowed;
}
.btn_custom {
  height: 50px;
  border-radius: 2px;
  font-family: $secondary-font-regular;
  background-color: $PrimaryButton-bg;
  font-size: 18px; 
  margin: 0 10px;
}

.btn_small,
.btn_small:hover {
  @include btn_primary;
  img{
      margin-right: 10px;
  }    
}
.btn_position{
  margin-left:-84px;      
  }
  .text_custom{
    margin-left:-37px;
    font-size:1rem;
  }

  .pdf_style{
    margin-top: -128px;
  }
.media_align{
  top: -120px;
  left: 450px;
  width: 400px;
  padding: 0 0 10px;
  border: solid 1px #e5e5e5;
  z-index: 1600;
}
.label_seq{
  top:42px;
}

.Or_Align{
  margin-left: 52px;
  margin-top: 5px;
  padding-bottom: 15px;
}
.pdfList_view{
  display: inline-flex;
}
.error_message {
  display: block;
  color: #de350b;
  font-family: $secondary-font-regular;
  font-size: 14px;
}

.media_view{
  margin: 0px;
  ul{
      margin: 0;
      padding: 0;
      list-style-type: none;
      li{
        display: flex;
          &:last-child{
              border-bottom: 0;
          }
          a{
            line-height: 1em;
            height: 1em;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0px;
              &:hover{
                color: #007bff;
                text-decoration: underline;
                  // font-weight: bold;
              }
          }
          > img{
            position: absolute;
            left: -8px;
          }
          >span{
            margin: -6px 2px 3px 4px;
              cursor: pointer;
              &:hover{
                  filter: brightness(70%);
              }
          }
          >span img{
            width: 18px;
            height: 18px;
        }
      }
  }
}
.error_PDF{
  margin-top: -35px;
  margin-left: 15px;
}
.error_CustNum{
  display: inline-flex;
  margin-right: -257px;
  margin-left: -12px;
  margin-top: 12px;
}
.errStar{
	color: $Error;
}
.disable_field{
	background-color: #dbdbdb !important;
  pointer-events: none;
}
.root_tag{
  position: relative;
  padding: 6px 0 0 6px;
  border: 1px solid #D1D1D1;
  border-radius: 1px;
  font-size: 13px;
  line-height: 1.2;
  width: 89%;
  height: 100px;
  margin-left: 0px;
  margin-bottom: 10px;
  display: inline-table;
}

.input_icons {
  padding-left: 15px !important;
    padding-right: 0 !important;
    position: relative;
    display: inline-block;
}

.x_icon {
  position: absolute;
  right: 28%;
  top: 8px;
  cursor: pointer;
}

.excla_icon{
  position: absolute;
  left: -18px;
  top: 0px;
  cursor: pointer;
  font-size: 24px;
}
.search_drop{  
  top: 0px;
    left: 44.6%;
    position: absolute;
    z-index: 999 !important;
    background-color: #ffffff;
    width: 42%;
}

.search_close_box {
  border: 1px solid $border-color;
  padding: 5px 5px;
  max-height: 250px;
  overflow-y: auto;
  
}
.search_close_box p {
  margin-bottom: -1px;
  padding: 6px;
  cursor: pointer !important;
}

.input_field_cust_num,
.input_field_cust_num:focus {
  height: 40px;
  padding: 0 5px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
  outline: none;
}