@import "../../../styles/standard.module.scss";
@import "../../../styles/common.module.scss";

.back_btn a {
  @include response-above(sm) {
      position: fixed;
      top:22px;
      left:25px;
      background:url(../../../static/images/back-arrow.png) center left no-repeat;
      text-indent: 999px;
  }
  @include response-above(lg) {
    position: static;
    top:auto;
    left:auto;
    color:$PrimaryColor-text !important;
    background:url(../../../static/images/left_arrow.png) center left no-repeat;
    padding-left: 10px;
    font-family: $secondary-font-regular;
    font-size:16px;
  }
}

.btn_small_cancel_color{
  border-radius: 25px!important;
}
.btn_small_cancel_bgcolor{
  border: 2px solid #0052cc!important;
  color: #0052cc!important;
}

.loader{
  text-align: center;
  padding: 150px 0;
  img{
      max-width: 50px;
      border-radius: 100%;
  }
  
}

.mergeWoundPage{
  // height: 100vh;
  padding-bottom: 25px;
}
.mergerWoundMain{
    padding: 14px;
    /* background-color: #f4f5f7; */
    max-height: 417px;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 370px;
    box-shadow: 0 0px 15px #b1b1b1;
    max-width: 670px;
}

.wholeDrag, .wholeDrag_Undo{
  padding: 5px 0 15px;
  border: 1px solid #c9c9c9;
  box-shadow: 5px 10px 20px 0 rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
  cursor: pointer;
}
.wholeDrag_none{
  display: none!important;
}

.wholeDrag:hover, .wholeDrag_Undo:hover{
  background-color: #fff;
  opacity: 0.2;
  cursor: all-scroll;
}

.wholeDragMoveStart{
  transform: rotate(20deg);
}

.woundTitle {
    a{
        color: #0052cc !important;
        text-decoration: underline;
        font-family: "globerbold";
        font-size: 21px;
    }
}
.woundAssessment_img_parent{
    position: relative;
    img{
      max-width: 100%;
      object-fit: cover;
      max-height: 140px;
      // height: 140px;
      // width: 105px;
    }
}

.lastContent{
  background-color: #f4f5f7;
  padding: 7px 5px 7px 8px;
  margin-top: 5px;
}

.assess_content_label{
    // font-family: $secondary-font-regular!important;
    font-family: "open_sansbold";
  }
//   .assess_content_label_content{
//     font-family: "open_sansbold"
//   }
  .assess_content {
    font-family: $secondary-font-regular!important;
  }
  .assess_label_subDetail_labelout{
    font-size: 15px;
  }

  

  .mergewoundWholeSection{
    // margin-top: -10px;
    padding-bottom: 75px!important;
  }

  .mergeListSection{
    background-color: #f4f5f7;
    box-shadow: 0 0 20px 0px #979595;
    height: auto;
    max-height: 90vh;
    overflow-y:auto;
    padding: 10px 10px 10px 20px;
    margin-top: -2px;
    // margin-top: 10px;
    h3{
      font-family: "open_sansregular";
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #333333;
      padding: 5px 0;
      margin-top: 12px;
    }
  }
  .mergeListSectionPaddingHeight{
    max-height: 105vh!important;
  }
  .mergeLeftsideCard{
    padding: 15px 0 0;
    h3{
      font-family: "open_sansregular";
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #333333;
      padding: 10px 0px 10px 5px;
      // margin-top: 12px;
    }
  }
  .mergeLeftsideCardAllAssess{
    h3{
      font-family: "open_sansregular";
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #333333;
      padding: 11px 0px 10px 5px!important;
    }
  }

  .mergeList {
    background-color: #fff;
    margin-bottom: 15px;
    box-shadow: 0 0px 6px #7e7e7e;
    cursor: pointer;
  }
  .mergeListHover{
    position: relative;
    width: 590px;
    height: 125px;    
    background-color: #f4f4f4;
    z-index: 999;
    margin: 20px 0 12px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    .mergeListHoverPara{
      height: 96px;
      width: 560px;
      border: 2px dashed #0052cc;
      margin: 15px;
      padding: 13px;
      z-index: 1;
      p{
        font-size: 18px;
        color: #0052cc;
        margin: 4% auto;
        font-weight: 500;
      }
    }  
  }
  .mergeListHover::after {
    content: "";
    position: absolute;
    left: 46%;
    top: -10px;
    width: 25px;
    height: 25px;
    border-right: 2px solid #f4f4f4;
    border-bottom: 2px solid #f4f4f4;
    clear: both;
    transform: rotate(-134deg);
    background: #f4f4f4;
    z-index: 0;
}


  .isWoundWithPhoto{
    height: 100px;
  }

  .closeIconMerge{
    padding: 10px;
  }

  .mergeWoundSuccess{
    width: 100%;
    p{
      color: rgb(0, 173, 47);
      padding: 8px 15px 12px;
      font-size: 16px;
      background-color: rgb(255, 255, 255);
      border: 2px solid rgb(0, 173, 47);
      border-radius: 25px;
      width: 321px;
      margin-left: 155px;
      font-family: cursive;
      letter-spacing: 1px;
    }
  }

  .mergeWoundUndo{
    p{
      color: rgb(0 82 204);
      padding: 10px 45px;
      font-size: 15px;
      border: 1px solid rgb(0 82 204);
      background-color: #ffffff;
      border-radius: 25px;
    }
  }

  .mergeWoundUndo:hover{
    p{
      color: #ffffff;
      padding: 10px 45px;
      font-size: 15px;
      background-color: rgb(0 82 204);
      border-radius: 25px;
    }
  }


  .arrow {
    // position: absolute;
    // top: 4%;
    // left: 63%;
    // padding-top: 5px;
    transform: translate(-50%, -50%);
    transform: rotate(270deg);
    cursor: pointer;
    margin-left: 40px;
    align-self: center;
    margin-top: -13px;
    
}
.arrowHeight{
  top: 24%!important;
}

.arrow span {
    display: block;
    width: 11px;
    height: 11px;
    border-bottom: 5px solid #5e5e5e;
    border-right: 5px solid #5a5a5a;
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 2s infinite;
}
.arrow span:nth-child(1) {
  border-bottom: 5px solid #579aff;
  border-right: 5px solid #579aff;
}

.arrow span:nth-child(2) {
  animation-delay: -0.2s;
  border-bottom: 5px solid #1e77fc;
  border-right: 5px solid #1e77fc;
}

.arrow span:nth-child(3) {
  animation-delay: -0.4s;
  border-bottom: 5px solid #0052cc;
  border-right: 5px solid #0052cc;
}

@keyframes animate {
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-20px, -20px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(45deg) translate(20px, 20px);
    }
}

.adminPageYesBatch{
  background-color: #0052cc;
  color: #ffffff;
  font-size: 15px;
  text-align: center;
  padding: 4px 10px 0px 10px;
  margin-bottom: 0;
  align-items: center;
  border-radius: 7px;
  box-shadow: 0 4px 3px #000
}


.assess_status {
  display: inline-block;
  color: $Plain-bg;
  border-radius: 4px;
  font-size: $Typography-31;
  width: 145px !important;
  text-align: center;
  font-family: $primary-font-regular !important;
  font-weight: 500;
}
.signed_locked {
  background-color: $hint-success-text;
}
.reviewed {
  background-color: $Success-text;
}
.pending_assess {
  background-color: $pending-status;
  color: $black-color!important;
}
.pending_review {
  display: inline-block;
  background-color: $review-pending;
  color: $Plain-bg;
  border-radius: 4px;
  font-size: $Typography-4;
  width: 145px !important;
  text-align: center;
}




.mergerWoundMainAllAssessment{
  padding: 14px;
  /* background-color: #f4f5f7; */
  // max-height: 417px;
  height: -moz-fit-content;
  height: fit-content;
  // min-height: 370px;
  box-shadow: 0 0px 15px #b1b1b1;
  max-width: 670px;  
}
.keepDivHeight{
  padding: 14px;
  height: -moz-fit-content;
  min-height: 350px;
  box-shadow: 0 0px 15px #b1b1b1;
  max-width: 670px; 
}

.modal_image {
  width: 47px;
  height: 33px;
  margin-top: 7px;
  margin-bottom: 6px;
  object-fit: cover;
}
.complete_td:hover {
  cursor: pointer;
}
.complete_td td:first-child {
  padding-left: 20px;
}
.complete_td td {
  font-size: $Typography-3;
  color: $PrimaryColor-text;
}
.complete_td {
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
}
// .complete_th {
//   border-bottom: 1px solid $tertiary-text;
// }
.complete_th th {
  padding-bottom: 17px;
  color: $table-heading-color;
  padding-right: 10px;
}
.complete_th th:first-child {
  padding-left: 20px;  
}
.sub_heading {
  font-size: 20px;
  color: #0052cc;
  text-transform: capitalize;
  font-family: $secondary-font-regular;
  font-weight: 600;
  cursor: pointer;
  padding-top: 10px;
  a{
    color: #0052cc;
    text-decoration: underline;
    font-family: "globerbold";
    font-size: 21px;
  }
}


.patient_details_blk_scroll{
  margin-bottom: 10px;
  display: flex;
  background-image: linear-gradient(180deg, #0052cc, rgb(54, 135, 255));
  padding: 28px 0 25px 10px;
  // border-top-left-radius: 25px;
  // border-top-right-radius: 25px;
  align-items: center;
  height: 100px;
  .patient_image{
      width:65px;    
      height: 65px;
      display: inline-block;
      vertical-align: top;   
      cursor: pointer;
      // margin-top: -42px;
  }
}

.patient_image img{
  border-radius:50%;
  width:100%;    
  height: 100%;
}

.patient_details{
  display: inline-block;
  padding-left: 17px;
      /* width:calc(100% - 120px);*/
 width: calc(100% - 100px);
  // width: 70%;
 color: white;
 margin-top: -7px;
}

.patient_details p{
margin-bottom: 0;
font-family: $secondary-font-regular;
white-space: nowrap;
}
.patient_details p label{
  margin-bottom: 0;
  margin-right: 5px;
  font-weight: bold;
  font-family:$secondary-font-bold;
}
.patient_details h4{
  font-family:$secondary-font-bold;
  font-size:24px;
  color: #ffffff;
  cursor: pointer;
  text-decoration: underline;
}
.patient_details a{
  color: #ffffff;
  cursor: pointer;
}
.patient_details a:hover{
  color: #ffffff;
  text-decoration: underline;
}
.patient_details small{
  font-family: $secondary-font-regular;
  font-size:14px;
  padding-right: 15px;
}
.labelSize{
  font-size: 17px;
}
.labelSize_one{
  font-size: 15px;
}
.vertical_Code {
  border-left: solid 2px #ffffff;
  margin-top: 5px;
  height: 18px;
  margin: 5px 7px 0 7px;
}
.vertical_Code_one {
  border-left: solid 2px #ffffff;
  margin-top: 5px;
  height: 16px;
  margin: 0 7px 0 7px;
}
.btn_custom_with_diabetic, .btn_custom_with_diabetic:hover{
  border: 1px solid #de350b !important;
  width: auto;
  height: 30px;
  // border-radius: 15px;
  background-color: #de350b !important;
  color: #ffffff !important;
  padding-top: 2px;
  padding-bottom: 7px;
  padding-left: 13px;
  padding-right: 13px;
  cursor: context-menu!important;
  box-shadow: 0px 1px 3px 0px white;
}