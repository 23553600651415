@import '../../../styles/standard.module.scss';
.vertical_tabs .tab_content {
    width: calc(100% - 320px);
    display: inline-block;
}

.vertical_tabs .tab_content>.active {
    display: block;
}
.vertical_tabs .tab_pane {
    padding: 0 0;
    box-shadow: none;
    background-color: #ffffff;
}
.measurement_blk {
    font-family: $secondary-font-regular;
    font-size: 18px;
    color: #333;
    padding: 24px 0;
    margin-bottom: 0;
}

.wound_left_block {
    width: 100%;
    
}
.wound_left_block, .figure {
    margin-left: 0;
    
}


.image_size {
    width: 191px;
    height: auto;
}



.wound_left_block .patient_details {
    width: 500px;
}
.patient_details {
    display: inline-block;
    margin-left: 10px;
}
.wound_right_block {
    display: inline-block;
    padding-left: 0;
    width: 100%;
}

.wound_right_block .heading_wound {
    font-size: 16px;
    font-family: $secondary-font-bold;
    color: #333;
    margin-bottom: 20px;
}
.wound_right_block .tab-pane {
    padding: 0 0 41px;
    box-shadow: none;
    background-color: #ffffff;
}
.wound_left_block_in{
    width:100%;
    padding:0 0 20px 0;
}
.btn_custom {
    height: 42px;
    padding: 0 15px;
    border-radius: 2px;
    font-family: $secondary-font-regular;
    background-color: $PrimaryButton-bg;
    font-size: $Typography-2;
    line-height: normal;
}

.figure_caption{
    font-size:16px;
    font-family: $secondary-font-bold;
    color:#333;
}

.btn_small_cancel {
    @include btn_secondary; 
    margin-right: 16px;
}

.wound_left_block .patient_details p{
    margin-bottom: 0;
    line-height: 30px;
}
.wound_left_block .patient_details button{
    margin-top: 10px;
}
.wound_left_block .patient_details label{
    font-family: $secondary-font-regular;
    font-size:14px;
    color:#505f79;
    padding: 0;
    margin-bottom:0;
}
.wound_left_block .patient_details span{
    font-family: $secondary-font-bold;
    font-size:14px;
    color:#333;
    margin-bottom: 0;
}
.wound_right_block .tab_pane{
    padding: 0 0;
    box-shadow: none !important;
    background-color: #ffffff !important;
}
.tab_none{
    padding: 0 0;
    box-shadow: none !important;
    background-color: #ffffff !important;
}
.heading{
    position: relative;
    padding: 15px;
    h3{
        font-size: 18px;
        font-weight: bold;
    }
    span{
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
    }
}
.no_data{
    margin-top: 25%;
}
.tab_none_history{
    padding: 0 0;
    box-shadow: none !important;
    background-color: #ffffff !important;
    font-size: 16px;
}