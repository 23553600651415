@import '../../../styles/standard.module.scss';
.tab_content_top {
    border-bottom: solid 1px $tab-border-bottom;
    margin-bottom: 15px;
}
 
.tab_content_hdg {
    padding: 18px 24px 16px;
    margin-bottom: 0;
    font-family:$secondary-font-bold;    
    position: relative;
    min-height: 65px;
}
.tab_hdg{
    font-size: 24px;
    color: $PrimaryColor-text ;
    width: auto;
    padding-right: 16px;
}
.tab_content_line{
    color: $PrimaryColor-text;
    font-family: $secondary-font-regular;
    font-size:14px;
}
.tab_content_subline {
    font-family: $primary-font-regular;
    font-size: 18px;
    display: block;
    margin-top:5px;
}
.tab_content_subline strong{
    font-family:$secondary-font-bold;
}