@import "../../../styles/common.module.scss";

.disabled_select_btn img:hover {
  cursor: default;
}
.disabled_select_btn {
  width: 50px;
  height: 50px;
  display: inline-flex;
  margin-left: 10px;
  opacity: 0.4;
}
