@import '../../../styles/standard.module.scss';
.subheading_2 {
    font-family: $primary-font-regular;
    font-size: 21px;
    color: $PrimaryColor-text;
    margin-bottom: 0;
}
.create_patient {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
    display: flex;
    flex-wrap: wrap;    
}
.col_spec{ 
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
    display: flex;
}
.basic_input {
    width: 100%;
    height: 40px;
    padding: 0 5px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}
.label_spec {
    margin-bottom: 0;
    font-family: $secondary-font-bold;
    
}
.btn_small {
    @include btn_primary;
}
.btn_custom {
    height: 42px;
    border-radius: 2px;
    font-family: $secondary-font-regular;
    background-color: $PrimaryButton-bg;
    font-size: $Typography-2;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.custom_modal_size {
    max-width: 967px;
}

