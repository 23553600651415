.rc-slider-handle{
    height: 19px !important;
    width: 19px !important;
    border: 0px;
    border: 2px solid #fff !important;
    background: #0052CC url('../../../static/images/scrub.JPG') no-repeat !important;
    background-size: 13px !important;
    background-position-x: 1px !important;
    background-position-y: 1px !important;
    margin-top: -7px;
}
.rc-slider-handle-click-focused{
    height: 19px;
    width: 19px;
    border: 2px solid #fff;
    background: #0052CC url('../../../static/images/scrub.JPG') no-repeat !important;
    background-size: 13px !important;
    background-position-x: 1px !important;
    background-position-y: 1px !important;
  }