@import '../../../styles/standard.module.scss';

.overview_blk{
    width:100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.overview_top{
    padding:30px;
    width:100%;
    display: flex;
    justify-content: space-between;
}
.overview_title{
    color: $PrimaryColor-text;
    font-family: $secondary-font-bold;
    font-size:22px;
    display: inline-block;
    margin:0;
}
.down_spread{
    color: $secondary-text;
    font-family: $secondary-font-bold;
    font-size:16px;
    display: inline-block;
}
.down_spread a{
    color: $secondary-text;
    text-decoration: none;
}
.down_spread a:hover{
    color: $secondary-text;
    text-decoration: none;
}
.loader{
    text-align: center;
    padding: 150px 0;
    img{
        max-width: 50px;
        border-radius: 100%;
    }
  }	
.back_btn a {
    @include response-above(sm) {
        position: fixed;
        top:22px;
        left:25px;
        background:url(../../../static/images/back-arrow.png) center left no-repeat;
        text-indent: 999px;
    }
    @include response-above(lg) {
        position: static;
        top:auto;
        left:auto;
        color:$PrimaryColor-text !important;
        background:url(../../../static/images/left_arrow.png) center left no-repeat;
        padding-left: 10px;
        font-family: $secondary-font-regular;
        font-size:16px;
    }
}

.no_matching_result{
    justify-content: center;
    align-items: center;
    text-align: center;
    width:100%;
    height: 200px;
    display: flex;
    font-weight: bold;
    font-size:22px;
}