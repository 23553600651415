@import "../../../styles/standard.module.scss";

.error_border {
	border: 1px solid $Error !important;
}
.error_message {
	display: block;
	color: $Error;
	padding-top: 4px;
	font-family: $secondary-font-regular;
	font-size:14px;
}
.text_field_label {
	font-size: $Typography-2;
	color: $PrimaryColor-text;
	font-family: $secondary-font-regular;
}
.form_control:focus {
	box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%);
	border-radius: 2px;
}
.form_control {
	border-radius: 2px;
}
.eyeopen, .eyeclose{
    position: absolute;
    right: 10px;
    top: 6px;
}

.char_count{
    float: right;
    color: #999999;
    font-family: $secondary-font-bold;
    font-size: 16px;
}
.char_count_font
{
	vertical-align: inherit;
}

.risk_content{
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .risk_content .col_spec {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
    display: flex;
  }
  .label_spec span{
    margin-bottom: 0;
    font-family: $secondary-font-regular;
    font-size: 16px;
    line-height: normal;
  }
  .risk_select_box{
    width: 102%;
    margin: 4px 0 7px 0px;
    padding: 0;
    min-height:40px;
    height:auto;
    border-radius: 2px;
    border: solid 0px #c1c7d0;
    background: #ffffff url("../../../static/images/down-arrow.png")  right 3px  top 10px no-repeat !important;
  }

  .risk_select_box .dropdown_toggle{
    width:100% !important;
    background-color:transparent !important;
    color: #333 !important;
    text-align: left !important;
    border-radius: 2px !important;
    border: solid 1px #c1c7d0 !important;
  }
  .risk_drop_down{
    width: 102% !important;
    max-height: 320px !important;
    overflow-y: auto !important;
    padding: 0px;
  }
  .risk_drop_down .risk_item_wrap{
    white-space:normal !important;
    padding:5px 10px !important;
  }
  .risk_drop_down:hover{
    cursor: pointer;
  }
  
  .astrick {
	  color: $Astrik;
  }
  .select_wrapper {
    border-radius: 2px;
    border: solid 1px $border-color;
    height:40px;
    background: url('../../../static/images/down-arrow.png') right 10px center no-repeat;
  }
  .cat {
    font-family: $secondary-font-bold;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .dropdown_toggle::after {
    border: 0px !important;
    display: none !important;
  }
  .risk_item_wrap:not(:last-child) {
    border-bottom: 1px solid #c8c7cc;
  }
  .recommended {
    font-family: $secondary-font-bold;
    font-size: 11px;
    text-align: center;
    color: $Plain-text;
    padding: 3px 0 3px 3px;
    border-radius: 4px;
    background-color: $btn-fetch;
    max-width: 90px;
    margin-top: 4px;
  }
  .risk_item_wrap_selected {
    color: #fafafa;
    text-decoration: none;
    background-color: #007bff;
  }
  .material_description{
    display:flex;
  }
  .tick_div{
    margin-top: -3px;
  }
  .tick_div img{
    width: 13px;
    height: 13px;
    position: absolute;
    right: 3px;
    margin-top:9px;
  }

  .risk_item_wrap:hover{
    color: black;
    text-decoration: none;
    background-color: lightgray !important;
  }
  .material_code{
    font-style: italic;
}