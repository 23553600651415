@import "../../../styles/standard.module.scss";
.fix_column {
  float: left;
  width: 50%;
}
.thead {
  height: 56px;
  white-space: nowrap;
  background-color: $thead-bg;
  position: relative;
}
.thead > span { //worked on this External Formulary
  display: inline-block;
  width: 120px;
  line-height: 40px;
  box-shadow: 0;
  background-color: $thead-bg;
  color: $Plain-text;
  text-align: left;
  font-family: $secondary-font-regular;
  font-size: 14px;
  padding-left: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.trow {
  white-space: nowrap;
  border-bottom: 1px solid #cccccc;
  height: 40px;
}
.trow > span {
  display: inline-block;
  /* width: 100%;*/
  /*  box-shadow: inset 0 0 1px 0 rgba(0,0,0,.5);    
    line-height: 80px;
    height: 80px; */
  padding: 8px 10px 10px;
  min-height: 40px;
  height: 40px;
  border-bottom: 1px solid $tertiary-text;
}
/* .trow > span::after {
  display: block;
  content: " - ";
  opacity: 0;
  font-size: 1px;
} */
.rest_columns .thead{
  width:calc(100% - 16px);
}
.tbody {
  height: 500px;
  width:100%;
  overflow: auto;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: $secondary-font-regular;
  font-size: 14px;
  box-shadow: 3px 5px 5px 0 rgba(191, 191, 191, 0.3);
}
span {
  input {
    border: 0;
    line-height: normal;
    background: transparent;
    &:focus {
      border: 0;
      outline: -webkit-focus-ring-color auto 0;
    }
    &:last-child {
      width: 100%;
    }
  }
}
.row_select {
  .thead {
    span {
      &:first-child {
        width: 50px;
        padding-left: 0;//added for External Formulary
      }
    }
  }
}
.fix_column > .tbody {
  overflow: hidden;
  background: $trow-left;
}
.row_select > .tbody > .trow {
  span {
    &:first-child {
      width: 50px;
      //background:$trow-left;
    }
  }
}

.fix_column > .tbody > .trow {
  margin-top: -50px;
  margin-bottom: 50px;
  span {
    &:nth-child(2n) {
      //background:$trow-left;
    }
    &:nth-child(3n) {
      //background:$trow-left;
    }
  }
}
.fix_column > .tbody > .trow:first-child {
  margin-top: 0px;
}
.no_data {
  width: 100% !important;
}
.rest_columns {
  //width: calc(100% - 410px) ;
  width: 50%;
  background-color: $thead-bg;
  box-shadow: 5px 10px 20px 0 rgba(191, 191, 191, 0.3);
  float: left;
  //max-width: fit-content;
}
.rest_columns > .thead {
  overflow: hidden;
}
/* .rest_columns > .thead > span {
  margin-right: 45px;
  margin-left: -50px;

  &:last-child {
    width: 135px;
    margin-right: 0;
  }
}
 */
.fixed_none + .rest_columns > .thead > span {
  margin-right: 0 !important;
  margin-left: 0 !important;

  &:last-child {
    width: 20px;
    margin-right: 0;
  }
  .last_span {
    width: 20px;
  }
}
.last_span {
  width: 20px;
}
.rest_columns > .thead > :first-child {
  margin-left: 0px;
}
.rest_columns > .tbody {
  background: $Plain-bg;
}
.rest_columns > .no_height {
  background: $Plain-bg;
}
.sort_by_asc {
  background: url(../../../static/images/uparrow-icon.png) 5px 8px no-repeat;
  width: 20px !important;
  height: 20px !important;
  display: inline-block;
}
.sort_by_desc {
  background: url(../../../static/images/downarrow-icon.png) 5px 15px no-repeat;
  width: 20px !important;
  height: 20px !important;
  display: inline-block;
}
.sort_by_default {
  background: url(../../../static/images/sort_icon.png) 5px 10px no-repeat;
  width: 20px !important;
  height: 20px !important;
  display: inline-block;
}

.cost_input {
  white-space: nowrap;
  width: 120px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.cost_input_formulary {
  font-weight: bold !important;
  white-space: nowrap;
  width: 120px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  font-size: 15px !important;
}

.cursor_pointer {
  cursor: pointer;
}

.no_data_table_center {
  height: 200px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
}

.thead_scroll {
  overflow-y: hidden !important;
  overflow-x: overlay !important;
  width: 100%;
}

.custom_check_box {
  width: 16px;
  height: 16px;
}

/* custome checkbox */

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.custom-control-input.is-invalid ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  border-color: $border-color;
}

.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
  color: $Error;
}

.custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before {
  border-color: $border-box;
  background-color: $CheckBox-bg !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: $Plain-bg;
  border-color: $border-box;
  background-color: $CheckBox-bg !important;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50% / 50% 50% no-repeat;
}

/* custome checkbox */

.sel-width {
  width: 75px;
  display: inline-block;
  border: 0;
  padding: 0;
  height: auto;
}

.phone-input {
  width: calc(100% - 85px);
  display: inline-block;
  border: 0;
  padding: 0;
  height: auto;
}

.custom_control_input {
  &:checked ~ .custom_control_label {
    &::before {
      color: $Plain-text;
      border-color: $border-box;
      background-color: $CheckBox-bg !important;
    }
  }
  z-index: 1 !important;
}

.custom_control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1rem !important;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.custom_checkbox .custom_control_label::before {
  border-radius: 0;
}
.custom_checkbox_header {
  margin-left: 11px;
  margin-top: 9px;
}
.custom_checkbox {
  min-height: 1rem !important;
}

.thead .custom_checkbox {
  position: absolute;
  top: -50px;
  left: 0px;
}
.rest_fullwidth {
  width: 100% !important;
}
.fixed_none {
  display: none !important;
}
.no_height {
  max-height: 500px;
  overflow: hidden;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: $secondary-font-regular;
  font-size: 14px;
  box-shadow: 3px 5px 5px 0 rgba(191, 191, 191, 0.3);
}
.rest_columns .no_height {
  overflow-x: auto;
}
.formulary {
  overflow-y: scroll;
  max-height: 400px !important;
  min-height: 400px !important;
  border: 1px solid #ccc;
  span {
    &:first-child {
      width: 5% !important;
    }
  }
}
.formulary_head {
  > span {
    &:first-child {
      width: 0 !important;
    }
  }
}
.no_matching_result {
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 200px;
  display: flex;
  font-weight: bold;
  font-size: 22px;
}
.cursor_default {
  cursor: default;
}
.no_thead{
  height: 65px;
  width: 100%;
  display: inline-flex;
}
.no_thead .cost_input{display: table !important;padding-top: 10px;}

.trow_strike,.span_strike{
  /*background: repeating-linear-gradient(
    180deg,
    #212529 0%,
    #212529 100%
  );
  background-size: 100% 1px;
  background-position: center;
  background-repeat: no-repeat;*/
  text-decoration: line-through;
}

.trow > span > img{
  margin-top: -7px;
  min-height: 40px;
  max-height: 40px;
  object-fit: scale-down;
}