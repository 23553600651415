@import "../../../styles/standard.module.scss";

.tab_content_top{
  border-bottom: solid 1px #a9a9a9;
  margin-bottom: 15px;
  margin-top: -15px;
}
.tab_content_hdg{
  padding: 24px 24px 16px;
  margin-bottom: 0;
  font-family: $secondary-font-regular;
  position: relative;
}

.basic_info {
  padding: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.basic_info div {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
}

.basic_info_form {
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.basic_info_form div {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
  display: flex;
  align-items: center;
}
.subheading_2 {
  font-family: $primary-font-regular;
  font-size: 21px;
  color: $PrimaryColor-text;
  margin-bottom: 0;
}

.single_product_block {
  padding: 0 24px;
}
.patient_content {
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.moreinfo_content,
.patient_content {
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.moreinfo_content .col_spec,
.patient_content .col_spec {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
  display: flex;
  flex-wrap: wrap;
}

.patient_content .basic_input,
.moreinfo_content .basic_input {
  width: 100%;
  height: 40px;
  padding: 0 5px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
}

.patient_content .select_wrapper,
.moreinfo_content .select_wrapper {
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
  height: 40px;
}

.patient_content .basic_select,
.moreinfo_content .basic_select {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  appearance: none;
  -webkit-appearance: none;
  outline: 0;
  background: 0;
  border: 0;
}
select::-ms-expand {
  display: none;
}

.label_spec {
  margin-bottom: 0;
  font-family: $secondary-font-bold;
}

/* Below for error message alignment */
// .label_spec ~ span {
//     margin-left: 50%;
// }


.content_spec {
  font-family: $secondary-font-regular;
  color: $PrimaryColor-text;
  display: inline-block;
}
.basic_input {
  width: 250px;
  height: 40px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
}
.small_input {
  width: 68px;
  height: 40px;
  padding-left: 7px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
}
.medium_input {
  width: 100px;
  height: 40px;
  padding-left: 7px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
}

.basic_select {
  width: 250px;
  height: 40px;
  padding: 0 10px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
}

.btn_custom {
  height: 50px;
  border-radius: 2px;
  font-family: $secondary-font-regular;
  background-color: $PrimaryButton-bg;
  font-size: 16px;
}
.btn_small,
.btn_small:hover {
  @include btn_primary;
  margin-right: 32px;
}
.btn_small_cancel,
.btn_small_cancel:hover,
.btn_small_cancel:active {
  @include btn_secondary;
}
.admission_upper {
  position: relative;
}
.admission_fix {
  position: absolute;
  top: 0;
  right: -7px;
}
.admission_date {
  padding: 7px;
  border: 1px solid $adm-color;
  width: 100px;
}
.select_wrapper {
  background: url("../../../static/images/down-arrow.png") right 10px center
    no-repeat;
}
.tab_edit {
  color: $PrimaryColor-text;
  font-family: $secondary-font-regular;
  font-size: 14px;
  position: absolute;
  right: 24px;
  top: 65px;
  z-index: 1000;
}

.tab_edit a,
.tab_edit a:hover {
  color: $PrimaryColor-text;
}
.general_error {
  margin-bottom: 20px;
  color: #fff;
  border-radius: 3px;
  padding: 5px 15px;
  display: inline-block;
  background-color: $Error;
}
.admission_time_fix {
  margin-left: 5px;
}
.basic_input.disabled {
  background-color: #dbdbdb !important;
}

.basic_select.disabled {
  background-color: #dbdbdb !important;
}
.hidden {
  display: none !important;
}
.int_str {
  background: #dbdbdb;
  border: 1px solid #c1c7d0;
  border-radius: 2px;
  padding: 7px 5px !important;
  color: #6b6b6b;
}

/*Adding Checkbox remove_icon */

.input_icons {
  padding-left: 0!important;
  padding-right: 0!important;
}

.x_icon {
  position: absolute;
  right: 22%;
  top: 8px;
  cursor: pointer;
}

.excla_icon{
  position: absolute;
  left: -18px;
  top: 0px;
  cursor: pointer;
  font-size: 24px;
}

.tooltip {
  // position: relative;
  display: inline-block; 
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 105px;
  background-color: white;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 7px 0;
  position: absolute;
  z-index: 1;
  font-size: 12px;
  font-family: $secondary-font-regular;
  font-weight: 500;
  letter-spacing: 0.01em;
  border: 1px solid #4f4f4f;
  right: 285%;
  top: -85%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}



.close_icon {  
  cursor: pointer;
  display: inline-flex;
  // margin-top: -2px;
  // padding-right: 15px;
  margin-top: 4px;
  padding-left: 7px;
  padding-right: 7px;
}


//added new

.vertical_Code {
  border-left: solid 2px #636363;
    margin-left: -25px;
    margin-top: 5px;
    height: 30px;
}

.close_icon_list {
  cursor: pointer;
  display: inline-flex;  
  margin-top: -2px;
  // padding-left: 4px;
  padding-right: 7px;
}

/*Toggle button Style*/
.toggle_shells{
  // margin-left: 35px;
  padding-left: 55px;
}
.toggle_switch {
  transform: scale(1.4, 1.1);
  // margin-left: 16px;
}

/*Error Message Facilities*/
.error_CustNum{
  display: inline-flex;
  margin-left: 25%;
  margin-top: -40px;
}

.error_message {
  display: block;
  color: #de350b;
  font-family: $secondary-font-regular;
  font-size: 14px;
}
/*Error Message Default*/
.error_CustDefu{
  margin-right: 27%;
  margin-top: -35px;
  font-size: 15px!important;
  font-weight: 500;
}


/*CustomerNumber Suggestions*/
.search_drop{  
  top: -21px;
  left: 35%;
  position: absolute;
  z-index: 999 !important;
  background-color: #ffffff;
  width: 46%;
}

.search_close_box {
  border: 1px solid $border-color;
  padding: 10px 5px;
  max-height: 250px;
  overflow-y: auto;
  
}
.search_close_box p {
  margin-bottom: -1px;
  padding: 6px;
  cursor: pointer !important;
}
