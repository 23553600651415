@import '../../../styles/standard.module.scss';
.product_video h3{
    text-align: center;
    margin: 25px 0;
    font-size: 18px;
}
.product_video h4{
    font-size: 15px;
    margin: 10px 0 5px 0;
    font-family: $primary-font-bold;
}
.upload_block{
    width: 100%;
    min-height: 130px;
    padding: 20px;
    background-color: #f6f6f6;
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text_area{
    width: 300px; 
    height: 80px;
    font-size: 14px;
    padding: 8px;
}
.btn_custom {
    height: 40px;
    border-radius: 2px;
    font-family: $secondary-font-regular;
    background-color: $PrimaryButton-bg;
    font-size: 18px; 
    margin: 0 10px;
    padding: 0;
}
.btn_small,
.btn_small:hover {
    width: 128px; 
    height: 40px;
    font-family: $secondary-font-regular;
    background-color: $PrimaryButton-bg;
    color: $Plain-text;
    font-size: 14px;
}
.btn_small_cancel,
.btn_small_cancel:hover,
.btn_small_cancel:active {
    width: 128px;
    height: 40px;
    font-family: $secondary-font-regular;
    background-color: $Plain-bg !important;
    border: 1px solid $PrimaryColor-text !important;
    color: $PrimaryColor-text !important;
    font-size: 14px;
}
.uploading_btn{
    background-color: #36b37e !important;
}
.loader{
    text-align: center;
    img{
        max-width: 50px;
        border-radius: 100%;
    }
}