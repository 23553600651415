@import "../../../styles/standard.module.scss";

.link_style2,
.link_style2:hover {
  color: $secondary-text !important;
  font-size: $Typography-3 !important;
  font-family: $secondary-font-bold;
}

.tel-input {
  width: 68% !important;
  margin-left: -3px;
  padding-right: 1px;
}

.custom_btn {
  border-radius: 3px;
}
.policy_link {
  font-weight: bold;
  color: #0052cc;
  font-size: 13px;
}
.policy_link:hover {
  text-decoration: none;
}
.check_policy {
  font-size: 13px;
  letter-spacing: 0.2px;
}

.hr_sect {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  color: rgba(0, 0, 0, 0.35);
  margin: 8px 0;
  font-weight: 900;
  font-size: 18px;
  font-family: $secondary-font-bold;
  &:before,
  &:after {
    content: "";
    flex-grow: 1;
    background: rgba(0, 0, 0, 0.35);
    height: 1.6px;
    font-size: 0;
    line-height: 0;
    margin: 0 8px;
  }
}
.info_icon {
  margin-top: -3px !important;
  position: absolute;
}
.custom_btn_cancel {
  background-color: #ffff !important;
  color: #0052cc;
  font-size: 16px;
  font-family: $secondary-font-bold;
  border-radius: 3px;
}

.custom_btn_cancel,
.custom_btn_cancel:hover,
.custom_btn_cancel:active {
  font-family: $secondary-font-regular;
  border: 1px solid #0052cc !important;
  border-radius: 2px;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  color: #0052cc !important;
  font-weight: 900;
}
.col_spec {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
  display: flex;
  flex-wrap: wrap;
}
.basic_select {
  width: 100%;
  height: 40px;
  padding: 0 30px 0 10px;
  appearance: none;
  -webkit-appearance: none;
  outline: 0;
  background: 0;
  border: 0;
}
.label_spec {
  margin-bottom: inherit;
  font-size: 1rem;
  color: #333333;
  font-family: $secondary-font-regular;
}
.select_wrapper {
  border-radius: 2px;
  border: solid 1px $border-color;
  height: 40px;
  background: $Plain-bg url("../../../static/images/down-arrow.png") right 10px center no-repeat;
  display: inline-block;
}
.search_drop {
  top: -15px;
  left: -13px;
  position: relative;
  z-index: 1 !important;
  background-color: $Plain-bg;
  width: 387px;
}
.search_close_box {
  border: 1px solid $border-color;
  padding: 10px 5px;
  max-height: 250px;
  overflow-y: auto;
}
.search_close_box p {
  margin-bottom: 5px;
  padding: 6px;
  cursor: pointer !important;
}
.error_border {
  border: 1px solid $Error !important;
}
.error_message {
  display: block;
  color: $Error !important;
  padding-top: 4px;
  font-family: $secondary-font-regular;
  font-size: 14px !important;
}
.btn_nxt_back {
  width: 180px !important;
  height: 42px;
  float: left;
}
.btn_back {
  background-color: #ffffff;
  font-family: inherit !important;
  border: 1px solid #0052cc !important;
  border-radius: 2px;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  color: #0052cc !important;
}

.btn_back,
.btn_back:hover {
  @include btn_secondary;
}
.close_password {
  float: right;
  cursor: pointer;
  border: 2px solid #fafafa;
  position: relative;
  padding: 1px 7px;
  top: -40px;
  left: 16px;
  background-color: #fafafa;
  border-radius: 50%;
}
.close_password:before {
  content: "×";
  font-size: 15px;
  font-weight: bold;
  color: #333;
  text-decoration: none;
}
.risk_select_box {
  width: 100%;
  margin: 4px 0 7px 0px;
  padding: 0;
  min-height: 40px;
  height: auto;
  border-radius: 2px;
  border: solid 0px #c1c7d0;
  background: #ffffff url("../../../static/images/down-arrow.png") right 3px top 10px no-repeat !important;
}
.risk_select_box .dropdown_toggle {
  width: 100% !important;
  background-color: transparent !important;
  color: #333 !important;
  text-align: left !important;
  border-radius: 2px !important;
  border: solid 1px #c1c7d0 !important;
}
.risk_drop_down {
  width: 102% !important;
  max-height: 320px !important;
  overflow-y: auto !important;
  padding: 0px;
}
.risk_drop_down .risk_item_wrap {
  white-space: normal !important;
  padding: 5px 10px !important;
}
.risk_drop_down:hover {
  cursor: pointer;
}

.astrick {
  color: $Astrik;
}
.select_wrapper {
  border-radius: 2px;
  border: solid 1px $border-color;
  height: 40px;
  background: url("../../../static/images/down-arrow.png") right 10px center no-repeat;
}
.cat {
  font-family: $secondary-font-bold;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown_toggle::after {
  border: 0px !important;
  display: none !important;
}
.risk_item_wrap:not(:last-child) {
  border-bottom: 1px solid #c8c7cc;
}
.risk_item_wrap_selected {
  color: #fafafa;
  text-decoration: none;
  background-color: #007bff;
}
.material_description {
  display: flex;
  white-space: pre-line;
    text-align: left;
    padding: initial;
}
.risk_item_wrap:hover {
  color: black;
  text-decoration: none;
  background-color: lightgray !important;
}
