@import "../../../styles/standard.module.scss";

.subheading_2 {
  font-family: $primary-font-regular;
  font-size: 21px;
  color: $PrimaryColor-text;
  margin-bottom: 0;
}
.create_patient {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
  display: flex;
  flex-wrap: wrap;
}
.col_spec {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 16px 0 0;
  width: auto;
  display: flex;
  flex-wrap: wrap;
}
.basic_input {
  width: 100%;
  height: 40px;
  padding: 0 5px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
}
.label_spec {
  margin-bottom: 0;
  font-family: $secondary-font-bold;
}
.btn_small {
  width: 140px;
  height: 42px;
  font-family: $secondary-font-regular;
  background-color: $PrimaryButton-bg;
  color: $Plain-bg;
  font-size: $Typography-2;
}
.btn_custom {
  height: 50px;
  border-radius: 2px;
  font-family: $secondary-font-regular;
  background-color: $PrimaryButton-bg;
  font-size: $Typography-1;
}
.btn_small,
.btn_small:hover {
  @include btn_primary;
  margin-right: 32px;
}
.btn_small_cancel,
.btn_small_cancel:hover,
.btn_small_cancel:active {
  @include btn_secondary;
}
.basic_input:focus-visible {
  outline: none !important;
}

.select_wrapper {
  background: url("../../../static/images/down-arrow.png") right 10px top 10px
    no-repeat;
  border-radius: 2px;

  background-color: $Plain-bg;
  min-height: 40px;
}
.basic_select {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border-radius: 2px;
  border: solid 1px $border-color;  
  background-color: transparent;
  appearance: none;
}
.custom_modal_size {
  max-width: 960px;
}
.custom_label_spec {
  width: 170px;
  padding-left: 15px;
}
.basic_select_pro {
  padding-right:36px;
  }


  .patient_content_mrn_field{
    background-color: #e4eaf1;
    padding: 20px 0px 2px 0px;
    width: 96%;
    margin-bottom: 20px;
    margin-top: 5px;
  }



  .tooltip {
    position: relative;
    display: inline-block; 
    float: right;
    top: -25px;
    padding: 5px;
  }
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 250px;
    font-size: 13px;
    height: 50px;
    // overflow-y: scroll; 
    text-align: center;
    background-color: white;
    color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 7px 0;
    position: absolute;
    z-index: 1;
    font-family: $secondary-font-regular;
    font-weight: 500;
    letter-spacing: 0.01em;
    border: 1px solid #4f4f4f;
    right: -85px;
    top: -60px;
    box-shadow: 0 1px 0px 1px #000;
  }
  
  .tooltip .tooltiptextOther{
    visibility: hidden;
    width: 110px;
    font-size: 7px;
    height: 105px;
    overflow-y: scroll;    
    background-color: white;
    color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 7px 0;
    position: absolute;
    z-index: 1;
    font-family: $secondary-font-regular;
    font-weight: 500;
    letter-spacing: 0.01em;
    border: 1px solid #4f4f4f;
    right: 101%;
    top: 25%;
    box-shadow: 0 1px 0px 1px #000;
  }
  
  .tooltip:hover .tooltiptext, .tooltip:hover .tooltiptextOther {
    visibility: visible;
  }
  
  .tooltip_list{
    // top: -3px !important;
    top: -58px !important;
    left: -110px;
  }