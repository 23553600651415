@import '../../../styles/standard.module.scss';
.down_spread{
    color:#0052cc;
    font-family: $secondary-font-bold;
    font-size:16px;
    display: inline-block;
    padding:15px 0 5px;
}
.down_spread a, .down_spread:hover{
    color:#0052cc;
    margin-bottom: 0;
    margin-left: 10px;
    text-decoration: none;
}
.excel, .excel:hover{
  padding-left: 10px;
  color:#0052cc;
  margin-bottom: 0;
  margin-left: 10px;
  text-decoration: none;
  cursor: pointer;
}
.no_matching_result {
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 200px;
    display: flex;
    font-weight: bold;
    font-size: 22px;
  }
  .empty {
    margin: 30px;
    position: relative;
    z-index: 1;
    .empty_heading {
      height: 56px;
      padding: 0px 10px;
      background-color: #505f79;
      line-height: 56px;
      color: #fff;
      font-size: 14px;
      position: relative;
      z-index: 7;
      span {
        margin: 0px 15px;
      }
    }
}