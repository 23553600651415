@import "../../../styles/standard.module.scss";

.tab_content_top {
    border-bottom: solid 1px $tab-border-bottom;
    margin-bottom: 15px;
   
}
.tab_content_hdg {
    padding: 24px 24px 16px;
    margin-bottom: 0;
    font-family: $secondary-font-regular;
    position: relative;
}
.col_spec {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0px 3px 1px 0;
    width: auto;
    display: flex;
}
.single_product_block {
    padding: 0 24px;
  }

.content_spec {
    font-family: $secondary-font-regular;
    color: $PrimaryColor-text;
    display: inline-block;
}
.basic_input {
    width: 250px;
    height: 40px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}
.small_input {
    width: 68px;
    height: 40px;
    padding-left: 7px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}

.btn_custom {
    height: 50px;
    border-radius: 2px;
    font-family: $secondary-font-regular;
    background-color: $PrimaryButton-bg;
    font-size: 18px;
}
.btn_small,
.btn_small:hover {
    @include btn_primary;
    width:116px;
}
.btn_small_cancel,
.btn_small_cancel:hover {
    @include btn_secondary; 
}

.formulary_content{
    position: relative;
}

.formulary_content .label_spec {
    display: inline;
    width: 200px;
    float: left;
}

.label_spec {
    margin-bottom: 0;
    font-family: $secondary-font-bold;
    line-height: normal;
}

.autocomplete {
    width: 543px;
    height: 45px;
    border: solid 1px $border-auto;
    background-color: $Plain-bg;
    font-family: $secondary-font-regular;
    color:$tertiary-text;
    float: left;
    position: relative;
}

.autocomplete input {
    height: 43px;
    background-color: transparent;
    border: 0;
    padding-left: 10px;
    color: $black-color;
    font-family: $secondary-font-regular;
    width: 100%;
}

.search_drop{
    top: -40px;
    left: 224px;
    position: absolute;
    z-index: 1 !important;
    background-color: $Plain-bg;
    width: 543px;
}

.search_close_box {
    border: 1px solid $border-color;
    padding: 10px 5px;
    max-height: 250px;
    overflow-y: auto;
    
}
.search_close_box p {
    margin-bottom: 5px;
    padding: 6px;
    cursor: pointer !important;
}

.autocomplete_disable {
    background-color: $trow-left;
}
.content_center{
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: CENTER;
    width: 100%;
    display: flex;
    min-height: 250px;
    font-family: $secondary-font-bold;
}
.search_btn{
    position: absolute;
    right: 10px;
    top: 7px;
}
.form_alert{
    display: inline-block;
}

.admin_meta{
    font-size: 17px;
    font-weight: normal;
    margin: 0px 10px 15px 15px;
    span{
        margin-right: 10px;
        b{
            font-weight: 600;
        }
    }
}

//added for External
.edit_duplicate_external{
    text-align: right;
    margin-bottom: 20px;
    position: absolute;
    right: 0;
    bottom: -20%;
    span{
        cursor: pointer;
        margin: 0 5px;
        color: $filter-btn !important;
        font-weight: 500;
        padding-right: 5px;
        img{
            max-width: 14px;
        }
        &:hover{
            color: $PrimaryColor-text !important;
            text-decoration: none;
        }
    }
}

.no_btn {
    width: 100px;
    border-radius: 0px;
    background-color: $Body-bg;
    color: $PrimaryColor-text;
    border: solid 1px $bdr-color-tertiary;
  }
  .no_btn:hover {
    background-color: $Body-bg;
    color: $PrimaryColor-text;
    border: solid 1px $bdr-color-tertiary;
  }
  .no_btn:active {
    background-color: $Body-bg !important;
    color: $PrimaryColor-text !important;
    border: solid 1px $bdr-color-tertiary !important;
  }
  .no_btn:focus {
    background-color: $Body-bg;
    color: $PrimaryColor-text;
    border: solid 1px $bdr-color-tertiary;
  }
  .yes_btn {
    width: 100px;
    border-radius: 0px;
  }



/*External admin Formulary */
.searchImage {
    margin-left: 216px;
    position: absolute;
    top:92px;
}
.searchBar{
    height: 36px;
    width: 200px;
    background-color: #f0f0f0;
    border-radius: 2px;
    border: 1px solid #c1c7d0;
}
.searchPos{
    margin-top: -25px;
    margin-left: 5px;
    background-color: #f0f0f0;
    option{
        background-color: #f0f0f0;
    }
 }

 .searchDrop{
    height: 36px;
    width: 543px; 
    border-radius: 2px;
    border: 1px solid #c1c7d0;
}
.searchDrop:focus {
    outline: none !important;
    border: 4px solid #80bdff;
    border-radius: 0.25rem;
  }
  #searchDropDown .option{
	background-color: blue !important;
}

.searchPosition{
    margin-top: -57px;
    margin-left: 205px;
    input{
        padding-left: 40px;
      }
}
.loader{
    text-align: center;
    padding: 150px 0;
    img{
        max-width: 50px;
        border-radius: 100%;
    }
  }