@import "../../../styles/standard.module.scss";
.mesurement_blk {
  font-family: $secondary-font-regular;
  font-size: 16px;
  color: #333;
  padding: 24px 10px;
  margin-bottom: 0;
  max-height: 750px;
  overflow-y: scroll;
  padding-bottom: 0;
}
.mesurement_blk p {
  padding: 0;
  margin-bottom: 16px;
}
.measurement_heading {
  font-family: $secondary-font-bold;
  font-size: 24px;
  color: #333333;
  line-height: normal;
}
.mesurement_blk label {
  font-family: $secondary-font-regular;
  font-size: 16px;
  color: #333;
  padding: 0;
  margin-bottom: 0;
  margin-right: 5px;
}
.mesurement_blk span {
  font-family: $secondary-font-regular;
  font-size: 16px;
  color: #333;
  margin-bottom: 0;
}
.support_block {
  background-color: #fafafa;
  height: calc(100% - 105px);
  padding: 10px 32px;
  position: relative;
}
.traj_heading {
  color: #333333;
  font-family: $secondary-font-bold;
  font-size: 20px;
  margin-bottom: 3px;
}

.traj_bwat {
  width: 90px;
  height: 60px;
  margin: 0 20px 0 0;
  padding: 5px 0px 0 0;
  border: solid 1px #cccccc;
  text-align: center;
}


.traj_img_zoom {
  width: 202px;
  height: 40px;
  opacity: 0.8;
  border-radius: 6px;
  background-color: #504d4a;
  position: absolute;
  bottom: 10px;
  left: 50px;
  img {
    padding: 10px 0 10px 30px;
  }
}

%scrub-image {
  background: #0052cc url("../../../static/images/scrub.JPG") no-repeat !important;
  background-size: 13px !important;
  background-position-x: 1px !important;
  background-position-y: 1px !important;
}

.rc_style_fix {
  width: 84%;
  margin-left: 8%;
  & > div[class="rc-slider-rail"] {
    height: 3px;
    background-color: #fff;
  }
  & > div[class="rc-slider-handle"] {
    height: 15px;
    width: 15px;
    border: 0px;
    @extend %scrub-image;
  }
  & > div[class="rc-slider-handle-click-focused"] {
    height: 15px;
    width: 15px;
    border: 0px;
    @extend %scrub-image;
    &:focus {
      @extend %scrub-image;
    }
  }
}
.rc_outer {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  height: 40px;
  padding: 13px 10px;
  width: 250px;
  opacity: 0.8;
  border-radius: 5px;
  background-color: #444;
}
.ch_bold{
  font-weight: bold;
  font-family: $secondary-font-regular;
}
.measure_surface {
  width: 100%;
  border-left: 7px solid $measure-surface;
  background-color: rgba(0, 221, 127, 0.05);
  font-family: $secondary-font-regular;
  padding: 5px 5px 5px 15px !important;
  margin-bottom: 0 !important;
  color: $PrimaryColor-text;
  .ch_bold{
    font-family: $secondary-font-bold;
    font-weight: bold;
  }
}
.measure_length {
  width: 100%;
  border-left: 7px solid $measure-length;
  background-color: rgba(0, 137, 255, 0.05);
  font-family: $secondary-font-regular;
  padding: 5px 5px 5px 15px !important;
  margin-bottom: 0 !important;
  color: $PrimaryColor-text;
}
.measure_width {
  width: 100%;
  border-left: 7px solid $measure-width;
  background-color: rgba(0, 221, 127, 0.05);
  font-family: $secondary-font-regular;
  padding: 5px 5px 5px 15px !important;
  margin-bottom: 0 !important;
  color: $PrimaryColor-text;
}
.measure_items {
  border-left: 7px solid $Plain-bg;
  padding: 5px 5px 5px 15px !important;
  margin-bottom: 0 !important;
  color: $PrimaryColor-text;
}

.change_surface {
  width: 100%;

  background-color: rgba(0, 221, 127, 0.05);
  font-family: $secondary-font-regular;
  padding: 5px 5px 5px 0px !important;
  margin-bottom: 0 !important;
  color: $PrimaryColor-text;
  .ch_bold{
    font-weight: bold;
  }
}
.change_length {
  width: 100%;
  background-color: rgba(0, 137, 255, 0.05);
  font-family: $secondary-font-regular;
  padding: 5px 5px 5px 0px !important;
  margin-bottom: 0 !important;
  color: $PrimaryColor-text;
}
.change_width {
  width: 100%;
  background-color: rgba(0, 221, 127, 0.05);
  font-family: $secondary-font-regular;
  padding: 5px 5px 5px 0px !important;
  margin-bottom: 0 !important;
  color: $PrimaryColor-text;
}
.change_items {
  padding: 5px 5px 5px 0px !important;
  margin-bottom: 0 !important;
  color: $PrimaryColor-text;
}
.image_block{
  img{
    object-fit: cover;
    width: 600px;
  }
}
.change_margin_fix{
  >div{
    >span{
      margin-left: -13px;
    }
  }
}
.surfaceText{
  font-weight:bolder;
}


//added for Label

.traj_bwat_clouser{
  width: 90px;
  height: 60px;
  margin: -8px 20px 0 0;
  text-align: center;
  font-weight: bold;
}
.closureColor {  
  font-size: 16px!important;
  display: block;
}

.closureColor_green {
  color:#15b471!important;
}
.closureColor_red {
  color: orangered!important;
}
.closureColor_grey {
  color: grey!important;
}