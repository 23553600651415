@import "../../../styles/standard.module.scss";
.tab_pane {
  padding: 0 0 41px;
  box-shadow: 5px 10px 20px 0 rgb(191 191 191 / 30%);
  background-color: $Plain-bg;
}
.titlte_wrapper {
  padding: 19px 24px;
  border-bottom: 1px solid $tab-border-bottom;
}
.heading {
  font-family: $secondary-font-bold;
  font-size: 24px;
  font-weight: 600;
  color: $PrimaryColor-text;
}
.sub_heading {
  font-family: $secondary-font-regular !important;
  font-size: 20px !important;
}
.about_container {
  max-width: 550px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.option_container li {
  list-style-type: none;
  font-size: $Typography-3;
  padding-top: 11px;
  padding-bottom: 9px;
  border-bottom: 1px solid #979797;
}
.option_title {
  color: $PrimaryColor-text;
  font-family: $secondary-font-bold;
  padding-bottom: 4px;
}
.option_container li i {
  font-family: $secondary-font-bold;
  font-style: normal;
  color: $PrimaryButton-bg;
}
.option_container li i:hover {
  cursor: pointer;
}
.btn_cancel,
.btn_cancel:hover,
.btn_cancel:active {
  width: 140px;
  height: 42px;
  padding: 10px 0px 10px;
  border-radius: 2px;
  border: solid 1px $tertiary-text !important;
  background-color: $Body-bg !important;
  color: $PrimaryColor-text !important;
  font-family: $secondary-font-regular;
  font-size: $Typography-2;
}
.btn_save,
.btn_save:hover,
.btn_save:active {
  width: 140px;
  height: 42px;
  padding: 10px 0;
  border-radius: 2px;
  background-color: $secondary-text;
  font-family: $secondary-font-regular;
  font-size: $Typography-2;
  margin-right: 32px;
}
.edit_icon:hover {
  cursor: pointer;
}
.textarea_box,
.textarea_box:focus {
  max-width: 100% !important;
  border: 1px solid $border-color;
  outline: none;
  padding: 10px;
}
.icon_align {
  justify-content: flex-start;
}
.label_cutom {
  font-family: $secondary-font-bold;
  font-size: 16px;
  font-weight: 600;
  color: $PrimaryColor-text;
}
.data_color {
  color: $PrimaryColor-text;
}
.custom_modal_size {
  max-width: 732px;
}
.border_top {
  border-top: 1px solid $btn-disable;
}
.title {
  font-size: $Heading-1;
  color: $PrimaryColor-text;
  font-family: $primary-font-bold;
}
.custom_heading {
  padding-bottom: 0px;
}
.password_container {
  max-width: 345px;
  margin: 0 auto;
  padding-bottom: 28px;
}
.parent {
  position: relative;
}
.close_icon {
  position: absolute;
  right: 24px;
  top: 4px;
  z-index: 1090;
}
.radius {
  border-radius: 2px;
}
.edit_container {
  max-width: 384px;
  margin: 0 auto;
  padding-bottom: 28px;
}
.reponse_top {
  @include responsive(lg) {
    padding-top: 60px;
  }
}
.user_picture {
  // max-width: 67px;
  width: 67px;
  height: 67px;
  border-radius: 50%;
}
.success_modal {
  width: 444px;
}
.modal_contr {
  padding: 50px 40px 50px 50px;
}
.close_x {
  position: absolute;
  right: 14px;
  top: 10px;
  z-index: 1090;
}
.status_heading {
  font-size: $Heading-1;
  font-family: $primary-font-bold;
  color: $Success-text;
  margin-bottom: 8px;
}
.msg_content {
  font-family: $secondary-font-regular;
  color: $PrimaryColor-text;
}
.continue_btn,
.continue_btn:hover,
.continue_btn:active {
  height: 42px;
  padding: 10px 0;
  border-radius: 2px;
  background-color: $secondary-text;
  font-family: $secondary-font-regular;
  font-size: $Typography-2;
  line-height: 20px;
}
.userpic_blk {
  border: 0;
  background-color: transparent;
}
.option_container li a {
  font-family: $secondary-font-bold;
  font-style: normal;
  color: $PrimaryButton-bg;
}
.option_container li a:hover {
  text-decoration: none;
}