@import '../../../styles/standard.module.scss';
.subheading_2 {
    font-family: $primary-font-regular;
    font-size: 21px;
    color: $PrimaryColor-text;
    margin-bottom: 0;
}
.create_patient {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
    display: flex;
    flex-wrap: wrap;    
}
.col_spec{ 
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
    display: flex;
}
.basic_input {
    width: 100%;
    height: 40px;
    padding: 0 5px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}
.label_spec {
    margin-bottom: 0;
    font-family: $secondary-font-bold;    
}

.btn_small {
    @include btn_primary;
}
.btn_custom {
    height: 42px;
    border-radius: 2px;
    font-family: $secondary-font-regular;
    background-color: $PrimaryButton-bg;
    font-size: $Typography-2;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.custom_modal_size {
    max-width: 950px;
}

.custom_content{
  height: 550px;
  overflow-y: scroll;
}




//dropdown
.basic_input_domain{
  width: 100%;
  height: 40px;
  padding: 0 5px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
  margin-left: -32px
}
.basic_input_email{
  width: 100%;
  height: 80px;
  padding: 0 5px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
}
//Specific Email Input
.basic_input_sepcific {
  width: 100%;
  height: 40px;
  // padding: 0 5px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
  padding: 0;
}

.hidden_input{
  float: right!important;
  border: none!important;
  margin-top: 20px!important;
  visibility: hidden!important;
}
.hidden_input_column{
  float: right;
  text-align: right;
  margin-left: 17%;
  margin-top: -4%;
}
.hidden_email_column{
  float: right;
  text-align: right;
  margin-left: 33%;
  margin-top: -4%;
}
.border_red{
  border: 1px solid #ff1d1d!important;
}

.basic_select {
  width: 215px;
  height: 40px;
  padding: 0 10px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
  margin-left: -25px;
}
.basic_select_role {
  width: 210px;
  height: 40px;
  padding: 0 10px;
  border-radius: 2px;
  border: solid 1px $border-color;
  background-color: $Plain-bg;
  margin-left: -21px;
}
.vertical_Code {
  border-left: solid 1px #007bff;
  margin-top: -5px;
  height: 48px;
  margin-left: 10px;
  border-left-style: solid;
}
.vertical_Code_role {
  margin-top: -4px;
  height: 48px;
  border-left: solid 1px #007bff;
  border-left-style: solid;
}

/*CustomerNumber Suggestions*/
.search_drop{  
  top: -21px;
  left: 25%;
  position: absolute;
  z-index: 999 !important;
  background-color: #ffffff;
  width: 48%;
  margin-left: 8px;
}

.search_close_box {
  border: 1px solid $border-color;
  padding: 10px 5px;
  max-height: 250px;
  overflow-y: auto;
  
}
.search_close_box p {
  margin-bottom: -1px;
  padding: 6px;
  cursor: pointer !important;
}
.x_icon {
  position: absolute;
  right: 29%;
  top: 8px;
  cursor: pointer;
}


.search_drop_domain{
  top: -21px;
  left: 43%;
  position: absolute;
  z-index: 999 !important;
  background-color: #ffffff;
  width: 61%;
  padding-left: 1px;
}

.disabled_Column {
  background-color: #dbdbdb!important;
}




// For SSO
.label_spec_Sso{
    margin-bottom: 0;
    font-family: $secondary-font-bold; 
    float: left;
}
.astrick{
    color: #de350b
}

.custom_control_input__Sso {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 16px;
    height: 16px;
    opacity: 0;
  }
  .col_form_label {
    padding-top: calc(-1.625rem + 1px)!important;
  }
  .custom_control_input__Sso.is_invalid ~ .custom_control_label_Sso::before,
  .was_validated .custom_control_input__Sso:invalid ~ .custom_control_label_Sso::before {
    border-color: $PrimaryColor-text;
  }
  .custom_control_input__Sso.is_valid ~ .custom_control_label_Sso,
  .was_validated .custom_control_input__Sso:valid ~ .custom_control_label_Sso {
    color: $Error;
  }
  .custom_control_input__Sso.is_valid:checked ~ .custom_control_label_Sso::before,
  .was_validated
    .custom_control_input__Sso:valid:checked
    ~ .custom_control_label_Sso::before {
    border-color: $border-box;
    background-color: $CheckBox-bg;
  }
  .custom_control_input__Sso:checked ~ .custom_control_label_Sso::before {
    color: $Plain-text;
    border-color: $border-box;
    background-color: $CheckBox-bg;
    border-radius: 0;
    width: 16px;
    height: 16px;
    top: 11px;
  }

  .custom_control_label_Sso {
    position: relative;
    margin-top: 5px;
    margin-bottom: 0;
    vertical-align: top;
    font-family: $secondary-font-regular;
    font-size: 14px;
    color: $PrimaryColor-text;
    // line-height: normal;
    line-height: 1.5;
    margin-bottom: 0;
    font-size: inherit;
  }
  .custom_control_label_Sso::after {
    position: absolute;
    top: 11px;
    left: -1.5rem;
    display: block;
    width: 16px;
    height: 16px;
    content: "";
  }
  .custom_control_label_Sso::before {
    border-radius: 50% !important;
    top: 11px;
  }
  
  .custom_control_inline {
    height: 22px;
  }
  
  .custom_control_inline .custom_control_label_Sso {
    font-family: $secondary-font-bold;
    color: $text-color;
    font-size: 14px;
  }
  
  .hide_outline:focus {
    border: 0px !important;
    outline: 0 !important;
  }
  /* .custom_control_label_Sso_t0::before {
      border-radius: 50% !important;
      top: 0px;
  }
  .custom_control_label_Sso_t0::after {
      border-radius: 50% !important;
      top: 0px;
  } */
  .custom_checkbox .custom_control_label_Sso::before {
    border-radius: 0 !important;
  }

  .btn_cancel{
    background-color: #fafafa !important;
    border: 1px solid #cccccc !important;
    color: #333333 !important;
    border-radius: 2px;
  }

  .heading_Domain{
    padding: 10px 0px;
    border-bottom: 1px solid #0052cc;
  }

  /*Toggle button Style*/
.toggle_shells{
  // margin-left: 35px;
  padding-left: 55px;
}
.toggle_switch {
  transform: scale(1.4, 1.1);
  // margin-left: 16px;
}







.multiple_emails-container { 
	border:1px #00ff00 solid; 
	border-radius: 1px; 
	box-shadow: inset 0 1px 1px rgba(0,0,0,.075); 
	padding:0; margin: 0; cursor:text; width:100%; 
}

.multiple_emails-container input { 

	width:100%; 
	border:0; 
	outline: none; 
	margin-bottom:30px; 
	padding-left: 5px; 
	
}

.multiple_emails-container input{
	border: 0 !important;
}

.multiple_emails-container input.multiple_emails-error {	
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px red !important; 
	outline: thin auto red !important; 
}

.multiple_emails-container ul {	
	list-style-type:none; 
	padding-left: 0; 
}

.multiple_emails-email { 
	margin: 3px 5px 3px 5px; 
	padding: 3px 5px 3px 5px; 
	border:1px #BBD8FB solid;	
	border-radius: 3px; 
	background: #F3F7FD; 
}

.multiple_emails-close { 
	float:left; 
	margin:0 3px;
}



//multiple Email
.add_new_link{
  font-family: $secondary-font-bold;
  font-size:16px;
  color:#0052cc;
  padding-left: 10px;
  float:right;
  cursor: pointer;
}
.add_new_link img{
  margin-right: 5px;
  vertical-align: middle;
}
.search_terms{
  margin:20px 0;
  width:100%;
  align-items: center;
}

.search_terms_excl{
  margin:28px 0;
  width:100%;
  align-items: center;
}

.col_spec_unBorder{
border: none!important;
font-size: 16px!important;
}
.main_list{
  float:left;
  // margin-top: 20px;
  // width:100%;
  width: auto;
  height: auto;
  border: 1px solid silver;
  margin-left: -14px;
  padding-bottom: 25px;
  min-height: 50px;
}
.main_list_excl{
  float:left;
  // margin-top: 20px;
  // width:100%;
  width: auto;
  height: auto;
  border: 1px solid silver;
  margin-left: -17px;
  padding-bottom: 25px;
  min-height: 50px;
}
.main_list_disabled{
background-color: #dbdbdb !important; 
min-height: 50px; 
margin-left: -18px;
}
.search_list ul{
  margin:0;
  padding-left: 0;   
}

.search_list ul li{
// margin:0 20px 20px 0;    
list-style-type: none;
font-family: $secondary-font-bold;
font-size:15px;
background:#e5e5e5;
// padding:5px 10px 5px 15px;
padding: 4px 10px 5px 10px;
border-radius:21px;
width: auto;
display: inline-flex;
justify-content: center;
align-items: center;
margin-left: -13px;
margin-top: 9px;
margin-right: 18px;
&.input_li{
    height:32px;
}
}

.search_list ul li img{
padding-left: 10px;
cursor: pointer;
}

.input_li{
// margin:0 20px 20px 0;    
list-style-type: none;
font-family: $secondary-font-bold;
font-size:12px;
background-color:#fff !important;
// padding:5px 10px 5px 15px;
border-radius:21px;
width: 100%;
float: left;
// margin-left: -11px;
margin-top: 10px;
height: 12px
}

.input_li img{
padding-left: 10px!important;
padding-top: 17px;
display: none;
}



.error_message {
  display: block;
  color: #de350b;
  font-family: $secondary-font-regular;
  font-size: 14px;
  margin-top: -15px;
  margin-left: -15px;
}

.error_message_IncExc{
  display: block;
  color: #de350b;
  font-family: $secondary-font-regular;
  font-size: 14px;
  margin-top: -15px;
  margin-left: 20px;
}





.and_or_buttons{
  display: flex;
  font-size: 18px;
  font-weight: bold;
  align-items: center;
  padding-right: 5px;
}

.and_or_block{
  input[type=radio]{
    accent-color: #007bff!important;
  }
}

// .and_or_block{
//   // input[type=radio]:disabled {
//   //   accent-color: #dbdbdb!important;
//   //   background-color:  #dbdbdb!important;
//   //   color:  #dbdbdb!important;
//   // }
//   .radio[disabled] {
//     background-color: #5e5e5e;
// }
// }

.and_or_radio{
  width: 24px!important;
  height: 18px!important;
  margin-top: -6px!important;
  margin-right: 18px!important;
}

