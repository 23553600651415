@import '../../../styles/standard.module.scss';

.new_admission_bgcolor{
    background-color: $bg-color-alert !important;
}
.new_wound_bgcolor{
    background-color: $bg-color-secondary !important;
    color: $black-color !important; 
    
}
.fix_column{
    display: none !important;
}
.rest_columns{
    width: 100% !important;
}
.wound_photo_color{
    background-color: $bg-color-secondary !important;
    color: $black-color !important;  //text-color   
}

.woundListCountSpan{
        border-radius: 14px;
        font-size:12px;
        padding: 0 9px;
        margin-left: 6px;
        display: inline-block;
        background-color: $PrimaryButton-bg;      
        color: $Plain-text;
        border: solid 0.5px $PrimaryButton-bg;
}