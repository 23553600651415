@import '../../../styles/standard.module.scss';
.media_hdg{
    background-color: rgba(80, 95, 121, 0.05);
    color: #505f79;
    font-family: $secondary-font-bold;
    font-size: 18px;
    padding: 11px 20px 11px 20px;
}
.media_btm {
    padding: 20px;
}
.no_shadow{
    box-shadow: none;
}
.specs{
    font-size: 13px;
    color: #777;
    margin-bottom: 20px;
}
.product_document h3{
    text-align: center;
    margin: 25px 0;
    font-size: 18px;
}
.product_document h4{
    font-size: 15px;
    margin: 10px 0 5px 0;
    font-family: $primary-font-bold;
}
.custom_control_label {
    position: relative;
    margin-top: 2px;
    margin-bottom: 0;
    vertical-align: top;
    font-family: $secondary-font-regular;
    font-size: 14px;
    color: $PrimaryColor-text;
    line-height: normal;
}
.custom_control_input.is_invalid~.custom_control_label::before,
.was_validated .custom_control_input:invalid~.custom_control_label::before {
    border-color: $PrimaryColor-text;
}
.custom_control_input.is_valid~.custom_control_label,
.was_validated .custom_control_input:valid~.custom_control_label {
    color: $Error;
}
.custom_control_input.is_valid:checked~.custom_control_label::before,
.was_validated .custom_control_input:valid:checked~.custom_control_label::before {
    border-color: $border-box;
    background-color: $CheckBox-bg;
}
.custom_control_label::after {
    position: absolute;
    top: 9px;
    left: -1.5rem;
    display: block;
    width: 16px;
    height: 16px;
    content: "";
}
.custom_control_label::before {
    border-radius: 50% !important;
    top: 9px;
}
.custom_control_inline {
    height: 22px;
}

.custom_control_inline .custom_control_label {
    font-family: $secondary-font-regular;
    color: $text-color;
    font-size: 14px;
}
.custom_control_input:checked~.custom_control_label::before {
    color: $Plain-text;
    border-color: $border-box;
    background-color: $CheckBox-bg;
    border-radius: 0;
    width: 16px;
    height: 16px;
    top: 9px;
} 
.upload_block{
    width: 100%;
    min-height: 130px;
    padding: 20px;
    background-color: #f6f6f6;
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.btn_custom {
    height: 40px;
    border-radius: 2px;
    font-family: $secondary-font-regular;
    background-color: $PrimaryButton-bg;
    font-size: 18px; 
    margin: 0 10px;
    padding: 0;
}
.btn_small,
.btn_small:hover {
    width: 128px; 
    height: 40px;
    font-family: $secondary-font-regular;
    background-color: $PrimaryButton-bg;
    color: $Plain-text;
    font-size: 14px;
}
.btn_small_cancel,
.btn_small_cancel:hover,
.btn_small_cancel:active {
    width: 128px;
    height: 40px;
    font-family: $secondary-font-regular;
    background-color: $Plain-bg !important;
    border: 1px solid $PrimaryColor-text !important;
    color: $PrimaryColor-text !important;
    font-size: 14px;
}
.uploading_btn{
    background-color: #36b37e !important;
}
.file_upload{
    background-color: #fcfcfc;
    font-size: 14px;
    padding: 5px 10px;
    border: 1px solid #ccc;
    cursor: pointer;
    color: #555;
}
.product_document h6{
    font-size: 13px;
}
.text_area{
    width: 300px; 
    height: 80px;
    font-size: 14px;
    padding: 8px;
}
.alert_fix{
    padding: 10px;
    font-size: 14px;
}
.loader{
    text-align: center;
    img{
        max-width: 50px;
        border-radius: 100%;
    }
}