@import "../../../styles/standard.module.scss";

.error_border {
	border: 1px solid $Error !important;
}
.error_message {
	display: block;
	color: $Error !important;
	padding-top: 4px;
	font-family: $secondary-font-regular !important; 
	font-size:14px !important;
}
//sso error message option
.error_message_sso{
	display: block;
	color: $Error !important;
	padding-top: 4px;
	font-family: $secondary-font-regular !important; 
	font-size:14px !important;
	margin-left: 25%!important;
}

.error_message_sso_token{
	display: block;
	color: $Error !important;
	padding-top: 4px;
	font-family: $secondary-font-regular !important; 
	font-size:14px !important;
	margin-left: 65% !important;
}