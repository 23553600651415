@import '../../../styles/standard.module.scss';
.calendar_right {
    position: absolute;
    right: 10px;
    top: 11px;
    cursor: pointer;
}
.hide{
    display: none;
}
.error_border {
	border: 1px solid $Error !important;
}
.error_message {
	color: $Error;
	padding-top: 4px;
        font-family: $secondary-font-regular;
        font-size:14px;
    position: relative;    
    width: 50%;
}
.error_focus{
    border: 2px solid #444 !important;
    border-radius: 2px !important;
}