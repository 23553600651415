@import '../../../styles/standard.module.scss';

.tab_content_top{
    border-bottom: solid 1px #f2f2f2;
    margin-bottom: 15px;
}
.tab_content_hdg{
    margin-bottom: 0;
    font-family: $secondary-font-regular;
    position: relative;
}
.tab_edit {
    color: $PrimaryColor-text;
    font-family: $secondary-font-regular;
    font-size: 14px;
    position: absolute;
    right: 24px;
    top: 10px;
    cursor: pointer;
    z-index:1000;
}
 
.tab_edit a,
.tab_edit a:hover {
    color: $PrimaryColor-text;
}
.tab_edit img{
    max-width: 20px;
    margin-right:3px;
}
.single_product_block {
    padding: 0 24px;
}
.patient_content {
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width:100%;
}
.col_spec{ 
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0 16px 0 0;
    width: auto;
    display: flex;
}
.basic_input {
    width: 100%;
    height: 40px;
    padding: 0 5px;
    border-radius: 2px;
    border: solid 1px $border-color;
    background-color: $Plain-bg;
}
.label_spec {
    margin-bottom: 0;
    font-family: $secondary-font-bold;
    word-wrap: break-word;
}
.btn_small {
    width: 140px;
    height: 42px;
    font-family: $secondary-font-regular;
    color: $Plain-bg;
    font-size: $Typography-2;
}
.btn_custom {
    height: 42px;
    border-radius: 2px;
    font-family: $secondary-font-regular;
    font-size: $Typography-2;
    padding: 4px;
}
.basic_select {
    width: 100%;
    height: 40px;
    padding: 0 10px;
    border-radius: 2px;
    border: 0;
    background-color: transparent;
    appearance: none;
}
.patient_content .select_wrapper,
.moreinfo_content .select_wrapper {
    height: fit-content;
    border-radius: 2px;
    border: solid 1px $border-color;
 //   background-color: $Plain-bg;
    background: url("../../../static/images/down-arrow.png") right 10px center no-repeat;
    //height: 42px;
}
.display_flex {
    display: flex;
}
.form_span {
    color: #0052cc;
    margin-left: 10px;
    cursor: pointer;
}
.img_left {
    margin-left: 10px;
}
.btn_small,
.btn_small:hover {
    @include btn_primary;
    width: 158px;
    height: 50px;
    font-family: $secondary-font-regular;
    background-color: $PrimaryButton-bg;
    color: $Plain-text;
    font-size: 16px;
    margin-right: 32px;
}
.btn_small_cancel,
.btn_small_cancel:hover {
    width: 158px;
    height: 50px;
    font-family: $secondary-font-regular;
    background-color: $Plain-bg;
    border: 1px solid $bdr-color-tertiary !important;
    color: $PrimaryColor-text;
    font-size: 16px;
}
.custom_modal_size {
    max-width: 967px;
}
.up_down_list {
    float: right;
    width: 100%;
    text-align: right;
    margin-bottom: 0;
}
.input_block {
    width: calc(100% - 36px) !important;
    float: right;
    font-family: $secondary-font-regular;
}

.custom_control_input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 16px;
    height: 16px;
    opacity: 0;
}
.custom_control_input.is_invalid~.custom_control_label::before,
.was_validated .custom_control_input:invalid~.custom_control_label::before {
    border-color: $PrimaryColor-text;
}
.custom_control_input.is_valid~.custom_control_label,
.was_validated .custom_control_input:valid~.custom_control_label {
    color: $Error;
}
.custom_control_input.is_valid:checked~.custom_control_label::before,
.was_validated .custom_control_input:valid:checked~.custom_control_label::before {
    border-color: $border-box;
    background-color: $CheckBox-bg;
}
.custom_control_input:checked~.custom_control_label::before {
    color: $Plain-text;
    border-color: $border-box;
    background-color: $CheckBox-bg;
    border-radius: 0;
    width: 16px;
    height: 16px;
    top: 11px;
} 
.pos_delete_form:first-child {
    left: 5px;
}
.pos_delete_form {
    position: absolute;
    left: 25px;
    cursor: pointer;
    margin-left: 10px;
}
.dropdown_align_add {
    width: auto;
    white-space: nowrap;
}
.dropdown_heading_add {
    font-family: $secondary-font-regular;
    color: $PrimaryColor-text;
    font-size: 16px;
    padding: 8px 16px;
    margin-bottom: 0;
}
.dropdown_heading_add span, .dropdown_heading_add span:hover {
    color: $PrimaryColor-text;
    cursor: pointer;
}
.display_inline_flex {
    display: inline-flex;
}
.singleSelect .col_spec_img .plus_icon {
    display: none;
} 
.singleSelect .col_spec_img:last-child .plus_icon {
    display: block;
}
.multiSelect .col_spec_img .plus_icon {
    display: none;
} 
.multiSelect .col_spec_img:last-child .plus_icon {
    display: block;
} 

.btn_mobile {
    border: 1px solid $bdr-color-tertiary;
    width: auto;
    height: 42px;
    border-radius: 2px;
    background-color: $Plain-bg;
}
.sequence_arrow {
    padding: 5px;
    cursor: pointer;
}
.next_question {
    width: 144px;
    height: 42px;
    margin: 0;
    padding: 9px 16px 9px 9px;
    border-radius: 2px;
    background-color: $PrimaryButton-bg;
    font-family: $secondary-font-regular;
    color: $Plain-text;
    font-size: 16px;
}
.next_question:hover {
    width: 144px;
    height: 42px;
    margin: 0;
    padding: 9px 16px 9px 9px;
    border-radius: 2px;
    background-color: $PrimaryButton-bg;
    font-family: $secondary-font-regular;
    color: $Plain-text;
    font-size: 16px;
}

.bir_subline {
    color: #505f79 !important;
    font-family: $secondary-font-regular !important;
    font-size: 12px !important;
    display: block !important;
  }
  .btn_primary,
.btn_primary:hover,
.btn_primary:active {
  width: 140px;
  background-color: $PrimaryButton-bg !important;
  border: solid 1px $PrimaryButton-bg !important;
  color: $Plain-bg !important;
  border-radius: 2px;
  font-size: $Typography-2;
}
.btn_scondary,
.btn_scondary:hover,
.btn_scondary:active {
  width: 140px;
  background-color: $Body-bg !important;
  border: solid 1px $tertiary-text !important;
  color: $PrimaryColor-text !important;
  border-radius: 2px;
  font-family: $secondary-font-regular;
  font-size: $Typography-2;
}
.errorTissueNull {
    font-weight: 600;
    font-size: 22px;
    margin-top: 10%;
    bottom: 0;
    margin-left: 3%;
  }

  .yes_btn {
    width: 140px;
    border-radius: 0px;
  }
  
  .no_btn {
    width: 140px;
    border-radius: 0px;
    background-color: $Body-bg;
    color: $PrimaryColor-text;
    border: solid 1px $tertiary-text;
  }
  .no_btn:hover {
    background-color: $Body-bg;
    color: $PrimaryColor-text;
    border: solid 1px $tertiary-text;
  }
  .no_btn:active {
    background-color: $Body-bg !important;
    color: $PrimaryColor-text !important;
    border: solid 1px $tertiary-text !important;
  }
  .no_btn:focus {
    background-color: $Body-bg;
    color: $PrimaryColor-text;
    border: solid 1px $tertiary-text;
  }