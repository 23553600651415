@import '../../../styles/standard.module.scss';

.btn_custom {
    height: 50px;
    border-radius: 2px;
    font-family: $secondary-font-regular;
    background-color: $PrimaryButton-bg;
    font-size: 18px; 
    margin: 0 10px;
}
.btn_small,
.btn_small:hover {
    @include btn_primary;
    img{
        margin-right: 10px;
    }    
}
.btn_small_cancel,
.btn_small_cancel:hover,
.btn_small_cancel:active {
    @include btn_secondary;
}
.head{
    padding: 24px 24px 16px;
    margin-bottom: 10px;
    font-family: $secondary-font-regular;
    position: relative;
    border-bottom: 1px solid #eee;
    min-height: 65px;
    > span{
        position: absolute;
        top: 25px;
        right: 25px;
        cursor: pointer;
        font-size: 14px;
        img{
            margin-right: 5px;
        }
    }
    .btn_position{
        position: absolute;
        top: 25px;
        right: 25px;
    }
}
.media_align {
    top: 70px;
    right: 20px;
    left: auto;
    width: 400px;
    padding: 0 0 10px;
    border: solid 1px #e5e5e5;
    z-index: 1600;
}
.media_view{
    margin: 20px 24px;
    ul{
        margin: 0;
        padding: 0;
        margin-bottom: 30px;
        list-style-type: none;
        li{
            margin: 5px 0;
            padding: 5px 0;
            border-bottom: 1px solid #eee;
            &:last-child{
                border-bottom: 0;
            }
            a{
                &:hover{
                    color: #3058db;
                    text-decoration: none;
                    font-weight: bold;
                }
            }
            > img{
                margin-right: 10px;
                max-width: 25px;
            }
            >span{
                margin-left: 10px;
                cursor: pointer;
                &:hover{
                    filter: brightness(70%);
                }
            }
        }
    }
}
.outer_document_view{
    h4{
        font-size: 18px;
    }
    h3{
        font-size: 16px;
    }
}