@import "../../../styles/standard.module.scss";
@import "../../../styles/common.module.scss";
.back_btn a {
    @include response-above(sm) {
      position: fixed;
      top: 22px;
      left: 25px;
      background: url(../../../static/images/back-arrow.png) center left no-repeat;
      text-indent: 999px;
      margin-bottom: 10px;
    }
    @include response-above(lg) {
      position: static;
      top: auto;
      left: auto;
      color: $PrimaryColor-text !important;
      background: url(../../../static/images/left_arrow.png) center left no-repeat;
      padding-left: 10px;
      font-family: $secondary-font-regular;
      font-size: 16px;
      margin-bottom: 10px
    }
  }


  .down_spread{
    color:#0052cc;
    font-family: $secondary-font-bold;
    font-size:16px;
    display: inline-block;
}
.down_spread label a, .down_spread label a:hover{
    color:#0052cc;
    margin-bottom: 0;
    margin-left: 10px;
    text-decoration: none;
}

.loader{
  text-align: center;
  padding: 150px 0;
  img{
      max-width: 50px;
      border-radius: 100%;
  }
}	