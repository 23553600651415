@import '../../../styles/standard.module.scss';

.warning{
    text-align: center;
    margin-bottom: 20px;
    padding: 10px 20px;
    border: 1px solid #ff0000;
    border-radius: 5px;
    display: inline-block;
    h6{
        margin: 0;
        font-size: 14px;
    }
    button{
        border-radius: 3px;
        margin-left: 10px;
        padding: 3px 10px;
        font-size: 12px;
        margin-top: 10px;
        &:nth-child(3){
            color: #fff;
            border: 1px solid $PrimaryButton-bg;
            background-color: $PrimaryButton-bg;
        } 
        &:last-child{
            border: 1px solid #ccc;
            background-color: #fcfcfc;
        }
    }
}